import { ReduxThunk } from "../../type";
import { setBusyAction, setErrorAction } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { AvatarItem } from "../../types/avatar-items";
import { addItems } from "../../slices/avatar-item";

export const fetchAvatarItems =
  (): ReduxThunk =>
  async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({ key: AsyncActions.FetchAvatarItems, value: true })
    );
    try {
      const res = await client.get<AvatarItem[]>(ApiRoutes.AvatarItems);
      dispatch(addItems(res.data));
    } catch (e) {
      dispatch(
        setErrorAction({ key: AsyncActions.FetchAvatarItems, value: "Error" })
      );
    }
    dispatch(
      setBusyAction({ key: AsyncActions.FetchAvatarItems, value: false })
    );
  };
