import { Timer, TimerWrapper } from "./styled";
import { useTournamentsSchedule } from "./useTournamentsSchedule";
import { useTranslation } from "react-i18next";

export const TimerComponent = ({ date }: { date: string }) => {
  const { t } = useTranslation();
  const { isWarningTime, timeToTournamentStart } = useTournamentsSchedule(
    date,
    t("tournaments.finished")
  );

  return (
    <TimerWrapper>
      {`${t("tournaments.timerLabel")}: `}
      <Timer warning={isWarningTime(timeToTournamentStart)}>
        {timeToTournamentStart}
      </Timer>
    </TimerWrapper>
  );
};
