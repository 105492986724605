import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SliceNames } from "../enums/SliceNames";
import { SubjectState } from "../types/subjects";

const initialState: SubjectState = {
  subjects: [],
  lessons: [],
  lesson: {
    video: null,
    author: { file: null, name: null },
    points: 0,
    chapter: "",
    code: "",
    complianceCheck: "",
    description: "",
    hasQuestionsAndAnswers: null,
    id: "",
    keywords: [],
    lessonOrder: 0,
    name: "",
    published: null,
    subject: null,
    quizPoints: 0,
    depository: {
      id: "",
      createdAt: "",
      updatedAt: "",
      deletedAt: null,
      lessonId: "",
      notesDownloadedAt: null,
      tasksDownloadedAt: null,
      solutionsDownloadedAt: null,
      videoWatchedAt: null,
      completedAt: null,
      videoTime: 0,
      learnerId: "",
      status: "",
    },
    quiz: null,
  },
  download: null,
};

export const subjectsSlice = createSlice({
  name: SliceNames.SubjectsSlice,
  initialState,
  reducers: {
    addSubjects: (
      state,
      { payload }: PayloadAction<SubjectState["subjects"]>
    ) => {
      state.subjects = payload;
    },
    addSubjectLessons: (state, { payload }) => {
      state.lessons = payload;
    },
    addLessonInfo: (state, { payload }) => {
      state.lesson = payload;
    },

    downloadAction: (state, { payload }) => {
      state.download = payload;
    },
    editDepository: (state, { payload }) => {
      state.lesson.depository = Object.assign(
        state.lesson.depository as any,
        payload
      );
    },
    removeSubjectState: (state) => {
      state.subjects = initialState.subjects;
      state.lesson = initialState.lesson;
    },
    removeLessons: (state) => {
      state.lessons = initialState.lessons;
    },
    reset: () => {
      return initialState;
    },
  },
});

export const {
  addSubjects,
  addSubjectLessons,
  addLessonInfo,
  downloadAction,
  editDepository,
  removeSubjectState,
  removeLessons,
  reset,
} = subjectsSlice.actions;

export default subjectsSlice.reducer;
