import { AsyncActions } from "../enums/AsyncActions";
import { setBusyAction, setErrorAction, setSuccess } from "../slices/async";
import { ReduxThunk } from "../type";
import i18n from "../../i18n";
import { ApiRoutes } from "../../common/enums/ApiRoutes";

export const resendOTP: () => ReduxThunk = () => {
  return async (dispatch, _, { client }) => {
    try {
      dispatch(
        setBusyAction({ key: AsyncActions.ResendOTPAction, value: true })
      );
      await client.get(ApiRoutes.ResendOTP);
      dispatch(setSuccess({ key: AsyncActions.ResendOTPAction, value: true }));
    } catch (err) {
      dispatch(
        setErrorAction({
          key: AsyncActions.ResendOTPAction,
          value: i18n.t("response.resendOTP"),
        })
      );
    }
    dispatch(
      setBusyAction({ key: AsyncActions.ResendOTPAction, value: false })
    );
  };
};
