import { Id, toast } from "react-toastify";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import i18n from "../../../i18n";
import { AsyncActions } from "../../enums/AsyncActions";
import { setBusyAction } from "../../slices/async";
import { setPaymentHistory } from "../../slices/paymentHistory";
import { ReduxThunk } from "../../type";
import { PaymentHistory } from "../../types/paymentHistory";

export const fetchPaymentHistory = (): ReduxThunk => {
  return async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({ key: AsyncActions.FetchPaymentHistory, value: true })
    );
    try {
      const res = await client.get<PaymentHistory[]>(ApiRoutes.PaymentHistory);
      dispatch(setPaymentHistory(res.data));
    } catch (error) {
      toast.error(i18n.t("response.errorFetchingPaymentHistory"), {
        position: toast.POSITION.TOP_CENTER,
        toastId: i18n.t("response.errorFetchingPaymentHistory") as Id,
        progress: undefined,
      });
    }

    dispatch(
      setBusyAction({ key: AsyncActions.FetchPaymentHistory, value: false })
    );
  };
};
