import tw, { styled } from "twin.macro";
import { SectionTitle, SectionWrapper } from "../LearnerHomeStyled";

export const EventsWrapper = styled(SectionWrapper)(() => tw`px-8 lg:px-8`);
export const Title = styled(SectionTitle)(() => tw`mb-4 md:mb-8`);

export const Description = styled.h5(
  () => tw`text-base text-black font-dyslexic mb-2 md:mb-4`
);

export const Wrapper = styled.div(() => tw`w-full`);
