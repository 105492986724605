import * as yup from "yup";
import i18n from "../../i18n";

export const editInfoSchema = () => {
  return yup
    .object({
      firstName: yup
        .string()
        .required(
          i18n.t(`validation.required`, {
            fieldName: i18n.t("fieldNames.firstName"),
          })
        )
        .min(
          2,
          i18n.t("validation.min", {
            fieldName: i18n.t("fieldNames.firstName"),
            suffix: "a",
            length: 2,
          })
        )
        .matches(/^\p{L}+'*\s*'*\p{L}+$/u, i18n.t("validation.alphabet"))
        .max(
          64,
          i18n.t(`validation.max`, {
            fieldName: i18n.t("fieldNames.firstName"),
          })
        ),
      lastName: yup
        .string()
        .required(
          i18n.t(`validation.required`, {
            fieldName: i18n.t("fieldNames.lastName"),
          })
        )
        .min(
          2,
          i18n.t("validation.min", {
            fieldName: i18n.t("fieldNames.lastName"),
            suffix: "a",
            length: 2,
          })
        )
        .matches(/^\p{L}+'*\s*'*\p{L}+$/u, i18n.t("validation.alphabet"))
        .max(
          64,
          i18n.t(`validation.max`, { fieldName: i18n.t("fieldNames.lastName") })
        ),
    })
    .required();
};
