import tw, { css, styled } from "twin.macro";

export const SpinnerWrapperStyled = styled.div<{
  fullscreen?: boolean;
  blur?: boolean;
}>(({ fullscreen, blur }) => [
  tw`flex items-center justify-center z-[100] backdrop-blur-sm absolute w-full h-full`,
  css`
    position: ${fullscreen ? "fixed" : "absolute"};
    bottom: 0;
    left: 0;
    backdrop-filter: ${blur ? "blur(0.2rem)" : "none"};
    color: red;
  `,
]);
