import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import Button from "../../../common/components/Button/Button";
import { StyledError } from "../../../common/components/Error/Error";
import Form from "../../../common/components/Form/Form";
import { avatarValidationSchema } from "../../../views/validation/profileSetupValidationSchema";
import { ButtonDiv, Heading, SubmitDiv } from "../ProfileSetupStyled";
import { Wrapper } from "./AvatarTypeStyled";
import { Slider } from "./Slider/Slider";
import { fetchAvatars } from "../../../store/thunks/profileSetup/fetchAvatars";
import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { Spinner } from "../../../common/components/Spinner/Spinner";
import { StoreState } from "../../../store/type";
import { removeAvatars } from "../../../store/slices/avatars";
import { reset, setSuccess } from "../../../store/slices/async";
import { SubmitModal } from "./SubmitModal/SubmitModal";
import { createFreeItems } from "../../../store/thunks/Learner/createFreeItems";

export const AvatarType = ({
  setCurrentStep,
}: {
  setCurrentStep: (val: number) => void;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentAvatar = useSelector(
    (state: StoreState) => state.user.info.avatar
  );
  const { avatar } = useSelector((state: StoreState) => state.avatars);

  const [loadingState, setLoadingState] = useState<boolean>(true);
  const [cannotChangeError, setCannotChangeError] = useState<boolean>(false);
  const [submitModalOpen, setSubmitModalOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>(null);

  const { requestInProgress: patchInProgress, success: patchSuccess } =
    useAsyncState(AsyncActions.PatchAvatar);
  const { requestInProgress: fetchAvatarsRequest, error: fetchAvatarsError } =
    useAsyncState(AsyncActions.FetchAvatars);
  const { requestInProgress: creatingItemsInProgress } = useAsyncState(
    AsyncActions.CreateFreeItems
  );
  const { register, formState, watch, handleSubmit } = useForm({
    mode: "onTouched",
    resolver: yupResolver(avatarValidationSchema()),
    defaultValues: { avatar: currentAvatar?.id },
  });
  const avatarWatch = watch("avatar");

  const onNextPressHandler: SubmitHandler<FieldValues> = (data) => {
    setCannotChangeError(false);
    if (currentAvatar) {
      return dispatch(
        setSuccess({ key: AsyncActions.PatchAvatar, value: true })
      );
    }
    setSubmitModalOpen(true);
    setFormData(data);
  };

  const onBackPressHandler = () => {
    setCannotChangeError(false);
    setCurrentStep(3);
  };

  useEffect(() => {
    dispatch(fetchAvatars());
    setLoadingState(false);
    return () => {
      dispatch(removeAvatars());
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    dispatch(createFreeItems());
  }, []);

  useEffect(() => {
    if (patchSuccess) {
      setCurrentStep(5);
    }
  }, [patchSuccess]);
  if (fetchAvatarsError) {
    return (
      <StyledError centered topMargin="auto" bottomMargin="auto">
        {fetchAvatarsError}
      </StyledError>
    );
  }
  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit(onNextPressHandler)}>
        <Wrapper>
          {loadingState || fetchAvatarsRequest || creatingItemsInProgress ? (
            <Spinner fullscreen size="normal" />
          ) : (
            <React.Fragment>
              <Heading>{t("profileSetup.avatarType.heading")}</Heading>
              <Slider
                setError={setCannotChangeError}
                avatarWatch={avatarWatch}
                register={register}
                avatars={avatar}
              />
              <SubmitDiv>
                {formState.errors?.avatar && (
                  <StyledError centered topMargin="0" bottomMargin="0">
                    {formState.errors.avatar.message}
                  </StyledError>
                )}

                {cannotChangeError && (
                  <StyledError centered topMargin="0" bottomMargin="0">
                    {t("profileSetup.avatarType.cannotSelect")}
                  </StyledError>
                )}
                <ButtonDiv>
                  <Button
                    resPending={patchInProgress}
                    type="button"
                    onClick={onBackPressHandler}
                    invert
                    buttonWidth="100%"
                    buttonHeight="2.5rem"
                  >
                    &larr; {t("profileSetup.backBtn")}
                  </Button>
                  <Button
                    resPending={patchInProgress}
                    invert={false}
                    buttonWidth="100%"
                    buttonHeight="2.5rem"
                  >
                    {t("profileSetup.nextBtn")} &rarr;
                  </Button>
                </ButtonDiv>
              </SubmitDiv>
            </React.Fragment>
          )}
        </Wrapper>
      </Form>
      {submitModalOpen && (
        <SubmitModal
          data={formData}
          setModalOpen={setSubmitModalOpen}
          setFormData={setFormData}
        />
      )}
    </React.Fragment>
  );
};
