import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LearnerItem, StripeSubscription, UserState } from "../types/user";
import { SliceNames } from "../enums/SliceNames";
import { REFUND_ITEM_FACTOR } from "../../common/consts/itemRefundFactor";

const initialState: UserState = {
  isLoggedIn: false,
  dataFetched: false,
  info: {
    firstName: null,
    lastName: null,
    username: null,
    role: null,
    emailConfirmed: null,
    cid: null,
    address: null,
    postalCode: null,
    city: null,
    createdAt: null,
    updatedAt: null,
    learnerLimit: null,
    totalPoints: 0,
    availablePoints: 0,
    pointsBySubject: {},
    tournamentPointsBySubject: {},
    tournamentPoints: 0,
    favoriteSubjects: null,
    traits: null,
    activeSubscription: null,
    tutorialSeen: null,
    items: [],
    stripeSubscriptions: [],
    deletedAvatarShowed: false,
  },
};

export const userSlice = createSlice({
  name: SliceNames.UserSlice,
  initialState,
  reducers: {
    logIn: (state) => {
      state.isLoggedIn = true;
    },
    setUserInfo: (
      state,
      { payload }: PayloadAction<Partial<UserState["info"]>>
    ) => {
      state.info = { ...state.info, ...payload };
      if (!state.isLoggedIn) {
        state.isLoggedIn = true;
      }
      if (!state.dataFetched) {
        state.dataFetched = true;
      }
    },
    dataFetchingFailed: (state) => {
      state.dataFetched = true;
    },
    addAvatarItem: (state, { payload }: PayloadAction<LearnerItem>) => {
      state.info.availablePoints -= payload.avatarItem.points;
      state.info.items.push(payload);
    },
    addAvatarItems: (state, { payload }: PayloadAction<LearnerItem[]>) => {
      state.info.items = state.info.items.concat(payload);
    },
    changeItemStatus: (state, { payload }: PayloadAction<LearnerItem[]>) => {
      state.info.items = state.info.items
        .filter(
          (i) => !payload.find((itemToUpdate) => itemToUpdate.id === i.id)
        )
        .concat(payload);
    },
    removeItem: (
      state,
      { payload }: PayloadAction<{ id: string; points: number }>
    ) => {
      state.info.availablePoints += Math.floor(
        payload.points * REFUND_ITEM_FACTOR
      );
      state.info.items = state.info.items.filter((i) => i.id !== payload.id);
    },
    setStripeSubscriptions: (
      state,
      { payload }: PayloadAction<StripeSubscription[]>
    ) => {
      state.info.stripeSubscriptions = payload;
    },
    deleteStripeSubscriptions: (state, { payload }: PayloadAction<string>) => {
      state.info.stripeSubscriptions = state.info.stripeSubscriptions.filter(
        ({ stripeSubscriptionId }) => stripeSubscriptionId !== payload
      );
    },
    setDeletedAvatarShowed: (state) => {
      state.info.deletedAvatarShowed = true;
    },
    reset: () => {
      return { ...initialState, dataFetched: true };
    },
  },
});

export const {
  logIn,
  setUserInfo,
  dataFetchingFailed,
  reset,
  addAvatarItem,
  changeItemStatus,
  removeItem,
  setStripeSubscriptions,
  deleteStripeSubscriptions,
  addAvatarItems,
  setDeletedAvatarShowed,
} = userSlice.actions;

export default userSlice.reducer;
