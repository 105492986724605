export const formatDate = (dateString: string | undefined): string => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dateFormat: any = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  const defaultLocale = "en-US";

  const d = dateString
    ? new Date(dateString).toLocaleString(defaultLocale, dateFormat)
    : "Not Available";
  return d === "Invalid Date" ? "N/A" : d;
};
