import { CloseIcon, CloseIconWrapper } from "./ModalStyled";
import closeIcon from "../../../assets/common/close.svg";

type Props = {
  onClose: () => void;
};

export const CloseModal = ({ onClose }: Props) => {
  return (
    <CloseIconWrapper onClick={onClose}>
      <CloseIcon src={closeIcon} />
    </CloseIconWrapper>
  );
};
