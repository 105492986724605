import { Tournament } from "../../../store/types/tournament";
import dayjs from "../../../common/config/dayjs";

export const filteredEventProps = [
  {
    label: "ongoing",
    filter: ({ startsAt }: Tournament) =>
      dayjs(startsAt).subtract(2, "minutes").isBefore(new Date()),
    sorter: (e1: Tournament, e2: Tournament) => {
      if (dayjs().isAfter(e1.endsAt)) {
        return 1;
      }
      if (dayjs().isAfter(e2.endsAt)) {
        return -1;
      }
      return dayjs(e1.startsAt).diff(e2.startsAt);
    },
  },
  {
    label: "upcoming",
    filter: ({ startsAt }: Tournament) =>
      dayjs(startsAt).subtract(2, "minutes").isAfter(new Date()),
    sorter: (e1: Tournament, e2: Tournament) =>
      dayjs(e1.startsAt).diff(e2.startsAt),
  },
] as const;

export type FilteredEventProps = typeof filteredEventProps[number];
