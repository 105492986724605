import { Depository } from "../../store/types/lessons";
import { sum } from "lodash/fp";

export const calculateProgress = (
  depository: Depository | undefined
): number => {
  let tasks = 0;
  if (!depository) return tasks;

  if (depository?.videoWatchedAt) {
    tasks += 1;
  }
  if (depository?.notesDownloadedAt) {
    tasks += 1;
  }
  if (depository?.solutionsDownloadedAt) {
    tasks += 1;
  }

  if (depository?.tasksDownloadedAt) {
    tasks += 1;
  }
  return (tasks / 4) * 100;
};

export const calculateEarnedPoints = (
  points: number,
  depository: Depository | undefined
) => {
  const earnedPoints = {
    video: 0,
    notes: 0,
    solutions: 0,
    tasks: 0,
  };
  if (depository?.videoWatchedAt) {
    earnedPoints.video += Math.floor(points * 0.52);
  }
  if (depository?.notesDownloadedAt) {
    earnedPoints.notes += Math.floor(points * 0.24);
  }
  if (depository?.solutionsDownloadedAt) {
    earnedPoints.solutions += Math.floor(points * 0.12);
  }
  if (depository?.tasksDownloadedAt) {
    earnedPoints.tasks += Math.floor(points * 0.12);
  }
  const total = sum(Object.values(earnedPoints));
  return { total, ...earnedPoints };
};
