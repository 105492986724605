import {
  Label,
  IconTextWrapper,
  Icon,
  Text,
  ResultComponentWrapper,
} from "./styled";

type Props = {
  icon: string;
  result: number;
  label: string;
  hide: boolean;
};

export const ResultComponent = ({ icon, result, label, hide }: Props) => {
  return (
    <ResultComponentWrapper hide={hide}>
      <IconTextWrapper>
        <Icon src={icon} alt={"Question mark"} />
        <Text>{result}</Text>
      </IconTextWrapper>
      <Label>{label}</Label>
    </ResultComponentWrapper>
  );
};
