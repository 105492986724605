enum CodeError {
  InvalidCode = "InvalidCode",
  CodeSubscriptionsMismatch = "CodeSubscriptionsMismatch",
}

const mapCodeError = (error: string): string => {
  switch (error) {
    case CodeError.InvalidCode:
      return "Napačna koda";
    case CodeError.CodeSubscriptionsMismatch:
      return "Vnesli ste napačno kodo. Preverite izbrano vrsto naročnine.";
    default:
      return "Napaka";
  }
};

export default mapCodeError;
