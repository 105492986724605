import tw, { styled } from "twin.macro";

export const Wrapper = styled.div(
  () => tw`w-full relative bg-white rounded-lg min-h-[20rem] pb-6 mb-6`
);

export const ContentWrapper = styled.div(() => tw`w-full flex flex-col p-6`);

export const Heading = styled.h2(() => tw`font-bold text-[1.25rem]`);

export const Category = styled.h5(
  () => tw`font-bold font-sans text-gold mb-4 mt-6`
);

export const TutorialTitle = styled(Category)(() => tw`py-4`);

export const ContactParagraph = styled.p(
  () => tw`font-medium text-sm font-sans text-black`
);

export const ContactSpan = styled.span(() => tw`text-dark-blue font-sans`);
