import tw, { styled } from "twin.macro";

export const LoadingDotWrapper = styled.div(() => tw`flex gap-x-1`);

export const LoadingDot = styled.div<{ delay: "none" | "1" | "2" }>(
  ({ delay }) => [
    tw`w-3 h-3 bg-gray rounded-full`,
    delay === "none" && tw`animate-bounce`,
    delay === "1" && tw`animate-bounce-delayed`,
    delay === "2" && tw`animate-bounce-even-more-delayed`,
  ]
);
