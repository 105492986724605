import tw, { styled } from "twin.macro";

export const QuestionWrapper = styled.div(
  () => tw`p-4 w-full border border-light-gray-secondary flex flex-col mb-2`
);

export const ExpandButton = styled.div(() => tw`w-full cursor-pointer`);

export const ExpandDiv = styled.div(() => tw`flex items-center w-full`);

export const QuestionBodyDiv = styled.div(
  () => tw`max-w-[15rem] sm:max-w-[unset] mr-8`
);

export const QuestionBody = styled.span(
  () => tw`font-sans font-medium text-black`
);

export const PlusButton = styled.button(
  () => tw`font-medium text-3xl text-dark-blue font-sans ml-auto`
);

export const AnswerDiv = styled.div(() => tw`w-full mt-8`);

export const Answer = styled.p(() => tw`font-sans text-sm text-black`);
