import Tooltip from "rc-tooltip";
import { Icon, TooltipOverlayWrapper } from "./SubjectStyled";
import "rc-tooltip/assets/bootstrap.css";

type Props = {
  icon: string;
  points: number;
};

const Overlay = ({ points, icon }: Props) => {
  return (
    <TooltipOverlayWrapper>
      <Icon src={icon} /> {points}
    </TooltipOverlayWrapper>
  );
};

export const PointsTooltip = ({ icon, points }: Props) => {
  return (
    <Tooltip
      placement={"top"}
      trigger={["hover"]}
      overlay={<Overlay points={points} icon={icon} />}
      mouseEnterDelay={0.3}
    >
      <Icon src={icon} />
    </Tooltip>
  );
};
