import tw, { styled, css } from "twin.macro";
import ReactPlayer from "react-player";

export const VideoDiv = styled.div<{ hideTimeline: boolean }>(
  ({ hideTimeline }) => [
    tw`w-full flex items-center justify-center py-7 lg:px-12 border-b border-gold`,
    hideTimeline &&
      css`
        video::-webkit-media-controls-timeline {
          display: none;
        }
      `,
  ]
);

export const Video = styled(ReactPlayer)(() => [
  tw`w-full max-w-[46.68rem] min-w-[18.75rem] min-h-[17rem] cursor-pointer`,
]);

export const PopupParagraphStyled = styled.p(
  () => tw`font-sans text-black-secondary opacity-60 text-sm text-center mb-8`
);

export const PopupBtnDiv = styled.div(
  () =>
    tw`flex flex-col space-y-8 md:space-y-0 md:flex-row md:space-x-2 w-full max-w-[26.875rem] items-center`
);

export const PopupWrapper = styled.div(
  () => tw`flex flex-col p-9 items-center w-full`
);

export const ContentWrapper = styled.div(
  () => tw`flex flex-col w-full px-4 sm:px-12 lg:px-56 items-center`
);
