import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Card from "../../common/components/Card/Card";
import StepComponent from "../../common/components/StepComponent/StepComponent";

import BaseInfo from "./BaseInfo";
import { OTP } from "./OTP/OTP";
import { Payment } from "./Payment/Payment";
import { AddLearners } from "./AddLearners/AddLearners";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/type";
import { Spinner } from "../../common/components/Spinner/Spinner";
import { PaymentSuccess } from "./PaymentSuccess/PaymentSuccess";
import { LearnerPins } from "./LearnerPins/LearnerPins";
import { useAsyncState } from "../../common/hooks/useAsyncState";
import { AsyncActions } from "../../store/enums/AsyncActions";

const Register = () => {
  const { t } = useTranslation();

  const learners = useSelector((state: StoreState) => state.learners);
  const { notification: addLearnersNotification } = useAsyncState(
    AsyncActions.AddLearnersAction
  );
  const subscriptions = useSelector((state: StoreState) => state.subscriptions);

  const [current, setCurrent] = useState(0);

  const {
    isLoggedIn,
    info: { emailConfirmed },
  } = useSelector((state: StoreState) => state.user);

  useEffect(() => {
    if (!isLoggedIn) {
      setCurrent(1);
    } else if (emailConfirmed === null && isLoggedIn) {
      setCurrent(1);
    } else if (emailConfirmed === false) {
      setCurrent(2);
    } else if (!learners || !(learners.length > 0) || addLearnersNotification) {
      setCurrent(3);
    } else if (!subscriptions || !(subscriptions.length > 0)) {
      setCurrent(4);
    } else {
      setCurrent(5);
    }
  }, [
    emailConfirmed,
    subscriptions,
    learners,
    isLoggedIn,
    addLearnersNotification,
  ]);

  let visibleTab;
  switch (current) {
    case 1:
      visibleTab = <BaseInfo onPopupClose={() => setCurrent(2)} />;
      break;
    case 2:
      visibleTab = (
        <OTP
          onReturnToInfo={() => setCurrent(1)}
          onPopupClose={() => setCurrent(3)}
        />
      );
      break;
    case 3:
      visibleTab = <AddLearners />;
      break;
    case 4:
      visibleTab = <Payment />;
      break;
    case 5:
      visibleTab = (
        <PaymentSuccess
          onContinue={() => {
            setCurrent(6);
          }}
        />
      );
      break;
    case 6:
      visibleTab = <LearnerPins />;
      break;
    default:
      visibleTab = null;
  }

  if (current === 0) {
    return <Spinner blur />;
  }

  return (
    <div
      className={`flex box-border justify-center items-center py-6 px-4 sm:px-10 lg:px-60 bg-cover min-h-app bg-auth`}
    >
      <Card
        center={true}
        paddingX={
          current === 3 || current === 4 || current === 6
            ? "2.208rem"
            : "6.43rem"
        }
        paddingY="2rem"
        cardWidth="100%"
      >
        <StepComponent
          current={current}
          total={6}
          titles={t("register.steps.titles", { returnObjects: true })}
        />
        <div className="w-full flex flex-col items-center justify-center">
          {visibleTab}
        </div>
      </Card>
    </div>
  );
};

export default Register;
