import {
  GridItemDiv,
  TitleStyled,
  InfoStyled,
  ColonStyled,
} from "./InfoItemStyled";
export const InfoItem = (props: {
  title: string;
  value: string | undefined | null;
}) => {
  return (
    <GridItemDiv>
      <TitleStyled>{props.title}</TitleStyled>
      <ColonStyled>:</ColonStyled>
      <InfoStyled>{props.value}</InfoStyled>
    </GridItemDiv>
  );
};
