import { useTranslation } from "react-i18next";
import { Action } from "./styled";
import { useNavigate } from "react-router-dom";
import { getRoute } from "../../utils/getRoute";
import { RoutePath } from "../../consts/route-path";
import { env } from "../../config/env";

export const ViewTournamentResult = ({ id }: { id: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToResult = () => {
    navigate(
      getRoute(
        {
          id,
        },
        RoutePath.tournaments.locale[env.locale],
        RoutePath.tournaments.sub.result.locale[env.locale]
      )
    );
  };

  return (
    <Action onClick={goToResult} invert>
      {t("tournaments.pastTournamentDetails.seeResults")}
    </Action>
  );
};
