import tutorialChild from "../../assets/videos/tutorial_child_si.mp4";
import tutorialParent from "../../assets/videos/tutorial_parent_si.mp4";

export default {
  parent: {
    si: tutorialParent,
    en: tutorialParent,
    sr: tutorialParent,
  },
  child: {
    si: tutorialChild,
    en: tutorialChild,
    sr: tutorialChild,
  },
};
