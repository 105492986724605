import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SliceNames } from "../enums/SliceNames";
import { School, SchoolState, Classroom } from "../types/school";

const initialState: SchoolState = {
  schools: [],
  classrooms: [],
};

export const schoolSlice = createSlice({
  name: SliceNames.SchoolSlice,
  initialState,
  reducers: {
    setSchools: (state, { payload }: PayloadAction<School[]>) => {
      state.schools = payload;
    },
    setClassrooms: (state, { payload }: PayloadAction<Classroom[]>) => {
      state.classrooms = payload;
    },
    reset: () => {
      return initialState;
    },
  },
});

export const { setSchools, setClassrooms, reset } = schoolSlice.actions;

export default schoolSlice.reducer;
