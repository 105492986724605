import { useTranslation } from "react-i18next";

import successImage from "../../../assets/common/Success.svg";

import { SuccessWrapper, SuccessImg } from "./PaymentSuccessStyled";
import { HeadingStyled } from "../../../common/components/Register/RegisterHeading";
import { ParagraphStyled } from "./PaymentSuccessStyled";
import Button from "../../../common/components/Button/Button";

export const PaymentSuccess = ({ onContinue }: { onContinue: () => void }) => {
  const { t } = useTranslation();
  return (
    <SuccessWrapper>
      <SuccessImg src={successImage} />
      <HeadingStyled>{t("register.paymentSuccess.heading")}</HeadingStyled>
      <ParagraphStyled>
        {t("register.paymentSuccess.paragraph")}
      </ParagraphStyled>
      <Button onClick={onContinue} bottomMargin="8.17rem" invert={false}>
        {t("register.paymentSuccess.button")}
      </Button>
    </SuccessWrapper>
  );
};
