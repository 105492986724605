import { useTranslation } from "react-i18next";
import {
  ShopTabBtn,
  ShotTabBtnWrapper,
  ShopTabBtnLabel,
  ShopTabBtnIcon,
} from "./styled";
import { itemCategories, OnShopTabSelect } from "./shared";
import { ShopTab } from "../../../common/types/ShopTabs";

type PaneProps = {
  activeKey: ShopTab;
  onTabSelect: OnShopTabSelect;
};

export const ShopTabsComponent = ({ activeKey, onTabSelect }: PaneProps) => {
  const { t } = useTranslation();
  return (
    <ShotTabBtnWrapper>
      {itemCategories.map(({ label, icon }) => (
        <ShopTabBtn
          key={label}
          $active={activeKey === label}
          onClick={() => onTabSelect(label)}
        >
          <ShopTabBtnLabel>{t(`shop.${label}` as any)}</ShopTabBtnLabel>
          <ShopTabBtnIcon
            alt={label}
            key={label}
            src={icon}
            onClick={() => onTabSelect(label)}
          />
        </ShopTabBtn>
      ))}
    </ShotTabBtnWrapper>
  );
};
