import { WrapperDiv } from "./InfoStyled";
import { LessonInfo } from "./LessonInfo/LessonInfo";
import { LessonResources } from "./LessonResources/LessonResources";

export const Info = () => {
  return (
    <WrapperDiv>
      <LessonInfo />
      <LessonResources />
    </WrapperDiv>
  );
};
