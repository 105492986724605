import { ReactStripeElements } from "react-stripe-elements";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import i18n from "../../../i18n";
import { AsyncActions } from "../../enums/AsyncActions";
import { setBusyAction, setErrorAction, setSuccess } from "../../slices/async";
import { ReduxThunk } from "../../type";
import { getError } from "./createSubscriptionTask";

export const postPaymentMethodTask: (
  form: { nameOnCard: string; email: string },
  stripe: ReactStripeElements.StripeProps | null,
  elements: stripe.elements.Elements | null
) => ReduxThunk = (form, stripe, elements) => {
  return async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({ key: AsyncActions.PostPaymentMethod, value: true })
    );
    try {
      if (!stripe || !elements) {
        dispatch(
          setErrorAction({
            key: AsyncActions.PostPaymentMethod,
            value: i18n.t(
              "register.payment.byCard.serverError.externalStripeError"
            ),
          })
        );
        dispatch(
          setBusyAction({ key: AsyncActions.PostPaymentMethod, value: false })
        );
        return;
      }
      const card = elements.getElement("cardNumber");
      if (!card) {
        return;
      }
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card,
        billing_details: {
          name: form.nameOnCard,
          email: form.email,
        },
      });
      if (!paymentMethod)
        throw i18n.t("register.payment.byCard.serverError.cardError");
      const paymentMethodId = paymentMethod?.id;

      await client.post(ApiRoutes.AddPaymentMethod, {
        paymentMethodId,
      });
      dispatch(
        setSuccess({ key: AsyncActions.PostPaymentMethod, value: true })
      );
    } catch (err: any) {
      if (
        err.response?.data?.message &&
        typeof err.response?.data?.message === "string"
      ) {
        dispatch(
          setErrorAction({
            key: AsyncActions.PostPaymentMethod,
            value: getError(err.response.data.message),
          })
        );
      } else if (
        err.response?.data?.message &&
        Array.isArray(err.response?.data?.message)
      ) {
        dispatch(
          setErrorAction({
            key: AsyncActions.PostPaymentMethod,
            value: getError(err.response.data.message),
          })
        );
      } else {
        dispatch(
          setErrorAction({
            key: AsyncActions.PostPaymentMethod,
            value: getError(err),
          })
        );
      }
    }
    dispatch(
      setBusyAction({ key: AsyncActions.PostPaymentMethod, value: false })
    );
  };
};
