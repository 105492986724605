import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { OptionSelectedTitle } from "./OptionSelectedTitle";
import pencil from "../../assets/common/assistant/pencil.svg";
import { useTranslation } from "react-i18next";
import { useAsyncState } from "../../common/hooks/useAsyncState";
import { AsyncActions } from "../../store/enums/AsyncActions";
import {
  LoadingWrapper,
  QnAWrapper,
  NextQuestionBtn,
  Question,
  Answer,
} from "./styled";
import { AnimatedDots } from "../../common/components/AnimatedDots/AnimatedDots";
import { StoreState } from "../../store/type";
import { NoResultComponent } from "./NoResult";

type Step = {
  index: number;
  subStep: "question" | "answer";
};

const getActionLabel = (step: Step, maxIndex: number) => {
  if (step.index >= maxIndex && step.subStep === "answer") {
    return "restart";
  }
  return getNextSubstep(step.subStep);
};

const getNextSubstep = (subStep: "question" | "answer") =>
  subStep === "question" ? "answer" : "question";

export const QnA = () => {
  const [step, setStep] = useState<Step>({ index: 1, subStep: "question" });
  const { t } = useTranslation();
  const { requestInProgress } = useAsyncState(AsyncActions.Assistant);
  const generatedQuestions = useSelector(
    (state: StoreState) => state.assistant.generatedQnA
  );

  const handleNextStep = () => {
    setStep((pre) => ({
      index:
        pre.subStep === "question"
          ? pre.index
          : generatedQuestions.length === pre.index
          ? 1
          : pre.index + 1,
      subStep: getNextSubstep(pre.subStep),
    }));
  };

  return (
    <>
      <OptionSelectedTitle
        icon={pencil}
        text={t("assistant.dialog.options.practice")}
      />
      {requestInProgress && generatedQuestions.length > 0 ? (
        <LoadingWrapper>
          <AnimatedDots />
        </LoadingWrapper>
      ) : generatedQuestions.length === 0 ? (
        <NoResultComponent />
      ) : (
        <QnAWrapper>
          {generatedQuestions
            .slice(0, step.index)
            .map(({ question, answer }, i) => (
              <Fragment key={i}>
                <Question>{question}</Question>
                {(step.subStep === "answer" || i < step.index - 1) && (
                  <Answer>{answer}</Answer>
                )}
              </Fragment>
            ))}
          <NextQuestionBtn invert onClick={handleNextStep}>
            {t(
              `assistant.dialog.qna.${getActionLabel(
                step,
                generatedQuestions.length
              )}`
            )}
          </NextQuestionBtn>
        </QnAWrapper>
      )}
    </>
  );
};
