import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { env } from "../../common/config/env";
import { RoutePath } from "../../common/consts/route-path";
import { LearnerHome } from "../../components/LearnerHome/LearnerHome";
import { redirect } from "../../store/slices/router";
import { StoreState } from "../../store/type";

const LearnerHomePage = () => {
  const dispatch = useDispatch();
  const { lastVisit } = useSelector((state: StoreState) => state.user.info);
  useEffect(() => {
    if (!lastVisit) {
      dispatch(redirect({ to: RoutePath.learnerSetup.locale[env.locale] }));
    }
  }, [lastVisit]);
  return <LearnerHome />;
};

export default LearnerHomePage;
