import { useRef } from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player";
import { Video, Wrapper } from "./styled";
import { Spinner } from "../Spinner/Spinner";

type Props = ReactPlayerProps & {
  hideTimeline?: boolean;
  showSpinner?: boolean;
};

export const VideoPlayer = ({
  hideTimeline,
  showSpinner,
  playing,
  url,
  ...rest
}: Props) => {
  const videoRef = useRef<ReactPlayer | null>(null);
  return (
    <Wrapper hideTimeline={!!hideTimeline}>
      {showSpinner ? (
        <Spinner />
      ) : (
        <Video
          ref={videoRef}
          controls
          onReady={() => {
            if (playing) {
              videoRef.current?.getInternalPlayer().play();
            }
          }}
          config={{
            file: {
              attributes: {
                controlsList: "nodownload noplaybackrate",
                disablePictureInPicture: true,
              },
            },
          }}
          onContextMenu={(e: Event) => e.preventDefault()}
          width="100%"
          height="100%"
          url={url ? url : undefined}
          volume={0.4}
          {...rest}
        />
      )}
    </Wrapper>
  );
};
