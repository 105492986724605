import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import popupImage from "../../assets/common/Frame.svg";

import { BaseInfoForm } from "../../common/types/BaseInfoForm";

import { baseInfoSchema } from "../../views/validation/registerValidationSchema";

import { registerBaseInfo } from "../../store/thunks/register";
import { useAsyncState } from "../../common/hooks/useAsyncState";
import { AsyncActions } from "../../store/enums/AsyncActions";

import { Modal } from "../../common/components/Modal/Modal";
import { StyledError } from "../../common/components/Error/Error";
import { LabelStyled } from "../../common/components/Label/Label";
import { HeadingStyled } from "../../common/components/Register/RegisterHeading";
import { ParagraphStyled } from "../../common/components/Register/RegisterParagraph";
import Form from "../../common/components/Form/Form";
import Input from "../../common/components/Input/Input";
import Button from "../../common/components/Button/Button";
import { omit } from "ramda";
import {
  PopupCloseStyled,
  PopupHeadingStyled,
  PopupImageStyled,
} from "../../common/components/Modal/ModalStyled";
import { logIn, setUserInfo } from "../../store/slices/user";
import { ModalWrapper } from "./ModalStyled";
import { UserRole } from "../../common/enums/UserRole";

type InfoForm = BaseInfoForm & {
  passwordConfirm: string;
  terms: boolean;
  subscribeToNotifications: boolean;
};

type Props = {
  onPopupClose: () => void;
};

const BaseInfo = ({ onPopupClose }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const { error, requestInProgress, notification, clearNotification } =
    useAsyncState(AsyncActions.RegisterAction);
  const dispatch = useDispatch();
  const { register, handleSubmit, formState, getValues } = useForm<InfoForm>({
    mode: "onTouched",
    resolver: yupResolver(baseInfoSchema()),
  });

  const onSubmitHandler = (data: InfoForm) => {
    dispatch(registerBaseInfo(omit(["passwordConfirm", "terms"], data)));
  };

  useEffect(() => {
    if (notification) {
      setModalOpen(true);
    }
  }, [notification]);

  const handleCloseModal = () => {
    dispatch(
      setUserInfo({
        ...getValues(),
        role: UserRole.Sponsor,
        emailConfirmed: false,
      })
    );
    dispatch(logIn());
    clearNotification();
    onPopupClose();
  };

  return (
    <React.Fragment>
      <HeadingStyled>{t("register.baseInfo.heading")}</HeadingStyled>
      <ParagraphStyled>{t("register.baseInfo.description")}</ParagraphStyled>
      <Form
        onSubmit={handleSubmit(onSubmitHandler)}
        className="grid grid-cols-1 sm:grid-cols-2  gap-6 mb-6 w-full"
      >
        <div>
          <Input
            registerInput={register}
            name="firstName"
            required
            type="text"
            inputWidth="100%"
            placeholderText={t(
              "register.baseInfo.inputs.firstName.placeholder"
            )}
            labelText={t("register.baseInfo.inputs.firstName.label")}
          />
          {formState.errors.firstName && (
            <StyledError bottomMargin="0" topMargin="0.441rem">
              {formState.errors.firstName?.message}
            </StyledError>
          )}
        </div>
        <div>
          <Input
            registerInput={register}
            name="lastName"
            required
            type="text"
            inputWidth="100%"
            placeholderText={t("register.baseInfo.inputs.lastName.placeholder")}
            labelText={t("register.baseInfo.inputs.lastName.label")}
          />
          {formState.errors.lastName && (
            <StyledError bottomMargin="0" topMargin="0.441rem">
              {formState.errors.lastName?.message}
            </StyledError>
          )}
        </div>
        <div>
          <Input
            registerInput={register}
            name="username"
            required
            type="text"
            inputWidth="100%"
            placeholderText={t("register.baseInfo.inputs.email.placeholder")}
            labelText={t("register.baseInfo.inputs.email.label")}
          />
          {formState.errors.username && (
            <StyledError bottomMargin="0" topMargin="0.441rem">
              {formState.errors.username?.message}
            </StyledError>
          )}
        </div>
        <div>
          <Input
            registerInput={register}
            name="password"
            required
            type="password"
            inputWidth="100%"
            placeholderText={t("register.baseInfo.inputs.password.placeholder")}
            labelText={t("register.baseInfo.inputs.password.label")}
          />
          {formState.errors.password && (
            <StyledError bottomMargin="0" topMargin="0.441rem">
              {formState.errors.password?.message}
            </StyledError>
          )}
        </div>
        <div>
          <Input
            registerInput={register}
            name="passwordConfirm"
            required
            type="password"
            inputWidth="100%"
            placeholderText={t(
              "register.baseInfo.inputs.passwordConfirm.placeholder"
            )}
            labelText={t("register.baseInfo.inputs.passwordConfirm.label")}
          />
          {formState.errors.passwordConfirm && (
            <StyledError bottomMargin="0" topMargin="0.441rem">
              {formState.errors.passwordConfirm?.message}
            </StyledError>
          )}
        </div>

        <div className="flex flex-col mb-6 items-start w-full col-start-1 sm:col-end-3 justify-self-stretch">
          <div className="mb-4">
            <input
              className="mr-2"
              id="terms"
              {...register("terms")}
              type="checkbox"
            />
            <LabelStyled
              htmlFor="terms"
              className="font-sans text-sm font-medium"
            >
              {t("register.baseInfo.checkbox.agree")}
            </LabelStyled>
            {formState.errors.terms && (
              <StyledError topMargin="0" bottomMargin="0">
                {formState.errors.terms?.message}
              </StyledError>
            )}
          </div>
          <div>
            <input
              className="mr-2"
              id="subscribeToNotifications"
              {...register("subscribeToNotifications")}
              type="checkbox"
            />
            <LabelStyled
              htmlFor="subscribeToNotifications"
              className="font-sans text-sm font-medium"
            >
              {t("register.baseInfo.checkbox.receive")}
            </LabelStyled>
          </div>
        </div>
        <div className="flex flex-col items-center col-start-1 sm:col-end-3 justify-self-stretch">
          {error && (
            <StyledError centered topMargin="0" bottomMargin="0.883rem">
              {error}
            </StyledError>
          )}
          <Button
            resPending={requestInProgress}
            type="submit"
            invert={false}
            buttonWidth="23.125rem"
          >
            {t("register.baseInfo.button.register")}
          </Button>
        </div>
      </Form>
      {modalOpen && (
        <Modal>
          <ModalWrapper>
            <PopupCloseStyled onClick={handleCloseModal}>
              &#10006;
            </PopupCloseStyled>
            <PopupImageStyled src={popupImage} />
            <PopupHeadingStyled>
              {t("register.baseInfo.popupHeading")}
            </PopupHeadingStyled>
            <Button
              invert={false}
              onClick={handleCloseModal}
              buttonWidth="14rem"
            >
              {t("register.otp.popup.button")}
            </Button>
          </ModalWrapper>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default BaseInfo;
