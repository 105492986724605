import { createSlice } from "@reduxjs/toolkit";
import { SliceNames } from "../enums/SliceNames";
import { FavoriteSubject } from "../types/favoriteSubjects";

const initialState: {
  favoriteSubjects: FavoriteSubject[];
  allSubjects: FavoriteSubject[];
} = { favoriteSubjects: [], allSubjects: [] };

export const favoriteSubjectsSlice = createSlice({
  name: SliceNames.FavoriteSubjectsSlice,
  initialState,
  reducers: {
    setAllSubjects: (state, { payload }) => {
      state.allSubjects = payload;
    },
    setFavoriteSubject: (state, { payload }) => {
      state.favoriteSubjects = payload;
    },
    reset: (state) => {
      state.allSubjects = initialState.allSubjects;
      state.favoriteSubjects = initialState.favoriteSubjects;
    },
  },
});

export const { setAllSubjects, setFavoriteSubject, reset } =
  favoriteSubjectsSlice.actions;

export default favoriteSubjectsSlice.reducer;
