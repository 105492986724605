import { useTranslation } from "react-i18next";
import {
  AvatarDiv,
  Heading,
  SelectAvatar,
  SelectName,
} from "../LearnerSubjects/LearnerSubjectsStyled";
import {
  SelectDiv,
  SelectOuterDiv,
  selectStyles,
  Wrapper,
} from "./LearnerActivityStyled";
import Select from "react-select";
import { useLearners } from "../../../../../common/hooks/useLearners";
import avatar from "../../../../../assets/common/Parent-Monitor/Avatar.svg";
import { useState } from "react";
import { ActivityList } from "./ActivityList";

export const LearnerActivity = () => {
  const { t } = useTranslation();
  const { activeLearners } = useLearners();
  const [learnerUsername, setLearnerUsername] = useState(
    activeLearners.length > 0 ? activeLearners[0].username : undefined
  );

  const selectOptions = activeLearners?.map((learner) => {
    return {
      label: (
        <SelectDiv>
          {learner.avatar?.image ? (
            <AvatarDiv>
              <SelectAvatar src={learner.avatar.image} />
            </AvatarDiv>
          ) : (
            <SelectAvatar noDiv src={avatar} />
          )}
          <SelectName>
            {learner.firstName} {learner.lastName}
          </SelectName>
        </SelectDiv>
      ),
      value: learner.username,
    };
  });

  return (
    <Wrapper>
      <Heading>{t("parentDashboard.monitorProgress.recentActivity")}</Heading>
      <SelectOuterDiv>
        <SelectDiv>
          <Select
            onChange={(val) => {
              setLearnerUsername(val?.value);
            }}
            controlShouldRenderValue
            styles={selectStyles}
            options={selectOptions}
            defaultValue={selectOptions && selectOptions[0]}
          />
        </SelectDiv>
      </SelectOuterDiv>
      <ActivityList
        avatar={
          activeLearners.find((learner) => {
            return learner.username === learnerUsername;
          })?.avatar?.image
        }
        learnerUsername={learnerUsername}
      />
    </Wrapper>
  );
};
