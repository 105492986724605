import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { EstimatedBillingRequest } from "../../../common/types/EstimateBillingRequest";
import {
  PaymentForm,
  PaymentFormFieldName,
} from "../../../common/types/PaymentForm";
import {
  setPaymentErrorAction,
  setCheckoutAttemptStatusAction,
} from "../../slices/payment";
import { ReduxThunk } from "../../type";
import { PaymentRequestStatus } from "../../types/payment";
import { ReactStripeElements } from "react-stripe-elements";
import i18n from "../../../i18n";
import { setNotification } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import { Subscription } from "../../types/subscriptions";
import { updateLearners } from "../../slices/learners";
import { addSubscriptions } from "../../slices/subscriptions";
import { Learner } from "../../types/learners";
import { Exception } from "../../../common/enums/Exceptions";

export const getError = (message: string | undefined) => {
  if (typeof message === "string") {
    if (
      message?.toLowerCase().includes("no such paymentmethod") ||
      message?.toLowerCase().includes("invalid card")
    ) {
      return i18n.t("register.payment.byCard.serverError.cardError");
    } else if (message?.toLowerCase().includes("kartica zavrnjena")) {
      return i18n.t("register.payment.byCard.serverError.cardDeclined");
    } else if (message === Exception.SubscriptionAlreadyRenewed) {
      return i18n.t("response.subscriptionAlreadyRenewed");
    } else if (message === Exception.InvalidPromoCode) {
      return i18n.t("response.invalidPromoCode");
    } else if (message === Exception.LearnersHaveActiveStripeSubscription) {
      return i18n.t("response.activeStripeSubscription");
    } else if (message === Exception.CantApplyDiscount) {
      return i18n.t("response.cantApplyDiscount");
    }
  }

  return i18n.t("register.payment.byCard.serverError.defaultError");
};

type CreateSubscriptionResponse = Array<{
  clientSecret: string;
  transactionId: string;
  learner: string;
  stripeSubscriptionId: string;
  start: string;
  end: string;
}>;

export const createSubscriptionTask: (
  form: PaymentForm,
  stripe: ReactStripeElements.StripeProps | null,
  elements: stripe.elements.Elements | null,
  subscriptions: EstimatedBillingRequest[]
) => ReduxThunk =
  (form, stripe, elements, subscriptions) =>
  async (dispatch, _, { client }) => {
    dispatch(setPaymentErrorAction(null));
    if (!stripe || !elements) {
      dispatch(
        setPaymentErrorAction(
          i18n.t("register.payment.byCard.serverError.externalStripeError")
        )
      );
      return;
    }

    dispatch(setCheckoutAttemptStatusAction(PaymentRequestStatus.Pending));

    let method = form[PaymentFormFieldName.PaymentMethodId];
    const card = elements.getElement("cardNumber");
    if (!card) return;

    try {
      if (!method) {
        const { paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card,
          billing_details: {
            name: form[PaymentFormFieldName.NameOnCard],
            email: form[PaymentFormFieldName.Email],
          },
        });
        if (!paymentMethod)
          throw i18n.t("register.payment.byCard.serverError.cardError");
        method = paymentMethod?.id;
      }

      const res = await client.post<CreateSubscriptionResponse>(
        ApiRoutes.CreateSubscription,
        {
          ...form,
          paymentMethodId: method,
          subscriptions,
        }
      );

      const error = (res as any).error;

      if (error) {
        dispatch(setCheckoutAttemptStatusAction(PaymentRequestStatus.Idle));
        return dispatch(setPaymentErrorAction(getError(error.message[0])));
      }

      const confirmPaymentData = [];

      for (const { clientSecret, ...rest } of res.data) {
        const paymentConfirmRes = await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: method,
          }
        );
        confirmPaymentData.push({
          ...rest,
          paymentIntentId: paymentConfirmRes.paymentIntent?.id,
        });
      }

      const confirmPaymentResponse = await Promise.all(
        confirmPaymentData.map(async (data, i) => {
          const promoCode = form[PaymentFormFieldName.PromoCode];
          const addPromoCode =
            !!promoCode && i + 1 === confirmPaymentData.length;

          return client.post<Learner[]>(ApiRoutes.ConfirmSubscriptionPayment, {
            ...data,
            ...(addPromoCode ? { promoCode } : {}),
          });
        })
      );

      const confirmPaymentResponseData = confirmPaymentResponse.flatMap(
        (r) => r.data
      );

      dispatch(setCheckoutAttemptStatusAction(PaymentRequestStatus.Successful));

      dispatch(
        setNotification({
          key: AsyncActions.PayByCardSuccessful,
          value: confirmPaymentResponseData.reduce(
            (acc: any, cur: any) => `${acc}&${cur.id}`,
            ""
          ),
        })
      );
      dispatch(updateLearners(confirmPaymentResponseData));
      dispatch(
        addSubscriptions(
          confirmPaymentResponseData.flatMap(
            (l: any) => l.subscriptions as Subscription[]
          )
        )
      );
    } catch (e: any) {
      dispatch(setCheckoutAttemptStatusAction(PaymentRequestStatus.Idle));
      dispatch(
        setPaymentErrorAction(
          Array.isArray(e.response?.data?.message)
            ? getError(e.response.data.message[0])
            : typeof e === "string"
            ? getError(e)
            : getError(e.response.data.message)
        )
      );
    }
  };
