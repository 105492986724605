import { LearnerActivity } from "./LearnerActivity/LearnerActivity";
import { LearnerInfoWrapper } from "./LearnerInfoStyled";
import { LearnerSubjects } from "./LearnerSubjects/LearnerSubjects";

export const LearnerInfo = () => {
  return (
    <LearnerInfoWrapper>
      <LearnerSubjects />
      <LearnerActivity />
    </LearnerInfoWrapper>
  );
};
