import tw, { styled } from "twin.macro";
import { HTMLProps } from "react";
import colors from "../../../colors.json";

export const LearnerInputsDiv = styled.div(
  ({ addOne }: { addOne?: boolean }) => [
    tw` grid gap-x-4 gap-y-4 grid-flow-row grid-cols-2 grid-rows-2 auto-rows-fr xl:gap-y-0 xl:grid-flow-col xl:grid-rows-1 xl:grid-cols-[11.04rem_11.04rem_6.624rem_15.45rem]  items-center justify-center`,
    addOne &&
      tw`grid-cols-1 grid-rows-4 xl:grid xl:gap-x-4 xl:gap-y-4 xl:grid-flow-row xl:grid-cols-2 xl:grid-rows-2 xl:auto-rows-fr`,
  ]
);

export const LearnerWrapper = styled.div(
  () => tw`w-full flex items-center justify-center space-x-6 mb-10 xl:mb-6`
);

export const NameDiv = styled.div(({ addOne }: { addOne?: boolean }) => [
  tw`max-w-[13.24rem] xl:max-w-[100%] xl:w-[11.04rem] h-full`,
  addOne && tw`xl:w-full`,
]);

export const SchoolSelectDiv = styled.div(
  ({ addOne }: { addOne?: boolean }) => [
    tw`max-w-[13.24rem] xl:max-w-[100%] xl:w-[15.45rem] h-full`,
    addOne && tw`xl:w-full`,
  ]
);

export const GradeSelectDiv = styled.div(({ addOne }: { addOne?: boolean }) => [
  tw`max-w-[13.24rem] xl:max-w-[100%] xl:w-30 h-full`,
  addOne && tw`xl:w-full`,
]);

export const InputButton = styled.button<HTMLProps<HTMLButtonElement>>(
  () => tw`xl:self-start xl:mt-10`
);

export const InputButtonImg = styled.img<HTMLProps<HTMLImageElement>>(
  tw`min-w-[1rem] min-h-[1rem]`
);

export const ButtonDiv = styled.div(
  () =>
    tw`w-full self-center max-w-[23.125rem] flex flex-col items-center justify-center mb-10 mt-4`
);

export const IndicatorSeparator = () => {
  return null;
};

export const gradeSelectStyles = {
  control: (provided: object, state: any) => ({
    ...provided,

    width: "100%",
    height: "2.208rem",
    minHeight: "0",
    borderRadius: "0.5rem",
    boxShadow: "0",
    border: state.isFocused
      ? `1px solid ${colors["dark-blue"]}`
      : `1px solid ${colors["dark-gray"]}`,
    flexShrink: "0",
  }),
  container: (provided: object) => ({
    ...provided,

    width: "100%",
    height: "2.208rem",
    minHeight: "0",
    borderRadius: "0.5rem",
    flexShrink: "0",
  }),
  indicatorsContainer: (provided: object) => ({
    ...provided,
    height: "2.208rem",
    padding: "0rem",
    paddingTop: "0rem",
    paddingBottom: "0rem",
    marginRight: "0.5rem",
    flexShrink: "0",
  }),
  valueContainer: (provided: object) => ({
    ...provided,
    height: "2rem",
    padding: "0rem",
    marginLeft: "0.5rem",
    flexShrink: "0",
  }),
  singleValue: (provided: object) => ({
    ...provided,
    fontFamily: "inter",
    color: colors.black,
    fontSize: "0.875rem",
    fontWeight: "400",
  }),
  placeholder: (provided: object) => ({
    ...provided,
    fontFamily: "inter",
    color: colors.black,
    fontSize: "0.875rem",
    fontWeight: "400",
    opacity: "0.6",
  }),
  option: (provided: object) => ({
    ...provided,
    fontFamily: "inter",
  }),
  input: (provided: object) => ({
    ...provided,
    fontFamily: "inter",
    color: colors.black,
  }),
  noOptionsMessage: (provided: object) => ({
    ...provided,
    fontFamily: "inter",
  }),
  clearIndicator: () => ({
    display: "none",
  }),
};

export const schoolSelectStyles = {
  indicatorsContainer: () => ({
    display: "none",
  }),
  control: (provided: object, state: any) => ({
    ...provided,

    width: "100%",
    height: "2.208rem",
    minHeight: "0",
    borderRadius: "0.5rem",
    boxShadow: "0",
    border: state.isFocused
      ? `1px solid ${colors["dark-blue"]}`
      : `1px solid ${colors["dark-gray"]}`,
    flexShrink: "0",
  }),
  container: (provided: object) => ({
    ...provided,

    width: "100%",
    height: "2.208rem",
    minHeight: "0",
    borderRadius: "0.5rem",
    flexShrink: "0",
  }),
  valueContainer: (provided: object) => ({
    ...provided,
    height: "2rem",
    padding: "0rem",
    marginLeft: "0.5rem",
    flexShrink: "0",
  }),
  singleValue: (provided: object) => ({
    ...provided,
    fontFamily: "inter",
    color: colors.black,
    fontSize: "0.875rem",
    fontWeight: "400",
  }),
  placeholder: (provided: object) => ({
    ...provided,
    fontFamily: "inter",
    color: colors.black,
    fontSize: "0.875rem",
    fontWeight: "400",
    opacity: "0.6",
  }),
  option: (provided: object) => ({
    ...provided,
    fontFamily: "inter",
  }),
  input: (provided: object) => ({
    ...provided,
    fontFamily: "inter",
    color: colors.black,
  }),
  noOptionsMessage: (provided: object) => ({
    ...provided,
    fontFamily: "inter",
  }),
};
