import dayjs from "dayjs";
import "dayjs/locale/sl";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import duration from "dayjs/plugin/duration";

import { env } from "./env";
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.locale(env.locale === "en" ? "en" : "sl");

dayjs.updateLocale("sl", {
  relativeTime: {
    future: "čez %s",
    past: "pred %s",
    s: "nekaj sekundami",
    m: "1 minuto",
    mm: "%d minutami",
    h: "1 uro",
    hh: "%d urami",
    d: "1 dnevom",
    dd: "%d dnevi",
    M: "1 mesecem",
    MM: "%d meseci",
    y: "1 letom",
    yy: "%d leti",
  },
});

export default dayjs;
