import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../store/type";
import { useEffect } from "react";
import { fetchTournamentsForSponsor } from "../../../store/thunks/Parent/tournaments";
import {
  TableWrapper,
  Table,
  TBody,
} from "../../../common/components/Tournament/styled";
import { LearnerUpcomingTournamentItem } from "./LearnerUpcomingTournamentItem";
import { LearnerUpcomingTournamentCollapse } from "./LearnerUpcomingTournamentCollapse";
import { NoEvents } from "./NoEvents";
import { useRefresh } from "../../../common/hooks/useRefresh";

export const LearnerUpcomingTournaments = () => {
  const dispatch = useDispatch();
  const tournaments = useSelector(
    (state: StoreState) => state.tournaments.tournamentsForSponsor
  );

  const state = useRefresh(60000);

  useEffect(() => {
    dispatch(fetchTournamentsForSponsor());
  }, [state]);

  return (
    <>
      {tournaments.length === 0 && <NoEvents />}
      <TableWrapper>
        <Table>
          <TBody>
            {tournaments.map((t) => (
              <LearnerUpcomingTournamentItem key={t.id} {...t} />
            ))}
          </TBody>
        </Table>
      </TableWrapper>
      <LearnerUpcomingTournamentCollapse />
    </>
  );
};
