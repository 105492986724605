import tw, { styled } from "twin.macro";

export const Wrapper = styled.div(
  () =>
    tw`p-4 pb-24 flex flex-col bg-white rounded-lg mt-4 border border-light-gray-secondary`
);

export const HeadingAndEditDiv = styled.div(
  () => tw`w-full flex justify-center items-center mb-6`
);

export const Heading = styled.h2(
  () => tw`font-bold text-2xl text-black m-auto`
);

export const SubjectsGrid = styled.div(
  () => tw`w-full grid grid-cols-1 sm:grid-cols-2 gap-x-30 gap-y-6 ml-6 `
);

export const EditIcon = styled.img(() => tw` w-[2.5rem] cursor-pointer`);

export const GridItemDiv = styled.div(() => tw`w-full flex items-center`);

export const SubjectIcon = styled.img(() => tw`w-6 h-6 mr-3`);

export const SubjectName = styled.span(
  () => tw`font-medium font-sans text-black text-lg`
);

export const NoSubjects = styled.span(
  () => tw`font-bold text-lg font-sans text-black mx-auto mt-12`
);
