import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Button from "../../common/components/Button/Button";
import { logOutTask } from "../../store/thunks/logOutTask";
import {
  BtnDiv,
  ContentWrapper,
  Description,
  Heading,
  Wrapper,
} from "./UnauthorizedPageStyled";

export const Unauthorized = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onRedirectToLogin = () => {
    dispatch(logOutTask());
  };
  return (
    <Wrapper>
      <ContentWrapper>
        <Heading>{t("unauthorizedPage.heading")}</Heading>
        <Description>{t("unauthorizedPage.description")}</Description>
        <BtnDiv>
          <Button
            onClick={onRedirectToLogin}
            buttonHeight="2.5rem"
            buttonWidth="100%"
          >
            {t("unauthorizedPage.btn")}
          </Button>
        </BtnDiv>
      </ContentWrapper>
    </Wrapper>
  );
};
