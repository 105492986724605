import { useTranslation } from "react-i18next";

import { Wrapper } from "./GameNameStyled";
import {
  ButtonDiv,
  Description,
  Heading,
  SubmitDiv,
} from "../ProfileSetupStyled";
import { InputStyled } from "../../../common/components/Input/Input";
import Button from "../../../common/components/Button/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { gameNameValidationSchema } from "../../../views/validation/profileSetupValidationSchema";
import Form from "../../../common/components/Form/Form";
import { StyledError } from "../../../common/components/Error/Error";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { reset } from "../../../store/slices/async";
import { patchUsername } from "../../../store/thunks/profileSetup/patchUsername";
import { StoreState } from "../../../store/type";

export const GameName = ({
  setCurrentStep,
}: {
  setCurrentStep: (val: number) => void;
}) => {
  const learnerUsername = useSelector(
    (state: StoreState) => state.user.info.username
  );
  const { requestInProgress, error, success } = useAsyncState(
    AsyncActions.PatchUsername
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { register, handleSubmit, watch, formState } = useForm({
    defaultValues: { gameName: learnerUsername },
    mode: "onTouched",
    resolver: yupResolver(gameNameValidationSchema()),
  });
  watch("gameName");
  const onPressNextHandler = (data: any) => {
    dispatch(patchUsername(data.gameName));
  };

  useEffect(() => {
    if (success) {
      setCurrentStep(3);
    }
  }, [success]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);
  return (
    <Form onSubmit={handleSubmit(onPressNextHandler)}>
      <Wrapper>
        <Heading smallMargin>{t("profileSetup.gameName.heading")}</Heading>
        <Description>{t("profileSetup.gameName.description")}</Description>
        <InputStyled
          {...register("gameName")}
          inputWidth="100%"
          inputHeight="2.5rem"
          type="text"
          placeholder="Alexanar_Doe"
        ></InputStyled>
        <SubmitDiv>
          {formState.errors?.gameName && (
            <StyledError centered topMargin="0" bottomMargin="0">
              {formState.errors.gameName.message}
            </StyledError>
          )}
          {error && (
            <StyledError centered topMargin="0" bottomMargin="0">
              {error}
            </StyledError>
          )}
          <ButtonDiv>
            <Button
              type="button"
              onClick={() => {
                setCurrentStep(1);
              }}
              invert
              resPending={requestInProgress}
              buttonWidth="100%"
              buttonHeight="2.5rem"
            >
              &larr; {t("profileSetup.backBtn")}
            </Button>
            <Button
              resPending={requestInProgress}
              type="submit"
              invert={false}
              buttonWidth="100%"
              buttonHeight="2.5rem"
            >
              {t("profileSetup.nextBtn")} &rarr;
            </Button>
          </ButtonDiv>
        </SubmitDiv>
      </Wrapper>
    </Form>
  );
};
