import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { Exception } from "../../../common/enums/Exceptions";
import i18n from "../../../i18n";
import { AsyncActions } from "../../enums/AsyncActions";
import { setBusyAction, setErrorAction, setSuccess } from "../../slices/async";
import { setUserInfo } from "../../slices/user";
import { ReduxThunk } from "../../type";

export const patchGender: (gender: string) => ReduxThunk = (gender) => {
  return async (dispatch, _, { client }) => {
    try {
      dispatch(setBusyAction({ key: AsyncActions.PatchGender, value: true }));
      await client.patch(ApiRoutes.LearnerProfile, { gender });

      dispatch(setUserInfo({ gender }));
      dispatch(setSuccess({ key: AsyncActions.PatchGender, value: true }));
    } catch (err: any) {
      if (err.response?.data?.message === Exception.InvalidGender) {
        dispatch(
          setErrorAction({
            key: AsyncActions.PatchGender,
            value: i18n.t("profileSetup.selectGender.errors.invalid"),
          })
        );
      } else {
        dispatch(
          setErrorAction({
            key: AsyncActions.PatchGender,
            value: i18n.t("profileSetup.selectGender.errors.failed"),
          })
        );
      }
    }
    dispatch(setBusyAction({ key: AsyncActions.PatchGender, value: false }));
  };
};
