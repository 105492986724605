import tw, { styled } from "twin.macro";
import colors from "../../../../../colors.json";

export const Wrapper = styled.div(
  () =>
    tw`w-full xl:w-[20.8125rem] xl:h-[45rem] bg-white flex flex-col rounded-lg p-4`
);

export const ActivityDiv = styled.div(
  () => tw`h-full relative xl:overflow-scroll mt-7`
);

export const SingleActivity = styled.div(
  () => tw`w-full py-4 border-b border-light-gray-secondary flex`
);

export const ActivityInfoDiv = styled.div(() => tw`flex flex-col`);

export const AvatarDiv = styled.div(
  () =>
    tw`flex items-center flex-shrink-0 justify-center w-10 h-10 mr-2 rounded-full bg-yellow`
);

export const ActivityIcon = styled.img(({ noDiv }: { noDiv?: boolean }) => [
  tw`h-6 w-6`,
  noDiv && tw`mr-2 h-8 w-8`,
]);

export const NameSpan = styled.span(() => tw`font-semibold font-sans`);

export const ActivityInfo = styled.p(
  () => tw`font-sans text-sm text-black mb-1`
);

export const Time = styled.span(
  () => tw`font-sans text-xs text-black opacity-60`
);

export const SelectOuterDiv = styled.div(() => tw`w-full mt-4 pr-2`);

export const SelectDiv = styled.div(
  () => tw`w-full flex items-center flex-wrap`
);

export const selectStyles = {
  indicatorSeparator: () => ({
    display: "hidden",
  }),
  control: (provided: any) => ({
    ...provided,
    width: "15rem",
    border: `1px solid ${colors["dark-gray"]}`,
    cursor: "pointer",
    marginTop: "0",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontColor: colors.black,
  }),

  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontColor: colors.black,
  }),
  option: (provided: any) => ({
    ...provided,
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontColor: colors.black,
    backgroundColor: colors.white,
    cursor: "pointer",
  }),
};
