import { useTranslation } from "react-i18next";
import { useState, useMemo, useEffect, Fragment } from "react";
import { path, equals } from "ramda";

import { PageWrapper } from "../../../common/components/Survey/styled";
import {
  Wrapper,
  DataRow,
  Header,
  HeaderCell,
  DataCell,
  FilterIcon,
  StarIcon,
  ResultPageTitle,
  ResultPageSubtitle,
} from "./styled";
import sortArrow from "../../../assets/common/Tournaments/sort-arrow.svg";
import starIcon from "../../../assets/common/Lesson-Page/Star.svg";

import { UserDetails } from "./UserDetails";
import { TournamentResultMd } from "./TournamentResultMd";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../store/type";
import { useParams } from "react-router-dom";
import { fetchTournamentResult } from "../../../store/thunks/Learner/fetchTournamentResult";
import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { Spinner } from "../../../common/components/Spinner/Spinner";
import { ResultsNotAvailable } from "./ResultsNotAvailable";
import dayjs from "../../../common/config/dayjs";
import { env } from "../../../common/config/env";

const header = [
  { label: "rank", path: ["rank"] },
  { label: "name", path: ["username"] },
  { label: "marks", path: ["answers", "correctlyAnswered"] },
  { label: "points", path: ["points"] },
] as const;

export type TournamentResultFilters = typeof header;

export type SortPaths =
  | readonly ["rank"]
  | readonly ["username"]
  | readonly ["answers", "correctlyAnswered"]
  | readonly ["points"];

type SortOptions = {
  path: SortPaths;
  ord: "ASC" | "DESC";
};

export const TournamentResultComponent = () => {
  const [sortOptions, setSortOptions] = useState<SortOptions>({
    path: ["rank"],
    ord: "ASC",
  });
  const { id } = useParams();
  const { t } = useTranslation();
  const { activeClass } = useSelector((state: StoreState) => state.user.info);
  const results = useSelector((state: StoreState) => state.tournaments.result);
  const { requestInProgress } = useAsyncState(
    AsyncActions.FetchTournamentResult
  );
  const dispatch = useDispatch();

  const handleSetFilter = (path: SortPaths) => () => {
    setSortOptions((pre) => ({
      path,
      ord: equals(pre.path, path)
        ? pre.ord === "DESC"
          ? "ASC"
          : "DESC"
        : "ASC",
    }));
  };

  const didEnded =
    results.length > 0
      ? dayjs(results[0]?.endsAt)
          .add(env.tournamentResultDelayInMinutes, "minutes")
          .isBefore(new Date())
      : true;

  const filteredData = useMemo(() => {
    const multiplyFactor = sortOptions.ord === "ASC" ? 1 : -1; // revert result
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isComparingStrings = sortOptions.path.includes("username");
    return results.slice().sort((a, b) => {
      if (isComparingStrings) {
        return (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          path(sortOptions.path)(a).localeCompare(path(sortOptions.path)(b)) *
          multiplyFactor
        );
      }
      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (path(sortOptions.path)(a) - path(sortOptions.path)(b)) * multiplyFactor
      );
    });
  }, [sortOptions, results]);

  useEffect(() => {
    if (id) {
      dispatch(fetchTournamentResult(id));
    }
  }, [id]);

  if (!didEnded) {
    return (
      <PageWrapper>
        <ResultsNotAvailable
          title={t("tournaments.resultsNotProcessed.title")}
          description={t("tournaments.resultsNotProcessed.description")}
          btnLabel={t("tournaments.resultsNotProcessed.btn")}
        />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      {requestInProgress ? (
        <Spinner />
      ) : results.length === 0 ? (
        <ResultsNotAvailable
          title={t("tournaments.noParticipants.title")}
          description={t("tournaments.noParticipants.description")}
          btnLabel={t("tournaments.noParticipants.btn")}
        />
      ) : (
        <Fragment>
          <ResultPageSubtitle>
            {t("tournaments.tournamentResult.resultPageTitle")}
          </ResultPageSubtitle>
          <ResultPageTitle>{filteredData[0]?.name}</ResultPageTitle>
          <Wrapper>
            <Header>
              {header.map((d) => (
                <HeaderCell onClick={handleSetFilter(d.path)} key={d.label}>
                  {t(`tournaments.tournamentResult.columns.${d.label}`)}
                  <FilterIcon src={sortArrow} />
                </HeaderCell>
              ))}
            </Header>
            {filteredData.map((d) => (
              <DataRow key={d.id}>
                <DataCell>{d.rank}</DataCell>
                <DataCell>
                  <UserDetails
                    username={d.username}
                    avatar={d.avatar}
                    grade={`${activeClass?.name}th ${t(
                      "tournaments.tournamentResult.grade"
                    )}`}
                    items={d.items.map((i) => i.avatarItem)}
                  />
                </DataCell>
                <DataCell>{`${d.answers.correctlyAnswered}/${d.answers.totalQuestions}`}</DataCell>
                <DataCell>
                  <StarIcon src={starIcon} /> {d.points}
                </DataCell>
              </DataRow>
            ))}
          </Wrapper>
          <TournamentResultMd
            filters={header}
            data={filteredData}
            onFilterClick={handleSetFilter}
          />
        </Fragment>
      )}
    </PageWrapper>
  );
};
