import { setPaymentErrorAction } from "../../slices/payment";
import { PaymentRequestStatus } from "../../types/payment";
import { removePaymentMethodAttemptStatus } from "../../slices/payment";
import { ReduxThunk } from "../../type";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";

export const removePaymentMethodsTask: (
  paymentMethodId: string
) => ReduxThunk =
  (paymentMethodId) =>
  async (dispatch, _, { client }) => {
    try {
      dispatch(removePaymentMethodAttemptStatus(PaymentRequestStatus.Pending));
      dispatch(setPaymentErrorAction(""));
      const res = await client.post(ApiRoutes.RemovePaymentMethods, {
        paymentMethodId,
      });
      const error = (res as any).error;
      if (error) {
        dispatch(removePaymentMethodAttemptStatus(PaymentRequestStatus.Idle));
        return dispatch(setPaymentErrorAction(error.message[0]));
      }
      dispatch(
        removePaymentMethodAttemptStatus(PaymentRequestStatus.Successful)
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      dispatch(removePaymentMethodAttemptStatus(PaymentRequestStatus.Idle));
      dispatch(setPaymentErrorAction(e?.message || "Nekaj ​​je šlo narobe"));
    }
  };
