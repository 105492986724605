import tw, { styled, css } from "twin.macro";
import RcTabs, { TabPane as RcTabPane } from "rc-tabs";

import { ButtonStyled } from "../../../common/components/Button/Button";

export const Wrapper = styled.div(
  () =>
    tw`flex flex-col gap-y-2 h-full min-h-[30rem] w-full bg-white border border-light-gray-secondary rounded-lg p-6`
);

export const CategoryItemWrapper = styled.div(
  () =>
    tw`mx-2 h-[7rem] relative border border-cream rounded-2xl overflow-hidden cursor-pointer`
);

export const BackgroundImage = () => {
  return <div className={"inset-0 opacity-50 absolute bg-old-paper"} />;
};

export const BalloonsImageWrapper = styled.div<{ pos: "left" | "right" }>(
  ({ pos }) => [
    tw`absolute w-[3.75rem] h-full overflow-hidden`,
    pos === "right" ? tw`right-2` : tw`left-2`,
    pos === "right" &&
      css`
        transform: scale(-1, 1);
      `,
  ]
);

export const BalloonsImage = () => {
  return <div className={"bg-balloons bg-no-repeat h-full"} />;
};

export const CategoryItemContentWrapper = styled.div(
  () => tw`absolute h-full w-full items-center`
);

export const CategoryItemContent = styled.div(
  () => tw`h-full flex flex-col items-center justify-center gap-y-2`
);

export const TitleWrapper = styled.div(
  () => tw`static md:flex justify-between w-full h-fit`
);

export const Title = styled.h2(
  () => tw`font-dyslexic font-bold text-2xl text-black text-center`
);

export const AllPointsWrapper = styled.div(
  () => tw`static sm:flex justify-between px-4`
);

export const PointsWrapper = styled.div(
  () => tw`w-[7rem] mt-2 md:mt-0 text-center`
);

export const CategoryItemLabel = styled.p(
  () => tw`font-serif font-semibold text-xl text-black`
);

export const Icon = styled.img(() => tw`hover:scale-150 transition-all`);

export const CloseIcon = styled(Icon)<{ hideOn: "small" | "big" }>(
  ({ hideOn }) => [
    tw`top-4 right-4 w-4 h-4 cursor-pointer`,
    hideOn === "small" ? tw`hidden md:block` : tw`absolute md:hidden`,
  ]
);

export const ModalContentWrapper = styled.div(
  () =>
    tw`h-5/6 w-11/12 md:h-[37rem] md:w-[66rem] flex flex-col md:flex-row overflow-auto`
);

export const AvatarWrapper = styled.div(() => [
  tw`md:h-full bg-gray-gradient relative md:static flex flex-col items-center justify-center gap-y-2 md:gap-y-3.5 rounded-t-2xl md:rounded-l-2xl md:rounded-t-none`,
  css`
    flex-basis: 20rem;
  `,
]);

export const AvatarIconWrapper = styled.div(
  () => tw`w-60 h-60 md:w-96 md:h-96`
);

export const Ellipse = styled.div(() => [
  tw`bg-dark-cream w-30 h-6 md:w-60 md:h-11 opacity-40 border-dark-cream`,
  css`
    filter: blur(8px);
    border-radius: 50%;
  `,
]);

export const ShopItemsWrapper = styled.div(() => [
  tw`bg-white py-3 px-6 md:p-6 rounded-b-2xl md:rounded-b-none md:rounded-r-2xl overflow-auto`,
  css`
    flex-grow: 2;
  `,
]);

export const ShopItemsTitle = styled.h1(
  () => tw`hidden md:block font-sans font-bold text-black text-2xl`
);

export const TabsWrapper = styled.div();

export const Tabs = styled(RcTabs)();
export const TabPane = styled(RcTabPane)();

export const ShotTabBtnWrapper = styled.div(
  () =>
    tw`flex justify-between md:justify-start gap-0 md:gap-[3px] px-2 md:px-0`
);

export const ShopTabBtn = styled.span<{ $active: boolean }>(({ $active }) => [
  tw`flex items-center justify-center text-sm text-black px-2 py-1.5 mb-2 md:mb-0 lg:px-4 lg:py-3 cursor-pointer rounded-t-lg`,
  $active ? tw`bg-gold-secondary` : tw`bg-cream`,
]);

export const ShopTabBtnLabel = styled.span(() => tw`hidden md:block font-sans`);

export const ShopTabBtnIcon = styled.img(() => tw`w-10 h-10 md:hidden`);

export const TabContentWrapper = styled.div(
  () =>
    tw`rounded-lg border border-dark-gray p-4 overflow-auto h-[15rem] md:h-[22.5rem]`
);

export const ItemsContent = styled.div(
  () => tw` grid gap-3.5 md:gap-4 grid-cols-3 md:grid-cols-5 lg:grid-cols-7`
);

export const ActionWrapper = styled.div(
  () => tw`bg-cream p-3.5 rounded-lg mt-2 flex items-center justify-between`
);

export const ItemActionsWrapper = styled.div(
  () =>
    tw`w-full px-1 max-w-[32rem] flex flex-col md:flex-row-reverse items-center lg:items-end gap-x-2 gap-y-2`
);

export const ShopItemWrapper = styled.div(() => tw`cursor-pointer`);

export const ShopItemIconWrapper = styled.div<{
  $active: boolean;
  $selected: boolean;
}>(({ $active, $selected }) => [
  tw`w-[4.5rem] h-[4.5rem] bg-yellow rounded-lg flex items-center justify-center relative overflow-hidden`,
  $active && tw`border border-light-green`,
  $selected && tw`border border-error-500`,
]);

export const ShopItemIcon = styled.img(
  () => tw`h-12 w-12 transition-all absolute`
);

export const ShopItemPoints = styled.div(
  () =>
    tw`w-[4.5rem] flex items-center justify-center gap-x-1.5 font-sans text-sm gap-x-1.5`
);

export const StarIcon = styled.img<{ $large?: boolean; $mb?: boolean }>(
  ({ $large, $mb }) => [
    tw`w-[0.75rem] h-[0.75rem]`,
    $large && tw`w-[1.1rem] h-[1.1rem]`,
    $mb && tw`mb-[3px]`,
  ]
);

const ItemButton = styled(ButtonStyled)<{ $active: boolean }>(({ $active }) => [
  tw`px-6 py-3 text-sm font-semibold`,
  !$active && tw`bg-dark-gray border-none`,
]);

export const BuyItemButton = styled(ItemButton)(
  () => tw`justify-between max-w-[16rem]`
);

export const UpdateAvatarButton = styled(ItemButton)(
  () => tw`max-w-[16rem] md:max-w-[10rem]`
);

export const BuyItemPointsWrapper = styled.span(
  () => tw`font-sans text-sm font-sans flex items-center gap-2`
);

export const AvailablePointsWrapper = styled(BuyItemPointsWrapper)<{
  showOnSmall?: boolean;
}>(({ showOnSmall }) => [
  tw`hidden md:flex flex-col gap-0 text-black text-xs whitespace-nowrap`,
  showOnSmall &&
    tw`flex md:hidden flex-row gap-x-1 text-xl justify-between px-2 mb-2`,
]);

export const AvailablePointsWrapperSpan = styled(BuyItemPointsWrapper)(
  () => tw`text-black text-base gap-x-0 font-semibold`
);

export const SubCategoryWrapper = styled.div();
export const SubCategoryTitle = styled.h6(
  () => tw`font-sans text-sm font-bold mb-1`
);

export const GoToHomeButton = styled(ButtonStyled)(
  () => tw`w-[10rem] md:w-[11rem]`
);
export const CloseModalButton = styled(ButtonStyled)(
  () => tw`bg-error-500 w-[10rem] md:w-[11rem] border-none`
);
