import React from "react";

import Button from "../../../common/components/Button/Button";

import studentIcon from "../../../assets/common/students-icon.svg";
import parentsIcon from "../../../assets/common/parents-icon.svg";
import teachersIcon from "../../../assets/common/teachers-icon.svg";
import { useTranslation } from "react-i18next";
import { env } from "../../../common/config/env";
import {
  ButtonLink,
  Icon,
  MissingAccountDescription,
} from "./RegisterButtonsStyled";

const RegisterButtons = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <MissingAccountDescription>
        {t("login.info.missingAccount")}
      </MissingAccountDescription>
      <ButtonLink href={env.studentsContentLink}>
        <Button bottomMargin="0.883rem" invert>
          <Icon src={studentIcon} />
          {t("login.button.students")}
        </Button>
      </ButtonLink>
      <ButtonLink href={env.parentsContentLink}>
        <Button bottomMargin="0.883rem" invert>
          <Icon src={parentsIcon} /> {t("login.button.parents")}
        </Button>
      </ButtonLink>
      <ButtonLink href={env.teachersContentLink}>
        <Button bottomMargin="0.883rem" invert>
          <Icon src={teachersIcon} />
          {t("login.button.teachers")}
        </Button>
      </ButtonLink>
    </React.Fragment>
  );
};

export default RegisterButtons;
