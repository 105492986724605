import { useTranslation } from "react-i18next";
import { Modal } from "../../../common/components/Modal/Modal";
import { PopupCloseStyled } from "../../../common/components/Modal/ModalStyled";
import { PopupHeadingStyled } from "../../../common/components/Modal/ModalStyled";
import {
  BtnDiv,
  ContentWrapper,
  Description,
  FontSelectDiv,
  fontSelectStyles,
  FormContentWrapper,
  LearnerNameSpan,
  SelectDiv,
  SubmitDiv,
  Wrapper,
} from "./ChooseFontModalStyled";

import {
  Controller,
  useForm,
  useFieldArray,
  SubmitHandler,
  FieldValues,
} from "react-hook-form";
import Form from "../../../common/components/Form/Form";
import Select from "react-select";
import Button from "../../../common/components/Button/Button";
import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { useDispatch } from "react-redux";
import { changeFont } from "../../../store/thunks/Parent/changeFont";
import { StyledError } from "../../../common/components/Error/Error";
import { Learner } from "../../../store/types/learners";

import fontPreview from "../../../assets/common/chooseFontImage.png";

type Props = {
  addOne?: boolean;
  learners: Learner[];
  toggleModal?: (val: string) => void;
};

const SELECT_OPTIONS = [
  { value: "Open Dyslexic", label: "Open Dyslexic" },
  { value: "Helvetica", label: "Helvetica" },
  { value: "Helvetica Neue", label: "Helvetica Neue" },
];

export const ChooseFontModal = ({ addOne, learners, toggleModal }: Props) => {
  if (!learners || learners.length === 0) {
    return null;
  }
  const { t } = useTranslation();
  const { clearNotification: clearAddLearnersNotification } = useAsyncState(
    AsyncActions.AddLearnersAction
  );
  const {
    requestInProgress: changeFontInProgress,
    error: changeFontError,
    clearNotification: clearChangeFontNotification,
  } = useAsyncState(AsyncActions.ChangeFont);

  const dispatch = useDispatch();

  const defaultValues: {
    learnerUsername: string;
    learnerName: string;
    font: string;
  }[] = learners.map((learner) => {
    return {
      font: "Open Dyslexic",
      learnerUsername: learner.username,
      learnerName: `${learner.firstName} ${learner.lastName}`,
    };
  });

  const { control, handleSubmit } = useForm({
    mode: "onTouched",
    defaultValues: { fontSelect: defaultValues },
  });

  const { fields } = useFieldArray({ control, name: "fontSelect" });

  const submitHandler: SubmitHandler<FieldValues> = (data) => {
    dispatch(changeFont(data.fontSelect, addOne));
  };

  const closeModalHandler = () => {
    if (toggleModal) {
      toggleModal("");
    }
    if (!addOne) {
      clearAddLearnersNotification();
    }
    clearChangeFontNotification();
  };

  return (
    <Modal lg>
      <Wrapper>
        <PopupCloseStyled onClick={closeModalHandler}>
          &#10006;
        </PopupCloseStyled>
        <ContentWrapper>
          <PopupHeadingStyled centered>
            {t("changeFont.heading")}
          </PopupHeadingStyled>
          <Description>{t("changeFont.description")}</Description>
          <img alt={"Font preview"} src={fontPreview} />
        </ContentWrapper>
        <Form
          onSubmit={handleSubmit(submitHandler)}
          className="w-full flex flex-col items-center"
        >
          <FormContentWrapper>
            {fields.map((field, index) => {
              return (
                <FontSelectDiv key={field.id}>
                  <LearnerNameSpan>{`${field.learnerName}:`}</LearnerNameSpan>
                  <SelectDiv>
                    <Controller
                      name={`fontSelect.${index}.font`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={fontSelectStyles}
                          options={SELECT_OPTIONS}
                          value={SELECT_OPTIONS.find(
                            (c) => c.value === field.value
                          )}
                          onChange={(val) => field.onChange(val?.value)}
                        />
                      )}
                    />
                  </SelectDiv>
                </FontSelectDiv>
              );
            })}
          </FormContentWrapper>
          <SubmitDiv>
            {changeFontError && (
              <StyledError centered topMargin="0" bottomMargin="0">
                {changeFontError}
              </StyledError>
            )}
            <BtnDiv>
              <Button
                type="button"
                onClick={closeModalHandler}
                invert
                buttonWidth="100%"
                resPending={changeFontInProgress}
              >
                {t("parentDashboard.myProfile.cancel")}
              </Button>
              <Button resPending={changeFontInProgress} buttonWidth="100%">
                {t("profileSetup.nextBtn")}
              </Button>
            </BtnDiv>
          </SubmitDiv>
        </Form>
      </Wrapper>
    </Modal>
  );
};
