import { ReduxThunk } from "../type";
import { reset as resetAsync } from "../slices/async";
import { reset as learnersReset } from "../slices/learners";
import { reset as paymentReset } from "../slices/payment";
import { reset as routerReset } from "../slices/router";
import { reset as schoolReset } from "../slices/school";
import { reset as subjectReset } from "../slices/subject";
import { reset as userReset } from "../slices/user";
import { reset as subscriptionReset } from "../slices/subscriptions";
import { reset as surveyReset } from "../slices/survey";
import { removeToken } from "../../common/utils/localStorageService";

export const logOutTask: () => ReduxThunk = () => {
  return async (dispatch) => {
    dispatch(resetAsync());
    dispatch(routerReset());
    dispatch(paymentReset());
    dispatch(schoolReset());
    dispatch(subjectReset());
    dispatch(userReset());
    dispatch(subscriptionReset());
    dispatch(learnersReset());
    dispatch(surveyReset());
    removeToken();
  };
};
