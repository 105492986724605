import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Payment } from "../../Register/Payment/Payment";
import { LearnersList } from "./LearnersList/LearnersList";
import { useSelector } from "react-redux";
import { StoreState } from "../../../store/type";
import {
  AddLearnerButton,
  AddLearnerDiv,
  AddLearnersIcon,
  CredentialsWrapper,
  Wrapper,
} from "./ManageLearnersStyled";

import addIcon from "../../../assets/common/Parent-Manage/Add.svg";
import { AddLearner } from "./AddLearner/AddLearner";
import { PaymentWrapper } from "./AddLearner/AddLearnerStyled";
import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { PaymentSuccess } from "../../Register/PaymentSuccess/PaymentSuccess";
import { LearnerPins } from "../../Register/LearnerPins/LearnerPins";
import { useLearners } from "../../../common/hooks/useLearners";
import { UnsubscribedLearners } from "./LearnersList/UnsubscribedLearners/UnsubscribedLearners";

type Props = {
  setSubscribeLearnerId: (id: string | null) => void;
  subscribeLearnerId: string | null;
};

export const ManageLearners = ({
  setSubscribeLearnerId,
  subscribeLearnerId,
}: Props) => {
  const { t } = useTranslation();
  const { notification: addedLearnersSuccess, clearNotification } =
    useAsyncState(AsyncActions.AddLearnersAction);
  const { notification: changeFontNotification } = useAsyncState(
    AsyncActions.ChangeFont
  );
  const {
    notification: payByCodeNotification,
    clearNotification: clearByCodeNotification,
  } = useAsyncState(AsyncActions.PayByCodeSuccessful);
  const {
    notification: payByCardNotifcation,
    clearNotification: clearByCardNotification,
  } = useAsyncState(AsyncActions.PayByCardSuccessful);

  const { activeLearners: learners, allLearners } = useLearners();
  const learnerLimit = useSelector(
    (state: StoreState) => state.user.info.learnerLimit
  );
  const [step, setStep] = useState(1);

  const addNewLearner = () => {
    if (learnerLimit === allLearners.length) return;
    setStep(2);
  };

  const unsubscribedLearner = allLearners.find(
    (l) => l.id === subscribeLearnerId
  );

  let VisibleComponent;

  useEffect(() => {
    if (addedLearnersSuccess && !changeFontNotification) {
      setStep(3);
    }
    if (payByCodeNotification || payByCardNotifcation) {
      setStep(4);
    }
  }, [
    addedLearnersSuccess,
    payByCardNotifcation,
    payByCodeNotification,
    changeFontNotification,
  ]);

  useEffect(() => {
    return () => {
      clearNotification();
      clearByCardNotification();
      clearByCodeNotification();
    };
  }, []);

  useEffect(() => {
    if (subscribeLearnerId) {
      setStep(3);
    }

    return () => {
      setSubscribeLearnerId(null);
    };
  }, [subscribeLearnerId]);

  switch (step) {
    case 1:
      VisibleComponent = (
        <React.Fragment>
          <AddLearnerDiv
            disabled={learnerLimit === allLearners.length}
            title={
              learnerLimit === allLearners.length
                ? t("parentDashboard.manage.limitReached")
                : ""
            }
          >
            <AddLearnersIcon src={addIcon} />
            <AddLearnerButton onClick={addNewLearner}>
              {t("parentDashboard.manage.addLearners")}
            </AddLearnerButton>
          </AddLearnerDiv>
          <LearnersList learners={learners} changePin />
          <UnsubscribedLearners onSubscribe={setSubscribeLearnerId} />
        </React.Fragment>
      );
      break;
    case 2:
      VisibleComponent = <AddLearner setStep={setStep} />;
      break;
    case 3:
      VisibleComponent = (
        <PaymentWrapper>
          <Payment learner={unsubscribedLearner} sm />
        </PaymentWrapper>
      );
      break;
    case 4:
      VisibleComponent = (
        <Wrapper>
          <PaymentSuccess
            onContinue={() => {
              setStep(5);
            }}
          />
        </Wrapper>
      );
      break;
    case 5:
      VisibleComponent = (
        <CredentialsWrapper>
          <LearnerPins setStep={setStep} />
        </CredentialsWrapper>
      );
      break;
    default:
      VisibleComponent = null;
  }
  return VisibleComponent;
};
