import { TournamentResult } from "../types/tournament";

export const sortTournamentResults = (
  tr: TournamentResult[]
): TournamentResult[] => {
  return tr
    .slice(0, 3) // skip winners
    .concat(
      tr
        .slice(3, tr.length)
        .sort(
          (a, b) => b.answers.correctlyAnswered - a.answers.correctlyAnswered
        )
    )
    .map((r, i) => {
      r.rank = ++i;
      return r;
    });
};
