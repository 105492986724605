import { LocalStorageKeys } from "../enums/LocalStorageKeys";

export const getToken = () => {
  return localStorage.getItem(LocalStorageKeys.TOKEN_KEY);
};

export const setToken = (value: string) => {
  localStorage.setItem(LocalStorageKeys.TOKEN_KEY, value);
};

export const removeToken = () => {
  localStorage.removeItem(LocalStorageKeys.TOKEN_KEY);
};

export const getItem = (key: LocalStorageKeys | string) => {
  return localStorage.getItem(key);
};

export const setItem = (key: LocalStorageKeys | string, value: string) => {
  localStorage.setItem(key, value);
};

export const removeItem = (key: LocalStorageKeys | string) => {
  return localStorage.removeItem(key);
};
