import { createSlice } from "@reduxjs/toolkit";
import { SliceNames } from "../enums/SliceNames";
import { Avatar } from "../types/avatars";

const initialState: { avatar: Avatar[] } = { avatar: [] };

export const avatarsSlice = createSlice({
  name: SliceNames.AvatarsSlice,
  initialState,
  reducers: {
    setAvatars: (state, { payload }) => {
      state.avatar = state.avatar.concat(payload);
    },
    removeAvatars: (state) => {
      state.avatar = initialState.avatar;
    },
  },
});

export const { setAvatars, removeAvatars } = avatarsSlice.actions;

export default avatarsSlice.reducer;
