import hat from "../../../assets/common/Profile-Settings/hat.svg";
import top from "../../../assets/common/Profile-Settings/top.svg";
import boots from "../../../assets/common/Profile-Settings/boots.svg";
import wand from "../../../assets/common/Profile-Settings/wand.svg";
import { ShopTab } from "../../../common/types/ShopTabs";

export type OnShopTabSelect = (tab: ShopTab) => void;

export const itemCategories: Array<{ icon: string; label: ShopTab }> = [
  {
    icon: hat,
    label: "hat",
  },
  {
    icon: top,
    label: "body",
  },
  {
    icon: boots,
    label: "boots",
  },
  {
    icon: wand,
    label: "other",
  },
];
