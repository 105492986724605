import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ErrorWrapper } from "../MonitorProgressStyled";
import { LearnersListItem } from "./LearnerListItem";
import { StyledError } from "../../../../common/components/Error/Error";
import { StoreState } from "../../../../store/type";
import { useLearners } from "../../../../common/hooks/useLearners";
import { useAsyncState } from "../../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../../store/enums/AsyncActions";
import { fetchLearnersProgress } from "../../../../store/thunks/Parent/fetchLearnerProgress";
import { Learner } from "../../../../store/types/learners";
import { LearnerProgress } from "../../../../store/types/learnerProgress";

export const LearnersList = () => {
  const learnersProgress = useSelector(
    (state: StoreState) => state.learnersProgress
  );

  const { activeLearners } = useLearners();
  const dispatch = useDispatch();

  const { error } = useAsyncState(AsyncActions.FetchLearnersProgress);

  const learnersProgressWithItems: Array<
    LearnerProgress & Pick<Learner, "items">
  > = learnersProgress.reduce((acc, lp) => {
    const learner = activeLearners.find((al) => al.username === lp.username);
    if (learner) {
      return [...acc, { ...lp, items: learner.items }];
    }
    return acc;
  }, [] as Array<LearnerProgress & Pick<Learner, "items">>);

  useEffect(() => {
    dispatch(fetchLearnersProgress());
  }, []);

  return error ? (
    <ErrorWrapper>
      <StyledError bottomMargin="1rem" topMargin="1rem">
        {error}
      </StyledError>
    </ErrorWrapper>
  ) : (
    <Fragment>
      {learnersProgressWithItems?.map((learner) => {
        return (
          <LearnersListItem
            key={learner.username}
            overallProgress={learner.overallProgress}
            totalPoints={learner.totalPoints}
            name={`${learner.firstName} ${learner.lastName}`}
            grade={learner.activeClass.name}
            avatar={learner.avatar?.image}
            tournamentPoints={learner.tournamentPoints}
            items={learner.items}
          />
        );
      })}
    </Fragment>
  );
};
