import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store/type";
import { AsyncActions } from "../../store/enums/AsyncActions";
import {
  setErrorAction,
  setNotification,
  setSuccess,
} from "../../store/slices/async";

export const useAsyncState = (key: AsyncActions) => {
  const requestInProgress = useSelector(
    (state: StoreState) => state.async.requestInProgress[key]
  );
  const error = useSelector((state: StoreState) => state.async.errors[key]);
  const notification = useSelector(
    (state: StoreState) => state.async.notifications[key]
  );
  const success = useSelector((state: StoreState) => state.async.success[key]);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(
        setErrorAction({
          key,
          value: null,
        })
      );
      dispatch(setSuccess({ key, value: false }));
    };
  }, []);

  useEffect(() => {
    if (requestInProgress) {
      dispatch(
        setErrorAction({
          key,
          value: null,
        })
      );
      dispatch(setSuccess({ key, value: false }));
    }
  }, [requestInProgress]);

  const clearNotification = () => {
    dispatch(setNotification({ key, value: null }));
  };

  const clearSuccess = () => {
    dispatch(setSuccess({ key, value: false }));
  };

  return {
    requestInProgress,
    error,
    notification,
    clearNotification,
    success,
    clearSuccess,
  };
};
