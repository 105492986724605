import { Consent } from "../../types/user";
import { ReduxThunk } from "../../type";
import {
  setBusyAction,
  setErrorAction,
  setNotification,
} from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { updateConsent } from "../../slices/learners";
import { TournamentUsernamePermission } from "../../../common/enums/TournamentUsernamePermission";
import i18n from "../../../i18n";

export const updateTournamentConsent =
  (
    username: string,
    tournamentUsernamePermission: TournamentUsernamePermission
  ): ReduxThunk =>
  async (dispatch, _, { client }) => {
    const key = `${AsyncActions.TournamentUsernameConsent}/${username}` as any;
    dispatch(
      setBusyAction({
        key,
        value: true,
      })
    );
    try {
      const { data } = await client.post<Consent>(
        `${ApiRoutes.TournamentUsernameConsent}/${username}`,
        { tournamentUsernamePermission }
      );
      dispatch(updateConsent(data));
      dispatch(setNotification({ key, value: "success" }));
    } catch (e) {
      dispatch(
        setErrorAction({
          key,
          value: i18n.t("response.500"),
        })
      );
    }
    dispatch(
      setBusyAction({
        key,
        value: false,
      })
    );
  };
