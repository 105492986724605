import { Fragment, useEffect } from "react";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store/type";
import {
  NoResultWrapper,
  QueryWrapper,
  SearchResultWrapper,
  Title,
} from "./styled";
import { AsyncActions } from "../../store/enums/AsyncActions";
import { useAsyncState } from "../../common/hooks/useAsyncState";
import { Spinner } from "../../common/components/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import { reset } from "../../store/slices/search";
import { isEmpty } from "lodash";
import { getRoute } from "../../common/utils/getRoute";
import { RoutePath } from "../../common/consts/route-path";
import { env } from "../../common/config/env";
import { searchLessons } from "../../store/thunks/Learner/searchLessons";
import { addDepository } from "../../store/thunks/Learner/lesson";
import { removeSubjectState } from "../../store/slices/subject";
import { SearchItem } from "./SearchItem";

export const Search = () => {
  const searchResults = useSelector((state: StoreState) => state.search.result);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const query = searchParams.get("query") || "";
  const { requestInProgress } = useAsyncState(AsyncActions.SearchLessons);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const name = useSelector(
    (state: StoreState) => state.user.info.activeClass?.name
  );
  const { lessonCode } = useParams();

  const navigateHandler = (
    subjectCode: string,
    lessonCode: string,
    lessonId: string
  ) => {
    dispatch(addDepository(lessonId));
    dispatch(removeSubjectState());

    navigate(
      getRoute(
        {
          subjectCode,
          lessonCode,
        },

        RoutePath.lessonPage.locale[env.locale]
      )
    );
  };

  useEffect(() => {
    if (query && name) {
      dispatch(searchLessons(name, query, lessonCode));
    }
  }, [query]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <Fragment>
      {requestInProgress ? (
        <Spinner />
      ) : !isEmpty(searchResults) ? (
        <SearchResultWrapper>
          <QueryWrapper>
            <Title>{`${t("searchPage.results")} '${query}'`}</Title>
          </QueryWrapper>
          {Object.keys(searchResults).map((subjectTitle) =>
            Object.entries(searchResults[subjectTitle]).map(
              ([sectionTitle, lessons]) => (
                <Fragment key={sectionTitle}>
                  {lessons.map((l) => (
                    <SearchItem
                      key={l.id}
                      name={l.name}
                      subject={l.subject.name}
                      section={sectionTitle}
                      onClick={() =>
                        navigateHandler(l.subject.code, l.code, l.id)
                      }
                    />
                  ))}
                </Fragment>
              )
            )
          )}
        </SearchResultWrapper>
      ) : (
        <NoResultWrapper>
          <Title>{t("searchPage.noResult")}</Title>
          <Title>{t("searchPage.tryAgain")}</Title>
        </NoResultWrapper>
      )}
    </Fragment>
  );
};
