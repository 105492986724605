import React from "react";

import {
  WithPoints,
  NotViewedIndicator,
  CompletedIndicator,
  Indicator,
  CompletedIcon,
} from "./LessonsPopupStyled";
import completedIcon from "../../../assets/common/Learner-Home/Lessons Popup/Completed.svg";

type Props = {
  selected: boolean;
  completed: boolean;
  hasCompletedTasks: boolean;
};

export const LessonStatusIndicator = ({
  selected,
  completed,
  hasCompletedTasks,
}: Props) => {
  if (selected) {
    return <Indicator />;
  } else if (completed) {
    return (
      <CompletedIndicator>
        <CompletedIcon src={completedIcon} />
      </CompletedIndicator>
    );
  } else if (hasCompletedTasks) {
    return <WithPoints />;
  } else {
    return <NotViewedIndicator />;
  }
};
