import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Menu from "rc-menu";

import { StoreState } from "../../../store/type";
import { removeToken } from "../../utils/localStorageService";
import { logOutTask } from "../../../store/thunks/logOutTask";
import { MenuItem } from "../MenuItem/MenuItem";
import { Typography } from "../Typography/Typography";

import anonymousIcon from "../../../assets/common/Header/Anonymous.svg";

import logo from "../../../assets/common/logo.svg";

import { HeaderStyled, Icon, IconDiv } from "./HeaderStyled";
import Dropdown from "rc-dropdown";
import { redirect } from "../../../store/slices/router";
import { useUserHomeRoute } from "../../hooks/useUserHomeRoute";

export const ParentLoggedIn = () => {
  const homeRoute = useUserHomeRoute();
  const { username } = useSelector((state: StoreState) => state.user.info);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSelect = ({ key }: { key: string }) => {
    if (key === "logout") {
      removeToken();
      dispatch(logOutTask());
    }
  };

  const toHomeNavigate = () => {
    dispatch(redirect({ to: `${homeRoute}?step=1` }));
  };

  const overlay = () => {
    return (
      <Menu onSelect={handleSelect}>
        <MenuItem key={username}>
          <Link to={`${homeRoute}?step=1`}>
            <Typography> {t("header.home")}</Typography>
          </Link>
        </MenuItem>
        {/*<MenuItem key="profile">
          <Link to={homeRoute}>
            <Typography>{t("header.profile")}</Typography>
          </Link>
    </MenuItem>*/}

        <MenuItem key="logout">
          <Typography>{t("header.logout")}</Typography>
        </MenuItem>
      </Menu>
    );
  };
  return (
    <HeaderStyled>
      <img
        src={logo}
        onClick={toHomeNavigate}
        className="mr-auto h-12 cursor-pointer"
      />
      {/* <IconDiv >
        <Icon src={notificationIcon} />
      </IconDiv>*/}

      <Dropdown trigger="click" animation="slide-up" overlay={overlay}>
        <IconDiv>
          <Icon src={anonymousIcon} />
        </IconDiv>
      </Dropdown>
    </HeaderStyled>
  );
};
