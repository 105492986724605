import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { env } from "../../common/config/env";
import { RoutePath } from "../../common/consts/route-path";
import { UserRole } from "../../common/enums/UserRole";
import { StoreState } from "../../store/type";

const LearningPage = () => {
  const role = useSelector((state: StoreState) => state.user.info.role);

  if (!(role === UserRole.Learner)) {
    return <Navigate to={RoutePath.home.locale[env.locale]} />;
  }

  return <Outlet />;
};

export default LearningPage;
