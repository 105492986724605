import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch } from "react-redux";
import { loginChild } from "../../../store/thunks/user";

import { LabelStyled } from "../../../common/components/Input/Input";
import FormTitle from "../../../common/components/FormTitle/FormTitle";
import Button from "../../../common/components/Button/Button";
import Form from "../../../common/components/Form/Form";
import Input from "../../../common/components/Input/Input";
import Pin from "../../../common/components/Pin/Pin";

import {
  getItem,
  removeItem,
  setItem,
} from "../../../common/utils/localStorageService";
import { LocalStorageKeys } from "../../../common/enums/LocalStorageKeys";

import { studentValidationSchema } from "../../../views/validation/loginValidationSchema";

import RegisterButtons from "../RegisterButtons/RegisterButtons";
import RememberUserCard from "../RememberUserCard/RemeberUserCard";

import inputIcon from "../../../assets/common/input-icon.svg";

import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { LoginForm } from "../../../common/types/LoginForm";
import { RememberUserInput } from "../RememberUserInput/RememberUserInput";

import { StyledError } from "../../../common/components/Error/Error";
import { useTranslation } from "react-i18next";

const Student = (props: any) => {
  const { requestInProgress, error } = useAsyncState(
    AsyncActions.LoginStudentAction
  );
  const dispatch = useDispatch();
  const [rememberUser, setRememberUser] = useState(false);
  const { t } = useTranslation();
  const [pinValue, setPinValue] = useState("");

  const [storedStudent, setStoredStudent] = useState(
    getItem(LocalStorageKeys.REMEMBERED_CHILD)
  );

  const { register, formState, setValue, handleSubmit, clearErrors } =
    useForm<LoginForm>({
      mode: "onTouched",
      resolver: yupResolver(studentValidationSchema()),
    });

  const onLogin: SubmitHandler<LoginForm> = (data) => {
    dispatch(loginChild(data));
    if (rememberUser) {
      setItem(LocalStorageKeys.REMEMBERED_CHILD, data.username);
    }
  };

  useEffect(() => {
    if (pinValue.length === 4) {
      clearErrors("password");
    }
    setValue("password", pinValue);
  }, [pinValue]);

  const removeStoredParent = () => {
    removeItem(LocalStorageKeys.REMEMBERED_CHILD);
    setStoredStudent(null);
  };

  return (
    <React.Fragment>
      <Form className="w-full" onSubmit={handleSubmit(onLogin)}>
        <FormTitle>{t("login.student.title")}</FormTitle>
        {storedStudent && (
          <RememberUserCard
            onTabChange={props.onStudentTabChange}
            removeUser={removeStoredParent}
            username={storedStudent}
          />
        )}
        <Input
          name="username"
          registerInput={register}
          required
          type="text"
          labelText={t("inputs.username.label")}
          placeholderText={t("inputs.username.placeholder")}
          inputWidth="100%"
          bottomMargin="0.883rem"
          icon={inputIcon}
        />
        {formState.errors.username ? (
          <StyledError topMargin="-0.441rem" bottomMargin="0.883rem">
            {formState.errors.username?.message}
          </StyledError>
        ) : null}
        <LabelStyled>{t("login.pin.label")}</LabelStyled>
        <Pin pinLength={4} bottomMargin="0.883rem" setPinValue={setPinValue} />
        {formState.errors.password ? (
          <StyledError topMargin="-0.441rem" bottomMargin="0.883rem">
            {formState.errors.password?.message}
          </StyledError>
        ) : null}
        <RememberUserInput
          inputId="student"
          value={rememberUser}
          onChange={setRememberUser}
        />
        {error && (
          <StyledError centered topMargin="-0.441rem" bottomMargin="0.883rem">
            {error}
          </StyledError>
        )}
        <Button
          resPending={requestInProgress}
          bottomMargin="1.766rem"
          invert={false}
        >
          {t("login.button.login")}
        </Button>
      </Form>
      <RegisterButtons />
    </React.Fragment>
  );
};

export default Student;
