import tw, { styled } from "twin.macro";

export const HomeWrapperStyled = styled.div(
  () => tw`flex flex-col min-h-app bg-white items-center `
);

export const ContentWrapperStyled = styled.div(
  () => tw`flex w-full flex-col bg-white items-center px-4 md:px-20 lg:px-38`
);

export const SectionWrapper = styled.div(
  () =>
    tw`w-full box-border py-6 px-8 lg:px-48 flex flex-col items-center border border-light-gray-secondary mb-4`
);

export const SectionTitle = styled.h2(
  () => tw`font-bold text-black text-2xl mb-7 font-dyslexic`
);
