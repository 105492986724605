import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { loginParent } from "../../../store/thunks/user";

import FormTitle from "../../../common/components/FormTitle/FormTitle";
import Form from "../../../common/components/Form/Form";
import Button from "../../../common/components/Button/Button";
import Input from "../../../common/components/Input/Input";

import { parentValidationSchema } from "../../../views/validation/loginValidationSchema";

import {
  getItem,
  setItem,
  removeItem,
} from "../../../common/utils/localStorageService";
import { LocalStorageKeys } from "../../../common/enums/LocalStorageKeys";

import RegisterButtons from "../RegisterButtons/RegisterButtons";
import RememberUserCard from "../RememberUserCard/RemeberUserCard";

import inputIcon from "../../../assets/common/input-icon.svg";
import { LoginForm } from "../../../common/types/LoginForm";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { useAsyncState } from "../../../common/hooks/useAsyncState";

import { RememberUserInput } from "../RememberUserInput/RememberUserInput";

import { StyledError } from "../../../common/components/Error/Error";

import { useTranslation } from "react-i18next";
import { ResetPasswordSpan } from "../LoginStyled";
import { Link } from "react-router-dom";
import { env } from "../../../common/config/env";
import { RoutePath } from "../../../common/consts/route-path";

const Parent = (props: any) => {
  const dispatch = useDispatch();
  const { error, requestInProgress } = useAsyncState(
    AsyncActions.LoginParentAction
  );
  const [rememberUser, setRememberUser] = useState(false);
  const { t } = useTranslation();

  const { register, formState, handleSubmit } = useForm<LoginForm>({
    mode: "onTouched",
    resolver: yupResolver(parentValidationSchema()),
  });

  const [storedParent, setStoredParent] = useState<string | null>(
    getItem(LocalStorageKeys.REMEMBERED_PARENT)
  );

  const removeStoredParent = () => {
    removeItem(LocalStorageKeys.REMEMBERED_PARENT);
    setStoredParent(null);
  };

  const onLogin = (data: LoginForm) => {
    dispatch(loginParent(data));
    if (rememberUser) {
      setItem(LocalStorageKeys.REMEMBERED_PARENT, data.username);
    }
  };

  return (
    <React.Fragment>
      <Form className="w-full" onSubmit={handleSubmit(onLogin)}>
        <FormTitle>{t("login.parent.title")}</FormTitle>
        {storedParent && (
          <RememberUserCard
            onTabChange={props.onParentTabChange}
            removeUser={removeStoredParent}
            username={storedParent}
          />
        )}
        <Input
          registerInput={register}
          name="username"
          type="text"
          required
          labelText={t("inputs.email.label")}
          placeholderText={t("inputs.email.placeholder")}
          inputWidth="100%"
          bottomMargin="0.883rem"
          icon={inputIcon}
        />
        {formState.errors.username ? (
          <StyledError topMargin="-0.441rem" bottomMargin="0.883rem">
            {formState.errors.username?.message}
          </StyledError>
        ) : null}
        <Input
          registerInput={register}
          name="password"
          required
          type="password"
          labelText={t("inputs.password.label")}
          placeholderText={t("inputs.password.placeholder")}
          inputWidth="100%"
          bottomMargin="0.883rem"
        />
        {formState.errors.password ? (
          <StyledError topMargin="-0.441rem" bottomMargin="0.883rem">
            {formState.errors.password?.message}
          </StyledError>
        ) : null}

        <RememberUserInput
          inputId="parent"
          value={rememberUser}
          onChange={setRememberUser}
        />
        {error && (
          <StyledError centered topMargin="-0.441rem" bottomMargin="0.883rem">
            {error}
          </StyledError>
        )}
        <Button resPending={requestInProgress} type="submit" invert={false}>
          {t("login.button.login")}
        </Button>
      </Form>
      <Link to={RoutePath.forgotPassword.locale[env.locale]}>
        <ResetPasswordSpan>
          {t("login.button.forgotPassword")}
        </ResetPasswordSpan>
      </Link>
      <RegisterButtons />
    </React.Fragment>
  );
};

export default Parent;
