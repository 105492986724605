import tw, { styled, css } from "twin.macro";

interface SuccessProps {
  centered?: boolean;
  bottomMargin: string;
  topMargin: string;
}
export const StyledSuccess = styled.p<SuccessProps>(
  ({ centered, bottomMargin, topMargin }) => [
    tw` font-normal text-black font-sans`,
    centered && tw`text-center`,
    css`
      margin-bottom: ${bottomMargin};
      margin-top: ${topMargin};
    `,
  ]
);
