import tw, { styled } from "twin.macro";

export const Wrapper = styled.div(
  () => tw`w-full sm:w-[25rem] max-w-[25rem] flex flex-col items-center`
);

export const GendersWrapper = styled.div(
  () => tw`flex  items-center justify-center`
);

export const GenderLabel = styled.label(
  () => tw`cursor-pointer last:ml-4 last:sm:ml-18 flex flex-col items-center`
);

export const GenderDiv = styled.div(({ selected }: { selected?: boolean }) => [
  tw`w-20 h-20 sm:w-[7.5rem] sm:h-[7.5rem] relative bg-white rounded-full border-2 border-gold-secondary flex items-end justify-center`,
  selected && tw`border-green`,
]);

export const GenderSpan = styled.span(
  () => tw`mt-3 font-medium text-lg text-black font-sans opacity-60`
);

export const SelectedMark = styled.img(
  () =>
    tw`absolute bottom-14 left-[3.3rem] w-6 sm:w-[unset] sm:bottom-24 sm:left-[5.5rem]`
);

export const GenderAvatar = styled.img();

export const GenderInput = styled.input(() => tw`hidden`);
