import { TournamentResultStatus } from "../../enums/TournamentResultStatus";
import { TFunction } from "react-i18next";

export const getDuration = (endDate: string, startDate: string) => {
  const diffInMs = new Date(endDate).getTime() - new Date(startDate).getTime();
  const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
  const diffInMinutes = Math.floor((diffInMs / (1000 * 60)) % 60);

  return `${diffInHours > 0 ? `${diffInHours}h` : ""}${
    diffInHours > 0 && diffInMinutes > 0 ? ":" : ""
  }${diffInMinutes > 0 ? `${diffInMinutes}m` : ""}`;
};

export const getParticipationTranslation = (
  status: TournamentResultStatus | undefined,
  pointsEarned: number | undefined,
  t: TFunction<"translation", undefined>
) => {
  return status === TournamentResultStatus.Completed
    ? pointsEarned
    : status === TournamentResultStatus.Started
    ? t("tournaments.pastTournamentDetails.notCompleted")
    : t("tournaments.pastTournamentDetails.noParticipation");
};
