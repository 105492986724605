import { Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { linkStyle, paragraphStyle } from "../../FooterStyle/FooterStyle";
import { RoutePath } from "../../consts/route-path";
import { env } from "../../config/env";

const DefaultFooter = () => {
  const { t } = useTranslation();

  return (
    <footer className="px-36 py-6 md:h-12 md:py-0 bg-white drop-shadow flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 items-center">
      <div className="text-center flex flex-col md:flex-row items-center md:mr-auto">
        <p className="font-sans text-base font-bold">{t("trademark.yuno")}</p>
        <span className={`${paragraphStyle} mb-2 md:mb-0 md:ml-2 md:mr-2`}>
          .
        </span>
        <p className={`${paragraphStyle} text-center`}>
          {t("trademark.copyright", { year: new Date().getFullYear() })}
        </p>
      </div>
      <div className="space-y-4 flex flex-col md:flex-row items-center md:space-x-2 md:space-y-0">
        <a
          target={"_blank"}
          href={env.generalConditionLink}
          className={linkStyle}
          rel="noreferrer"
        >
          {t("trademark.conditions")}
        </a>
        <span className={`${linkStyle}`}> . </span>
        <a
          target={"_blank"}
          href={env.privacyPolicyLink}
          className={linkStyle}
          rel="noreferrer"
        >
          {t("trademark.dataProtection")}
        </a>
      </div>
    </footer>
  );
};

const Footer = () => {
  return (
    <Routes>
      <Route path={`${RoutePath.lessonPage.locale[env.locale]}`}>
        <Route index element={<DefaultFooter />} />
      </Route>
      <Route path="*" element={<DefaultFooter />} />
    </Routes>
  );
};

export default Footer;
