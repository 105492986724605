import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  ActionButton,
  ActionButtonWrapper,
  ActionWrapper,
  PermissionChosenIcon,
  Username,
  IconWrapper,
} from "./styled";
import checked from "../../../assets/common/Survey/check-round.svg";
import unchecked from "../../../assets/common/Survey/cancel-round.svg";
import { TournamentUsernamePermission } from "../../../common/enums/TournamentUsernamePermission";
import { updateTournamentConsent } from "../../../store/thunks/Parent/tournamentConsent";
import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../store/enums/AsyncActions";

type Props = {
  username: string;
  permission: TournamentUsernamePermission;
  closeOnActionDone?: boolean;
  onClose: () => void;
};

export const TournamentConsentAction = ({
  username,
  permission: incomingPermission,
  closeOnActionDone,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const key = `${AsyncActions.TournamentUsernameConsent}/${username}` as any;
  const { requestInProgress, notification, clearNotification } =
    useAsyncState(key);

  const onAction =
    (username: string, permission: TournamentUsernamePermission) => () => {
      if (permission === incomingPermission && closeOnActionDone) {
        onClose();
        return;
      }
      dispatch(updateTournamentConsent(username, permission));
    };

  useEffect(() => {
    return () => {
      clearNotification();
    };
  }, []);

  useEffect(() => {
    let id: NodeJS.Timeout;
    if (!!notification && closeOnActionDone && onClose) {
      id = setTimeout(onClose, 400);
    }
    return () => {
      clearTimeout(id);
    };
  }, [notification, closeOnActionDone]);

  return (
    <ActionWrapper>
      <Username>{username}</Username>
      <ActionButtonWrapper>
        {notification === "success" ? (
          <IconWrapper>
            <PermissionChosenIcon
              src={
                incomingPermission === TournamentUsernamePermission.ShowUsername
                  ? checked
                  : unchecked
              }
            />
          </IconWrapper>
        ) : (
          <Fragment>
            <ActionButton
              onClick={onAction(
                username,
                TournamentUsernamePermission.ShowUsername
              )}
              resPending={requestInProgress}
            >
              {t(
                "parentDashboard.monitorProgress.consentTournamentModal.acceptBtnLabel"
              )}
            </ActionButton>
            <ActionButton
              invert
              onClick={onAction(
                username,
                TournamentUsernamePermission.HideUsername
              )}
              resPending={requestInProgress}
            >
              {t(
                "parentDashboard.monitorProgress.consentTournamentModal.declineBtnLabel"
              )}
            </ActionButton>
          </Fragment>
        )}
      </ActionButtonWrapper>
    </ActionWrapper>
  );
};
