import { FC, HTMLProps } from "react";
import tw, { styled, css } from "twin.macro";

interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  invert?: boolean;
  buttonWidth?: string;
  type?: any;
  bottomMargin?: string;
  topMargin?: string;
  padding?: string;
  resPending?: boolean;
  sm?: boolean;
  alignLeft?: boolean;
  buttonHeight?: string;
  success?: boolean;
}

interface ButtonStyledProps extends HTMLProps<HTMLButtonElement> {
  invert?: boolean;
  buttonWidth?: string;
  bottomMargin?: string | undefined;
  topMargin?: string | undefined;
  resPending?: boolean;
  sm?: boolean;
  alignLeft?: boolean;
  buttonHeight?: string;
  success?: boolean;
  padding?: string;
}

export const ButtonStyled = styled.button<ButtonStyledProps>(
  ({
    invert,
    buttonWidth,
    bottomMargin,
    topMargin,
    resPending,
    sm,
    alignLeft,
    buttonHeight,
    success,
    padding,
  }) => [
    tw`box-border rounded-[2.76rem] h-12 font-semibold font-sans border border-dark-blue inline-flex items-center justify-center`,
    buttonWidth
      ? css`
          width: ${buttonWidth};
        `
      : css`
          width: 100%;
        `,
    bottomMargin &&
      css`
        margin-bottom: ${bottomMargin};
      `,
    topMargin &&
      css`
        margin-top: ${topMargin};
      `,
    invert ? tw`text-dark-blue bg-transparent` : tw`text-white bg-dark-blue`,
    success && tw`bg-light-green-secondary border-green`,
    resPending &&
      tw`text-dark-gray bg-light-gray border-none cursor-not-allowed`,
    sm && tw`text-xs md:text-base`,
    alignLeft && tw`text-left justify-start px-6`,
    buttonHeight &&
      css`
        height: ${buttonHeight};
      `,
    padding &&
      css`
        @media only screen and (max-width: 380px) {
          padding: ${padding};
        }
      `,
  ]
);

const Button: FC<ButtonProps> = (props: ButtonProps) => {
  return (
    <ButtonStyled
      resPending={props.resPending || false}
      invert={props.invert}
      buttonWidth={props.buttonWidth}
      bottomMargin={props.bottomMargin}
      topMargin={props.topMargin}
      type={props.type}
      sm={props.sm}
      padding={props.padding}
      alignLeft={props.alignLeft}
      buttonHeight={props.buttonHeight}
      disabled={props.resPending}
      success={props.success}
      onClick={props.onClick}
    >
      {props.children}
    </ButtonStyled>
  );
};

export default Button;
