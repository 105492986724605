import Collapse, { Panel } from "rc-collapse";
import tw, { styled } from "twin.macro";

export const Wrapper = styled.div(
  () => tw`hidden lg:flex flex-col lg:flex-row w-full items-center`
);

export const CloseDiv = styled.div(
  () => tw`w-full flex items-center px-8 pt-8`
);

//Lessons List

export const ListDiv = styled.div(() => [
  tw`hidden w-full lg:w-100 order-2 lg:order-1 [::-webkit-scrollbar-track-piece]:rounded-lg lg:flex flex-col lg:rounded-lg lg:h-[31.25rem] [::-webkit-scrollbar]:h-full [::-webkit-scrollbar]:w-2 [::-webkit-scrollbar-track]:bg-transparent [::-webkit-scrollbar-thumb]:bg-dark-gray lg:overflow-scroll bg-light-gray-secondary py-4 pl-4`,
]);

export const LessonDiv = styled.div(() => tw`flex items-center space-x-2 mb-3`);

export const InnerLessonDiv = styled.div(
  ({ selected }: { selected?: boolean }) => [
    tw`flex flex-col px-4 py-3 rounded-lg w-full cursor-pointer`,
    selected ? tw`bg-white` : tw`bg-transparent`,
  ]
);

export const LessonNameList = styled.h4(
  () => tw`font-sans font-semibold text-black-secondary`
);

export const Indicator = styled.div(
  () =>
    tw`w-5 h-5 flex-grow-0 flex-shrink-0 box-border border border-gold rounded-full bg-gold`
);

export const WithPoints = styled(Indicator)(() => tw`bg-error-450`);

export const NotViewedIndicator = styled(Indicator)(() => tw`bg-white`);

export const CompletedIndicator = styled(Indicator)(
  () => tw`flex items-center justify-center border-green rounded-full bg-green`
);

export const CompletedIcon = styled.img();

// Lesson info

export const InfoDiv = styled.div(() => [
  tw`w-full hidden lg:w-100 order-1 lg:order-2 flex flex-col items-center lg:h-[31.25rem] [::-webkit-scrollbar-track-piece]:rounded-lg flex flex-col lg:rounded-lg [::-webkit-scrollbar]:h-full [::-webkit-scrollbar]:w-2 [::-webkit-scrollbar-track]:bg-transparent [::-webkit-scrollbar-thumb]:bg-dark-gray lg:rounded-lg overflow-scroll bg-white px-6 py-8 relative`,
]);

export const LessonName = styled.h3(
  () =>
    tw`font-sans text-lg mb-4 font-semibold text-black-secondary text-center`
);

export const ValuesDiv = styled.div(
  () =>
    tw`flex flex-shrink-0 h-[2.5rem] mb-6 items-center w-full bg-yellow px-3 lg:rounded-lg`
);

export const ValuesIcon = styled.img(() => tw`mr-3`);

export const PointsSpan = styled.span(
  () =>
    tw`font-sans text-sm font-semibold text-black-secondary opacity-60 mr-auto lowercase`
);

export const TimeSpan = styled.span(
  () => tw`font-sans text-sm font-semibold text-black-secondary opacity-60`
);

export const LessonInfoHeading = styled.h5(
  () =>
    tw`font-sans text-black-secondary self-start font-semibold mb-2 pl-2 lg:pl-0`
);

export const LessonInfo = styled.div(
  () =>
    tw`self-start font-sans text-sm text-black-secondary [& *]:(font-sans text-sm text-black-secondary) mb-10 pl-2 lg:pl-0`
);

export const CollapseStyled = styled(Collapse)(
  () => tw`lg:hidden bg-light-gray-secondary overflow-auto w-full py-4 pl-4`
);
export const PanelStyled = styled(Panel)(() => tw``);

export const PanelContentWrapper = styled.div(
  () => tw`mx-2 bg-white flex flex-col items-center`
);

export const SpinnerWrapper = styled.div(() => tw`relative h-30 w-full`);

export const ErrorWrapper = styled.div(() => tw`h-30 flex items-center w-full`);

export const StyledSpinnerWrapper = styled.div(() => tw`h-[90vh] lg:h-[32rem]`);
