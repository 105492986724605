import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useUserHomeRoute } from "../../common/hooks/useUserHomeRoute";
import { InactiveSubscription } from "../../components/InactiveSubscription/InactiveSubscription";
import { StoreState } from "../../store/type";

export const InactiveSubscriptionPage = () => {
  const { activeSubscription } = useSelector(
    (state: StoreState) => state.user.info
  );
  const homeRoute = useUserHomeRoute();

  if (activeSubscription) {
    return <Navigate to={homeRoute} replace />;
  }

  return <InactiveSubscription />;
};
