import {
  PointsStyled,
  PointsImageStyled,
  InnerPointsDivStyled,
} from "./styled";
import starIcon from "../../../assets/common/Learner-Home/Star.svg";

type Props = {
  points: number;
  small?: boolean;
};

export const Points = ({ points, small = false }: Props) => {
  return (
    <InnerPointsDivStyled smallPadding={small} transparent={small}>
      <PointsImageStyled src={starIcon} size={"xs"} />
      <PointsStyled>{points}</PointsStyled>
    </InnerPointsDivStyled>
  );
};
