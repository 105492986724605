import { useTranslation } from "react-i18next";
import * as LearnerListStyled from "./LearnersListStyled";
import avatarIcon from "../../../../assets/common/Parent-Monitor/Avatar.svg";
import starIcon from "../../../../assets/common/Parent-Monitor/Star.svg";
import cupIcon from "../../../../assets/common/Header/Cup.svg";
import { buildStyles } from "react-circular-progressbar";
import colors from "../../../../colors.json";
import { GlobalStyles } from "twin.macro";
import React from "react";
import { LearnerItem } from "../../../../store/types/user";
import { Avatar } from "../../../../common/components/Avatar/Avatar";

type LearnersListItemProps = {
  name: string | undefined;
  grade: number | undefined;
  overallProgress: number;
  totalPoints: number;
  avatar?: string;
  tournamentPoints: number;
  items: LearnerItem[];
};

export const LearnersListItem = ({
  name,
  grade,
  overallProgress,
  totalPoints,
  avatar,
  tournamentPoints,
  items,
}: LearnersListItemProps) => {
  const { t } = useTranslation();

  return (
    <LearnerListStyled.LearnerWrapper>
      <LearnerListStyled.LearnerInfo>
        <LearnerListStyled.Heading>
          {t("parentDashboard.monitorProgress.learnerName")}
        </LearnerListStyled.Heading>
        <LearnerListStyled.AvatarNameDiv>
          {avatar ? (
            <LearnerListStyled.AvatarDiv>
              <Avatar items={items.map((i) => i.avatarItem)} icon={avatar} />
            </LearnerListStyled.AvatarDiv>
          ) : (
            <LearnerListStyled.Avatar noDiv src={avatarIcon} />
          )}
          <LearnerListStyled.NameDiv>
            <LearnerListStyled.Value>{name}</LearnerListStyled.Value>
            <LearnerListStyled.Grade>
              {t("parentDashboard.monitorProgress.grade")} {grade}
            </LearnerListStyled.Grade>
          </LearnerListStyled.NameDiv>
        </LearnerListStyled.AvatarNameDiv>
      </LearnerListStyled.LearnerInfo>
      <LearnerListStyled.LearnerInfo>
        <LearnerListStyled.Heading>
          {t("parentDashboard.monitorProgress.points")}
        </LearnerListStyled.Heading>
        <LearnerListStyled.IconDiv>
          <LearnerListStyled.Icon src={starIcon} />
          <LearnerListStyled.Value>{totalPoints}</LearnerListStyled.Value>
        </LearnerListStyled.IconDiv>
      </LearnerListStyled.LearnerInfo>
      <LearnerListStyled.LearnerInfo>
        <LearnerListStyled.Heading>
          {t("parentDashboard.monitorProgress.tournamentPoints")}
        </LearnerListStyled.Heading>
        <LearnerListStyled.IconDiv>
          <LearnerListStyled.Icon src={cupIcon} />
          <LearnerListStyled.Value>{tournamentPoints}</LearnerListStyled.Value>
        </LearnerListStyled.IconDiv>
      </LearnerListStyled.LearnerInfo>
      <LearnerListStyled.LearnerInfo>
        <LearnerListStyled.Heading>
          {t("parentDashboard.monitorProgress.progress")}
        </LearnerListStyled.Heading>
        <LearnerListStyled.ProgressBarWrapper>
          <LearnerListStyled.ProgressBar
            value={overallProgress}
            strokeWidth={7}
            styles={buildStyles({
              trailColor: colors["light-gray-secondary"],
              pathColor: colors.green,
            })}
          >
            <LearnerListStyled.Value>
              {overallProgress}%
            </LearnerListStyled.Value>
          </LearnerListStyled.ProgressBar>
        </LearnerListStyled.ProgressBarWrapper>
      </LearnerListStyled.LearnerInfo>
      <GlobalStyles />
    </LearnerListStyled.LearnerWrapper>
  );
};
