import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { ChangeEvent, useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import Button from "../../../common/components/Button/Button";
import { StyledError } from "../../../common/components/Error/Error";
import Form from "../../../common/components/Form/Form";
import { addTraitValidationSchema } from "../../../views/validation/profileSetupValidationSchema";
import {
  ButtonDiv,
  Description,
  Heading,
  SubmitDiv,
} from "../ProfileSetupStyled";

import {
  AddButton,
  Input,
  InputWrapper,
  TraitDiv,
  TraitIcon,
  TraitInput,
  TraitLabel,
  TraitsGrid,
  TraitSpan,
  Wrapper,
  TraitWrapper,
  TraitWarning,
} from "./PersonalityTraitsStyled";
import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTraits,
  fetchUserTraits,
} from "../../../store/thunks/profileSetup/fetchTraits";
import { Spinner } from "../../../common/components/Spinner/Spinner";
import { StoreState } from "../../../store/type";
import { removeTraits } from "../../../store/slices/traits";
import { reset } from "../../../store/slices/async";
import {
  patchTraits,
  postTrait,
} from "../../../store/thunks/profileSetup/patchTraits";
import { Trait } from "../../../store/types/traits";

export const PersonalityTraits = ({
  setCurrentStep,
}: {
  setCurrentStep: (val: number) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { traits, userTraits } = useSelector(
    (state: StoreState) => state.traits
  );
  const { avatar } = useSelector((state: StoreState) => state.user.info);

  const [selectedTraits, setSelectedTraits] = useState<string[]>([
    ...userTraits.map((trait) => {
      return trait.id;
    }),
  ]);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [loadingState, setLoadingState] = useState<boolean>(true);
  const { requestInProgress: fetchTraitsRequest, error: fetchTraitsError } =
    useAsyncState(AsyncActions.FetchTraits);
  const { requestInProgress: postTraitsRequest, error: postTraitsError } =
    useAsyncState(AsyncActions.PostTrait);
  const {
    requestInProgress: fetchUserTraitsRequest,
    error: fetchUserTraitsError,
  } = useAsyncState(AsyncActions.FetchUserTraits);
  const {
    requestInProgress: patchTraitsInProgress,
    error: patchTraitsError,
    success: patchTraitsSuccess,
  } = useAsyncState(AsyncActions.PatchTraits);

  const { register, handleSubmit, formState, setError, clearErrors } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(addTraitValidationSchema()),
  });

  const addNewTrait: SubmitHandler<FieldValues> = (data) => {
    const selectedTraitsIndex = traits.findIndex((selectedTrait) => {
      return selectedTrait.name.toLowerCase() === data.addedTrait.toLowerCase();
    });

    if (selectedTraitsIndex >= 0) {
      return setError("traitExists", {
        type: "custom",
        message: t("profileSetup.personalityTraits.validation.exists"),
      });
    }

    dispatch(postTrait(data.addedTrait));
  };

  const clearErrorHandler = () => {
    if (formState.errors) {
      clearErrors();
    }
  };

  const onTraitSelect = (e: ChangeEvent<HTMLInputElement>) => {
    setSubmitError(null);

    const index = selectedTraits.findIndex((selectedTrait) => {
      return selectedTrait === e.target.value;
    });
    if (index >= 0) {
      return setSelectedTraits([
        ...selectedTraits.slice(0, index),
        ...selectedTraits.slice(index + 1),
      ]);
    } else {
      setSelectedTraits([...selectedTraits, e.target.value]);
    }
  };

  const checkboxShouldBeChecked = (trait: Trait) => {
    return selectedTraits.includes(trait.id);
  };

  const onClickBackHandler = () => {
    if (!avatar?.id) {
      setCurrentStep(4);
    } else {
      setCurrentStep(3);
    }
  };
  const onPressNextHandler = () => {
    if (selectedTraits.length === 0) {
      return setSubmitError(
        t("profileSetup.personalityTraits.validation.empty")
      );
    }
    if (selectedTraits.length > 3) {
      return setSubmitError(
        t("profileSetup.personalityTraits.validation.exceeds")
      );
    }
    dispatch(patchTraits(selectedTraits));
  };

  useEffect(() => {
    dispatch(fetchTraits());
    dispatch(fetchUserTraits());
    setLoadingState(false);
    return () => {
      dispatch(removeTraits());
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    setSelectedTraits([
      ...userTraits.map((userTrait) => {
        return userTrait.id;
      }),
    ]);
  }, [userTraits]);

  useEffect(() => {
    if (patchTraitsSuccess) {
      setCurrentStep(6);
    }
  }, [patchTraitsSuccess]);

  if (fetchTraitsError || fetchUserTraitsError) {
    return (
      <StyledError centered topMargin="auto" bottomMargin="auto">
        {fetchTraitsError || fetchUserTraitsError}
      </StyledError>
    );
  }
  return (
    <Wrapper>
      {fetchTraitsRequest || fetchUserTraitsRequest || loadingState ? (
        <Spinner fullscreen />
      ) : (
        <React.Fragment>
          <Heading smallMargin>
            {t("profileSetup.personalityTraits.heading")}
          </Heading>
          <Description smallMargin>
            {t("profileSetup.personalityTraits.description")}
          </Description>
          <Form
            className="w-full flex flex-col items-center"
            onSubmit={handleSubmit(addNewTrait)}
          >
            <InputWrapper>
              <Input
                {...register("addedTrait")}
                onChange={clearErrorHandler}
                type="text"
                placeholder={t("profileSetup.personalityTraits.placeholder")}
              />
              <AddButton resPending={postTraitsRequest}>
                {t("profileSetup.personalityTraits.addBtn")}
              </AddButton>
            </InputWrapper>
            {formState.errors?.traitExists && (
              <StyledError topMargin="-1.5rem" bottomMargin="2.5rem">
                {formState.errors.traitExists.message}
              </StyledError>
            )}
            {formState.errors?.addedTrait && (
              <StyledError topMargin="-1.5rem" bottomMargin="2.5rem">
                {formState.errors.addedTrait.message}
              </StyledError>
            )}
            {postTraitsError && (
              <StyledError topMargin="-1.5rem" bottomMargin="2.5rem">
                {postTraitsError}
              </StyledError>
            )}
          </Form>
          <TraitsGrid>
            {traits.map((trait) => {
              if (!trait.approved && !trait.newlyCreatedByUser) return null;
              return (
                <TraitWrapper key={trait.id}>
                  <TraitDiv>
                    <TraitInput
                      onChange={(e) => {
                        onTraitSelect(e);
                      }}
                      checked={checkboxShouldBeChecked(trait)}
                      id={trait.id}
                      value={trait.id}
                      type="checkbox"
                    />
                    <TraitLabel htmlFor={trait.id}>
                      {trait.image && <TraitIcon src={trait.image} />}
                      <TraitSpan>{trait.name}</TraitSpan>
                    </TraitLabel>
                  </TraitDiv>
                  {trait.newlyCreatedByUser && (
                    <TraitWarning>
                      {t("profileSetup.personalityTraits.newlyCreatedTrait")}
                    </TraitWarning>
                  )}
                </TraitWrapper>
              );
            })}
          </TraitsGrid>
          <SubmitDiv>
            {userTraits.filter((t) => !t.approved).length > 0 && (
              <TraitWarning>
                {t("profileSetup.personalityTraits.hasUnapprovedTraits", {
                  number: userTraits.filter((t) => !t.approved).length,
                  plural:
                    userTraits.filter((t) => !t.approved).length > 1 ? "s" : "",
                })}
              </TraitWarning>
            )}
            {submitError && (
              <StyledError centered bottomMargin="0" topMargin="0">
                {submitError}
              </StyledError>
            )}
            {patchTraitsError && (
              <StyledError centered bottomMargin="0" topMargin="0">
                {patchTraitsError}
              </StyledError>
            )}
            <ButtonDiv>
              <Button
                resPending={patchTraitsInProgress}
                onClick={onClickBackHandler}
                invert
                buttonWidth="100%"
                buttonHeight="2.5rem"
              >
                &larr; {t("profileSetup.backBtn")}
              </Button>
              <Button
                resPending={patchTraitsInProgress}
                onClick={onPressNextHandler}
                invert={false}
                buttonWidth="100%"
                buttonHeight="2.5rem"
              >
                {t("profileSetup.nextBtn")} &rarr;
              </Button>
            </ButtonDiv>
          </SubmitDiv>
        </React.Fragment>
      )}
    </Wrapper>
  );
};
