import { useTranslation } from "react-i18next";

import { ButtonDiv, Heading, SubmitDiv } from "../ProfileSetupStyled";
import {
  GenderAvatar,
  GenderDiv,
  GenderInput,
  GenderLabel,
  GenderSpan,
  GendersWrapper,
  SelectedMark,
  Wrapper,
} from "./SelectGenderStyled";
import boyAvatar from "../../../assets/common/Setup-Profile/Boy.svg";
import girlAvatar from "../../../assets/common/Setup-Profile/Girl.svg";
import selectedIcon from "../../../assets/common/Setup-Profile/Selected.svg";
import Button from "../../../common/components/Button/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { genderValidationSchema } from "../../../views/validation/profileSetupValidationSchema";
import Form from "../../../common/components/Form/Form";
import { StyledError } from "../../../common/components/Error/Error";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../store/type";
import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { useEffect } from "react";
import { reset } from "../../../store/slices/async";
import { patchGender } from "../../../store/thunks/profileSetup/patchGender";

export const SelectGender = ({
  setCurrentStep,
}: {
  setCurrentStep: (val: number) => void;
}) => {
  const { requestInProgress, error, success } = useAsyncState(
    AsyncActions.PatchGender
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userGender = useSelector((state: StoreState) => state.user.info.gender);
  const { avatar } = useSelector((state: StoreState) => state.user.info);

  const { register, formState, handleSubmit, watch } = useForm({
    mode: "onTouched",
    resolver: yupResolver(genderValidationSchema()),
    defaultValues: { gender: userGender },
  });

  const gender = watch("gender");

  const onNextPressHandler = (data: any) => {
    dispatch(patchGender(data.gender));
  };

  useEffect(() => {
    if (success) {
      if (!avatar?.id) {
        setCurrentStep(4);
      } else {
        setCurrentStep(5);
      }
    }
  }, [success]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <Form onSubmit={handleSubmit(onNextPressHandler)}>
      <Wrapper>
        <Heading>{t("profileSetup.selectGender.heading")}</Heading>
        <GendersWrapper>
          <GenderInput
            id="Female"
            type="radio"
            value="Female"
            {...register("gender")}
          />
          <GenderLabel htmlFor="Female">
            <GenderDiv selected={gender === "Female"}>
              <GenderAvatar src={girlAvatar} />
              {gender === "Female" && <SelectedMark src={selectedIcon} />}
            </GenderDiv>
            <GenderSpan>{t("profileSetup.selectGender.girl")}</GenderSpan>
          </GenderLabel>
          <GenderInput
            id="Male"
            type="radio"
            value="Male"
            {...register("gender")}
          />
          <GenderLabel htmlFor="Male">
            <GenderDiv selected={gender === "Male"}>
              <GenderAvatar src={boyAvatar} />
              {gender === "Male" && <SelectedMark src={selectedIcon} />}
            </GenderDiv>
            <GenderSpan>{t("profileSetup.selectGender.boy")}</GenderSpan>
          </GenderLabel>
        </GendersWrapper>
        <SubmitDiv>
          {formState.errors?.gender && (
            <StyledError centered bottomMargin="0" topMargin="0">
              {formState.errors.gender.message}
            </StyledError>
          )}
          {error && (
            <StyledError centered bottomMargin="0" topMargin="0">
              {error}
            </StyledError>
          )}
          <ButtonDiv>
            <Button
              resPending={requestInProgress}
              type="button"
              onClick={() => {
                setCurrentStep(2);
              }}
              invert
              buttonWidth="100%"
              buttonHeight="2.5rem"
            >
              &larr; {t("profileSetup.backBtn")}
            </Button>
            <Button
              resPending={requestInProgress}
              type="submit"
              invert={false}
              buttonWidth="100%"
              buttonHeight="2.5rem"
            >
              {t("profileSetup.nextBtn")} &rarr;
            </Button>
          </ButtonDiv>
        </SubmitDiv>
      </Wrapper>
    </Form>
  );
};
