import tw, { styled } from "twin.macro";

export const ValueDiv = styled.div(() => tw`w-full`);
export const Value = styled.span(
  () => tw`font-sans text-sm font-medium text-black w-full`
);

export const Pending = styled.span(
  () =>
    tw`font-sans rounded-lg text-center bg-light-gold bg-opacity-20 text-light-gold border border-black-light font-medium text-xs inline-block min-w-[5.625rem] py-2`
);

export const Paid = styled.span(
  () =>
    tw`font-sans rounded-lg text-center bg-green bg-opacity-20 text-green border border-black-light font-medium text-xs inline-block min-w-[5.625rem] py-2`
);

export const Failed = styled.span(
  () =>
    tw`font-sans rounded-lg text-center bg-danger bg-opacity-20 text-danger border border-black-light font-medium text-xs inline-block min-w-[5.625rem] py-2`
);

export const DownloadButton = styled.button<{ disabled?: boolean }>(
  ({ disabled }) => [tw`flex items-center`, disabled && tw`opacity-20`]
);

export const DownloadIcon = styled.img(() => tw`w-4 h-4 mr-2`);

export const DownloadSpan = styled.span(
  () => tw`font-sans font-medium text-sm text-dark-blue`
);

// small screen components

export const ItemWrapper = styled.div(
  () => tw`w-full flex flex-col relative xl:hidden bg-white mb-4`
);

export const DropdownTriggerButton = styled.button(() => tw`w-full`);

export const DropdownTriggerDiv = styled.div(
  () => tw`w-full px-6 py-4 bg-white rounded-lg flex items-center`
);

export const LearnerName = styled.span(
  () => tw`mr-auto font-sans text-sm font-semibold text-black`
);

export const PlusSpan = styled.span(
  () => tw`font-sans font-normal text-3xl text-dark-blue`
);

export const DropdownDiv = styled.div(({ open }: { open?: boolean }) => [
  tw` hidden`,
  open && tw` flex w-full py-4  px-1 sm:px-12 items-center justify-center`,
]);

export const HeadingDiv = styled.div(
  () => tw`flex flex-col pr-4 border-r border-dark-gray py-4`
);

export const SmallScreenHeading = styled.span(
  () => tw`font-sans text-black text-xs sm:text-sm opacity-70 mb-4`
);

export const SmallScreenValueDiv = styled.div(
  () => tw`pl-4 flex flex-col py-4`
);

export const SmallScreenValueSpan = styled.span(
  () =>
    tw` font-medium text-xs sm:text-sm font-sans text-black inline-block mb-4 `
);
