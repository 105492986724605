import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/user";
import asyncReducer from "./slices/async";
import routerReducer from "./slices/router";
import schoolReducer from "./slices/school";
import paymentReducer from "./slices/payment";
import subjectsSlice from "./slices/subject";
import learnerSlice from "./slices/learners";
import subscriptionSlice from "./slices/subscriptions";
import activityReducer from "./slices/activity";
import traitsReducer from "./slices/traits";
import avatarsReducer from "./slices/avatars";
import favoriteSubjectsReducer from "./slices/favoriteSubjects";
import learnersProgressReducer from "./slices/learnersProgress";
import paymentHistoryReducer from "./slices/paymentHistory";
import surveyReducer from "./slices/survey";
import searchReducer from "./slices/search";
import notificationReducer from "./slices/notification";
import tournamentReducer from "./slices/tournament";
import avatarItemsReducer from "./slices/avatar-item";
import assistantSliceReducer from "./slices/assistant";

import { client } from "../common/config/client";
import { env } from "../common/config/env";

export const store = configureStore({
  reducer: {
    user: userReducer,
    async: asyncReducer,
    router: routerReducer,
    schoolsState: schoolReducer,
    payments: paymentReducer,
    subjects: subjectsSlice,
    learners: learnerSlice,
    subscriptions: subscriptionSlice,
    activity: activityReducer,
    traits: traitsReducer,
    avatars: avatarsReducer,
    favoriteSubjects: favoriteSubjectsReducer,
    learnersProgress: learnersProgressReducer,
    paymentHistory: paymentHistoryReducer,
    survey: surveyReducer,
    search: searchReducer,
    notifications: notificationReducer,
    tournaments: tournamentReducer,
    avatarItems: avatarItemsReducer,
    assistant: assistantSliceReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: { client },
      },
    }),
  devTools: env.nodeEnv === "development",
});
