import { useEffect, useState } from "react";

export const useRefresh = (interval = 1000) => {
  const [state, setState] = useState(true);

  useEffect(() => {
    const id = setInterval(() => setState((pre) => !pre), interval);

    return () => {
      clearInterval(id);
    };
  }, [interval]);

  return state;
};
