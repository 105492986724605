import { Avatar } from "./Avatar";
import { useSelector } from "react-redux";
import { StoreState } from "../../../store/type";
import { ItemStatus } from "../../enums/ItemStatus";
import { AvatarItem } from "../../../store/types/avatar-items";

type Props = {
  noHoverTransform?: boolean;
};

export const AvatarWithSelectedItems = ({ noHoverTransform }: Props) => {
  const { items, avatar } = useSelector((state: StoreState) => state.user.info);

  const activeItems = items.reduce((acc, cur) => {
    if (cur.status === ItemStatus.Active) {
      acc.push(cur.avatarItem);
    }
    return acc;
  }, [] as AvatarItem[]);

  return (
    <Avatar
      noHoverTransform={noHoverTransform}
      items={activeItems}
      icon={avatar?.image}
    />
  );
};
