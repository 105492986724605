import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { Exception } from "../../../common/enums/Exceptions";
import i18n from "../../../i18n";
import { AsyncActions } from "../../enums/AsyncActions";
import { setBusyAction, setErrorAction, setSuccess } from "../../slices/async";
import { setFavoriteSubject } from "../../slices/favoriteSubjects";
import { ReduxThunk } from "../../type";

export const patchFavoriteSubjects: (subjectIds: string[]) => ReduxThunk = (
  subjectIds
) => {
  return async (dispatch, _, { client }) => {
    try {
      dispatch(
        setBusyAction({ key: AsyncActions.PatchFavoriteSubjects, value: true })
      );
      const response = await client.patch(ApiRoutes.FavoriteSubjects, {
        subjectIds,
      });

      dispatch(setFavoriteSubject(response.data));
      dispatch(
        setSuccess({ key: AsyncActions.PatchFavoriteSubjects, value: true })
      );
    } catch (err: any) {
      if (err.response?.data?.message === Exception.BadNumberOfSubjects) {
        dispatch(
          setErrorAction({
            key: AsyncActions.PatchFavoriteSubjects,
            value: i18n.t("profileSetup.chooseSubjects.errors.number"),
          })
        );
      } else {
        dispatch(
          setErrorAction({
            key: AsyncActions.PatchFavoriteSubjects,
            value: i18n.t("profileSetup.chooseSubjects.errors.failed"),
          })
        );
      }
    }
    dispatch(
      setBusyAction({ key: AsyncActions.PatchFavoriteSubjects, value: false })
    );
  };
};
