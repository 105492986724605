import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import logo from "../../assets/common/logo.svg";
import { SetupSteps } from "../../common/components/SetupSteps/SetupSteps";
import { AvatarType } from "./AvatarType/AvatarType";
import { ChooseSubjects } from "./ChooseSubjects/ChooseSubjects";
import { GameName } from "./GameName/GameName";
import { LandingPage } from "./LandingPage/LandingPage";
import { Logo } from "./LandingPage/LandingPageStyled";
import { PersonalityTraits } from "./PersonalityTraits/PersonalityTraits";
import { PageWrapper } from "./ProfileSetupStyled";
import { SelectGender } from "./SelectGender/SelectGender";
import { TutorialModal } from "../../common/components/Tutorial/TutorialModal";
import { useShowTutorial } from "../../common/hooks/useShowTutorial";
import tutorial from "../../common/consts/tutorials";
import { env } from "../../common/config/env";

export const ProfileSetup = () => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const { showTutorial, handleCloseTutorial } = useShowTutorial();
  let VisibleComponent;
  const [urlSearchParams] = useSearchParams();
  const startFrom = urlSearchParams.get("startFrom");

  useEffect(() => {
    if (startFrom) {
      setCurrentStep(+startFrom);
    }
  }, [startFrom]);

  switch (currentStep) {
    case 1:
      VisibleComponent = <LandingPage setCurrentStep={setCurrentStep} />;
      break;
    case 2:
      VisibleComponent = <GameName setCurrentStep={setCurrentStep} />;
      break;
    case 3:
      VisibleComponent = <SelectGender setCurrentStep={setCurrentStep} />;
      break;
    case 4:
      VisibleComponent = <AvatarType setCurrentStep={setCurrentStep} />;
      break;
    case 5:
      VisibleComponent = <PersonalityTraits setCurrentStep={setCurrentStep} />;
      break;
    case 6:
      VisibleComponent = <ChooseSubjects setCurrentStep={setCurrentStep} />;
      break;
    default:
      VisibleComponent = null;
  }

  return (
    <PageWrapper>
      {
        <React.Fragment>
          <Logo src={logo} hidden={currentStep === 1} />
          <SetupSteps
            steps={[1, 2, 3, 4, 5]}
            currentStep={currentStep - 1}
            hidden={currentStep === 1}
          />
          {VisibleComponent}
          {showTutorial && (
            <TutorialModal
              url={tutorial.child[env.locale]}
              onClose={handleCloseTutorial}
            />
          )}
        </React.Fragment>
      }
    </PageWrapper>
  );
};
