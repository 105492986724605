import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loginParent } from "../../../store/thunks/user";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { getItem } from "../../../common/utils/localStorageService";
import { LocalStorageKeys } from "../../../common/enums/LocalStorageKeys";

import { parentValidationSchema } from "../../../views/validation/loginValidationSchema";

import Form from "../../../common/components/Form/Form";
import Button from "../../../common/components/Button/Button";
import Input from "../../../common/components/Input/Input";

import { useTranslation } from "react-i18next";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { LoginForm } from "../../../common/types/LoginForm";

import { StyledError } from "../../../common/components/Error/Error";
import { LoginAvatar } from "../LoginAvatar/LoginAvatar";
import { RememberedParentWrapper, StyledButton } from "./ParentStyled";

const RememberedParentTab = (props: any) => {
  const { t } = useTranslation();
  const { requestInProgress, error } = useAsyncState(
    AsyncActions.LoginParentAction
  );
  const { register, formState, handleSubmit, setValue } = useForm<LoginForm>({
    mode: "onTouched",
    resolver: yupResolver(parentValidationSchema()),
  });
  const dispatch = useDispatch();

  const onLogin = (data: LoginForm) => {
    dispatch(loginParent(data));
  };

  useEffect(() => {
    const username = getItem(LocalStorageKeys.REMEMBERED_PARENT);
    if (username) {
      setValue("username", username);
    }
  }, []);

  return (
    <RememberedParentWrapper>
      <LoginAvatar
        onTabChange={props.onParentTabChange}
        username={getItem(LocalStorageKeys.REMEMBERED_PARENT) as string}
      />
      <Form className="w-full" onSubmit={handleSubmit(onLogin)}>
        <Input
          name="password"
          registerInput={register}
          required
          type="password"
          placeholderText="Password"
          labelText="Enter Your Password"
          bottomMargin="1.325rem"
          inputWidth="100%"
        />
        {formState.errors.password && (
          <StyledError topMargin="-0.883rem" bottomMargin="1.325rem">
            {formState.errors.password?.message}
          </StyledError>
        )}
        {error && (
          <StyledError centered bottomMargin="1.325rem" topMargin="-0.883rem">
            {error}
          </StyledError>
        )}
        <Button
          type="submit"
          resPending={requestInProgress}
          bottomMargin="1.325rem"
          invert={false}
        >
          {t("login.button.login")}
        </Button>
      </Form>
      <StyledButton
        invert
        data-parent-tab="false"
        onClick={props.onParentTabChange}
      >
        {t("login.button.loginWith")}
      </StyledButton>
    </RememberedParentWrapper>
  );
};

export default RememberedParentTab;
