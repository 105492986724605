import {
  ContentWrapper,
  LessonContent,
  LessonName,
  SectionName,
  SubjectSpan,
  Button,
  Icon,
  LessonDataWrapper,
  ButtonWrapper,
  TitleWrapper,
  HeaderWrapper,
} from "./styled";

import goTo from "../../assets/common/Setup-Profile/Right-Arrow.svg";

type Props = {
  name: string;
  subject: string;
  section: string;
  onClick: () => void;
};

export const SearchItem = ({ onClick, name, section, subject }: Props) => {
  return (
    <ContentWrapper>
      <LessonDataWrapper>
        <HeaderWrapper>
          <TitleWrapper>
            <SectionName>
              <SubjectSpan>{`${subject} `}&#x2022; </SubjectSpan>
              {section}
            </SectionName>
            <LessonContent>
              <LessonName>{name}</LessonName>
            </LessonContent>
          </TitleWrapper>
        </HeaderWrapper>
      </LessonDataWrapper>
      <ButtonWrapper>
        <Button onClick={onClick} invert>
          <Icon src={goTo} />
        </Button>
      </ButtonWrapper>
    </ContentWrapper>
  );
};
