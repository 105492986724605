import { ApiRoutes } from "../../common/enums/ApiRoutes";
import { ReduxThunk } from "../type";
import { Classroom } from "../types/school";
import { setBusyAction, setErrorAction } from "../slices/async";
import { AsyncActions } from "../enums/AsyncActions";
import { t } from "i18next";
import { setClassrooms } from "../slices/school";
import { prepareClassrooms } from "../utils/prepareClassrooms";

export const fetchClassrooms: () => ReduxThunk = () => {
  return async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({ key: AsyncActions.FetchClassroomsAction, value: true })
    );
    try {
      const res = await client.get<Classroom[]>(ApiRoutes.Classroom);
      dispatch(
        setClassrooms(
          prepareClassrooms(res.data.sort((a, b) => a.name - b.name))
        )
      );
    } catch (error: any) {
      dispatch(
        setErrorAction({
          key: AsyncActions.FetchClassroomsAction,
          value: t("response.getClassrooms"),
        })
      );
    }
    dispatch(
      setBusyAction({ key: AsyncActions.FetchClassroomsAction, value: false })
    );
  };
};
