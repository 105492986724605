import { ReduxThunk } from "../../type";
import { setActivity } from "../../slices/activity";
import { Activity } from "../../types/activity";
import { setBusyAction, setErrorAction } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import i18n from "../../../i18n";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";

export const fetchLearnerActivity: (
  learnerUsername: string | undefined
) => ReduxThunk = (learnerUsername) => {
  return async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({ key: AsyncActions.FetchLearnerActivity, value: true })
    );
    try {
      if (!learnerUsername) {
        dispatch(
          setBusyAction({
            key: AsyncActions.FetchLearnerActivity,
            value: false,
          })
        );
        return;
      }
      const response = await client.get<Activity[]>(ApiRoutes.LearnerActivity, {
        params: { learnerUsername },
      });
      dispatch(setActivity(response.data));
    } catch (err: any) {
      dispatch(
        setErrorAction({
          key: AsyncActions.FetchLearnerActivity,
          value: i18n.t("response.errorActivity"),
        })
      );
    }
    dispatch(
      setBusyAction({ key: AsyncActions.FetchLearnerActivity, value: false })
    );
  };
};
