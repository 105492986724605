import Button from "../../../common/components/Button/Button";
import { Modal } from "../../../common/components/Modal/Modal";
import popupImage from "../../../assets/common/Frame.svg";
import {
  PopupCloseStyled,
  PopupHeadingStyled,
  PopupImageStyled,
} from "../../../common/components/Modal/ModalStyled";
import { ModalWrapper } from "../ModalStyled";
import { useTranslation } from "react-i18next";

type ModalProps = {
  handleCloseModal: () => void;
};

export const ResendOTPModal = ({ handleCloseModal }: ModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal>
      <ModalWrapper>
        <PopupCloseStyled onClick={handleCloseModal}>&#10006;</PopupCloseStyled>

        <PopupImageStyled src={popupImage}></PopupImageStyled>
        <PopupHeadingStyled>
          {t("register.otp.resendPopup.heading")}
        </PopupHeadingStyled>
        <Button
          invert={false}
          onClick={handleCloseModal}
          buttonWidth="12.366rem"
        >
          {t("register.otp.resendPopup.button")}
        </Button>
      </ModalWrapper>
    </Modal>
  );
};
