import tw, { styled, css } from "twin.macro";

export const SliderWrapper = styled.div(
  () => tw`w-full max-w-[49rem] flex flex-col items-center`
);

export const SlidesButtonsWrapper = styled.div(
  () => tw`w-full flex items-center justify-center`
);

export const ArrowButton = styled.button(
  () =>
    tw`w-[2.5rem] -mt-8 flex-shrink-0 h-[2.5rem] rounded-full border-2 border-dark-blue flex items-center justify-center`
);

export const ArrowImg = styled.img();

export const SlideWrapper = styled.div(
  () =>
    tw`w-full  max-w-[9rem] md:max-w-[36rem] ml-1 md:ml-[3rem] overflow-hidden`
);

export const SlideFlexDiv = styled.div(({ offset }: { offset: number }) => [
  tw`w-[max-content] mr-[3.3rem] md:mr-[4rem] flex flex-row flex-nowrap transition-all`,
  css`
    transform: translateX(-${offset * 11.25}rem);
  `,
]);

export const AvatarInput = styled.input(() => tw`hidden`);

export const AvatarLabel = styled.label(
  () =>
    tw`mr-[3.3rem] md:mr-[4rem] last:mr-0 flex flex-col items-center cursor-pointer`
);

export const AvatarDivWrapper = styled.div(() => tw`w-26 h-26`);

export const AvatarDiv = styled.div(({ selected }: { selected?: boolean }) => [
  tw`w-[8rem] h-[8rem] box-border relative bg-white rounded-full border-2 border-gold-secondary flex items-center justify-center`,
  selected && tw`border-green`,
]);

export const AvatarTypeSpan = styled.span(
  () =>
    tw`mt-3 max-w-[8rem] text-center font-medium text-sm md:text-lg text-black font-sans opacity-60`
);

export const DotsWrapper = styled.div(
  () => tw`w-full flex items-center justify-center space-x-2 mt-8`
);

export const DotDiv = styled.div(({ active }: { active?: boolean }) => [
  tw`w-3 h-3 rounded-full bg-dark-gray transition-all`,
  active && tw`bg-gold-secondary`,
]);
