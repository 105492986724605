import { GlobalStyles } from "twin.macro";
import {
  Number,
  ProgressInnerDiv,
  ProgressOuterDiv,
  StepDiv,
  Wrapper,
} from "./SetupStepsStyled";

export const SetupSteps = ({
  currentStep,
  hidden,
  steps,
}: {
  currentStep: number;
  hidden?: boolean;
  steps: number[];
}) => {
  return (
    <Wrapper hidden={hidden}>
      <ProgressOuterDiv>
        <ProgressInnerDiv
          progress={((currentStep - 1) * 100) / 4}
        ></ProgressInnerDiv>
      </ProgressOuterDiv>
      {steps.map((step, index) => {
        return (
          <StepDiv
            key={step}
            offset={(index * 100) / (steps.length - 1)}
            reached={step <= currentStep ? true : false}
            last={step === 5 ? true : false}
          >
            <Number reached={step <= currentStep ? true : false}>{step}</Number>
          </StepDiv>
        );
      })}
      <GlobalStyles />
    </Wrapper>
  );
};
