export const countryCodes = [
  { name: "Slovenia", dialCode: "+386", code: "SI", flag: "🇸🇮" },
  { name: "Albania", dialCode: "+355", code: "AL", flag: "🇦🇱" },
  { name: "Austria", dialCode: "+43", code: "AT", flag: "🇦🇹" },
  { name: "Belarus", dialCode: "+375", code: "BY", flag: "🇧🇾" },
  { name: "Bosnia and Herzegovina", dialCode: "+387", code: "BA", flag: "🇧🇦" },
  { name: "Bulgaria", dialCode: "+359", code: "BG", flag: "🇧🇬" },
  { name: "Croatia", dialCode: "+385", code: "HR", flag: "🇭🇷" },
  { name: "Czech Republic", dialCode: "+420", code: "CZ", flag: "🇨🇿" },
  { name: "Denmark", dialCode: "+45", code: "DK", flag: "🇩🇰" },
  { name: "Estonia", dialCode: "+372", code: "EE", flag: "🇪🇪" },
  { name: "Finland", dialCode: "+358", code: "FI", flag: "🇫🇮" },
  { name: "France", dialCode: "+33", code: "FR", flag: "🇫🇷" },
  { name: "Germany", dialCode: "+49", code: "DE", flag: "🇩🇪" },
  { name: "Greece", dialCode: "+30", code: "GR", flag: "🇬🇷" },
  { name: "Hungary", dialCode: "+36", code: "HU", flag: "🇭🇺" },
  { name: "Ireland", dialCode: "+353", code: "IE", flag: "🇮🇪" },
  { name: "Israel", dialCode: "+972", code: "IL", flag: "🇮🇱" },
  { name: "Italy", dialCode: "+39", code: "IT", flag: "🇮🇹" },
  { name: "Latvia", dialCode: "+371", code: "LV", flag: "🇱🇻" },
  { name: "Liechtenstein", dialCode: "+423", code: "LI", flag: "🇱🇮" },
  { name: "Lithuania", dialCode: "+370", code: "LT", flag: "🇱🇹" },
  { name: "Luxembourg", dialCode: "+352", code: "LU", flag: "🇱🇺" },
  {
    name: "North Macedonia",
    dialCode: "+389",
    code: "MK",
    flag: "🇲🇰",
  },
  { name: "Malta", dialCode: "+356", code: "MT", flag: "🇲🇹" },
  { name: "Moldova, Republic of", dialCode: "+373", code: "MD", flag: "🇲🇩" },
  { name: "Monaco", dialCode: "+377", code: "MC", flag: "🇲🇨" },
  { name: "Montenegro", dialCode: "+382", code: "ME", flag: "🇲🇪" },
  { name: "Netherlands", dialCode: "+31", code: "NL", flag: "🇳🇱" },
  { name: "Norway", dialCode: "+47", code: "NO", flag: "🇳🇴" },
  { name: "Poland", dialCode: "+48", code: "PL", flag: "🇵🇱" },
  { name: "Portugal", dialCode: "+351", code: "PT", flag: "🇵🇹" },
  { name: "Romania", dialCode: "+40", code: "RO", flag: "🇷🇴" },
  { name: "Russia", dialCode: "+7", code: "RU", flag: "🇷🇺" },
  { name: "Serbia", dialCode: "+381", code: "RS", flag: "🇷🇸" },
  { name: "Slovakia", dialCode: "+421", code: "SK", flag: "🇸🇰" },
  { name: "Spain", dialCode: "+34", code: "ES", flag: "🇪🇸" },
  { name: "Sweden", dialCode: "+46", code: "SE", flag: "🇸🇪" },
  { name: "Switzerland", dialCode: "+41", code: "CH", flag: "🇨🇭" },
  { name: "Ukraine", dialCode: "+380", code: "UA", flag: "🇺🇦" },
  {
    name: "United Kingdom",
    dialCode: "+44",
    code: "GB",
    preferred: true,
    flag: "🇬🇧",
  },
  {
    name: "United States",
    dialCode: "+1",
    code: "US",
    preferred: true,
    flag: "🇺🇸",
  },
];
