import tw, { styled, css } from "twin.macro";
import colors from "../../../../colors.json";

export const Icon = styled.img(() => tw`w-5 h-5`);

export const TooltipOverlayWrapper = styled.div(() => tw`flex font-sans`);

export const SubjectIcon = styled.img(() => tw`w-10 h-10 mr-2`);

export const SubjectName = styled.h4(
  () => tw`font-sans font-medium text-black break-words pr-2`
);

export const ProgressSpan = styled.span(
  () => tw`font-bold font-sans text-base text-black w-16 text-right`
);

export const TotalSpan = styled.span(
  () => tw`font-sans text-base opacity-60 text-black`
);

export const SubjectNameDiv = styled.div(() => tw`flex justify-between`);

export const ProgressBarWrapper = styled.div(({ lg }: { lg?: boolean }) => [
  tw`w-full h-2 bg-light-gray-tertiary rounded-full`,
  lg && tw`h-2.5`,
]);

export const ProgressBarInner = styled.div(
  ({ width, lg }: { width: string; lg?: boolean }) => [
    tw`h-2 rounded-full`,
    css`
      width: ${width};
      background: repeating-linear-gradient(
        137deg,
        ${colors.green},
        ${colors.green} 0.85rem,
        ${colors.white} 0.85rem,
        ${colors.white} 1rem
      );
    `,
    lg && tw`h-2.5`,
  ]
);

export const SubjectInfoDiv = styled.div(
  () => tw`flex flex-col w-full space-y-4 `
);

export const SubjectWrapper = styled.div(() => tw`flex w-full`);

export const ViewLessonBtn = styled.button(
  () =>
    tw` text-white bg-dark-blue box-border rounded-[2.76rem] h-10 font-semibold font-sans border py-2 border-dark-blue inline-flex items-center justify-center w-38`
);

export const PointsCompletedWrapper = styled.div(() => tw`flex gap-x-0.5`);

export const PointsWrapper = styled.div(
  () => tw`min-w-[3rem] pt-1 flex h-[1.4rem]`
);
