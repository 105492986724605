import { useTranslation } from "react-i18next";

import { Learner } from "../../../store/types/learners";

import copyIcon from "../../../assets/common/copy.svg";
import sendIcon from "../../../assets/common/send.svg";
import learnerAvatar from "../../../assets/common/main-avatar.svg";
import {
  CopyButton,
  CopyIcon,
  CopyInfoDiv,
  LearnerWrapper,
  LightTitle,
  LoginInfoStyled,
  InfoDiv,
  InfoTextStyled,
  SendInfoStyled,
  NameStyled,
  AvatarIcon,
} from "./LearnerPinsStyled";

type SinglePinProps = {
  learner: Learner;
};
export const Pins = ({ learner }: SinglePinProps) => {
  const { t } = useTranslation();

  return (
    <LearnerWrapper>
      <AvatarIcon src={learnerAvatar} />
      <InfoDiv>
        <NameStyled>
          {learner.firstName} {learner.lastName}
        </NameStyled>
        <InfoTextStyled>
          {learner.activeClass.name}, {learner.school?.name}
        </InfoTextStyled>
      </InfoDiv>
      <InfoDiv>
        <LightTitle>{t("register.LearnersInfo.username")}</LightTitle>
        <CopyInfoDiv>
          <LoginInfoStyled>{learner.username}</LoginInfoStyled>
          <CopyButton
            onClick={() => {
              navigator.clipboard.writeText(learner.username);
            }}
          >
            <CopyIcon src={copyIcon} />
          </CopyButton>
        </CopyInfoDiv>
      </InfoDiv>
      <InfoDiv>
        <LightTitle>{t("register.LearnersInfo.pin")}</LightTitle>
        <CopyInfoDiv>
          <LoginInfoStyled>{learner.pinCode}</LoginInfoStyled>
          <CopyButton
            onClick={() => {
              navigator.clipboard.writeText(learner.pinCode);
            }}
          >
            <CopyIcon src={copyIcon} />
          </CopyButton>
        </CopyInfoDiv>
      </InfoDiv>
      <SendInfoStyled src={sendIcon} />
    </LearnerWrapper>
  );
};
