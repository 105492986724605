import tw, { styled } from "twin.macro";

export const Wrapper = styled.div(
  () =>
    tw`w-full bg-white relative rounded-lg flex flex-col lg:flex-row items-center justify-center p-8 min-h-[6rem]`
);

export const BankLogo = styled.img(() => tw`w-8 mb-6 lg:mb-0 lg:mr-4`);

export const InfoDiv = styled.div(
  () => tw`flex flex-col items-center lg:items-start mb-6 lg:mb-0 lg:mr-auto`
);

export const MethodName = styled.span(
  () => tw`font-sans text-black font-medium`
);

export const MethodInfo = styled.span(
  () => tw`font-sans text-black text-sm text-center lg:text-left opacity-60`
);

export const ButtonDiv = styled.div(
  () => tw`w-full max-w-[20rem] lg:max-w-[14.75rem]`
);
