export enum Exception {
  EntityNotFound = "EntityNotFound",
  EmailConfirmCodeMismatch = "EmailConfirmCodeMismatch",
  InvalidCredentials = "InvalidCredentials",
  LearnersNotEligibleForPayment = "LearnersNotEligibleForPayment",
  NewPasswordSameAsOld = "NewPasswordSameAsOld",
  RemoveLearnerFailedBecauseOfActiveSubscriptions = "RemoveLearnerFailedBecauseOfActiveSubscriptions",
  InvalidCode = "InvalidCode",
  CodeSubscriptionsMismatch = "CodeSubscriptionsMismatch",
  DepositoryLimitReached = "DepositoryLimitReached",
  DepositoryDailyLimitReached = "DepositoryDailyLimitReached",
  LessonAlreadyInDepository = "LessonAlreadyInDepository",
  SubscriptionAlreadyRenewed = "SubscriptionAlreadyRenewed",
  InvalidPromoCode = "InvalidPromoCode",
  UsernameAlreadyExists = "UsernameAlreadyExists",
  InvalidLearnerUsername = "InvalidLearnerUsername",
  FailedToUpdateUsername = "FailedToUpdateUsername",
  InvalidGender = "InvalidGender",
  TraitArrayIsEmpty = "TraitArrayIsEmpty",
  MoreThanThreeTraits = "MoreThanThreeTraits",
  FailedToUpdateTraits = "FailedToUpdateTraits",
  BadNumberOfSubjects = "BadNumberOfSubjects",
  LearnersHaveActiveStripeSubscription = "LearnersHaveActiveStripeSubscription",
  CantApplyDiscount = "CantApplyDiscount",
}
