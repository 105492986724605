import { Fragment, useState } from "react";
import { TableWrapper, Table, TBody } from "./styled";
import { UpcomingTournamentListItem } from "./UpcomingTournamentListItem";
import { UpcomingTournamentList } from "./UpcomingTournamentsList";
import { InsufficientPointsModal } from "../../../components/Tournament/modals/InsufficientPointsModal";
import { Tournament } from "../../../store/types/tournament";

type Props = {
  tournaments: Tournament[];
};

export const UpcomingTournamentsComponent = ({ tournaments }: Props) => {
  const [openInsufficientPointsModal, setOpenInsufficientPointsModal] =
    useState<string | boolean>(false);

  const handleOpenInsufficientPointsModal = (subject: string | null) => {
    setOpenInsufficientPointsModal(subject ? subject : true);
  };

  const handleCloseInsufficientPointsModal = () => {
    setOpenInsufficientPointsModal(false);
  };

  return (
    <Fragment>
      <TableWrapper>
        <Table>
          <TBody>
            {tournaments.map((t) => (
              <UpcomingTournamentListItem
                openModal={handleOpenInsufficientPointsModal}
                key={t.id}
                {...t}
              />
            ))}
          </TBody>
        </Table>
      </TableWrapper>
      <UpcomingTournamentList
        openModal={handleOpenInsufficientPointsModal}
        tournaments={tournaments}
      />
      {openInsufficientPointsModal && (
        <InsufficientPointsModal
          subjectCode={openInsufficientPointsModal}
          onClose={handleCloseInsufficientPointsModal}
        />
      )}
    </Fragment>
  );
};
