import { ReduxThunk } from "../../type";
import { Notification } from "../../types/notification";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { addAlerts } from "../../slices/notification";

export const fetchNotifications: () => ReduxThunk =
  () =>
  async (dispatch, __, { client }) => {
    try {
      const res = await client.get<Notification[]>(
        ApiRoutes.FetchNotifications
      );

      dispatch(addAlerts(res.data));
    } catch {
      // ignore...
    }
  };
