import tw, { styled } from "twin.macro";
import colors from "../../../colors.json";

export const Wrapper = styled.div(
  () =>
    tw`w-full h-full p-8 flex flex-col items-center overflow-scroll [::-webkit-scrollbar-track-piece]:rounded-lg flex flex-col lg:rounded-lg [::-webkit-scrollbar]:h-full [::-webkit-scrollbar]:w-2 [::-webkit-scrollbar-track]:bg-transparent [::-webkit-scrollbar-thumb]:bg-dark-gray`
);

export const ContentWrapper = styled.div(
  () => tw`w-full flex flex-col items-center max-w-[40rem]`
);

export const Description = styled.p(() => tw`font-sans text-black text-center`);

export const FormContentWrapper = styled.div(
  () => tw`mt-4 w-full flex flex-col`
);

export const FontSelectDiv = styled.div(
  () => tw`mb-8 w-full max-w-[50rem] flex flex-col sm:flex-row items-center`
);

export const LearnerNameSpan = styled.span(
  () => tw`font-sans text-black font-semibold min-w-[max-content] mr-4`
);

export const SelectDiv = styled.div(() => tw`w-full ml-auto max-w-[40rem]`);

export const BtnDiv = styled.div(
  () =>
    tw`flex flex-col sm:flex-row items-center justify-center w-full max-w-[23.125rem] sm:max-w-[40rem] sm:space-x-4 space-y-4 sm:space-y-0`
);

export const SubmitDiv = styled.div(
  () => tw`w-full flex flex-col mt-8 space-y-4 items-center`
);

export const fontSelectStyles = {
  control: (provided: object, state: any) => ({
    ...provided,

    width: "100%",
    height: "2.208rem",
    minHeight: "0",
    borderRadius: "0.5rem",
    boxShadow: "0",
    border: state.isFocused
      ? `1px solid ${colors["dark-blue"]}`
      : `1px solid ${colors["dark-gray"]}`,
    flexShrink: "0",
  }),
  container: (provided: object) => ({
    ...provided,

    width: "100%",
    height: "2.208rem",
    minHeight: "0",
    borderRadius: "0.5rem",
    flexShrink: "0",
  }),
  indicatorsContainer: (provided: object) => ({
    ...provided,
    height: "2.208rem",
    padding: "0rem",
    paddingTop: "0rem",
    paddingBottom: "0rem",
    marginRight: "0.5rem",
    flexShrink: "0",
  }),
  valueContainer: (provided: object) => ({
    ...provided,
    height: "2rem",
    padding: "0rem",
    marginLeft: "0.5rem",
    flexShrink: "0",
  }),
  singleValue: (provided: object) => ({
    ...provided,
    fontFamily: "inter",
    color: colors.black,
    fontSize: "0.875rem",
    fontWeight: "400",
  }),
  placeholder: (provided: object) => ({
    ...provided,
    fontFamily: "inter",
    color: colors.black,
    fontSize: "0.875rem",
    fontWeight: "400",
    opacity: "0.6",
  }),
  option: (provided: object) => ({
    ...provided,
    fontFamily: "inter",
  }),
  input: (provided: object) => ({
    ...provided,
    fontFamily: "inter",
    color: colors.black,
  }),
  noOptionsMessage: (provided: object) => ({
    ...provided,
    fontFamily: "inter",
  }),
  clearIndicator: () => ({
    display: "none",
  }),
};
