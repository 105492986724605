import React from "react";
import { useTranslation } from "react-i18next";
import { useLearners } from "../../../common/hooks/useLearners";
import { LearnerInfo } from "./LearnerInfo/LearnerInfo";
import { LearnersList } from "./LearnersList/LearnersList";

import {
  MonitorProgressWrapper,
  DescriptionWrapper,
  StyledLink,
} from "./MonitorProgressStyled";

type Props = {
  goToSubscriptions: () => void;
};

export const MonitorProgress = ({ goToSubscriptions }: Props) => {
  const { activeLearners } = useLearners();
  const { t } = useTranslation();
  return (
    <MonitorProgressWrapper>
      <LearnersList />
      {activeLearners.length > 0 && <LearnerInfo />}
      {activeLearners.length === 0 && (
        <DescriptionWrapper>
          {t("parentDashboard.monitorProgress.descriptionFirstPart")}
          <StyledLink onClick={goToSubscriptions}>
            {t("parentDashboard.monitorProgress.linkDescription")}
          </StyledLink>
          {t("parentDashboard.monitorProgress.descriptionSecondPart")}
        </DescriptionWrapper>
      )}
    </MonitorProgressWrapper>
  );
};
