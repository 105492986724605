import { Fragment, useState } from "react";
import { repeat } from "lodash";
import { useTranslation } from "react-i18next";
import {
  ButtonWrapper,
  OpenConsentModalButton,
  PermissionDiv,
  PermissionDivContentWrapper,
  PermissionDivLabel,
  PermissionDivValue,
} from "./styled";
import { TournamentConsentModal } from "../TournamentConsent/TournamentConsentModal";
import { TournamentUsernamePermission } from "../../../common/enums/TournamentUsernamePermission";

type Props = {
  name: string;
  username: string;
  permission?: TournamentUsernamePermission;
};

const NUMBER_OF_LETTERS_TO_SHOW = 2;

const hideUsername = (username: string): string => {
  return (
    username.slice(0, NUMBER_OF_LETTERS_TO_SHOW) +
    repeat("*", username.length - 2)
  );
};

export const TournamentPermissionItem = ({
  name,
  username,
  permission = TournamentUsernamePermission.HideUsername,
}: Props) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const hasConsent = permission === TournamentUsernamePermission.ShowUsername;

  const onClose = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  return (
    <Fragment>
      <PermissionDiv>
        <PermissionDivContentWrapper>
          <PermissionDivLabel>
            {t("parentDashboard.events.tournamentConsent.student")}
          </PermissionDivLabel>
          <PermissionDivValue>{name}</PermissionDivValue>
        </PermissionDivContentWrapper>
        <PermissionDivContentWrapper>
          <PermissionDivLabel>
            {t("parentDashboard.events.tournamentConsent.username")}
          </PermissionDivLabel>
          <PermissionDivValue>{username}</PermissionDivValue>
        </PermissionDivContentWrapper>
        <PermissionDivContentWrapper>
          <PermissionDivLabel>
            {t("parentDashboard.events.tournamentConsent.seenByOthers")}
          </PermissionDivLabel>
          <PermissionDivValue>
            {hasConsent ? username : hideUsername(username)}
          </PermissionDivValue>
        </PermissionDivContentWrapper>
        <ButtonWrapper>
          <OpenConsentModalButton onClick={handleOpenModal}>
            {hasConsent
              ? t("parentDashboard.events.tournamentConsent.hideUsername")
              : t("parentDashboard.events.tournamentConsent.showUsername")}
          </OpenConsentModalButton>
        </ButtonWrapper>
      </PermissionDiv>
      {openModal && (
        <TournamentConsentModal
          onClose={onClose}
          learnerConsents={[
            { username, tournamentUsernamePermission: permission },
          ]}
          closeOnActionDone
        />
      )}
    </Fragment>
  );
};
