import { isEmpty, isNil } from "ramda";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useDebounce } from "../../hooks/useDebounce";

export const SelectComponent = ({
  options,
  fetchOptions,
  fetchOnEmpty = true,
  debounceTime = 500,
  ...rest
}: any) => {
  const [value, setValue] = useState("");
  const debouncedValue = useDebounce<string>(value, debounceTime);

  useEffect(() => {
    if ((!fetchOnEmpty && isEmpty(debouncedValue)) || isNil(debouncedValue))
      return;
    fetchOptions?.(debouncedValue);
  }, [debouncedValue]);

  const handleInputChange = (val: string) => {
    setValue(val);
  };

  return (
    <Select {...rest} onInputChange={handleInputChange} options={options} />
  );
};
