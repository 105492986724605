import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { HomeWrapperStyled, ContentWrapperStyled } from "./LearnerHomeStyled";
import { Banner } from "./Banner/Banner";
import { LearnerInfo } from "./LearnerInfo/LearnerInfo";
import { LearnerProgress } from "./LearnerProgress/LearnerProgress";
import { LessonsPopup } from "./LessonsPopup/LessonsPopup";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store/type";
import { fetchFavoriteSubjects } from "../../store/thunks/profileSetup/fetchFavoriteSubjects";
import { useAsyncState } from "../../common/hooks/useAsyncState";
import { AsyncActions } from "../../store/enums/AsyncActions";
import { Spinner } from "../../common/components/Spinner/Spinner";
import { IncompleteProfile } from "./IncompleteProfile/IncompleteProfile";
import { Events } from "./Events/Events";
import { AvatarDeletedModal } from "../../common/components/AvatarDeltedModal/AvatarDeletedModal";

export const LearnerHome = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const SubjectCode = searchParams.get("subjectCode");
  const favoriteSubjects = useSelector(
    (state: StoreState) => state.favoriteSubjects.favoriteSubjects
  );
  const [lessonsPopup, setLessonsPopup] = useState(false);
  const [popupSubjectCode, setPopupSubjectCode] = useState("");
  const [loadingState, setLoadingState] = useState<boolean>(true);
  const { requestInProgress: fetchFavoriteSubjectsInProgress } = useAsyncState(
    AsyncActions.FetchFavoriteSubjects
  );

  const { requestInProgress: fetchPointsInProgress } = useAsyncState(
    AsyncActions.FetchPoints
  );

  useEffect(() => {
    dispatch(fetchFavoriteSubjects());
    setLoadingState(false);
  }, []);

  useEffect(() => {
    if (SubjectCode) {
      setPopupSubjectCode(SubjectCode);
      setLessonsPopup(true);
      setSearchParams({});
    }
  }, [SubjectCode]);

  return (
    <HomeWrapperStyled>
      {loadingState ||
      fetchFavoriteSubjectsInProgress ||
      fetchPointsInProgress ? (
        <Spinner fullscreen />
      ) : (
        <React.Fragment>
          {lessonsPopup && (
            <LessonsPopup
              subjectCode={popupSubjectCode}
              viewLessons={setLessonsPopup}
            />
          )}
          <Banner />
          {favoriteSubjects.length === 0 && <IncompleteProfile />}
          <ContentWrapperStyled>
            <LearnerInfo />
            <LearnerProgress
              setSubjectCode={setPopupSubjectCode}
              viewLessons={setLessonsPopup}
            />
            <Events />
          </ContentWrapperStyled>
        </React.Fragment>
      )}
      <AvatarDeletedModal />
    </HomeWrapperStyled>
  );
};
