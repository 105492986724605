import tw, { styled } from "twin.macro";

export const AvatarWrapped = styled.div<{ noHoverTransform?: boolean }>(
  ({ noHoverTransform }) => [
    [
      tw`relative w-full h-full transform hover:scale-150 transition-all`,
      noHoverTransform && tw`scale-150`,
    ],
  ]
);
const Icon = styled.img(() => tw`absolute w-full h-full`);
export const MainIcon = styled(Icon)(() => tw`z-[1]`);
export const AccessoryIcon = styled(Icon)();
