import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store/type";
import { editInfo } from "../../store/thunks/editInfo";

export const useShowTutorial = () => {
  const tutorialSeen = useSelector(
    (state: StoreState) => state.user.info.tutorialSeen
  );
  const [showTutorial, setShowTutorial] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!tutorialSeen) {
      setShowTutorial(true);
    }
  }, [tutorialSeen]);

  const handleCloseTutorial = () => {
    setShowTutorial(false);
    dispatch(editInfo({ tutorialSeen: true }));
  };

  return { showTutorial, handleCloseTutorial };
};
