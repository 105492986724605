import { FC } from "react";
import { RoutePath } from "../consts/route-path";
import { env } from "../config/env";
import LoginPage from "../../views/pages/LoginPage";
import { UserRole } from "../enums/UserRole";
import RegisterPage from "../../views/pages/RegisterPage";
import OnBoarding from "../../views/pages/OnBoarding";
import LearnerHomePage from "../../views/pages/LearnerHomePage";
import LearningPage from "../../views/pages/LearningPage";
import { ParentDashboardPage } from "../../views/pages/ParentDashboard";
import { ResetPasswordPage } from "../../views/pages/ResetPasswordPage";
import { LearnerSetupPage } from "../../views/pages/LearnerSetupPage";
import { LearnerProfilePage } from "../../views/pages/LearnerProfilePage";
import { UnauthorizedPage } from "../../views/pages/UnauthorizedPage";
import { ForgotPasswordPage } from "../../views/pages/ForgotPasswordPage";
import { InactiveSubscriptionPage } from "../../views/pages/InactiveSubscriptionPage";
import { LessonSurveyPage } from "../../views/pages/SurveyPage";
import { LessonPage } from "../../components/LessonPage/LessonPage";
import { SearchPage } from "../../views/pages/SearchPage";

// Tournaments
import { TournamentPage } from "../../views/pages/TournamentPage";
import { IndividualTournamentPage } from "../../views/pages/IndividualTournamentPage";
import { MemoizedTournamentList } from "../../components/Tournament/TournamentList";
import { TournamentResultComponent } from "../../components/Tournament/TournamentResult/TournamentResult";

type AppRoute = {
  path: string;
  element: FC;
  roles: UserRole[];
  authProtected: boolean;
  subPaths?: AppRoute[];
  index?: boolean;
};

export const AppRoutes: AppRoute[] = [
  {
    path: RoutePath.login.locale[env.locale],
    element: LoginPage,
    roles: [UserRole.Learner, UserRole.Sponsor],
    authProtected: false,
  },
  {
    path: RoutePath.register.locale[env.locale],
    element: RegisterPage,
    roles: [UserRole.Sponsor],
    authProtected: false,
  },
  {
    path: RoutePath.onboarding.locale[env.locale],
    element: OnBoarding,
    roles: [UserRole.Learner, UserRole.Sponsor],
    authProtected: true,
  },
  {
    path: RoutePath.home.locale[env.locale],
    element: LearnerHomePage,
    roles: [UserRole.Learner],
    authProtected: true,
  },
  {
    path: RoutePath.lessonPage.locale[env.locale],
    element: LearningPage,
    roles: [UserRole.Learner],
    authProtected: true,
    subPaths: [
      {
        path: RoutePath.lessonPage.sub.survey.locale[env.locale],
        element: LessonSurveyPage,
        roles: [UserRole.Learner],
        authProtected: true,
      },
      {
        path: RoutePath.lessonPage.sub.lesson.locale[env.locale],
        element: LessonPage,
        roles: [UserRole.Learner],
        authProtected: true,
        index: true,
      },
    ],
  },
  {
    path: RoutePath.parentDashboard.locale[env.locale],
    element: ParentDashboardPage,
    roles: [UserRole.Sponsor],
    authProtected: true,
  },
  {
    path: RoutePath.resetPassword.locale[env.locale],
    element: ResetPasswordPage,
    roles: [UserRole.Sponsor],
    authProtected: false,
  },
  {
    path: RoutePath.learnerSetup.locale[env.locale],
    element: LearnerSetupPage,
    roles: [UserRole.Learner],
    authProtected: true,
  },
  {
    path: RoutePath.learnerProfile.locale[env.locale],
    element: LearnerProfilePage,
    roles: [UserRole.Learner],
    authProtected: true,
  },
  {
    path: RoutePath.unauthorized.locale[env.locale],
    element: UnauthorizedPage,
    roles: [UserRole.Learner, UserRole.Sponsor],
    authProtected: true,
  },
  {
    path: RoutePath.forgotPassword.locale[env.locale],
    element: ForgotPasswordPage,
    roles: [UserRole.Learner, UserRole.Sponsor],
    authProtected: false,
  },
  {
    path: RoutePath.inactiveSubscription.locale[env.locale],
    element: InactiveSubscriptionPage,
    roles: [UserRole.Learner],
    authProtected: true,
  },
  {
    path: RoutePath.search.locale[env.locale],
    element: SearchPage,
    roles: [UserRole.Learner],
    authProtected: true,
  },
  {
    path: RoutePath.tournaments.locale[env.locale],
    element: TournamentPage,
    roles: [UserRole.Learner],
    authProtected: true,
    subPaths: [
      {
        path: `${RoutePath.tournaments.sub.list.locale[env.locale]}/*`,
        element: MemoizedTournamentList,
        roles: [UserRole.Learner],
        authProtected: true,
      },
      {
        path: RoutePath.tournaments.sub.individualTournaments.locale[
          env.locale
        ],
        element: IndividualTournamentPage,
        roles: [UserRole.Learner],
        authProtected: true,
      },
      {
        path: RoutePath.tournaments.sub.result.locale[env.locale],
        element: TournamentResultComponent,
        roles: [UserRole.Learner],
        authProtected: true,
      },
    ],
  },
];
