import tw, { styled } from "twin.macro";

export const PageWrapperStyled = styled.div(
  () => tw`flex relative flex-col bg-white min-h-app`
);

export const LessonContentWrapper = styled.div(
  tw`w-full px-4 lg:px-[14.625rem] flex flex-col items-center mb-12`
);

export const StepsStyled = styled.p(
  () => tw`m-auto text-black text-lg text-center mt-4 mb-4 font-bold font-sans`
);

export const ErrorStyled = styled.p(
  () => tw`m-auto text-danger text-lg font-bold font-sans`
);
