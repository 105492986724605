export enum PaymentFormFieldName {
  Name = "name",
  AddrLine1 = "address",
  City = "city",
  ZipCode = "postalCode",
  Country = "country",
  State = "state",
  Email = "email",
  PhoneNumber = "phoneNumber",
  PaymentMethodId = "paymentMethodId",
  PromoCode = "promoCode",
  PostalCode = "postalCode",
  NameOnCard = "nameOnCard",
}

export type PaymentForm = {
  [PaymentFormFieldName.PaymentMethodId]: string | undefined;
  [PaymentFormFieldName.Name]: string;
  [PaymentFormFieldName.AddrLine1]: string;
  [PaymentFormFieldName.City]: string;
  [PaymentFormFieldName.ZipCode]: string;
  [PaymentFormFieldName.Country]: string;
  [PaymentFormFieldName.Email]: string;
  [PaymentFormFieldName.PhoneNumber]: string;
  [PaymentFormFieldName.PromoCode]: string;
  [PaymentFormFieldName.PostalCode]: string;
  [PaymentFormFieldName.State]: string;
  [PaymentFormFieldName.NameOnCard]: string;
};
