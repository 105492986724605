import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { useShowTutorial } from "../../common/hooks/useShowTutorial";

import { Banner } from "./Banner/Banner";
import { ChangePassword } from "./ChangePassword/ChangePassword";
import { Help } from "./Help/Help";
import { ManageLearners } from "./ManageLearners/ManageLearners";
import { MonitorProgress } from "./MonitorProgress/MonitorProgress";
import { MyProfile } from "./MyProfile/MyProfile";
import { NavBar } from "./NavBar/NavBar";
import { ContentWrapper, PageWrapper } from "./ParentDashboardStyled";
import { Subscriptions } from "./Subscriptions/Subscriptions";
import { Events } from "./Events/Events";
import { env } from "../../common/config/env";
import tutorials from "../../common/consts/tutorials";
import { TutorialModal } from "../../common/components/Tutorial/TutorialModal";
import { TournamentConsent } from "./TournamentConsent/TournamentConsent";

export const ParentDashboard = () => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const step = searchParams.get("step");
  const { showTutorial, handleCloseTutorial } = useShowTutorial();
  const [currentPage, setCurrentPage] = useState(1);
  const [subscribeLearnerId, setSubscribeLearnerId] = useState<string | null>(
    null
  );

  let VisiblePage;
  let bannerPath: any;

  const handleSetLearnerId = useCallback(
    (id: string | null) => {
      setSubscribeLearnerId(id);
      setCurrentPage(2);
    },
    [setCurrentPage]
  );

  const goToSubscriptionPage = () => setSearchParams({ step: "4" });

  useEffect(() => {
    if (step) {
      setCurrentPage(+step);
    }
  }, [step]);

  switch (currentPage) {
    case 1:
      VisiblePage = (
        <MonitorProgress goToSubscriptions={goToSubscriptionPage} />
      );
      bannerPath = "parentDashboard.monitorProgress.heading";
      break;
    case 2:
      VisiblePage = (
        <ManageLearners
          setSubscribeLearnerId={setSubscribeLearnerId}
          subscribeLearnerId={subscribeLearnerId}
        />
      );
      bannerPath = "parentDashboard.sideNav.manage";
      break;
    case 3:
      VisiblePage = <Events />;
      bannerPath = "parentDashboard.sideNav.events";
      break;
    case 4:
      VisiblePage = (
        <Subscriptions
          renewalLearnerId={subscribeLearnerId}
          setRenewalLearnerId={handleSetLearnerId}
        />
      );
      bannerPath = "parentDashboard.sideNav.subscriptions";
      break;
    case 5:
      VisiblePage = <MyProfile />;
      bannerPath = "parentDashboard.sideNav.profile";
      break;
    case 6:
      VisiblePage = <ChangePassword />;
      bannerPath = "parentDashboard.sideNav.password";
      break;
    case 7:
      VisiblePage = <Help />;
      bannerPath = "parentDashboard.sideNav.help";
      break;
    default:
      VisiblePage = null;
  }

  const translation = t(bannerPath);

  return (
    <PageWrapper>
      <NavBar currentPage={currentPage} />
      <ContentWrapper>
        <Banner bannerPath={translation} />
        {VisiblePage}
      </ContentWrapper>
      {showTutorial && (
        <TutorialModal
          url={tutorials.parent[env.locale]}
          onClose={handleCloseTutorial}
        />
      )}
      <TournamentConsent />
    </PageWrapper>
  );
};
