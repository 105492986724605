import { ReduxThunk } from "../type";
import { setUserInfo, dataFetchingFailed } from "../slices/user";
import { ApiRoutes } from "../../common/enums/ApiRoutes";
import { setBusyAction } from "../slices/async";
import { AsyncActions } from "../enums/AsyncActions";
import { setLearners } from "../slices/learners";
import { addSubscriptions } from "../slices/subscriptions";

export const fetchUserInfo: () => ReduxThunk = () => {
  return async (dispatch, _, { client }) => {
    dispatch(setBusyAction({ key: AsyncActions.FetchUserAction, value: true }));
    try {
      const response = await client.get(ApiRoutes.UserInfo);
      if (response.data.learners) {
        dispatch(setLearners(response.data.learners));
      }
      if (response.data.subscriptions) {
        dispatch(addSubscriptions(response.data.subscriptions));
      }
      dispatch(setUserInfo(response.data));
    } catch (e: any) {
      dispatch(dataFetchingFailed());
    }
    dispatch(
      setBusyAction({ key: AsyncActions.FetchUserAction, value: false })
    );
  };
};
