import tw, { styled } from "twin.macro";

export const Wrapper = styled.div(
  () => tw`flex flex-col p-8 items-center w-full h-full`
);

export const WarningHeading = styled.h1(
  () => tw`font-bold text-2xl text-black text-center mt-32 mb-8`
);

export const WarningDescrpition = styled.p(
  () => tw`font-sans opacity-60 text-black max-w-[30rem] text-center mb-32`
);

export const ButtonDiv = styled.div(
  () =>
    tw`  flex flex-col md:flex-row w-full max-w-[23.125rem] md:max-w-[30rem] space-y-4 md:space-y-0 md:space-x-4  items-center justify-center`
);
