import {
  Username,
  UserClassWrapper,
  UserDetailsWrapper,
  UserGrade,
} from "./styled";
import avatarIcon from "../../../assets/common/main-avatar.svg";
import { Avatar } from "../../../common/components/Avatar/Avatar";
import { AvatarItem } from "../../../store/types/avatar-items";
import { AvatarWrapper } from "../../../common/components/Header/HeaderStyled";

type Props = {
  avatar: string | null;
  grade: string;
  username: string;
  items: AvatarItem[];
};
export const UserDetails = ({ username, avatar, grade, items }: Props) => {
  return (
    <UserDetailsWrapper>
      <AvatarWrapper>
        <Avatar items={items} icon={avatar || avatarIcon} />
      </AvatarWrapper>
      <UserClassWrapper>
        <Username>{username}</Username>
        <UserGrade>{grade}</UserGrade>
      </UserClassWrapper>
    </UserDetailsWrapper>
  );
};
