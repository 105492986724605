import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../../common/components/Button/Button";
import { Modal } from "../../../../common/components/Modal/Modal";
import { PopupCloseStyled } from "../../../../common/components/Modal/ModalStyled";
import { env } from "../../../../common/config/env";
import { RoutePath } from "../../../../common/consts/route-path";
import { StoreState } from "../../../../store/type";
import {
  AvatarDiv,
  BtnDiv,
  ContentWrapper,
  NameSpan,
  SuccessParagraph,
  Wrapper,
} from "./ProfileCompleteModalStyled";
import { AvatarWithSelectedItems } from "../../../../common/components/Avatar/AvatarWithSelectedItems";

export const ProfileCompleteModal = () => {
  const navigate = useNavigate();
  const onButtonClick = () => {
    navigate(RoutePath.learnerProfile.locale[env.locale]);
  };
  const { firstName, lastName, avatar } = useSelector(
    (state: StoreState) => state.user.info
  );
  const { t } = useTranslation();
  return (
    <Modal>
      <Wrapper>
        <PopupCloseStyled onClick={onButtonClick}>&#10006;</PopupCloseStyled>
        <ContentWrapper>
          {avatar && (
            <AvatarDiv>
              <AvatarWithSelectedItems />
            </AvatarDiv>
          )}
          <SuccessParagraph>
            {t("profileSetup.modal.hiSegment")}
            <NameSpan>
              {" "}
              {firstName} {lastName}
            </NameSpan>
            ! {t("profileSetup.modal.profileCreatedSegment")}
          </SuccessParagraph>
          <BtnDiv>
            <Button
              onClick={onButtonClick}
              invert={false}
              buttonWidth="100%"
              buttonHeight="2.5rem"
            >
              {t("profileSetup.modal.viewProfileBtn")}
            </Button>
          </BtnDiv>
        </ContentWrapper>
      </Wrapper>
    </Modal>
  );
};
