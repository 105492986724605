import React from "react";
import { Navigate } from "react-router-dom";
import { useAsyncState } from "../../common/hooks/useAsyncState";
import { useUserHomeRoute } from "../../common/hooks/useUserHomeRoute";
import { Unauthorized } from "../../components/UnauthorizedPage/UnauthorizedPage";
import { AsyncActions } from "../../store/enums/AsyncActions";

export const UnauthorizedPage = () => {
  const { error } = useAsyncState(AsyncActions.Authorization);
  const route = useUserHomeRoute();

  if (!error) {
    return <Navigate to={route} />;
  }
  return <Unauthorized />;
};
