import React, { Fragment } from "react";
import { TutorialTitle } from "./HelpStyled";
import { VideoPlayer } from "../../../common/components/Video/Video";
import { useTranslation } from "react-i18next";
import { UserRole } from "../../../common/enums/UserRole";
import tutorial from "../../../common/consts/tutorials";
import { env } from "../../../common/config/env";

const tutorials = [
  { role: UserRole.Sponsor, url: tutorial.parent[env.locale] },
  { role: UserRole.Learner, url: tutorial.child[env.locale] },
];

export const Tutorials = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      {tutorials.map(({ role, url }) => (
        <Fragment key={role}>
          <TutorialTitle>
            {t(
              `parentDashboard.help.${
                role === UserRole.Sponsor ? "video" : "learnerVideo"
              }`
            )}
          </TutorialTitle>
          <VideoPlayer
            hideTimeline={false}
            playing={false}
            url={url}
            showSpinner={false}
          />
        </Fragment>
      ))}
    </Fragment>
  );
};
