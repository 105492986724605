import React, { useState } from "react";
import { GlobalStyles } from "twin.macro";
import {
  DrawerButton,
  DrawerButtonDiv,
  NavBarWrapper,
  Drawer,
  DrawerCloseButton,
} from "./NavBarStyled";

import { NavBarContent } from "./NavBarContent";

type NavBarProps = {
  currentPage: number;
};

export const NavBar = ({ currentPage }: NavBarProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };
  return (
    <React.Fragment>
      <NavBarWrapper id="navbar-wrapper">
        <NavBarContent
          setDrawerOpen={setDrawerOpen}
          currentPage={currentPage}
        />
      </NavBarWrapper>
      <DrawerButton onClick={openDrawer}>
        <DrawerButtonDiv></DrawerButtonDiv>
        <DrawerButtonDiv></DrawerButtonDiv>
        <DrawerButtonDiv></DrawerButtonDiv>
      </DrawerButton>
      <Drawer open={drawerOpen}>
        <DrawerCloseButton onClick={closeDrawer}>&#10006;</DrawerCloseButton>
        <NavBarContent
          setDrawerOpen={setDrawerOpen}
          currentPage={currentPage}
        />
      </Drawer>
      <GlobalStyles />
    </React.Fragment>
  );
};
