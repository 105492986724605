import { useTranslation } from "react-i18next";

import { ContentWrapper } from "../BillingHistoryStyled";
import {
  DownloadButton,
  DownloadIcon,
  DownloadSpan,
  DropdownDiv,
  DropdownTriggerButton,
  DropdownTriggerDiv,
  Failed,
  HeadingDiv,
  ItemWrapper,
  LearnerName,
  Paid,
  Pending,
  PlusSpan,
  SmallScreenHeading,
  SmallScreenValueDiv,
  SmallScreenValueSpan,
  Value,
  ValueDiv,
} from "./BillingItemStyled";
import downloadIcon from "../../../../../../assets/common/Parent-Subscriptions/Bill.svg";
import React, { useState } from "react";
import { GlobalStyles } from "twin.macro";
import { TransactionStatus } from "../../../../../../common/enums/TransactionStatus";
import { PaymentHistory } from "../../../../../../store/types/paymentHistory";
import { fetchPaymentReceipt } from "../../../../../../store/thunks/Parent/fetchPaymentReceipt";
import { useDispatch } from "react-redux";
import { useAsyncState } from "../../../../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../../../../store/enums/AsyncActions";
import { BtnSpinner } from "../../../../../../common/components/BtnSpinner/BtnSpinner";
import { formatDate } from "../../../../../../common/utils/formatDate";

export const BillingItem = ({
  createdAt,
  netAmount,
  currency,
  id,
  status,
  learnerUsername,
}: PaymentHistory) => {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();

  currency = currency || "eur";

  const { requestInProgress } = useAsyncState(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    `${AsyncActions.FetchPaymentReceipt}/${id}`
  );
  const downloadReceipt = () => {
    dispatch(fetchPaymentReceipt(id));
  };

  const triggerDropdown = () => {
    setDropdownOpen((pre) => !pre);
  };

  const transactionDate = formatDate(createdAt);
  const amount = netAmount.toLocaleString("en-US", {
    style: "currency",
    currency,
  });

  return (
    <React.Fragment>
      <ContentWrapper>
        <ValueDiv>
          <Value>{transactionDate}</Value>
        </ValueDiv>
        <ValueDiv>
          <Value>{learnerUsername}</Value>
        </ValueDiv>
        <ValueDiv>
          <Value>{amount}</Value>
        </ValueDiv>
        <ValueDiv>
          <Value>{t("parentDashboard.subscriptions.online")}</Value>
        </ValueDiv>
        <ValueDiv>
          {status === TransactionStatus.Complete && (
            <Paid>{t("parentDashboard.subscriptions.billingPaid")}</Paid>
          )}
          {status === TransactionStatus.Pending && (
            <Pending>
              {t("parentDashboard.subscriptions.billingPending")}
            </Pending>
          )}
          {(status === TransactionStatus.Disputed ||
            status === TransactionStatus.UnsupportedCard) && (
            <Failed>{t("parentDashboard.subscriptions.billingFailed")}</Failed>
          )}
        </ValueDiv>
        <ValueDiv>
          <DownloadButton
            disabled={requestInProgress}
            onClick={downloadReceipt}
          >
            <DownloadIcon src={downloadIcon} />
            <DownloadSpan>
              {t("parentDashboard.subscriptions.download")}
            </DownloadSpan>
            {requestInProgress && <BtnSpinner />}
          </DownloadButton>
        </ValueDiv>
      </ContentWrapper>
      <ItemWrapper>
        <DropdownTriggerButton onClick={triggerDropdown}>
          <DropdownTriggerDiv>
            <LearnerName>{learnerUsername}</LearnerName>
            <PlusSpan>{dropdownOpen ? "-" : "+"}</PlusSpan>
          </DropdownTriggerDiv>
        </DropdownTriggerButton>
        <DropdownDiv open={dropdownOpen}>
          <HeadingDiv>
            <SmallScreenHeading>
              {t("parentDashboard.subscriptions.billingDate")}
            </SmallScreenHeading>
            <SmallScreenHeading>
              {t("parentDashboard.subscriptions.billingLearnerName")}
            </SmallScreenHeading>
            <SmallScreenHeading>
              {t("parentDashboard.subscriptions.billingAmount")}
            </SmallScreenHeading>
            <SmallScreenHeading>
              {t("parentDashboard.subscriptions.billingMode")}
            </SmallScreenHeading>
            <SmallScreenHeading>
              {t("parentDashboard.subscriptions.billingStatus")}
            </SmallScreenHeading>
            <SmallScreenHeading>
              {t("parentDashboard.subscriptions.billingReceipt")}
            </SmallScreenHeading>
          </HeadingDiv>
          <SmallScreenValueDiv>
            <SmallScreenValueSpan>{transactionDate}</SmallScreenValueSpan>
            <SmallScreenValueSpan>{learnerUsername}</SmallScreenValueSpan>
            <SmallScreenValueSpan>{amount}</SmallScreenValueSpan>
            <SmallScreenValueSpan>
              {t("parentDashboard.subscriptions.online")}
            </SmallScreenValueSpan>
            <SmallScreenValueSpan>
              {status === TransactionStatus.Complete &&
                t("parentDashboard.subscriptions.billingPaid")}
              {status === TransactionStatus.Pending &&
                t("parentDashboard.subscriptions.billingPending")}
              {status === TransactionStatus.Disputed &&
                t("parentDashboard.subscriptions.billingFailed")}
            </SmallScreenValueSpan>
            <SmallScreenValueSpan>
              <DownloadButton
                disabled={requestInProgress}
                onClick={downloadReceipt}
              >
                <DownloadIcon src={downloadIcon} />
                <DownloadSpan>
                  {t("parentDashboard.subscriptions.download")}
                </DownloadSpan>
                {requestInProgress && <BtnSpinner />}
              </DownloadButton>
            </SmallScreenValueSpan>
          </SmallScreenValueDiv>
        </DropdownDiv>
      </ItemWrapper>
      <GlobalStyles />
    </React.Fragment>
  );
};
