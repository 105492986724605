import tw, { styled } from "twin.macro";

export const Wrapper = styled.div(
  () => tw`flex flex-col w-full items-center max-w-[70rem] px-8`
);

export const SubjectsGrid = styled.div(
  () => tw`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-x-48 gap-y-6`
);

export const SubjectLabel = styled.label(
  () => tw`ml-4 flex items-center cursor-pointer`
);

export const SubjectIcon = styled.img(() => tw`mr-1 w-6`);

export const SubjectSpan = styled.span(
  () => tw`font-sans font-medium text-left text-lg`
);

export const SubjectInput = styled.input(
  () => tw`w-[1.125rem] flex-shrink-0 h-[1.125rem] cursor-pointer`
);

export const SubjectDiv = styled.div(() => tw`flex items-center`);
