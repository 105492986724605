import { useDispatch, useSelector } from "react-redux";
import { useUserHomeRoute } from "../../common/hooks/useUserHomeRoute";
import { ForgotPassword } from "../../components/ForgotPassword/ForgotPassword";
import { redirect } from "../../store/slices/router";
import { StoreState } from "../../store/type";

export const ForgotPasswordPage = () => {
  const { isLoggedIn } = useSelector((state: StoreState) => state.user);
  const homeRoute = useUserHomeRoute();
  const dispatch = useDispatch();
  if (isLoggedIn) {
    dispatch(redirect({ to: homeRoute }));
  }
  return <ForgotPassword />;
};
