import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NavigateOptions } from "react-router-dom";
import { SliceNames } from "../enums/SliceNames";
import { RedirectAction } from "../types/router";

type RouterState = {
  redirectTo: undefined | string;
  routeInfo: NavigateOptions | undefined;
};

const initialState: RouterState = {
  redirectTo: undefined,
  routeInfo: undefined,
};

const routerSlice = createSlice({
  name: SliceNames.RouterSlice,
  initialState,
  reducers: {
    redirect: (state, { payload }: PayloadAction<RedirectAction>) => {
      state.redirectTo = payload.to;
      state.routeInfo = payload.options;
    },
    clearRedirectState: (state) => {
      state.redirectTo = undefined;
      state.routeInfo = undefined;
    },
    reset: () => {
      return initialState;
    },
  },
});

export const { redirect, clearRedirectState, reset } = routerSlice.actions;

export default routerSlice.reducer;
