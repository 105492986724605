import { Fragment } from "react";
import {
  Item,
  StarIcon,
  StyledRow,
  Timer,
  TournamentClosed,
  TData,
} from "./styled";
import dayjs from "../../config/dayjs";
import starIcon from "../../../assets/common/Lesson-Page/Star.svg";
import { useTranslation } from "react-i18next";
import { Tournament } from "../../../store/types/tournament";
import { useTournamentsSchedule } from "./useTournamentsSchedule";
import { StartTournamentButton } from "./StartTournamentButton";
import { getDuration } from "./utils";
import { TournamentTableItemComponent } from "./TournamentTableItemComponent";
import { TableItemComponent } from "./TableItemComponent";

type Props = Tournament & { openModal: (subjectName: string | null) => void };

export const UpcomingTournamentListItem = ({
  subjectCode,
  name,
  endsAt,
  startsAt,
  pointsRequired,
  id,
  isSubjectPoints,
  openModal,
  participated,
  points,
  quiz,
}: Props) => {
  const { t } = useTranslation();
  const duration = getDuration(endsAt, startsAt);

  const { timeToTournamentStart, isTournamentClosed, isWarningTime } =
    useTournamentsSchedule(startsAt, t("tournaments.ongoing"));

  return (
    <StyledRow>
      <TData>
        <TournamentTableItemComponent name={name} />
      </TData>
      <TData>
        <TableItemComponent
          label={t("tournaments.pastTournamentDetails.duration")}
          value={duration}
        />
      </TData>
      <TData>
        <TableItemComponent
          label={t("tournaments.pastTournamentDetails.startsAt")}
          value={dayjs(startsAt).format("DD MMM/HH:mm")}
        />
      </TData>
      <TData>
        <TableItemComponent
          label={t("tournaments.pastTournamentDetails.pointsRequired")}
          value={
            <Fragment>
              <StarIcon src={starIcon} />
              {pointsRequired}
            </Fragment>
          }
        />
      </TData>
      <TData>
        <TableItemComponent
          label={t("tournaments.pastTournamentDetails.rewards")}
          value={
            <Fragment>
              <StarIcon src={starIcon} />
              {points}
            </Fragment>
          }
        />
      </TData>
      <TData>
        <TableItemComponent
          label={t("tournaments.pastTournamentDetails.startsIn")}
          value={
            isTournamentClosed(endsAt) ? (
              <TournamentClosed>
                {t("tournaments.pastTournamentDetails.closed")}
              </TournamentClosed>
            ) : (
              <Timer warning={isWarningTime(timeToTournamentStart)}>
                {timeToTournamentStart}
              </Timer>
            )
          }
        />
      </TData>
      <TData>
        <Item>
          <StartTournamentButton
            participated={participated}
            isSubjectPoints={isSubjectPoints}
            startsAt={startsAt}
            pointsRequired={pointsRequired}
            subjectCode={subjectCode}
            id={id}
            openModal={openModal}
            name={name}
            endsAt={endsAt}
            quizId={quiz?.id}
          />
        </Item>
      </TData>
    </StyledRow>
  );
};
