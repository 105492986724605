import tw, { styled, css } from "twin.macro";

export const WrapperDiv = styled.div(
  () => tw`w-full items-center lg:w-2/4 flex flex-col`
);

export const ResourcesHeading = styled.h2(
  () =>
    tw`font-sans w-full font-semibold text-xl text-black-secondary text-left mb-4`
);

export const BtnDiv = styled.div(
  () => tw`relative w-full max-w-[20rem] md:max-w-[29.375rem]`
);

export const BtnIcon = styled.img(
  ({ filterEffect }: { filterEffect?: boolean | undefined }) => [
    tw`mr-4`,
    filterEffect &&
      css`
        filter: invert(86%) sepia(7%) saturate(0%) hue-rotate(224deg)
          brightness(123%) contrast(70%);
      `,
  ]
);

export const FontDropdownWrapper = styled.div(
  tw`absolute bottom-12 left-10 w-52 bg-white z-10 rounded-lg border border-dark-gray`
);

export const FontDropdownHeading = styled.h6(
  () =>
    tw`font-sans text-black opacity-60 font-semibold text-center w-full mt-4 mb-4`
);

export const FontDropdownButton = styled.button(
  () =>
    tw`font-sans bg-transparent text-black font-semibold hover:(text-white bg-dark-blue) w-full text-center mb-4`
);

export const PopupErrorBtnDiv = styled.div(
  () => tw`flex w-full max-w-[23.175rem] justify-center mt-10`
);

export const DoneImage = styled.img(() => tw`ml-auto`);
