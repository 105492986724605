import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SliceNames } from "../enums/SliceNames";
import {
  Tournament,
  TournamentResult,
  TournamentState,
  SponsorTournamentView,
  TournamentResultForSponsor,
} from "../types/tournament";

const initialState: TournamentState = {
  upcoming: [],
  history: [],
  result: [],
  current: null,
  tournamentsForSponsor: [],
  resultsForSponsor: [],
};

const tournamentSlice = createSlice({
  name: SliceNames.TraitsSlice,
  initialState,
  reducers: {
    reset: (state) => {
      Object.assign(state, initialState);
    },
    setUpcomingTournaments: (
      state,
      { payload }: PayloadAction<Tournament[]>
    ) => {
      state.upcoming = payload;
    },
    setHistoryTournaments: (
      state,
      { payload }: PayloadAction<Tournament[]>
    ) => {
      state.history = payload;
    },
    setTournamentResult: (
      state,
      { payload }: PayloadAction<TournamentResult[]>
    ) => {
      state.result = payload;
    },
    setTournamentsForSponsor: (
      state,
      { payload }: PayloadAction<SponsorTournamentView[]>
    ) => {
      state.tournamentsForSponsor = payload;
    },
    setResultsForSponsor: (
      state,
      { payload }: PayloadAction<TournamentResultForSponsor[]>
    ) => {
      state.resultsForSponsor = payload;
    },
    setCurrentTournament: (state, { payload }: PayloadAction<Tournament>) => {
      state.current = payload;
    },
    removeCurrentTournament: (state) => {
      state.current = null;
    },
  },
});

export const {
  reset,
  setUpcomingTournaments,
  setHistoryTournaments,
  setTournamentResult,
  setTournamentsForSponsor,
  setResultsForSponsor,
  setCurrentTournament,
  removeCurrentTournament,
} = tournamentSlice.actions;

export default tournamentSlice.reducer;
