import { TournamentPermissionItem } from "./TournamentPermissionItem";
import { TournamentPermissionsWrapper, Title } from "./styled";
import { useLearners } from "../../../common/hooks/useLearners";
import { useTranslation } from "react-i18next";

export const TournamentPermissions = () => {
  const { activeLearners } = useLearners();
  const { t } = useTranslation();

  return (
    <TournamentPermissionsWrapper>
      <Title>{t("parentDashboard.events.tournamentConsent.title")}</Title>
      {activeLearners.map(({ consent, username, firstName, lastName }) => (
        <TournamentPermissionItem
          key={username}
          name={`${firstName} ${lastName}`}
          permission={consent?.tournamentUsernamePermission}
          username={username}
        />
      ))}
    </TournamentPermissionsWrapper>
  );
};
