import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { StoreState } from "../../../store/type";
import { Modal } from "../Modal/Modal";
import { Button, Description, Title, Wrapper } from "../Tutorial/styled";
import { Image } from "./styled";
import avatarIcon from "../../../assets/common/main-avatar.svg";
import { RoutePath } from "../../consts/route-path";
import { env } from "../../config/env";
import { setDeletedAvatarShowed } from "../../../store/slices/user";

export const AvatarDeletedModal = () => {
  const { t } = useTranslation();
  const { lastVisit, avatar, deletedAvatarShowed } = useSelector(
    (state: StoreState) => state.user.info
  );
  const dispatch = useDispatch();

  const shouldShowDialog = !!lastVisit && !avatar && !deletedAvatarShowed;

  return shouldShowDialog ? (
    <Modal md>
      <Wrapper>
        <Title>{t("avatarDeleted.title")}</Title>
        <Description>{t("avatarDeleted.description")}</Description>
        <Image src={avatarIcon} />
        <Link to={`${RoutePath.learnerSetup.locale[env.locale]}?startFrom=2`}>
          <Button onClick={() => dispatch(setDeletedAvatarShowed())}>
            {t("avatarDeleted.btnLabel")}
          </Button>
        </Link>
      </Wrapper>
    </Modal>
  ) : null;
};
