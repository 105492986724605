import {
  Heading,
  SelectWrapper,
  Wrapper,
  selectStyles,
  SelectAvatar,
  SelectDiv,
  SelectName,
  SubjectsWrapper,
  AvatarDiv,
} from "./LearnerSubjectsStyled";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import avatar from "../../../../../assets/common/Parent-Monitor/Avatar.svg";
import { Subject } from "../../../../LearnerHome/LearnerProgress/Subject/Subject";
import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "../../../../../store/type";
import { useEffect, useState } from "react";
import { fetchSubjects } from "../../../../../store/thunks/Parent/fetchLearnerSubjects";
import { useAsyncState } from "../../../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../../../store/enums/AsyncActions";
import { Spinner } from "../../../../../common/components/Spinner/Spinner";
import { StyledError } from "../../../../../common/components/Error/Error";
import { useLearners } from "../../../../../common/hooks/useLearners";

export const LearnerSubjects = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { requestInProgress, error } = useAsyncState(
    AsyncActions.FetchSubjectsAction
  );
  const subjects = useSelector((state: StoreState) => state.subjects.subjects);
  const { activeLearners: learners } = useLearners();

  const [learnerUsername, setLearnerUsername] = useState<string | undefined>(
    learners?.[0]?.username
  );
  const overallProgress = useSelector(
    (state: StoreState) => state.learnersProgress
  ).filter((lp) => lp.username === learnerUsername)[0];

  const selectOptions = learners?.map((learner) => {
    return {
      label: (
        <SelectDiv>
          {learner.avatar?.image ? (
            <AvatarDiv>
              <SelectAvatar src={learner.avatar.image} />
            </AvatarDiv>
          ) : (
            <SelectAvatar noDiv src={avatar} />
          )}
          <SelectName>
            {learner.firstName} {learner.lastName}
          </SelectName>
        </SelectDiv>
      ),
      value: learner.username,
    };
  });

  useEffect(() => {
    dispatch(fetchSubjects(learnerUsername));
  }, [learnerUsername]);

  useEffect(() => {
    setLearnerUsername((pre) =>
      !learners?.[0]?.username ? pre : learners?.[0]?.username
    );
  }, [learners, setLearnerUsername]);

  return (
    <Wrapper>
      <SelectWrapper>
        <Heading>
          {t("parentDashboard.monitorProgress.subjectCompletion")}
        </Heading>
        <Select
          onChange={(val) => {
            setLearnerUsername(val?.value);
          }}
          controlShouldRenderValue
          styles={selectStyles}
          options={selectOptions}
          value={selectOptions?.find((o) => o.value === learnerUsername)}
        />
      </SelectWrapper>
      <SubjectsWrapper>
        {requestInProgress ? (
          <Spinner size="sm" />
        ) : error ? (
          <StyledError bottomMargin="auto" topMargin="auto">
            {error}
          </StyledError>
        ) : (
          subjects?.map((subject) => {
            return (
              <Subject
                hideBtn
                key={subject.id}
                completed={subject.completedLessonsCount}
                total={subject.lessonCount}
                name={subject.name}
                iconPath={subject.file?.path}
                code={subject.code}
                points={
                  overallProgress?.pointsBySubject?.[subject?.code || ""] || 0
                }
                tournamentPoints={
                  overallProgress?.tournamentPointsBySubject?.[
                    subject?.code || ""
                  ] || 0
                }
              />
            );
          })
        )}
      </SubjectsWrapper>
    </Wrapper>
  );
};
