import tw, { styled } from "twin.macro";

export const Wrapper = styled.div(
  () =>
    tw`cursor-pointer flex mb-4 items-center bg-light-gray rounded-[3.199rem] pl-1 pr-6 h-14 w-full`
);

export const Avatar = styled.img(() => tw`mr-1`);

export const InfoWrapper = styled.div(() => tw`mr-auto`);

export const Description = styled.p(
  () => tw`text-xs font-sans text-black font-medium`
);

export const Username = styled.h5(
  () => tw`font-sans text-dark-blue font-semibold text-base`
);

export const CloseSpan = styled.span(() => tw`text-gray`);
