import { Modal } from "../../../../../common/components/Modal/Modal";
import { CloseModal } from "../../../../../common/components/Modal/CloseModal";
import {
  ButtonWrapper,
  Description,
  ModalCancelSubscriptionButton,
  ModalWrapper,
  Title,
  Error,
} from "./styled";
import { useTranslation } from "react-i18next";
import { deleteStripeSubscriptionTasks } from "../../../../../store/thunks/payments/stripeSubscriptionTasks";
import { useDispatch } from "react-redux";
import { useAsyncState } from "../../../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../../../store/enums/AsyncActions";
import { StyledSpinner } from "../../../../../common/components/Spinner/Spinner";
import { useEffect } from "react";

type Props = {
  onClose: () => void;
  subscriptionId: string;
};

export const CancelSubscriptionModal = ({ onClose, subscriptionId }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { requestInProgress, notification, clearNotification, error } =
    useAsyncState(AsyncActions.CancelStripeSubscription);

  const handleCancelSubscription = () => {
    if (requestInProgress) return;
    dispatch(deleteStripeSubscriptionTasks(subscriptionId));
  };

  useEffect(() => {
    if (notification === subscriptionId) {
      onClose();
    }
    return () => {
      clearNotification();
    };
  }, [notification]);

  return (
    <Modal sm>
      <ModalWrapper>
        <CloseModal onClose={onClose} />
        <Title>{t("parentDashboard.subscriptions.stripe.modal.title")}</Title>
        <Description>
          {t("parentDashboard.subscriptions.stripe.modal.description")}
        </Description>
        <ButtonWrapper>
          <ModalCancelSubscriptionButton
            disabled={requestInProgress}
            onClick={handleCancelSubscription}
            resPending={requestInProgress}
          >
            {t("parentDashboard.subscriptions.stripe.modal.btnLabel")}
            {requestInProgress && (
              <StyledSpinner size={"tiny"} variant={"white"} />
            )}
          </ModalCancelSubscriptionButton>
        </ButtonWrapper>
        {error && <Error>{error}</Error>}
      </ModalWrapper>
    </Modal>
  );
};
