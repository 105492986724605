import { Portal } from "react-portal";

import { PopupDivStyled, PopupWrapperStyled, CloseStyled } from "./ModalStyled";

type ModalProps = {
  children: JSX.Element | JSX.Element[];
  full?: boolean;
  lg?: boolean;
  sm?: boolean;
  md?: boolean;
  closeOutside?: boolean;
  openModal?: (isOpen: boolean) => void;
  fit?: boolean;
  xs?: boolean;
  onClose?: () => void;
  noPadding?: boolean;
};
export const Modal = ({
  children,
  closeOutside,
  openModal,
  onClose,
  ...rest
}: ModalProps) => {
  return (
    <Portal>
      <PopupWrapperStyled closeOutside>
        {closeOutside && (
          <CloseStyled
            onClick={() => {
              openModal && openModal(false);
              onClose && onClose();
            }}
          >
            &#10006;
          </CloseStyled>
        )}
        <PopupDivStyled closeOutside full {...rest}>
          {children}
        </PopupDivStyled>
      </PopupWrapperStyled>
    </Portal>
  );
};
