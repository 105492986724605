import { Link } from "react-router-dom";
import { TournamentModal as Modal } from "../../Tournament/modals/TournamentModal";
import { GoToHomeButton, CloseModalButton } from "./styled";
import gif from "../../../assets/gifs/Insufficient-Points.gif";
import { useTranslation } from "react-i18next";
import { RoutePath } from "../../../common/consts/route-path";
import { env } from "../../../common/config/env";

type Props = {
  onClose: () => void;
};

export const InsufficientPointsModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const homeRoute = RoutePath.home.locale[env.locale];
  return (
    <Modal
      title={t("shop.insufficientPoints.title")}
      descriptions={[
        { text: t("shop.insufficientPoints.description"), bold: false },
      ]}
      icon={gif}
      showClose
      onClose={onClose}
    >
      <Link to={homeRoute}>
        <GoToHomeButton>
          {t("shop.insufficientPoints.goToClassroom")}
        </GoToHomeButton>
      </Link>
      <CloseModalButton onClick={onClose}>
        {t("shop.insufficientPoints.close")}
      </CloseModalButton>
    </Modal>
  );
};
