import tw, { styled, css } from "twin.macro";

type CardProps = {
  paddingX: string;
  paddingY: string;
  cardWidth: string;
  center?: boolean;
  children: any;
};
type CardStyledProps = {
  paddingX: string;
  paddingY: string;
  cardWidth: string;
  center?: boolean;
};
export const CardStyled = styled.div<CardStyledProps>(
  ({ paddingX, paddingY, cardWidth, center }) => [
    tw`rounded-2xl bg-white box-border border border-light-gray border-solid flex flex-col h-full `,
    css`
      padding: ${paddingY} ${paddingX};
      width: ${cardWidth};
      @media only screen and (max-width: 1024px) {
        width: 100%;
        padding: 1.7rem 2rem;
      }
    `,
    center && tw`items-center`,
  ]
);

const Card = (props: CardProps) => {
  return (
    <CardStyled
      paddingX={props.paddingX}
      paddingY={props.paddingY}
      cardWidth={props.cardWidth}
      center={props.center}
    >
      {props.children}
    </CardStyled>
  );
};

export default Card;
