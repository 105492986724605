import React from "react";
import { Lessons } from "../../../store/types/lessons";
import * as LessonsPopupStyled from "./LessonsPopupStyled";
import { LessonStatusIndicator } from "./LessonStatusIndicator";
import { fetchLessonInfo } from "../../../store/thunks/Learner/fetchLessons";
import { useDispatch } from "react-redux";

type Props = {
  index: number;
  lesson: Lessons;
  selectedLessonIndex: number;
  subjectCode: string;
  setSelectedLesson: (selectedLesson: { index: number; code: string }) => void;
};

export const LessonPopupListItemHeader = ({
  index,
  lesson,
  selectedLessonIndex,
  subjectCode,
  setSelectedLesson,
}: Props) => {
  const dispatch = useDispatch();

  const getLessonInfo = (code: string) => {
    dispatch(
      fetchLessonInfo({
        subjectCode: subjectCode,
        lessonCode: code,
      })
    );
  };

  const handleLessonSelect = () => {
    getLessonInfo(lesson.code);
    setSelectedLesson({ index, code: lesson.code });
  };

  return (
    <LessonsPopupStyled.LessonDiv onClick={handleLessonSelect} key={lesson.id}>
      <LessonStatusIndicator
        selected={index === selectedLessonIndex}
        completed={lesson.completed}
        hasCompletedTasks={lesson.hasCompletedTasks}
      />
      <LessonsPopupStyled.InnerLessonDiv
        selected={index === selectedLessonIndex}
      >
        <LessonsPopupStyled.LessonNameList>
          {lesson.name}
        </LessonsPopupStyled.LessonNameList>
      </LessonsPopupStyled.InnerLessonDiv>
    </LessonsPopupStyled.LessonDiv>
  );
};
