import { AvatarWrapped, MainIcon, AccessoryIcon } from "./styled";
import avatar from "../../../assets/common/main-avatar.svg";
import { AvatarItem } from "../../../store/types/avatar-items";

type Props = {
  items: AvatarItem[];
  icon?: string;
  noHoverTransform?: boolean;
};

export const Avatar = ({ items, icon, noHoverTransform }: Props) => {
  return (
    <AvatarWrapped noHoverTransform={noHoverTransform}>
      <MainIcon src={icon || avatar} />
      {!!icon &&
        items.map(({ x, y, z, id, image }) => (
          <AccessoryIcon
            key={id}
            style={{ top: `${y}%`, left: `${x}%`, zIndex: z + 1 }}
            src={image}
          />
        ))}
    </AvatarWrapped>
  );
};
