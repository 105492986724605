import tw, { styled } from "twin.macro";

import { LearnerWrapper } from "../AvailableLearner/AvailableLearnerStyled";
import { ButtonStyled } from "../../../../../common/components/Button/Button";

export const StripeSubscriptionWrapper = styled(LearnerWrapper)(
  () => tw`justify-between`
);

export const CancelSubscriptionButton = styled(ButtonStyled)(
  () => tw`bg-error-450 border-none`
);

export const ModalCancelSubscriptionButton = styled(CancelSubscriptionButton)(
  () => tw`w-[22rem] lg:w-[14rem] flex gap-x-2`
);

export const ModalWrapper = styled.div(
  () =>
    tw`flex flex-col items-center gap-y-4 font-sans w-full px-4 md:px-8 overflow-auto`
);

export const Title = styled.h1(() => tw`my-8 font-bold text-2xl font-dyslexic`);

export const Description = styled.div(
  () => tw`my-8 text-base font-sans text-center mb-9`
);

export const ButtonWrapper = styled.div(() => tw`flex items-center`);

export const NoActiveSubscriptionsWrapper = styled(LearnerWrapper)(
  () => tw`min-h-[5rem] relative`
);

export const Error = styled.div(
  () => tw`font-sans text-error-500 text-base p-2 text-lg`
);
