import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { EstimatedBillingRequest } from "../../../common/types/EstimateBillingRequest";
import {
  PaymentForm,
  PaymentFormFieldName,
} from "../../../common/types/PaymentForm";
import {
  setPaymentErrorAction,
  setCheckoutRenewalAttemptStatusAction,
} from "../../slices/payment";
import { ReduxThunk } from "../../type";
import { PaymentRequestStatus } from "../../types/payment";
import { ReactStripeElements } from "react-stripe-elements";
import i18n from "../../../i18n";
import { setNotification } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import { Subscription } from "../../types/subscriptions";
import { updateLearners } from "../../slices/learners";
import { addSubscriptions } from "../../slices/subscriptions";
import { getError } from "./createSubscriptionTask";
import { Learner } from "../../types/learners";

type CreateSubscriptionResponse = Array<{
  clientSecret: string;
  transactionId: string;
  learner: string;
  stripeSubscriptionId: string;
  start: string;
  end: string;
}>;

export const renewSubscriptionTask: (
  form: PaymentForm,
  stripe: ReactStripeElements.StripeProps | null,
  elements: stripe.elements.Elements | null,
  subscription: EstimatedBillingRequest
) => ReduxThunk =
  (form, stripe, elements, { username, subscriptionType }) =>
  async (dispatch, _, { client }) => {
    dispatch(setPaymentErrorAction(null));
    if (!stripe || !elements) {
      dispatch(
        setPaymentErrorAction(
          i18n.t("register.payment.byCard.serverError.externalStripeError")
        )
      );
      return;
    }

    dispatch(
      setCheckoutRenewalAttemptStatusAction(PaymentRequestStatus.Pending)
    );

    let method = form[PaymentFormFieldName.PaymentMethodId];
    const card = elements.getElement("cardNumber");
    if (!card) return;

    try {
      if (!method) {
        const { paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card,
          billing_details: {
            name: form[PaymentFormFieldName.Name],
            email: form[PaymentFormFieldName.Email],
          },
        });
        if (!paymentMethod)
          throw i18n.t("register.payment.byCard.serverError.cardError");
        method = paymentMethod?.id;
      }

      const res = await client.post<CreateSubscriptionResponse>(
        `${ApiRoutes.RenewStripeSubscription}/${username}`,
        {
          ...form,
          paymentMethodId: method,
          subscriptionType: subscriptionType,
        }
      );

      const error = (res as any).error;

      if (error) {
        dispatch(
          setCheckoutRenewalAttemptStatusAction(PaymentRequestStatus.Idle)
        );
        return dispatch(setPaymentErrorAction(getError(error.message[0])));
      }

      const { clientSecret, ...rest } = res.data[0];

      const paymentConfirmRes = await stripe.confirmCardPayment(clientSecret, {
        payment_method: method,
      });
      const confirmPaymentData = {
        ...rest,
        paymentIntentId: paymentConfirmRes.paymentIntent?.id,
        ...(form[PaymentFormFieldName.PromoCode]
          ? { promoCode: form[PaymentFormFieldName.PromoCode] }
          : {}),
      };

      const confirmPaymentResponse = await client.post<Learner[]>(
        ApiRoutes.ConfirmSubscriptionPayment,
        confirmPaymentData
      );

      const confirmPaymentResponseData = confirmPaymentResponse.data.flat();

      dispatch(
        setCheckoutRenewalAttemptStatusAction(PaymentRequestStatus.Successful)
      );

      dispatch(
        setNotification({
          key: AsyncActions.RenewByCardSuccessful,
          value: confirmPaymentResponseData.reduce(
            (acc: any, cur: any) => `${acc}&${cur.id}`,
            ""
          ),
        })
      );
      dispatch(updateLearners(confirmPaymentResponseData));
      dispatch(
        addSubscriptions(
          confirmPaymentResponseData.flatMap(
            (l: any) => l.subscriptions as Subscription[]
          )
        )
      );
    } catch (e: any) {
      dispatch(
        setCheckoutRenewalAttemptStatusAction(PaymentRequestStatus.Idle)
      );
      dispatch(
        setPaymentErrorAction(
          Array.isArray(e.response?.data?.message)
            ? getError(e.response.data.message[0])
            : typeof e === "string"
            ? getError(e)
            : getError(e.response?.data.message)
        )
      );
    }
  };
