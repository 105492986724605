import { Fragment } from "react";
import { AvatarItem, AvatarItemKind } from "../../../store/types/avatar-items";
import { ItemsContent, SubCategoryTitle, SubCategoryWrapper } from "./styled";
import { ShopItem } from "./ShopItem";
import { useTranslation } from "react-i18next";

type Props = {
  handleItemSelect: (item: AvatarItem) => () => void;
  subCategory: Partial<Record<AvatarItemKind, AvatarItem[]>>;
  selectedItemId: string | undefined;
  isActive: (id: string) => boolean;
};

const kindLabelMap: Record<AvatarItemKind, string> = {
  [AvatarItemKind.Belt]: "belt",
  [AvatarItemKind.Hat]: "hat",
  [AvatarItemKind.Body]: "body",
  [AvatarItemKind.Hat]: "hat",
  [AvatarItemKind.Badge]: "badge",
  [AvatarItemKind.Boots]: "boots",
  [AvatarItemKind.Accessory]: "other",
  [AvatarItemKind.Eyes]: "eyes",
  [AvatarItemKind.Gloves]: "gloves",
  [AvatarItemKind.Legs]: "legs",
  [AvatarItemKind.Mantle]: "mantle",
  [AvatarItemKind.Mask]: "mask",
  [AvatarItemKind.Boots]: "boots",
  [AvatarItemKind.Torso]: "torso",
};

export const TabContent = ({
  handleItemSelect,
  subCategory,
  selectedItemId,
  isActive,
}: Props) => {
  const { t } = useTranslation();
  return (
    <SubCategoryWrapper>
      {Object.entries(subCategory).map(([title, items]) => (
        <Fragment key={title}>
          <SubCategoryTitle>
            {t(`shop.${kindLabelMap[items[0]?.kind]}` as any)}
          </SubCategoryTitle>
          <ItemsContent>
            {items.map((i: AvatarItem) => (
              <ShopItem
                key={i.id}
                id={i.id}
                url={i.image}
                points={i.points}
                onClick={handleItemSelect(i)}
                selected={selectedItemId === i.id}
                active={isActive(i.id)}
                kind={i.kind}
              />
            ))}
          </ItemsContent>
        </Fragment>
      ))}
    </SubCategoryWrapper>
  );
};
