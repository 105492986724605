import { FC } from "react";
import tw, { styled } from "twin.macro";
import { SpinnerWrapperStyled } from "./spinner.styled";

type SpinnerProps = {
  fullscreen?: boolean;
  size?: "sm" | "normal" | "tiny";
  blur?: boolean;
  variant?: "white";
};

export const StyledSpinner = styled.div<Pick<SpinnerProps, "size" | "variant">>(
  ({ size = "normal", variant }) => [
    tw`border-t-4 border-b-4 border-dark-blue rounded-full animate-spin`,
    size === "sm" && tw`w-20 h-20`,
    size === "normal" && tw`w-40 h-40`,
    size === "tiny" && tw`w-5 h-5`,
    variant === "white" && tw`border-white`,
  ]
);

export const Spinner: FC<SpinnerProps> = ({
  fullscreen,
  size,
  blur,
  variant,
}) => {
  return (
    <SpinnerWrapperStyled blur={blur} fullscreen={fullscreen}>
      <StyledSpinner size={size} variant={variant} />
    </SpinnerWrapperStyled>
  );
};
