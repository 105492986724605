import { useTranslation } from "react-i18next";

import {
  AvatarGroup,
  AvatarHeading,
  CreateHeading,
  Logo,
  StartBtnDiv,
  WelcomeHeading,
  Wrapper,
} from "./LandingPageStyled";

import logo from "../../../assets/common/logo.svg";
import avatarGroup from "../../../assets/common/Setup-Profile/Avatars.svg";
import Button from "../../../common/components/Button/Button";

type Props = {
  setCurrentStep: (val: number) => void;
};

export const LandingPage = ({ setCurrentStep }: Props) => {
  const startHandler = () => {
    setCurrentStep(2);
  };

  const { t } = useTranslation();
  return (
    <Wrapper>
      <WelcomeHeading>
        {t("profileSetup.landingPage.welcomeHeading")}
      </WelcomeHeading>

      <Logo src={logo} marginLg />

      <CreateHeading>
        {t("profileSetup.landingPage.createHeading")}
      </CreateHeading>
      <AvatarHeading>
        {t("profileSetup.landingPage.avatarHeading")}
      </AvatarHeading>

      <AvatarGroup src={avatarGroup} />
      <StartBtnDiv>
        <Button
          invert={false}
          buttonWidth="100%"
          buttonHeight="2.5rem"
          onClick={startHandler}
        >
          {t("profileSetup.landingPage.startBtn")}
        </Button>
      </StartBtnDiv>
    </Wrapper>
  );
};
