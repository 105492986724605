import { useSelector } from "react-redux";
import { StoreState } from "../../store/type";
import { useMemo } from "react";

type SortedLearners = {
  activeLearners: StoreState["learners"];
  neverSubscribed: StoreState["learners"];
  expiredSubscription: StoreState["learners"];
  allLearners: StoreState["learners"];
};

export const useLearners = () => {
  const learners = useSelector((state: StoreState) => state.learners);

  const groupedLearners = useMemo(() => {
    return (learners || []).reduce(
      (acc, cur) => {
        if (cur.activeSubscription) {
          acc.activeLearners = [...(acc.activeLearners || []), cur];
        } else if (cur?.subscriptions?.length && cur.subscriptions.length > 0) {
          acc.expiredSubscription = [...(acc.expiredSubscription || []), cur];
        } else {
          acc.neverSubscribed = [...(acc.neverSubscribed || []), cur];
        }

        return acc;
      },
      {
        activeLearners: [],
        neverSubscribed: [],
        expiredSubscription: [],
        allLearners: learners,
      } as SortedLearners
    );
  }, [learners]);

  return groupedLearners;
};
