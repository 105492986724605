import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { AssistantDialogTitleComponent } from "./AssistantDialogTitle";
import { AssistantDialogContent } from "./AssistantDialogContent";
import { reset } from "../../store/slices/assistant";
export type DialogContentOptions =
  | "summary"
  | "search"
  | "qna"
  | "related"
  | "none";

import {
  AssistantDialogWrapper,
  AssistantDialogContentWrapper,
  AssistantDialogContentDescription,
} from "./styled";

type Props = {
  onClose: () => void;
};

export const AssistantDialog = ({ onClose }: Props) => {
  const { t } = useTranslation();

  const [option, setOption] = useState<DialogContentOptions>("none");
  const dispatch = useDispatch();

  const handleBack = () => {
    setOption("none");
    dispatch(reset());
  };

  return (
    <AssistantDialogWrapper bgGray={option !== "none"}>
      <AssistantDialogTitleComponent
        selectedOption={option}
        onBackClick={handleBack}
      />
      <AssistantDialogContentWrapper>
        {option === "none" && (
          <AssistantDialogContentDescription>
            {t("assistant.dialog.contentSubtitle")}
          </AssistantDialogContentDescription>
        )}
        <AssistantDialogContent
          selectedOption={option}
          onOptionSelect={setOption}
          onClose={onClose}
        />
      </AssistantDialogContentWrapper>
    </AssistantDialogWrapper>
  );
};
