import { useTranslation } from "react-i18next";
import backIcon from "../../../assets/common/back-arrow-no-fill.svg";
import {
  SurveyWithResultsWrapper,
  SurveyResultHeader,
  SurveyResultHeaderTitleWrapper,
  BackIcon,
  SurveyResultHeaderTitle,
  MarksWrapper,
  Marks,
  MarksLabel,
  SolutionsWrapper,
} from "./styled";
import { ShowQuestionAnswer } from "./ShowQuestionAnswer";
import { useSelector } from "react-redux";
import { StoreState } from "../../../store/type";

type Props = {
  onBack: () => void;
};

export const ShowSurveyAnswers = ({ onBack }: Props) => {
  const { result, quiz } = useSelector((s: StoreState) => s.survey);

  if (!quiz || !result) return null;

  const { t } = useTranslation();
  return (
    <SurveyWithResultsWrapper>
      <SurveyResultHeader>
        <SurveyResultHeaderTitleWrapper>
          <BackIcon src={backIcon} onClick={onBack} />
          <SurveyResultHeaderTitle>
            {t("showQuizAnswers.title", {
              name: "Cell, tissue, organs, organism",
            })}
          </SurveyResultHeaderTitle>
        </SurveyResultHeaderTitleWrapper>
        <MarksWrapper>
          <MarksLabel>{t("showQuizAnswers.marks")}</MarksLabel>
          <Marks>{` ${result.correctlyAnswered}/${result.totalQuestions}`}</Marks>
        </MarksWrapper>
      </SurveyResultHeader>
      <SolutionsWrapper>
        {quiz.questions.map((question) => (
          <ShowQuestionAnswer key={question.id} question={question} />
        ))}
      </SolutionsWrapper>
    </SurveyWithResultsWrapper>
  );
};
