import { ReduxThunk } from "../../type";
import { setBusyAction } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import { removeItem } from "../../slices/user";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { showNotification } from "../../../common/utils/toastNotification";
import i18n from "../../../i18n";

export const refundItem =
  (id: string, points: number): ReduxThunk =>
  async (dispatch, _, { client }) => {
    dispatch(setBusyAction({ key: AsyncActions.RefundItem, value: true }));
    try {
      await client.delete(`${ApiRoutes.RefundItem}/${id}`);
      dispatch(removeItem({ id, points }));
    } catch (e) {
      showNotification(i18n.t("response.genericError"), "error");
    }
    dispatch(setBusyAction({ key: AsyncActions.RefundItem, value: false }));
  };
