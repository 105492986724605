import * as yup from "yup";
import i18n from "../../i18n";

export const parentValidationSchema = () => {
  return yup
    .object({
      username: yup
        .string()
        .trim()
        .required(
          i18n.t("validation.required", {
            fieldName: i18n.t("fieldNames.email"),
          })
        )
        .email(i18n.t("validation.email"))
        .min(
          2,
          i18n.t("validation.min", {
            length: 2,
            suffix: "a",
            fieldName: i18n.t("fieldNames.email"),
          })
        )
        .max(
          64,
          i18n.t("validation.max", {
            length: 64,
            fieldName: i18n.t("fieldNames.email"),
          })
        ),
      password: yup
        .string()
        .required(
          i18n.t("validation.required", {
            fieldName: i18n.t("fieldNames.password"),
          })
        )
        .matches(/^\S*$/, i18n.t("validation.whitespace"))
        .min(
          8,
          i18n.t("validation.min", {
            length: 8,
            suffix: "ov",
            fieldName: i18n.t("fieldNames.password"),
          })
        )
        .max(
          64,
          i18n.t("validation.max", {
            fieldName: i18n.t("fieldNames.password"),
          })
        ),
    })
    .required();
};

export const studentValidationSchema = () => {
  return yup
    .object({
      username: yup
        .string()
        .trim()
        .required(
          i18n.t("validation.required", {
            fieldName: i18n.t("fieldNames.userName"),
          })
        )
        .matches(/^\S*$/, i18n.t("validation.whitespace"))
        .min(
          2,
          i18n.t("validation.min", {
            length: 2,
            suffix: "a",
            fieldName: i18n.t("fieldNames.userName"),
          })
        )
        .max(
          64,
          i18n.t("validation.max", { fieldName: i18n.t("fieldNames.userName") })
        ),
      password: yup
        .string()
        .required(i18n.t("validation.pin.required"))
        .matches(/^\S*$/, i18n.t("validation.whitespace"))
        .length(4, i18n.t("validation.pin.min")),
    })
    .required();
};
