import { ReduxThunk } from "../../type";
import { addLearnersProgress } from "../../slices/learnersProgress";
import { setBusyAction, setErrorAction } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import i18n from "../../../i18n";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { LearnerProgress } from "../../types/learnerProgress";

export const fetchLearnersProgress: () => ReduxThunk = () => {
  return async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({ key: AsyncActions.FetchLearnersProgress, value: true })
    );
    try {
      const response = await client.get<LearnerProgress[]>(
        ApiRoutes.LearnersProgress
      );
      dispatch(addLearnersProgress(response.data));
    } catch (err: any) {
      dispatch(
        setErrorAction({
          key: AsyncActions.FetchLearnersProgress,
          value: i18n.t("response.errorFetchingLearnersProgress"),
        })
      );
    }
    dispatch(
      setBusyAction({ key: AsyncActions.FetchLearnersProgress, value: false })
    );
  };
};
