import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SliceNames } from "../enums/SliceNames";
import { SearchState, SearchResult } from "../types/search";

const initialState: SearchState = {
  result: {},
};

const searchSlice = createSlice({
  name: SliceNames.Search,
  initialState,
  reducers: {
    setResult: (
      state,
      { payload }: PayloadAction<Record<string, Record<string, SearchResult[]>>>
    ) => {
      state.result = payload;
    },
    reset: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const { setResult, reset } = searchSlice.actions;

export default searchSlice.reducer;
