import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAnimation } from "../../store/slices/assistant";
import {
  AssistantWrapper,
  BubbleInnerWrapper,
  BubbleOuterWrapper,
  CloseIconComponent,
  AssistantModalInnerWrapper,
  AssistantModalOuterWrapper,
} from "./styled";
import { AvatarWithSelectedItems } from "../../common/components/Avatar/AvatarWithSelectedItems";
import { AssistantDialog } from "./AssistantDialog";
import { HelpMessage } from "./HelpMessage";
import { StoreState } from "../../store/type";

const ANIMATION_DURATION = 2900;

export const Assistant = () => {
  const [showDialog, setShowDialog] = useState(false);
  const animate = useSelector((state: StoreState) => state.assistant.animate);
  const [showMessage, setShowMessage] = useState(false);
  const dispatch = useDispatch();

  const handleCloseMessage = () => {
    setShowMessage(false);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    let id: NodeJS.Timeout;

    if (animate) {
      id = setTimeout(() => {
        dispatch(setAnimation(false));
        setShowMessage(true);
      }, ANIMATION_DURATION);
    }

    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [animate]);

  useEffect(() => {
    let id: NodeJS.Timeout | null = null;
    id = setTimeout(() => {
      dispatch(setAnimation(true));
    }, 3000);
    return () => {
      if (id !== null) {
        clearTimeout(id);
      }
    };
  }, []);

  const handleOpenDialog = () => {
    setShowDialog(true);
    setShowMessage(false);
  };

  return (
    <AssistantWrapper>
      {showDialog && (
        <>
          <AssistantDialog onClose={handleCloseDialog} />
          <BubbleOuterWrapper onClick={handleCloseDialog}>
            <BubbleInnerWrapper>
              <CloseIconComponent />
            </BubbleInnerWrapper>
          </BubbleOuterWrapper>
        </>
      )}

      {!showDialog && (
        <>
          {showMessage && (
            <HelpMessage
              onMessageClick={handleOpenDialog}
              onClose={handleCloseMessage}
            />
          )}
          <AssistantModalOuterWrapper
            animate={animate}
            onClick={handleOpenDialog}
          >
            <AssistantModalInnerWrapper>
              <AvatarWithSelectedItems noHoverTransform />
            </AssistantModalInnerWrapper>
          </AssistantModalOuterWrapper>
        </>
      )}
    </AssistantWrapper>
  );
};
