import {
  ActivityIcon,
  ActivityInfo,
  ActivityInfoDiv,
  AvatarDiv,
  NameSpan,
  SingleActivity,
  Time,
} from "./LearnerActivityStyled";
import avatarIcon from "../../../../../assets/common/Parent-Monitor/Avatar.svg";
import {
  Activity as ActivityType,
  ActivityKind,
  TournamentResultStatus,
} from "../../../../../store/types/activity";
import { useTranslation } from "react-i18next";
import dayjs from "../../../../../common/config/dayjs";

type Props = {
  username: string | undefined;
  avatar?: string;
  description: string;
  name: string | null;
  timestamp: string;
};

const LessonActivity = ({
  username,
  avatar,
  description,
  name,
  timestamp,
}: Props) => {
  return (
    <SingleActivity>
      {avatar ? (
        <AvatarDiv>
          <ActivityIcon src={avatar} />
        </AvatarDiv>
      ) : (
        <ActivityIcon noDiv src={avatarIcon} />
      )}
      <ActivityInfoDiv>
        <ActivityInfo>
          <NameSpan>{username}</NameSpan>
          {` ${description} "${name}"`}
        </ActivityInfo>
        <Time>{dayjs(timestamp).fromNow()}</Time>
      </ActivityInfoDiv>
    </SingleActivity>
  );
};

const TournamentActivity = LessonActivity;

export const Activity = ({
  activity,
  username,
  avatar,
}: {
  activity: ActivityType;
  username: string | undefined;
  avatar?: string;
}) => {
  const { t } = useTranslation();

  switch (activity.kind) {
    case ActivityKind.LessonActivity:
      return (
        <LessonActivity
          username={username}
          avatar={avatar}
          description={t(
            activity.data.status === "done"
              ? "parentDashboard.monitorProgress.completedLesson"
              : "parentDashboard.monitorProgress.startedLesson"
          )}
          name={activity.data.name}
          timestamp={activity.data.timestamp}
        />
      );
    case ActivityKind.TournamentActivity:
      return (
        <TournamentActivity
          avatar={avatar}
          username={username}
          description={t(
            activity.data.status === TournamentResultStatus.Started
              ? "parentDashboard.monitorProgress.startedTournament"
              : "parentDashboard.monitorProgress.completedTournament"
          )}
          name={activity.data.name || t("tournaments.tournament")}
          timestamp={activity.data.timestamp}
        />
      );
    default:
      return <></>;
  }
};
