import tw, { styled, css } from "twin.macro";

export const NavBarWrapper = styled.div(
  () =>
    tw`box-border w-full flex-shrink-0 sm:w-64 lg:w-76 3xl:w-[14.9rem] md:p-6 justify-start flex-col py-2 border-r border-dark-gray hidden sm:flex`
);

export const NavBarItem = styled.button(
  ({ selected }: { selected?: boolean }) => [
    tw` justify-center sm:justify-start w-full flex text-sm text-black font-sans items-center h-[3rem] md:rounded-full box-border px-2 md:px-4`,
    selected && tw`bg-gold text-white`,
  ]
);

export const NavBarIcon = styled.img(({ selected }: { selected?: boolean }) => [
  tw`mr-2 hidden sm:inline`,
  selected &&
    css`
      filter: brightness(0) invert(1);
    `,
]);

export const DrawerButton = styled.div(
  () =>
    tw`w-12 flex flex-col space-y-2 mt-4 ml-2 flex-shrink-0 cursor-pointer sm:hidden`
);

export const DrawerButtonDiv = styled.div(() => tw`w-full h-1 bg-gold`);

export const Drawer = styled.div(({ open }: { open?: boolean }) => [
  tw`flex h-full bg-white flex-col w-86 fixed left-0 top-0 -translate-x-full sm:hidden z-[100] transition-all`,
  open && tw`translate-x-0`,
]);

export const DrawerCloseButton = styled.div(
  () => tw`ml-auto mr-4 my-4 text-gold cursor-pointer`
);
