import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SliceNames } from "../enums/SliceNames";
import { Quiz, SurveyState } from "../types/survey";

const initialState: SurveyState = {
  result: null,
  showLeaveTournamentModal: false,
  quiz: null,
  answers: null,
};

const surveySlice = createSlice({
  name: SliceNames.Survey,
  initialState,
  reducers: {
    addSurveyResult: (
      state,
      { payload }: PayloadAction<Pick<SurveyState, "result" | "answers">>
    ) => {
      state = Object.assign(state, payload);
    },
    setShowLeaveTournamentModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showLeaveTournamentModal = payload;
    },
    setQuizData: (state, { payload }: PayloadAction<Quiz>) => {
      state.quiz = payload;
    },
    reset: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  reset,
  addSurveyResult,
  setShowLeaveTournamentModal,
  setQuizData,
} = surveySlice.actions;

export default surveySlice.reducer;
