import { ReduxThunk } from "../../type";
import { setBusyAction, setErrorAction } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import i18n from "../../../i18n";
import { editLearnerPin } from "../../slices/learners";

export const changePin: (
  learnerUsername: string,
  password: string
) => ReduxThunk = (learnerUsername, password) => {
  return async (dispatch, _, { client }) => {
    dispatch(setBusyAction({ key: AsyncActions.ChangePinAction, value: true }));
    try {
      await client.patch(`/learner/${learnerUsername}`, {
        password,
      });
      dispatch(editLearnerPin({ username: learnerUsername, pin: password }));
    } catch (err: any) {
      dispatch(
        setErrorAction({
          key: AsyncActions.ChangePinAction,
          value: i18n.t("response.500"),
        })
      );
    }
    dispatch(
      setBusyAction({ key: AsyncActions.ChangePinAction, value: false })
    );
  };
};
