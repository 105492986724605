export enum ApiRoutes {
  LoginLearner = "/auth/learner/login",
  LoginSponsor = "/auth/sponsor/login",
  BaseInfo = "auth/sponsor/register",
  OTP = "auth/sponsor/confirm-email",
  UserInfo = "/me",
  Schools = "/school",
  Classroom = "/classroom",
  AddLearners = "/learner",
  SubscriptionCheckoutByCode = "/subscription/checkout-by-code",
  SubscriptionRenewByCode = "/subscription/renew-by-code/",
  CreateSubscription = "/subscription/create-subscription",
  RenewStripeSubscription = "/subscription/renew-with-stripe",
  ConfirmSubscriptionPayment = "/subscription/confirm-subscription-payment",
  PaymentMethods = "/subscription/payment-methods",
  AddPaymentMethod = "/subscription/payment-methods/add",
  RemovePaymentMethods = "/subscription/payment-methods/detach",
  EstimateBilling = "/subscription/estimated-billing",
  EstimateBillingRenewal = "/subscription/renew/billing",
  AddDepository = "/depository/add",
  Depository = "/depository",
  ForgotPassword = "/auth/sponsor/forgot-password",
  ResetPassword = "/auth/sponsor/reset-password",
  ResendOTP = "/auth/resend-code",
  FetchAvatars = "/avatar/all",
  FetchTraits = "/trait/all",
  LearnerProfile = "/learner/profile",
  PatchAvatar = "/avatar",
  Traits = "/trait",
  FetchFavoriteSubjects = "/subject/learner/favorite-subject/all",
  FavoriteSubjects = "/subject/learner/favorite-subject",
  LearnerActivity = "/activity/me",
  LearnersProgress = "learner/sponsor/progress",
  PaymentHistory = "/subscription/payment-history",
  PaymentReceipt = "/subscription/payment-receipt",
  SendCodeToMobile = "/auth/resend-code/phone",
  UpcomingTournaments = "tournaments/upcoming",
  RecentTournaments = "tournaments/recent",
  FetchNotifications = "/notification/recent",
  MarkNotificationsAsRead = "/notification/mark-all-as-read",
  MarkNotificationAsRead = "notification/mark-as-read",
  FetchPoints = "/points",
  TournamentResult = "/tournament-result",
  TournamentsForSponsor = "/tournaments/sponsor",
  TournamentResultsForSponsor = "/tournament-result/sponsor",
  TournamentUsernameConsent = "/consent/tournament",
  Tournament = "/tournaments",
  AvatarItems = "/avatar_item/all",
  BuyAvatarItem = "/learner_item",
  ChangeItemStatus = "/learner_item",
  RefundItem = "/learner_item",
  GetStripeSubscriptions = "/subscription/stripe",
  DeleteStripeSubscription = "/subscription/stripe",
  CreateFreeItems = "/learner_item/random-items",
  LessonSummary = "/ai-assistant/lesson/summary",
  AssistantSearch = "/ai-assistant/search",
  RelatedLessons = "/ai-assistant/related",
  GenerateQnA = "/ai-assistant/generate-qa",
  FetchQuiz = "/quiz/learner",
  SubmitLessonQuizResult = "/quiz/lesson/result",
  SubmitTournamentQuizResult = "/quiz/tournament/result",
}
