import { useTranslation } from "react-i18next";

import React, { ChangeEvent, useEffect, useState } from "react";
import Button from "../../../common/components/Button/Button";
import { StyledError } from "../../../common/components/Error/Error";
import {
  ButtonDiv,
  Description,
  Heading,
  SubmitDiv,
} from "../ProfileSetupStyled";
import {
  SubjectDiv,
  SubjectIcon,
  SubjectInput,
  SubjectLabel,
  SubjectsGrid,
  SubjectSpan,
  Wrapper,
} from "./ChooseSubjectsStyled";
import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../store/type";
import { Spinner } from "../../../common/components/Spinner/Spinner";
import {
  fetchAllSubjects,
  fetchFavoriteSubjects,
} from "../../../store/thunks/profileSetup/fetchFavoriteSubjects";
import { patchFavoriteSubjects } from "../../../store/thunks/profileSetup/patchSubjects";
import { ProfileCompleteModal } from "./ProfileCompleteModal/ProfileCompleteModal";
import { reset } from "../../../store/slices/async";

export const ChooseSubjects = ({
  setCurrentStep,
}: {
  setCurrentStep: (val: number) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loadingState, setLoadingState] = useState<boolean>(true);

  const {
    requestInProgress: fetchAllSubjectsInProgress,
    error: fetchAllSUbjectsError,
  } = useAsyncState(AsyncActions.FetchAvailableSubjects);

  const {
    requestInProgress: fetchFavoriteSubjectsInProgress,
    error: fetchFavoriteSubjectsError,
  } = useAsyncState(AsyncActions.FetchFavoriteSubjects);

  const {
    requestInProgress: patchSubjectsInProgress,
    error: patchSubjectsError,
    success: patchSubjectsSuccess,
  } = useAsyncState(AsyncActions.PatchFavoriteSubjects);
  const { allSubjects, favoriteSubjects } = useSelector(
    (state: StoreState) => state.favoriteSubjects
  );

  const [selectedSubjects, setSelectedSubjects] = useState<string[]>([]);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const onSubjectSelect = (e: ChangeEvent<HTMLInputElement>) => {
    setSubmitError(null);
    const index = selectedSubjects.findIndex((selectedSubject) => {
      return selectedSubject === e.target.value;
    });
    if (index >= 0) {
      return setSelectedSubjects([
        ...selectedSubjects.slice(0, index),
        ...selectedSubjects.slice(index + 1),
      ]);
    }
    setSelectedSubjects([...selectedSubjects, e.target.value]);
  };

  const onSubmit = () => {
    if (selectedSubjects.length === 0) {
      return setSubmitError(t("profileSetup.chooseSubjects.validation.empty"));
    }
    if (selectedSubjects.length > 3) {
      return setSubmitError(
        t("profileSetup.chooseSubjects.validation.exceeds")
      );
    }
    dispatch(patchFavoriteSubjects(selectedSubjects));
  };

  const checkboxShouldBeChecked = (subjectId: string) => {
    if (
      selectedSubjects.some((subject) => {
        return subject === subjectId;
      })
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    dispatch(fetchAllSubjects());
    dispatch(fetchFavoriteSubjects());
    setLoadingState(false);
    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    setSelectedSubjects([
      ...favoriteSubjects.map((subject) => {
        return subject.id;
      }),
    ]);
  }, [favoriteSubjects]);

  if (fetchAllSUbjectsError || fetchFavoriteSubjectsError) {
    return (
      <StyledError centered topMargin="auto" bottomMargin="auto">
        {fetchAllSUbjectsError || fetchFavoriteSubjectsError}
      </StyledError>
    );
  }

  return (
    <Wrapper>
      {fetchAllSubjectsInProgress ||
      loadingState ||
      fetchFavoriteSubjectsInProgress ? (
        <Spinner fullscreen />
      ) : (
        <React.Fragment>
          <Heading smallMargin>
            {t("profileSetup.chooseSubjects.heading")}
          </Heading>
          <Description>
            {t("profileSetup.chooseSubjects.description")}
          </Description>
          <SubjectsGrid>
            {allSubjects.map((subject) => {
              return (
                <SubjectDiv key={subject.id}>
                  <SubjectInput
                    checked={checkboxShouldBeChecked(subject.id)}
                    onChange={(e) => {
                      onSubjectSelect(e);
                    }}
                    id={subject.id}
                    value={subject.id}
                    type="checkbox"
                  />
                  <SubjectLabel htmlFor={subject.id}>
                    <SubjectIcon src={subject.image} />
                    <SubjectSpan>{subject.name}</SubjectSpan>
                  </SubjectLabel>
                </SubjectDiv>
              );
            })}
          </SubjectsGrid>
          <SubmitDiv>
            {submitError && (
              <StyledError centered topMargin="0" bottomMargin="0">
                {submitError}
              </StyledError>
            )}
            {patchSubjectsError && (
              <StyledError centered topMargin="0" bottomMargin="0">
                {patchSubjectsError}
              </StyledError>
            )}
            <ButtonDiv>
              <Button
                resPending={patchSubjectsInProgress}
                onClick={() => {
                  setCurrentStep(5);
                }}
                invert
                buttonWidth="100%"
                buttonHeight="2.5rem"
              >
                &larr; {t("profileSetup.backBtn")}
              </Button>
              <Button
                resPending={patchSubjectsInProgress}
                onClick={onSubmit}
                invert={false}
                buttonWidth="100%"
                buttonHeight="2.5rem"
              >
                {t("profileSetup.chooseSubjects.submitBtn")}
              </Button>
            </ButtonDiv>
          </SubmitDiv>
        </React.Fragment>
      )}
      {patchSubjectsSuccess && <ProfileCompleteModal />}
    </Wrapper>
  );
};
