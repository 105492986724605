import { ReactNode } from "react";
import tw, { styled } from "twin.macro";

export const PageWrapper = ({ children }: { children?: ReactNode }) => {
  return (
    <div className="min-h-app bg-pattern-black flex flex-col lg:flex-row px-2 lg:px-38 py-6 space-y-4 lg:space-y-0 lg:space-x-[2.5rem]">
      {children}
    </div>
  );
};

export const LearnerWrapper = styled.div(
  () => tw`w-full lg:w-[36.0625rem] 3xl:w-[32rem] flex flex-col`
);

export const ShopWrapper = styled.div(
  () => tw`w-full lg:w-[36.0625rem] 3xl:w-[32rem]`
);
