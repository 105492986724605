import tw, { styled } from "twin.macro";

export const SurveyWithResultsWrapper = styled.div(
  () => tw`min-h-app pb-6 [&>*]:font-sans!`
);

export const SurveyResultHeader = styled.div(
  () => tw`bg-light-gray-secondary flex justify-between h-14 px-32 py-4`
);

export const SurveyResultHeaderTitleWrapper = styled.div(() => tw`flex`);

export const SurveyResultHeaderTitle = styled.h2(
  () => tw`text-base text-black-secondary font-bold`
);
export const BackIcon = styled.img(() => tw`w-8 mr-5 cursor-pointer`);

export const MarksWrapper = styled.div(() => tw`text-base`);
export const MarksLabel = styled.span();
export const Marks = styled.span(() => tw`font-bold`);

export const ShowAnswerWrapper = styled.div(
  () => tw`text-black text-lg mx-auto w-6/12`
);

export const SolutionsWrapper = styled.div(
  () => tw`flex flex-col gap-y-8 pt-6`
);

export const QuestionStyled = styled.p(() => tw`font-medium mb-4`);

export const AnswersWrapper = styled.div(() => tw`pl-5`);

export const AnswerWrapper = styled.div(() => tw`flex gap-x-1 items-center`);

export const Dot = styled.div<{ $selected?: boolean; $noOpacity?: boolean }>(
  ({ $selected, $noOpacity }) => [
    tw`rounded-full w-4 h-4 border border-black opacity-70`,
    $selected && tw`bg-dark-blue border-dark-blue`,
    $noOpacity && tw`opacity-100`,
  ]
);

export const AnswerStyled = styled.p<{ $color: "green" | "red" | "default" }>(
  ({ $color }) => [
    $color === "green" && tw`text-green`,
    $color === "red" && tw`text-danger`,
    $color === "default" && tw`opacity-70`,
  ]
);

export const SolutionParagraph = styled.p(() => tw`italic text-sm`);
