import { ActivityDiv } from "./LearnerActivityStyled";
import { Activity } from "./Activity";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLearnerActivity } from "../../../../../store/thunks/Parent/fetchActivity";
import { StoreState } from "../../../../../store/type";
import { useAsyncState } from "../../../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../../../store/enums/AsyncActions";
import { Spinner } from "../../../../../common/components/Spinner/Spinner";
import { StyledError } from "../../../../../common/components/Error/Error";

type Props = {
  learnerUsername: string | undefined;
  avatar?: string;
};

export const ActivityList = ({ learnerUsername, avatar }: Props) => {
  const dispatch = useDispatch();
  const activities = useSelector((state: StoreState) => state.activity);
  const [loadingState, setLoadingState] = useState<boolean>(true);

  const { requestInProgress, error } = useAsyncState(
    AsyncActions.FetchLearnerActivity
  );

  useEffect(() => {
    if (!learnerUsername) {
      setLoadingState(false);
      return;
    }
    dispatch(fetchLearnerActivity(learnerUsername));
    setLoadingState(false);
  }, [learnerUsername]);

  return (
    <ActivityDiv>
      {loadingState || requestInProgress ? (
        <Spinner size="sm" />
      ) : error ? (
        <StyledError centered topMargin="auto" bottomMargin="auto">
          {error}
        </StyledError>
      ) : (
        activities.map((activity, i) => (
          <Activity
            key={i}
            activity={activity}
            avatar={avatar}
            username={learnerUsername}
          />
        ))
      )}
    </ActivityDiv>
  );
};
