import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import i18n from "../../../i18n";
import { AsyncActions } from "../../enums/AsyncActions";
import { setBusyAction, setErrorAction } from "../../slices/async";
import { setAvatars } from "../../slices/avatars";
import { ReduxThunk } from "../../type";

export const fetchAvatars: () => ReduxThunk = () => {
  return async (dispatch, _, { client }) => {
    try {
      dispatch(setBusyAction({ key: AsyncActions.FetchAvatars, value: true }));
      const response = await client.get(ApiRoutes.FetchAvatars);
      dispatch(setAvatars(response.data));
    } catch (e: any) {
      dispatch(
        setErrorAction({
          key: AsyncActions.FetchAvatars,
          value: i18n.t("profileSetup.avatarType.errors.fetch"),
        })
      );
    }
    dispatch(setBusyAction({ key: AsyncActions.FetchAvatars, value: false }));
  };
};
