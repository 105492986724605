import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import i18n from "../../../i18n";
import { AsyncActions } from "../../enums/AsyncActions";
import { setBusyAction, setErrorAction } from "../../slices/async";
import { setTraits, setUserTraits } from "../../slices/traits";
import { ReduxThunk } from "../../type";

export const fetchTraits: () => ReduxThunk = () => {
  return async (dispatch, _, { client }) => {
    try {
      dispatch(setBusyAction({ key: AsyncActions.FetchTraits, value: true }));
      const response = await client.get(ApiRoutes.FetchTraits);
      dispatch(setTraits(response.data));
    } catch (err: any) {
      dispatch(
        setErrorAction({
          key: AsyncActions.FetchTraits,
          value: i18n.t("profileSetup.personalityTraits.errors.fetchTraits"),
        })
      );
    }
    dispatch(setBusyAction({ key: AsyncActions.FetchTraits, value: false }));
  };
};

export const fetchUserTraits: () => ReduxThunk = () => {
  return async (dispatch, _, { client }) => {
    try {
      dispatch(
        setBusyAction({ key: AsyncActions.FetchUserTraits, value: true })
      );
      const response = await client.get(ApiRoutes.Traits);
      dispatch(setUserTraits(response.data));
    } catch (err: any) {
      dispatch(
        setErrorAction({
          key: AsyncActions.FetchTraits,
          value: i18n.t(
            "profileSetup.personalityTraits.errors.fetchUserTraits"
          ),
        })
      );
    }
    dispatch(
      setBusyAction({ key: AsyncActions.FetchUserTraits, value: false })
    );
  };
};
