import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Button from "../../../../common/components/Button/Button";
import { StyledError } from "../../../../common/components/Error/Error";
import { Modal } from "../../../../common/components/Modal/Modal";
import { PopupCloseStyled } from "../../../../common/components/Modal/ModalStyled";
import { useAsyncState } from "../../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../../store/enums/AsyncActions";
import { patchAvatar } from "../../../../store/thunks/profileSetup/patchAvatar";
import {
  ButtonDiv,
  WarningDescrpition,
  WarningHeading,
  Wrapper,
} from "./SubmitModalStyled";

type Props = {
  data: any;
  setModalOpen: (val: boolean) => void;
  setFormData: (val: any) => void;
};

export const SubmitModal = ({ data, setModalOpen, setFormData }: Props) => {
  const { requestInProgress, error } = useAsyncState(AsyncActions.PatchAvatar);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      setFormData(null);
    };
  }, []);

  const onSubmit = () => {
    dispatch(patchAvatar(data.avatar));
  };

  const onClose = () => {
    if (requestInProgress) {
      return;
    }
    setModalOpen(false);
  };
  return (
    <Modal>
      <Wrapper>
        <PopupCloseStyled onClick={onClose}>&#10006;</PopupCloseStyled>
        <WarningHeading>
          {t("profileSetup.avatarType.confirmationHeading")}
        </WarningHeading>
        <WarningDescrpition>
          {t("profileSetup.avatarType.confirmationParagraph")}
        </WarningDescrpition>
        {error && (
          <StyledError centered topMargin="0" bottomMargin="1rem">
            {error}
          </StyledError>
        )}
        <ButtonDiv>
          <Button
            resPending={requestInProgress}
            onClick={onClose}
            invert
            buttonWidth="100%"
            buttonHeight="2.5rem"
          >
            {t("profileSetup.avatarType.cancelBtn")}
          </Button>
          <Button resPending={requestInProgress} onClick={onSubmit}>
            {t("profileSetup.avatarType.submitBtn")}
          </Button>
        </ButtonDiv>
      </Wrapper>
    </Modal>
  );
};
