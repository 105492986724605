import {
  IconWrapper,
  ItemDescription,
  ItemValue,
  TournamentDetails,
  TournamentIcon,
  TournamentItem,
} from "./styled";
import cupIcon from "../../../assets/common/Lesson-Page/Cup.svg";
import { useTranslation } from "react-i18next";

type Props = {
  name: string | null;
};

export const TournamentTableItemComponent = ({ name }: Props) => {
  const { t } = useTranslation();
  return (
    <TournamentItem>
      <IconWrapper>
        <TournamentIcon src={cupIcon} />
      </IconWrapper>
      <TournamentDetails>
        <ItemValue>{name || t("tournaments.tournament")}</ItemValue>
        <ItemDescription extraSmall>
          {t("tournaments.pastTournamentDetails.individual")}
        </ItemDescription>
      </TournamentDetails>
    </TournamentItem>
  );
};
