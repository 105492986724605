import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import { BtnDiv, GridItemWrapper, GridWrapper } from "./EditInfoStyled";

import Input from "../../../../common/components/Input/Input";
import Button from "../../../../common/components/Button/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { editInfoSchema } from "../../../../views/validation/EditParentInfoSchema";
import Form from "../../../../common/components/Form/Form";
import { StyledError } from "../../../../common/components/Error/Error";
import { StoreState } from "../../../../store/type";
import { editInfo } from "../../../../store/thunks/editInfo";
import { useAsyncState } from "../../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../../store/enums/AsyncActions";
import { useTranslation } from "react-i18next";

type Props = {
  changeCurrentComponent: () => void;
};

export const EditInfo = ({ changeCurrentComponent }: Props) => {
  const { t } = useTranslation();

  const { firstName, lastName } = useSelector(
    (state: StoreState) => state.user.info
  );

  const dispatch = useDispatch();

  const { register, watch, formState, handleSubmit } = useForm({
    mode: "onTouched",
    resolver: yupResolver(editInfoSchema()),
    defaultValues: { firstName, lastName },
  });

  const watchedFields = watch();

  const { success, error, requestInProgress } = useAsyncState(
    AsyncActions.EditParentInfoAction
  );

  const saveChangesHandler = (data: any) => {
    dispatch(editInfo(data));
  };

  useEffect(() => {
    if (success) {
      changeCurrentComponent();
    }
  }, [success]);
  return (
    <React.Fragment>
      <Form
        onSubmit={handleSubmit(saveChangesHandler)}
        className="w-full flex flex-col items-center"
      >
        <GridWrapper>
          <GridItemWrapper>
            <Input
              value={watchedFields.firstName}
              labelText={t("parentDashboard.myProfile.firstNameLabel")}
              inputWidth="100%"
              type="text"
              required
              placeholderText={t("parentDashboard.myProfile.firstName")}
              name="firstName"
              registerInput={register}
            />
            {formState.errors?.firstName && (
              <StyledError bottomMargin="0" topMargin="0.441rem">
                {formState.errors.firstName.message}
              </StyledError>
            )}
          </GridItemWrapper>
          <GridItemWrapper>
            <Input
              value={watchedFields.lastName}
              labelText={t("parentDashboard.myProfile.lastNameLabel")}
              inputWidth="100%"
              type="text"
              required
              placeholderText={t("parentDashboard.myProfile.lastName")}
              name="lastName"
              registerInput={register}
            />
            {formState.errors?.lastName && (
              <StyledError bottomMargin="0" topMargin="0.441rem">
                {formState.errors.lastName.message}
              </StyledError>
            )}
          </GridItemWrapper>
        </GridWrapper>
        <BtnDiv>
          <Button
            resPending={requestInProgress}
            onClick={changeCurrentComponent}
            invert
            buttonWidth="100%"
          >
            {t("parentDashboard.myProfile.cancel")}
          </Button>
          {error && (
            <StyledError centered topMargin="0" bottomMargin="0.883rem">
              {error}
            </StyledError>
          )}
          <Button
            resPending={requestInProgress}
            disabled={requestInProgress}
            invert={false}
            buttonWidth="100%"
          >
            {t("parentDashboard.myProfile.saveBtn")}
          </Button>
        </BtnDiv>
      </Form>
    </React.Fragment>
  );
};
