import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Wrapper, ContentWrapper } from "./styled";
import {
  ToggleTournamentButton,
  ToggleTournamentsWrapper,
} from "../../../common/components/Tournament/styled";
import { LearnerUpcomingTournaments } from "./LearnerUpcomingTournaments";
import { LearnerTournamentHistory } from "./LearnerTournamentHistory";
import { TournamentPermissions } from "./TournamentPermissions";

type Tab = "upcoming" | "history";

export const Events = () => {
  const [activeTab, setActiveTab] = useState<Tab>("upcoming");
  const { t } = useTranslation();

  const handleSetActiveTab = (tab: Tab) => {
    setActiveTab(tab);
  };

  return (
    <Wrapper>
      <TournamentPermissions />
      <ToggleTournamentsWrapper>
        <ToggleTournamentButton
          onClick={() => handleSetActiveTab("upcoming")}
          selected={activeTab === "upcoming"}
        >
          {t("parentDashboard.events.upcoming")}
        </ToggleTournamentButton>
        <ToggleTournamentButton
          onClick={() => handleSetActiveTab("history")}
          selected={activeTab === "history"}
          second
        >
          {t("parentDashboard.events.history")}
        </ToggleTournamentButton>
      </ToggleTournamentsWrapper>
      <ContentWrapper>
        {activeTab === "upcoming" ? (
          <LearnerUpcomingTournaments />
        ) : (
          <LearnerTournamentHistory />
        )}
      </ContentWrapper>
    </Wrapper>
  );
};
