import tw, { styled } from "twin.macro";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

export const LearnerWrapper = styled.div(
  () =>
    tw`flex w-full flex-col  xl:flex-row items-center xl:items-start py-4 px-4 bg-white rounded-xl mb-2`
);

export const LearnerInfo = styled.div(
  () =>
    tw`flex flex-col w-48 justify-center xl:justify-start items-center xl:items-start my-2 xl:my-0 xl:mr-16`
);

export const AvatarNameDiv = styled.div(
  () => tw`flex justify-center xl:justify-start w-full`
);

export const NameDiv = styled.div(
  () => tw`flex justify-center xl:justify-start flex-col`
);

export const IconDiv = styled.div(
  () => tw`flex justify-center xl:justify-start w-full`
);

export const Heading = styled.h3(
  () => tw`text-gold text-sm text-center xl:text-left font-sans mb-4`
);

export const Value = styled.span(
  () => tw`font-sans text-center xl:text-left font-semibold text-black`
);

export const ExtraInfo = styled.span(
  () => tw`font-sans text-center xl:text-left  text-black text-sm`
);

export const Passed = styled.span(
  () => tw`font-sans text-center xl:text-left font-medium text-green`
);

export const Failed = styled.span(
  () => tw`font-sans text-center xl:text-left text-danger font-medium`
);

export const Grade = styled.span(
  () => tw`font-sans text-black text-center xl:text-left opacity-60 text-xs`
);

export const ProgressBarWrapper = styled.div(
  () => tw`w-14 h-14 justify-center xl:justify-start`
);
export const ProgressBar = styled(CircularProgressbarWithChildren)();

export const Icon = styled.img(() => tw`w-4 mr-2`);

export const AvatarDiv = styled.div(
  () =>
    tw`flex items-center justify-center w-12 h-12 mr-2 rounded-full bg-yellow`
);
export const Avatar = styled.img(({ noDiv }: { noDiv?: boolean }) => [
  tw`w-8 h-8`,
  noDiv && tw`mr-2 h-12 w-12`,
]);
