import { PaymentRequestStatus, PaymentStoreState } from "../types/payment";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";

import {
  setCheckoutAttemptStatusAction,
  setPaymentErrorAction,
} from "../slices/payment";
import { PaymentForm } from "../../common/types/PaymentForm";
import {
  getPaymentMethodsTask,
  checkoutByCodeTask,
  removePaymentMethodsTask,
} from "../thunks/payments";
import { EstimatedBillingRequest } from "../../common/types/EstimateBillingRequest";
import { StoreState } from "../type";
import { Learner } from "../types/learners";
import { ReactStripeElements } from "react-stripe-elements";
import { postPaymentMethodTask } from "../thunks/payments/postPaymentMethodTask";
import { createSubscriptionTask } from "../thunks/payments/createSubscriptionTask";
import { renewSubscriptionTask } from "../thunks/payments/renewSubscriptionTask";

type UsePayments = {
  getPaymentMethods: () => void;
  checkoutByCode: (
    code: string,
    subscriptions: EstimatedBillingRequest[],
    renewal: boolean,
    learners?: Learner[]
  ) => void;
  emptyPaymentError: () => void;
  clearPaymentStatus: () => void;
  setPaymentRequestStatus: (status: PaymentRequestStatus) => void;
  removePaymentMethod: (paymentMethodId: string) => void;
  postPaymentMethod: (form: { nameOnCard: string; email: string }) => void;
  state: PaymentStoreState;
  createSubscriptions: (
    form: PaymentForm,
    subscriptions: EstimatedBillingRequest[]
  ) => void;
  renewSubscription: (
    form: PaymentForm,
    subscription: EstimatedBillingRequest
  ) => void;
};

export const usePayments = (
  stripe: ReactStripeElements.StripeProps | null,
  elements: stripe.elements.Elements | null
): UsePayments => {
  const state = useSelector((s: StoreState) => s.payments);
  const dispatch = useDispatch();

  const getPaymentMethods = useCallback(
    () => dispatch(getPaymentMethodsTask()),
    [dispatch]
  );

  const removePaymentMethod = useCallback(
    (paymentMethodId: string) =>
      dispatch(removePaymentMethodsTask(paymentMethodId)),
    [dispatch]
  );

  const postPaymentMethod = useCallback(
    (form: { nameOnCard: string; email: string }) =>
      dispatch(postPaymentMethodTask(form, stripe, elements)),
    [dispatch, stripe, elements]
  );

  const checkoutByCode = useCallback(
    (
      code: string,
      subscriptions: EstimatedBillingRequest[],
      renewal: boolean,
      learners?: Learner[]
    ) => {
      dispatch(checkoutByCodeTask(code, subscriptions, renewal, learners));
    },
    [dispatch]
  );

  const setPaymentRequestStatus = useCallback(
    (status: PaymentRequestStatus) => {
      dispatch(setCheckoutAttemptStatusAction(status));
    },
    [dispatch]
  );

  const emptyPaymentError = useCallback(() => {
    dispatch(setPaymentErrorAction(""));
  }, [dispatch]);

  const clearPaymentStatus = useCallback(() => {
    setPaymentRequestStatus(PaymentRequestStatus.Idle);
  }, [dispatch, setPaymentRequestStatus]);

  const createSubscriptions = useCallback(
    (form: PaymentForm, subscriptions: EstimatedBillingRequest[]) => {
      dispatch(createSubscriptionTask(form, stripe, elements, subscriptions));
    },
    [dispatch]
  );

  const renewSubscription = useCallback(
    (form: PaymentForm, subscription: EstimatedBillingRequest) => {
      dispatch(renewSubscriptionTask(form, stripe, elements, subscription));
    },
    []
  );

  return {
    getPaymentMethods,
    removePaymentMethod,
    checkoutByCode,
    setPaymentRequestStatus,
    emptyPaymentError,
    clearPaymentStatus,
    postPaymentMethod,
    state,
    createSubscriptions,
    renewSubscription,
  };
};
