import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SliceNames } from "../enums/SliceNames";
import {
  PaymentStoreState,
  RemovePaymentMethodAttemptStatus,
  SetCheckoutAttemptStatusAction,
  SetCheckoutRenewalAttemptStatusAction,
  SetPaymentErrorAction,
  SetPaymentMethodAction,
  SetPaymentMethodAttemptStatus,
  SetEstimateBillingAction,
  SetEstimateBillingErrorAction,
} from "../types/payment";

export const initialState: PaymentStoreState = {
  estimatedBilling: {
    data: {
      learners: [],
    },
    error: null,
  },
};

export const paymentSlice = createSlice({
  name: SliceNames.PaymentSlice,
  initialState,
  reducers: {
    setCheckoutAttemptStatusAction: (
      state,
      { payload }: PayloadAction<SetCheckoutAttemptStatusAction>
    ) => {
      state.checkoutAttemptStatus = payload;
    },
    setPaymentErrorAction: (
      state,
      { payload }: PayloadAction<SetPaymentErrorAction>
    ) => {
      state.error = payload;
    },
    setCheckoutRenewalAttemptStatusAction: (
      state,
      { payload }: PayloadAction<SetCheckoutRenewalAttemptStatusAction>
    ) => {
      state.checkoutRenewalAttemptStatus = payload;
    },
    removePaymentMethodAttemptStatus: (
      state,
      { payload }: PayloadAction<RemovePaymentMethodAttemptStatus>
    ) => {
      state.removePaymentMethodAttemptStatus = payload;
    },
    setPaymentMethodAttemptStatus: (
      state,
      { payload }: PayloadAction<SetPaymentMethodAttemptStatus>
    ) => {
      state.paymentMethodsAttemptStatus = payload;
    },
    setPaymentMethodAction: (
      state,
      { payload }: PayloadAction<SetPaymentMethodAction>
    ) => {
      state.methods = payload;
    },
    setEstimatedBilling: (
      state,
      { payload }: PayloadAction<SetEstimateBillingAction>
    ) => {
      state.estimatedBilling.data = payload;
    },
    setEstimatedBillingError: (
      state,
      { payload }: PayloadAction<SetEstimateBillingErrorAction>
    ) => {
      state.estimatedBilling.error = payload;
    },
    reset: () => {
      return initialState;
    },
  },
});

export const {
  setCheckoutAttemptStatusAction,
  setCheckoutRenewalAttemptStatusAction,
  setPaymentErrorAction,
  removePaymentMethodAttemptStatus,
  setPaymentMethodAttemptStatus,
  setPaymentMethodAction,
  setEstimatedBilling,
  setEstimatedBillingError,
  reset,
} = paymentSlice.actions;

export default paymentSlice.reducer;
