import { KeyboardEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { OptionSelectedTitle } from "./OptionSelectedTitle";

import rocket from "../../assets/common/assistant/rocket.svg";
import {
  AssistantSearchResultWrapper,
  AssistantSearchWrapper,
  SearchButton,
  SearchInput,
  SearchInputWrapper,
  TermWrapper,
  SearchIcon,
} from "./styled";
import { searchLessons } from "../../store/thunks/Learner/assistant";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store/type";
import { useAsyncState } from "../../common/hooks/useAsyncState";
import { AsyncActions } from "../../store/enums/AsyncActions";
import { AnimatedDots } from "../../common/components/AnimatedDots/AnimatedDots";
import { SearchResultComponent } from "./SearchResult";
import { NoResultComponent } from "./NoResult";

type Props = {
  onSearchClick: () => void;
};

export const AssistantSearch = ({ onSearchClick }: Props) => {
  const [value, setValue] = useState("");
  const [term, setTerm] = useState("");
  const dispatch = useDispatch();
  const { activeClass } = useSelector((state: StoreState) => state.user.info);
  const searchResult = useSelector(
    (state: StoreState) => state.assistant.searchResult
  );
  const { requestInProgress } = useAsyncState(AsyncActions.Assistant);

  const { t } = useTranslation();

  const handleEnter = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    setTerm(value);
    dispatch(searchLessons(activeClass?.name || 1, value));
  };

  return (
    <>
      <OptionSelectedTitle
        icon={rocket}
        text={t("assistant.dialog.options.explore")}
      />
      <AssistantSearchWrapper>
        <AssistantSearchResultWrapper>
          <TermWrapper>
            {term !== "" && t("assistant.dialog.search.result", { term })}
          </TermWrapper>
          {requestInProgress ? (
            <AnimatedDots />
          ) : searchResult.length === 0 && term !== "" ? (
            <NoResultComponent />
          ) : (
            <>
              {searchResult.map((result) => (
                <SearchResultComponent
                  key={result.externalId}
                  {...result}
                  onSearchClicked={onSearchClick}
                />
              ))}
            </>
          )}
        </AssistantSearchResultWrapper>
        <SearchInputWrapper>
          <SearchInput
            onKeyDown={handleEnter}
            value={value}
            placeholder={t("assistant.dialog.search.placeholder")}
            onChange={(e) => setValue(e.target.value)}
          />
          <SearchButton onClick={handleSearch}>
            <SearchIcon />
          </SearchButton>
        </SearchInputWrapper>
      </AssistantSearchWrapper>
    </>
  );
};
