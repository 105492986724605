import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SliceNames } from "../enums/SliceNames";
import { Notification } from "../types/notification";

const initialState = [] as Notification[];

const notificationSlice = createSlice({
  name: SliceNames.Notification,
  initialState,
  reducers: {
    addAlerts: (state, { payload }: PayloadAction<Notification[]>) => {
      state.splice(0, state.length, ...payload);
    },
    addAlert: (state, { payload }: PayloadAction<Notification>) => {
      state.unshift(payload);
    },
    markAllAsRead: (state) => {
      state.forEach((n) => (n.seen = true));
    },
    markAsRead: (state, { payload }: PayloadAction<string>) => {
      state.map((n) => {
        if (n.id === payload) {
          n.seen = true;
        }
      });
    },
  },
});

export const { addAlerts, addAlert, markAllAsRead, markAsRead } =
  notificationSlice.actions;

export default notificationSlice.reducer;
