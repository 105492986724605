import { ReduxThunk } from "../../type";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { setBusyAction, setErrorAction, setSuccess } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import { setUserInfo } from "../../slices/user";
import i18n from "../../../i18n";

export const patchAvatar: (avatarId: string) => ReduxThunk = (avatarId) => {
  return async (dispatch, _, { client }) => {
    try {
      dispatch(setBusyAction({ key: AsyncActions.PatchAvatar, value: true }));
      const response = await client.patch(
        `${ApiRoutes.PatchAvatar}/${avatarId}`
      );
      dispatch(setUserInfo({ avatar: response.data }));
      dispatch(setSuccess({ key: AsyncActions.PatchAvatar, value: true }));
    } catch (err: any) {
      dispatch(
        setErrorAction({
          key: AsyncActions.PatchAvatar,
          value: i18n.t("profileSetup.avatarType.errors.failed"),
        })
      );
    }
    dispatch(setBusyAction({ key: AsyncActions.PatchAvatar, value: false }));
  };
};
