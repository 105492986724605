import { useTranslation } from "react-i18next";

import { CancelModalButton } from "./styled";
import success from "../../../assets/gifs/Successfully Registered.gif";
import { TournamentModal } from "./TournamentModal";

type Props = {
  onClose: () => void;
  reason: string;
};
export const CannotStartTournamentModal = ({ onClose, reason }: Props) => {
  const { t } = useTranslation();

  return (
    <TournamentModal
      onClose={onClose}
      title={t("tournaments.modal.cannotStart.title")}
      descriptions={[{ text: reason }]}
      icon={success}
    >
      <CancelModalButton buttonWidth={"9.125rem"} invert onClick={onClose}>
        {t("tournaments.modal.cannotStart.btnLabel")}
      </CancelModalButton>
    </TournamentModal>
  );
};
