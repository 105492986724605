import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getUpcomingTournaments } from "../../../store/thunks/Learner/tournament";
import { StoreState } from "../../../store/type";
import { UpcomingTournamentsComponent } from "../../../common/components/Tournament/UpcomingTournamentsComponent";

import { NoTournaments } from "./NoTournaments";
import { fetchSubjects } from "../../../store/thunks/Learner/fetchSubjects";

export const UpcomingTournaments = () => {
  const dispatch = useDispatch();
  const tournaments = useSelector(
    (state: StoreState) => state.tournaments.upcoming
  );

  useEffect(() => {
    dispatch(getUpcomingTournaments());
    dispatch(fetchSubjects());
  }, []);

  return tournaments.length > 0 ? (
    <UpcomingTournamentsComponent tournaments={tournaments} />
  ) : (
    <NoTournaments />
  );
};
