import { ReduxThunk } from "../../type";
import { setBusyAction, setErrorAction } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { addAvatarItem } from "../../slices/user";
import { LearnerItem } from "../../types/user";

export const buyItem =
  (itemId: string): ReduxThunk =>
  async (dispatch, _, { client }) => {
    dispatch(setBusyAction({ key: AsyncActions.BuyItem, value: true }));
    try {
      const res = await client.post<LearnerItem>(ApiRoutes.BuyAvatarItem, {
        itemId,
      });
      dispatch(addAvatarItem(res.data));
    } catch (e) {
      dispatch(setErrorAction({ key: AsyncActions.BuyItem, value: "Error" }));
    }

    dispatch(setBusyAction({ key: AsyncActions.BuyItem, value: false }));
  };
