import { useState } from "react";
import {
  Collapse,
  Panel,
  PanelContent,
  PanelHeader,
  StarIcon,
  PointsWrapper,
  Filter,
  FilterWrapper,
  FilterIcon,
} from "./styled";
import { DescriptionCell } from "./DescriptionCell";
import { UserDetails } from "./UserDetails";
import starIcon from "../../../assets/common/Lesson-Page/Star.svg";
import sortArrow from "../../../assets/common/Tournaments/sort-arrow.svg";

import { TournamentResultFilters, SortPaths } from "./TournamentResult";
import { TournamentResult } from "../../../store/types/tournament";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreState } from "../../../store/type";

type Props = {
  filters: TournamentResultFilters;
  data: TournamentResult[];
  onFilterClick: (path: SortPaths) => () => void;
};

export const TournamentResultMd = ({ filters, data, onFilterClick }: Props) => {
  const [activeKey, setActiveKey] = useState("");
  const { t } = useTranslation();
  const { activeClass } = useSelector((state: StoreState) => state.user.info);

  const handleSetActivePanel = (val: string) => {
    setActiveKey((pre) => (pre === val ? "" : val));
  };

  return (
    <>
      <FilterWrapper>
        {filters.map((f) => (
          <Filter onClick={onFilterClick(f.path)} key={f.label}>
            {t(`tournaments.tournamentResult.columns.${f.label}`)}{" "}
            <FilterIcon src={sortArrow} />
          </Filter>
        ))}
      </FilterWrapper>
      <Collapse activeKey={activeKey} accordion destroyInactivePanel>
        {data.map((d) => (
          <Panel
            collapsible="header"
            key={d.id}
            header={
              <PanelHeader
                active={d.id === activeKey}
                onClick={() => handleSetActivePanel(d.id)}
              >
                <span>{d.username}</span>
                <PointsWrapper>
                  <StarIcon src={starIcon} /> {d.points}
                </PointsWrapper>
              </PanelHeader>
            }
          >
            <PanelContent>
              <DescriptionCell
                label={t("tournaments.tournamentResult.columns.rank")}
              >
                {d.rank}
              </DescriptionCell>
              <DescriptionCell
                label={t("tournaments.tournamentResult.columns.name")}
              >
                <UserDetails
                  username={d.username}
                  avatar={d.avatar}
                  grade={`${activeClass?.name}th ${t(
                    "tournaments.tournamentResult.grade"
                  )}`}
                  items={d.items.map((i) => i.avatarItem)}
                />
              </DescriptionCell>
              <DescriptionCell
                label={t("tournaments.tournamentResult.columns.marks")}
              >{`${d.answers.correctlyAnswered}/${d.answers.totalQuestions}`}</DescriptionCell>
              <DescriptionCell
                label={t("tournaments.tournamentResult.columns.points")}
              >
                <PointsWrapper>
                  <StarIcon src={starIcon} /> {d.points}
                </PointsWrapper>
              </DescriptionCell>
            </PanelContent>
          </Panel>
        ))}
      </Collapse>
    </>
  );
};
