import tw, { styled } from "twin.macro";
import { InputStyled } from "../../../common/components/Input/Input";
import { LabelStyled } from "../../../common/components/Label/Label";
import Select from "react-select";

type ActionButtonProps = {
  addMargin?: boolean;
  inProgress?: boolean;
  error?: string | null;
};
export const PINWrapperStyled = styled.div(
  () => tw`flex flex-col  w-full text-left mt-6 max-w-[20.42rem]`
);

export const ActionsButtonStyled = styled.button<ActionButtonProps>(
  ({ addMargin, inProgress, error }) => [
    tw`font-sans font-semibold cursor-pointer text-base text-dark-blue mb-7`,
    addMargin && tw`mr-auto`,
    inProgress && tw`text-dark-gray`,
    error && tw`text-danger`,
  ]
);

export const ActionsWrapperStyled = styled.div(() => tw` flex w-full `);

export const UsernameStyled = styled.p(
  () => tw`italic font-sans text-black text-base font-normal`
);

export const ActionWrapper = styled.div(
  () =>
    tw`flex items-center justify-around flex-col gap-2 md:flex-row gap-4 mt-16`
);

export const InputWrapper = styled.div(
  () => tw`w-full grid grid-rows-3 md:grid-rows-2 grid-cols-12 items-center`
);

export const MobilePhoneNumberInput = styled(InputStyled)(
  () => tw`w-full md:w-[17rem]`
);

export const MobilePhoneInputGroup = styled.div(
  () =>
    tw`flex flex-col col-start-1 col-span-full md:flex-row lg:mb-2 md:col-start-5 md:col-span-6 `
);

export const StyledSelect = styled(Select)(
  () => tw`placeholder:font-sans text-sm h-10 mb-2 md:mb-0`
);

export const MobileCodeWrapper = styled.div(() => tw`w-64 lg:w-40`);
export const Label = styled(LabelStyled)(
  () =>
    tw`flex items-center row-start-1 col-span-full col-start-1 md:col-start-3 md:col-span-2 h-10 self-end md:self-auto`
);
export const ErrorWrapper = styled.div(
  () => tw`row-start-3 col-start-1 md:row-start-2 md:col-start-5 col-span-full`
);
