import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { StoreState } from "../../../store/type";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { removeLessons } from "../../../store/slices/subject";
import { fetchSubjectLessons } from "../../../store/thunks/Learner/fetchLessons";

import { Modal } from "../../../common/components/Modal/Modal";
import * as LessonsPopupStyled from "./LessonsPopupStyled";

import { Spinner } from "../../../common/components/Spinner/Spinner";
import { StyledError } from "../../../common/components/Error/Error";

import { useTranslation } from "react-i18next";
import { PopupCloseStyled } from "../../../common/components/Modal/ModalStyled";

import { LessonPopupListItemHeader } from "./LessonPopupListItemHeader";
import { LessonInfoDiv } from "./LessonInfoDiv";

export const LessonsPopup = ({
  subjectCode,
  viewLessons,
}: {
  subjectCode: string;
  viewLessons: (isOpen: boolean) => void;
}) => {
  const { t } = useTranslation();

  const { lessons } = useSelector((state: StoreState) => state.subjects);
  const { lesson } = useSelector((state: StoreState) => state.subjects);

  const [selectedLesson, setSelectedLesson] = useState({
    index: 0,
    code: lesson.code,
  });

  const dispatch = useDispatch();

  const { error: subjectLessonsError } = useAsyncState(
    AsyncActions.FetchSubjectLessonsAction
  );

  const { error: lessonInfoError, requestInProgress: lessonInfoInProgress } =
    useAsyncState(AsyncActions.FetchLessonInfoAction);

  useEffect(() => {
    dispatch(fetchSubjectLessons(subjectCode, true));
    return () => {
      dispatch(removeLessons());
    };
  }, []);

  return (
    <Modal full openModal={viewLessons} fit>
      <React.Fragment>
        <LessonsPopupStyled.CloseDiv>
          <PopupCloseStyled onClick={() => viewLessons(false)}>
            &#10006;
          </PopupCloseStyled>
        </LessonsPopupStyled.CloseDiv>
        {lessons.length === 0 ? (
          <LessonsPopupStyled.StyledSpinnerWrapper>
            <Spinner size="sm" />
          </LessonsPopupStyled.StyledSpinnerWrapper>
        ) : subjectLessonsError ? (
          <StyledError topMargin="auto" bottomMargin="auto">
            {t("learnerHome.errors.subjectFetch")}
          </StyledError>
        ) : (
          <React.Fragment>
            <LessonsPopupStyled.CollapseStyled
              destroyInactivePanel
              activeKey={selectedLesson ? selectedLesson.index : undefined}
              collapsible="header"
            >
              {lessons.map((l, index) => {
                return (
                  <LessonsPopupStyled.PanelStyled
                    collapsible="header"
                    key={index}
                    header={
                      <LessonPopupListItemHeader
                        lesson={l}
                        index={index}
                        setSelectedLesson={setSelectedLesson}
                        selectedLessonIndex={selectedLesson?.index}
                        subjectCode={subjectCode}
                      />
                    }
                  >
                    <LessonsPopupStyled.PanelContentWrapper>
                      {lessonInfoInProgress ? (
                        <LessonsPopupStyled.SpinnerWrapper>
                          <Spinner size="sm" />
                        </LessonsPopupStyled.SpinnerWrapper>
                      ) : lessonInfoError ? (
                        <LessonsPopupStyled.ErrorWrapper>
                          <StyledError bottomMargin="auto" topMargin="auto">
                            {t("learnerHome.errors.lessonFetch")}
                          </StyledError>
                        </LessonsPopupStyled.ErrorWrapper>
                      ) : (
                        <LessonInfoDiv
                          lesson={lesson}
                          subjectCode={subjectCode}
                          isMobileView={true}
                        />
                      )}
                    </LessonsPopupStyled.PanelContentWrapper>
                  </LessonsPopupStyled.PanelStyled>
                );
              })}
            </LessonsPopupStyled.CollapseStyled>
            <LessonsPopupStyled.Wrapper>
              <LessonsPopupStyled.ListDiv>
                {lessons.map((lesson, index) => {
                  return (
                    <LessonPopupListItemHeader
                      key={index}
                      lesson={lesson}
                      index={index}
                      setSelectedLesson={setSelectedLesson}
                      selectedLessonIndex={selectedLesson?.index}
                      subjectCode={subjectCode}
                    />
                  );
                })}
              </LessonsPopupStyled.ListDiv>
              <LessonsPopupStyled.InfoDiv>
                {lessonInfoInProgress ? (
                  <Spinner size="sm" />
                ) : lessonInfoError ? (
                  <StyledError bottomMargin="auto" topMargin="auto">
                    {t("learnerHome.errors.lessonFetch")}
                  </StyledError>
                ) : (
                  <LessonInfoDiv
                    lesson={lesson}
                    subjectCode={subjectCode}
                    isMobileView={false}
                  />
                )}
              </LessonsPopupStyled.InfoDiv>
            </LessonsPopupStyled.Wrapper>
          </React.Fragment>
        )}
      </React.Fragment>
    </Modal>
  );
};
