import { useEffect, useState } from "react";
import {
  QuestionTitle,
  QuestionWrapper,
  QuestionsAnsweredLabel,
  AnswersWrapper,
  SkipLabel,
  QuestionActionsWrapper,
  SurveyButton,
  QuestionButtonsWrapper,
} from "../../../components/LessonPage/LessonSurvey/styled";
import { QuestionAnswer } from "./QuestionAnswer";
import { QuizQuestion } from "../../../store/types/survey";
import { useTranslation } from "react-i18next";

type Props = {
  currentQuestion: number;
  totalQuestions: number;
  question: QuizQuestion;
  onSkipQuestion: () => void;
  onPreviousQuestion: () => void;
  onQuestionAnswer: (questionId: string, answerId: string) => void;
  onNextQuestion: () => void;
  answer: string | null;
};

export const QuestionComponent = ({
  currentQuestion,
  totalQuestions,
  question,
  onSkipQuestion,
  onPreviousQuestion,
  onQuestionAnswer,
  onNextQuestion,
  answer,
}: Props) => {
  const { t } = useTranslation();
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);

  const handleSelectAnswer = (id: string) => {
    setSelectedAnswer(id);
  };

  const handleAnswer = (questionId: string) => {
    if (!selectedAnswer) return;
    onQuestionAnswer(questionId, selectedAnswer);
    onNextQuestion();
  };

  useEffect(() => {
    if (answer) setSelectedAnswer(answer);
  }, [answer]);

  return (
    <QuestionWrapper>
      <QuestionsAnsweredLabel>
        {`${t(
          "quiz.currentQuestionLabel"
        )} ${currentQuestion}/${totalQuestions}`}
      </QuestionsAnsweredLabel>
      <QuestionTitle>{question.text}</QuestionTitle>
      <AnswersWrapper>
        {question.answers.map((answer, index) => (
          <QuestionAnswer
            key={answer.id}
            answer={answer}
            order={index}
            selected={selectedAnswer === answer.id}
            onSelectAnswer={handleSelectAnswer}
          />
        ))}
      </AnswersWrapper>
      <QuestionActionsWrapper>
        <SkipLabel onClick={onSkipQuestion}>
          {t("quiz.skipQuestionLabel")}
        </SkipLabel>
        <QuestionButtonsWrapper>
          <SurveyButton onClick={onPreviousQuestion} invert>
            {t("quiz.goBackButtonLabel")}
          </SurveyButton>
          <SurveyButton
            resPending={!selectedAnswer}
            disabled={!selectedAnswer}
            onClick={() => handleAnswer(question.id)}
          >
            {answer ? t("quiz.changeAnswerLabel") : t("quiz.answerButtonLabel")}
          </SurveyButton>
        </QuestionButtonsWrapper>
      </QuestionActionsWrapper>
    </QuestionWrapper>
  );
};
