import { ErrorWrapper, ErrorTitle } from "./styled";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";
import { useUserHomeRoute } from "../../hooks/useUserHomeRoute";
import { Link } from "react-router-dom";

export const SurveyError = ({ msg }: { msg: string }) => {
  const { t } = useTranslation();
  const homeRoute = useUserHomeRoute();

  return (
    <ErrorWrapper>
      <ErrorTitle>{msg}</ErrorTitle>
      <Link to={homeRoute}>
        <Button buttonWidth={"8.75rem"}>
          {t("tournaments.tournamentResult.errBtnLabel")}
        </Button>
      </Link>
    </ErrorWrapper>
  );
};
