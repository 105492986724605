import { ReduxThunk } from "../../type";
import { setBusyAction, setErrorAction } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { setPaymentMethodAction } from "../../slices/payment";
import i18n from "../../../i18n";

export const fetchPaymentMethod: () => ReduxThunk = () => {
  return async (dispatch, _, { client }) => {
    try {
      dispatch(
        setBusyAction({ key: AsyncActions.FetchPaymentMethods, value: true })
      );
      const response = await client.get(ApiRoutes.PaymentMethods);
      dispatch(setPaymentMethodAction(response.data));
    } catch (err: any) {
      dispatch(
        setErrorAction({
          key: AsyncActions.FetchPaymentMethods,
          value: i18n.t("response.fetchPaymentMethod"),
        })
      );
    }
    dispatch(
      setBusyAction({ key: AsyncActions.FetchPaymentMethods, value: false })
    );
  };
};
