import { useState } from "react";
import {
  Answer,
  AnswerDiv,
  ExpandButton,
  ExpandDiv,
  PlusButton,
  QuestionBody,
  QuestionBodyDiv,
  QuestionWrapper,
} from "./SingleQuestionStyled";

type Props = {
  questionBody: string;
  answer: string;
};
export const SingleQuestion = ({ questionBody, answer }: Props) => {
  const [questionOpen, setQuestionOpen] = useState(false);
  const triggerQuestionOpen = () => {
    setQuestionOpen((pre) => !pre);
  };
  return (
    <QuestionWrapper>
      <ExpandButton onClick={triggerQuestionOpen}>
        <ExpandDiv>
          <QuestionBodyDiv>
            <QuestionBody>{questionBody}</QuestionBody>
          </QuestionBodyDiv>
          <PlusButton>{questionOpen ? "-" : "+"}</PlusButton>
        </ExpandDiv>
      </ExpandButton>
      {questionOpen && (
        <AnswerDiv>
          <Answer dangerouslySetInnerHTML={{ __html: answer }} />
        </AnswerDiv>
      )}
    </QuestionWrapper>
  );
};
