import tw, { styled, css, GlobalStyles } from "twin.macro";

interface StepProps {
  current: number;
  total: number;
  titles: string[];
}

interface LengthProps {
  current: number;
  total: number;
}

interface StepWrapperProps {
  counter: number;
  total: number;
}

interface NumberProps {
  counter: number;

  current: number;
}

export const ComponentWrapperStyled = styled.div(
  () =>
    tw`hidden h-6 box-border w-full max-w-[43.32rem] px-[5%] xl:flex items-center justify-center mb-6 mt-6`
);

export const WrapperStyled = styled.div(
  () => tw`relative w-full h-0.5 bg-light-gray`
);

export const LengthDivStyled = styled.div<LengthProps>(({ current, total }) => [
  tw`h-0.5  bg-gold transition-all ease-out duration-300`,
  css`
    width: ${((current - 1) / (total - 1)) * 100}%;
  `,
]);

export const StepWrapperStyled = styled.div<StepWrapperProps>(
  ({ counter, total }) => [
    tw`absolute top-0 flex justify-center
-translate-x-2/4`,
    css`
      left: ${counter === total
        ? (counter / total) * 100
        : ((counter - 1) / (total - 1)) * 100}%;
    `,
  ]
);

export const NumberWrapperStyled = styled.div<NumberProps>(
  ({ counter, current }) => [
    tw`absolute top-2/4 -translate-y-2/4 transition-all ease-out duration-300 rounded-full w-6 h-6 flex items-center justify-center`,
    counter <= current ? tw`bg-gold text-white` : tw`bg-light-gray text-black`,
  ]
);

export const NumberStyled = styled.span(() => tw`text-xs font-sans`);

export const TitleStyled = styled.p<NumberProps>(({ counter, current }) => [
  tw`w-max absolute top-4 font-medium transition-all ease-out duration-300 text-xs font-sans`,
  counter <= current ? tw`text-gold` : tw`text-black opacity-60`,
]);

const StepComponent = (props: StepProps) => {
  const stepDivs: any = [];

  for (let i = 1; i <= props.total; i++) {
    stepDivs.push(
      <StepWrapperStyled key={i} counter={i} total={props.total}>
        <NumberWrapperStyled counter={i} current={props.current}>
          <NumberStyled>{i}</NumberStyled>
        </NumberWrapperStyled>
        <TitleStyled counter={i} current={props.current}>
          {props.titles[i - 1]}
        </TitleStyled>
      </StepWrapperStyled>
    );
  }

  return (
    <ComponentWrapperStyled>
      <WrapperStyled>
        <LengthDivStyled current={props.current} total={props.total} />
        {stepDivs}
      </WrapperStyled>
      <GlobalStyles />
    </ComponentWrapperStyled>
  );
};

export default StepComponent;
