import React from "react";
import { useTranslation } from "react-i18next";
import { NavBarIcon, NavBarItem } from "./NavBarStyled";

import monitorIcon from "../../../assets/common/Parent-Monitor/Monitor.svg";
import manageIcon from "../../../assets/common/Parent-Monitor/Manage.svg";
import subscriptionIcon from "../../../assets/common/Parent-Monitor/Subscription.svg";
import profileIcon from "../../../assets/common/Parent-Monitor/Profile.svg";
import passwordIcon from "../../../assets/common/Parent-Monitor/Password.svg";
import eventsIcon from "../../../assets/common/Parent-Monitor/Events.svg";
import helpIcon from "../../../assets/common/Parent-Monitor/Help.svg";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../../common/consts/route-path";
import { env } from "../../../common/config/env";

type NavContentProps = {
  currentPage: number;

  setDrawerOpen: (val: boolean) => void;
};

export const NavBarContent = ({
  currentPage,

  setDrawerOpen,
}: NavContentProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const selectHandler = (e: any) => {
    navigate(
      `${RoutePath.parentDashboard.locale[env.locale]}?step=${
        e.target.dataset.tab
      }`
    );
    setDrawerOpen(false);
  };
  return (
    <React.Fragment>
      <NavBarItem
        data-tab={1}
        selected={currentPage === 1}
        onClick={(e) => {
          selectHandler(e);
        }}
      >
        <NavBarIcon selected={currentPage === 1} src={monitorIcon} />
        {t("parentDashboard.sideNav.monitor")}
      </NavBarItem>
      <NavBarItem
        data-tab={2}
        selected={currentPage === 2}
        onClick={(e) => {
          selectHandler(e);
        }}
      >
        <NavBarIcon selected={currentPage === 2} src={manageIcon} />
        {t("parentDashboard.sideNav.manage")}
      </NavBarItem>
      <NavBarItem
        data-tab={3}
        selected={currentPage === 3}
        onClick={(e) => {
          selectHandler(e);
        }}
      >
        <NavBarIcon selected={currentPage === 3} src={eventsIcon} />
        {t("parentDashboard.sideNav.events")}
      </NavBarItem>
      <NavBarItem
        data-tab={4}
        selected={currentPage === 4}
        onClick={(e) => {
          selectHandler(e);
        }}
      >
        <NavBarIcon selected={currentPage === 4} src={subscriptionIcon} />
        {t("parentDashboard.sideNav.subscriptions")}
      </NavBarItem>
      <NavBarItem
        data-tab={5}
        selected={currentPage === 5}
        onClick={(e) => {
          selectHandler(e);
        }}
      >
        <NavBarIcon selected={currentPage === 5} src={profileIcon} />
        {t("parentDashboard.sideNav.profile")}
      </NavBarItem>
      <NavBarItem
        data-tab={6}
        selected={currentPage === 6}
        onClick={(e) => {
          selectHandler(e);
        }}
      >
        <NavBarIcon selected={currentPage === 6} src={passwordIcon} />
        {t("parentDashboard.sideNav.password")}
      </NavBarItem>
      <NavBarItem
        data-tab={7}
        selected={currentPage === 7}
        onClick={(e) => {
          selectHandler(e);
        }}
      >
        <NavBarIcon selected={currentPage === 7} src={helpIcon} />
        {t("parentDashboard.sideNav.help")}
      </NavBarItem>
    </React.Fragment>
  );
};
