import { ReduxThunk } from "../type";
import { setBusyAction, setErrorAction, setSuccess } from "../slices/async";
import { AsyncActions } from "../enums/AsyncActions";
import i18n from "../../i18n";
import { ApiRoutes } from "../../common/enums/ApiRoutes";
import { setUserInfo } from "../slices/user";
import { UserState } from "../types/user";

type payloadData = Partial<UserState["info"]>;

export const editInfo: (data: payloadData) => ReduxThunk = (data) => {
  return async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({ key: AsyncActions.EditParentInfoAction, value: true })
    );
    try {
      await client.patch(ApiRoutes.UserInfo, data);
      dispatch(setUserInfo(data));
      dispatch(
        setSuccess({ key: AsyncActions.EditParentInfoAction, value: true })
      );
    } catch (err: any) {
      dispatch(
        setErrorAction({
          key: AsyncActions.EditParentInfoAction,
          value: i18n.t("parentDashboard.myProfile.error"),
        })
      );
    }
    dispatch(
      setBusyAction({ key: AsyncActions.EditParentInfoAction, value: false })
    );
  };
};
