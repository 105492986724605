import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as en from "./translation/en.json";
import * as si from "./translation/si.json";

import { env } from "./common/config/env";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
export const resources = {
  en,
  si,
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // fallbackLng: "en",
    lng: env.locale,
    debug: env.nodeEnv === "production",
    saveMissing: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  });

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.i18n = i18n;

export default i18n;
