import tw, { styled } from "twin.macro";
import { Icon } from "../../Tournament/modals/styled";
import { ButtonStyled } from "../../../common/components/Button/Button";

export const Wrapper = styled.div(
  () =>
    tw`flex flex-col items-center gap-y-2 font-sans w-full px-4 md:px-8 overflow-auto`
);

export const Title = styled.h1(
  () => tw`font-bold text-2xl font-dyslexic my-10 text-center`
);

export const Description = styled.div(
  () => tw`text-base font-sans text-center mb-9 whitespace-pre-line text-center`
);

export const Image = styled.img(() => tw`h-[15.625rem]`);

export const CloseIconWrapper = styled.div(
  () => tw`w-full relative cursor-pointer`
);

export const CloseIcon = styled(Icon)(() => tw`top-4 right-4`);

export const ConsentActionWrapper = styled.div(
  () => tw`flex flex-col gap-y-2 w-10/12`
);

export const ActionWrapper = styled.div(() => [
  tw`flex flex-col md:flex-row items-center justify-around gap-x-4 gap-y-4 border-b first:border-t drop-shadow-lg border-smoke p-2 justify-between`,
]);

export const ActionButtonWrapper = styled.div(
  () => tw`flex flex-col md:flex-row gap-x-2 gap-y-2`
);

export const ActionButton = styled(ButtonStyled)(() => tw`w-[10rem]`);

export const Username = styled.p(() => tw`font-sans`);

export const IconWrapper = styled.div(
  () => tw`w-[2.65rem] h-[2.65rem] flex items-center justify-around`
);

export const PermissionChosenIcon = styled.img(() => tw`w-5 h-5`);
