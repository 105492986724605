export type Env = {
  locale: "en" | "si";
  baseUrl: string;
  teachersContentLink: string;
  studentsContentLink: string;
  parentsContentLink: string;
  stripeKey: string;
  contactEmail: string;
  contactNumber: string;
  nodeEnv: string;
  pollInterval: number;
  generalConditionLink: string;
  privacyPolicyLink: string;
  tournamentResultDelayInMinutes: number;
  yunoPortal: string;
};

export const env: Env = {
  locale: process.env.REACT_APP_LANGUAGE as Env["locale"],
  baseUrl: process.env.REACT_APP_BASE_URL as string,
  teachersContentLink: process.env.REACT_APP_YUNO_FOR_TEACHERS as string,
  studentsContentLink: process.env.REACT_APP_YUNO_FOR_STUDENTS as string,
  parentsContentLink: process.env.REACT_APP_YUNO_FOR_PARENTS as string,
  stripeKey: process.env.REACT_APP_STRIPE_KEY as string,
  contactEmail: process.env.REACT_APP_YUNO_EMAIL as string,
  contactNumber: process.env.REACT_APP_PHONE_CONTACT as string,
  nodeEnv: process.env.REACT_APP_NODE_ENV as string,
  pollInterval: +(process.env.REACT_APP_POLL_INTERVAL as string),
  generalConditionLink: process.env.REACT_APP_GENERAL_CONDITION_LINK as string,
  privacyPolicyLink: process.env.REACT_APP_PRIVACY_POLICY_LINK as string,
  tournamentResultDelayInMinutes: +(
    process.env.REACT_APP_TOURNAMENT_RESULT_DELAY_IN_MINUTES || 0
  ),
  yunoPortal: process.env.REACT_APP_YUNO_PORTAL as string,
};
