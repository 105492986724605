import { omit } from "ramda";
import { ReduxThunk } from "../../type";
import { setBusyAction } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";

type SubmitQuizData = {
  lessonId: string;
  quizId: string;
  totalQuestions: number;
  correctlyAnswered: number;
  wronglyAnswered: number;
  depositoryId: string;
  lessonCode: string;
};

export const submitLessonQuizResult =
  (body: SubmitQuizData): ReduxThunk =>
  async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({ key: AsyncActions.SubmitSurveyResult, value: true })
    );

    try {
      await client.post(
        ApiRoutes.SubmitLessonQuizResult,
        omit(["depositoryId"], body)
      );
    } catch (e) {
      console.log("error");
    }

    dispatch(
      setBusyAction({ key: AsyncActions.SubmitSurveyResult, value: false })
    );
  };
