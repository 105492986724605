import tw, { styled } from "twin.macro";

export const BannerWrapperStyled = styled.div(() => tw`relative w-full`);

export const InnerWrapperStyled = styled.div(
  () =>
    tw`flex flex-col items-center bg-light-gray-secondary py-4 md:flex-row md:py-6 px-4 md:px-38`
);

export const NameDivStyled = styled.div(() => tw`flex flex-col md:max-w-[30%]`);

export const GreetingStyled = styled.span(
  () => tw`md:text-left text-center font-bold text-gold`
);

export const NameStyled = styled.span(
  () => tw`font-bold text-[1.75rem] text-black md:text-left text-center`
);

export const PointsDivStyled = styled.div(
  () => tw`flex md:ml-auto mt-9 mb-12 md:mb-0 md:mt-0 items-center`
);

export const PointsHeading = styled.h6(
  () => tw`font-sans text-black mr-2 font-medium text-sm`
);
