import tw, { styled } from "twin.macro";

export const GridWrapper = styled.div(
  () => tw`grid gap-y-4 my-8 gap-x-6 grid-cols-1 md:grid-cols-2 w-full`
);

export const GridItemWrapper = styled.div(() => tw`w-full flex flex-col`);
export const BtnDiv = styled.div(
  () =>
    tw`w-full flex flex-col md:flex-row space-y-4 md:space-x-4 md:space-y-0 max-w-[23.125rem]`
);
