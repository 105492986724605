import {
  DropdownHeaderWrapper,
  HeaderDescription,
  ActionWrapper,
  CheckIcon,
} from "./styled";
import checkIcon from "../../../../assets/common/Header/Check.svg";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { markNotificationsAsRead } from "../../../../store/thunks/Learner/markNotificationsAsRead";

export const DropdownHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const markAsRead = () => {
    dispatch(markNotificationsAsRead());
  };

  return (
    <DropdownHeaderWrapper key={"alert-header"}>
      <HeaderDescription>{t("header.alert.headerLabel")}</HeaderDescription>
      <ActionWrapper onClick={markAsRead}>
        {t("header.alert.actionLabel")} <CheckIcon src={checkIcon} />
      </ActionWrapper>
    </DropdownHeaderWrapper>
  );
};
