import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../../../../../common/components/Spinner/Spinner";
import { useAsyncState } from "../../../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../../../store/enums/AsyncActions";
import { fetchPaymentHistory } from "../../../../../store/thunks/Parent/fetchPaymentHistory";
import { StoreState } from "../../../../../store/type";

import {
  ContentWrapper,
  Heading,
  Wrapper,
  InfoDiv,
  MethodName,
  SpinnerWrapper,
} from "./BillingHistoryStyled";
import { BillingItem } from "./BillingItem/BillingItem";

export const BillingHistory = () => {
  const { requestInProgress } = useAsyncState(AsyncActions.FetchPaymentHistory);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const paymentHistory = useSelector(
    (state: StoreState) => state.paymentHistory
  );

  useEffect(() => {
    dispatch(fetchPaymentHistory());
  }, []);

  return (
    <Wrapper>
      {requestInProgress ? (
        <SpinnerWrapper>
          <Spinner size="sm" />
        </SpinnerWrapper>
      ) : (
        <>
          {paymentHistory.length > 0 && (
            <ContentWrapper>
              <Heading>
                {t("parentDashboard.subscriptions.billingDate")}
              </Heading>
              <Heading>
                {t("parentDashboard.subscriptions.billingLearnerName")}
              </Heading>
              <Heading>
                {t("parentDashboard.subscriptions.billingAmount")}
              </Heading>
              <Heading>
                {t("parentDashboard.subscriptions.billingMode")}
              </Heading>
              <Heading>
                {t("parentDashboard.subscriptions.billingStatus")}
              </Heading>
              <Heading>
                {t("parentDashboard.subscriptions.billingReceipt")}
              </Heading>
            </ContentWrapper>
          )}
          {paymentHistory.map((payment) => (
            <BillingItem key={payment.id} {...payment} />
          ))}
          {paymentHistory.length === 0 && (
            <InfoDiv>
              <MethodName>
                {t("parentDashboard.subscriptions.noPayments")}
              </MethodName>
            </InfoDiv>
          )}
        </>
      )}
    </Wrapper>
  );
};
