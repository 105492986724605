import tw, { styled } from "twin.macro";
import { ButtonStyled } from "../../../../common/components/Button/Button";

export const ModalWrapper = styled.div(
  () => tw`w-full flex flex-col justify-center items-center p-10`
);

export const ContentWrapper = styled.div(
  () => tw`w-full max-w-[23.125rem] flex flex-col items-center`
);

export const AvatarDiv = styled.div(
  () =>
    tw`w-16 h-16 rounded-full bg-yellow flex items-center justify-center flex-shrink-0 xl:mr-3`
);

export const AvatarWrapper = styled.div(() => tw`w-15 h-15`);

export const ChangePinAvatar = styled.img(({ noDiv }: { noDiv?: boolean }) => [
  tw`w-24 h-24`,
  noDiv && tw`w-34 h-34`,
]);

export const ChangePinAvatarDiv = styled.div(
  () =>
    tw`w-34 h-34 rounded-full bg-light-gray-secondary flex items-center justify-center flex-shrink-0`
);

export const LearnerName = styled.span(
  () => tw`font-sans font-semibold text-lg text-black mb-6 mt-2`
);

export const PinLabel = styled.label(
  () => tw`self-start mb-2 font-sans text-sm text-black`
);

export const UnsubscribedLearnerDiv = styled.div(
  () =>
    tw`self-center m-auto xl:m-0 xl:ml-auto last:xl:ml-6 flex items-center justify-center mb-4 xl:mb-0`
);

export const UnsubscribedTitle = styled.h2(
  () => tw`font-bold text-black mt-6 first:mt-2 mb-4`
);

export const UnsubscribedWrapper = styled.div(() => tw`my-2`);

export const DeleteButton = styled(ButtonStyled)(() => tw`bg-danger border-0`);

export const ChangeFontDiv = styled.div(
  () => tw`self-center flex items-center justify-center mb-4 xl:mb-0 xl:mr-4`
);

export const ChangeFontButton = styled(ButtonStyled)(
  () => tw`w-[10.375rem] h-[2.5rem] bg-error-450 border-none font-dyslexic`
);
