import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { changePinSchema } from "../../../../views/validation/changePinValidationSchema";

import { Modal } from "../../../../common/components/Modal/Modal";

import {
  ChangePinAvatar,
  ChangePinAvatarDiv,
  ContentWrapper,
  LearnerName,
  ModalWrapper,
  PinLabel,
} from "./LearnersListStyled";
import avatar from "../../../../assets/common/Parent-Manage/Avatar.svg";
import Pin from "../../../../common/components/Pin/Pin";
import Button from "../../../../common/components/Button/Button";
import { PopupCloseStyled } from "../../../../common/components/Modal/ModalStyled";
import { Learner } from "../../../../store/types/learners";
import Form from "../../../../common/components/Form/Form";
import { StyledError } from "../../../../common/components/Error/Error";
import { useDispatch } from "react-redux";
import { changePin } from "../../../../store/thunks/Parent/ChangePin";
import { useAsyncState } from "../../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../../store/enums/AsyncActions";

type ChangePinProps = {
  toggleModal: (learnerId: string) => void;
  learner: Learner;
};
export const ChangePinModal = ({ toggleModal, learner }: ChangePinProps) => {
  const [pinValue, setPinValue] = useState("");
  const [pinChanged, setPinChanged] = useState(false);

  const { t } = useTranslation();

  const { requestInProgress, error } = useAsyncState(
    AsyncActions.ChangePinAction
  );

  const dispatch = useDispatch();

  const { formState, handleSubmit, setValue, clearErrors } = useForm({
    mode: "onTouched",
    resolver: yupResolver(changePinSchema()),
  });

  const onSubmitHandler = () => {
    dispatch(changePin(learner.username, pinValue));
    if (error) {
      return;
    }
    setPinChanged(true);
  };

  useEffect(() => {
    if (!pinChanged || requestInProgress || error) {
      return;
    }
    toggleModal("");
  }, [pinChanged, requestInProgress, error]);

  useEffect(() => {
    if (pinValue.length === 4) {
      clearErrors("pin");
    }
    setValue("pin", pinValue);
  }, [pinValue]);

  return (
    <Modal>
      <ModalWrapper>
        <PopupCloseStyled onClick={() => toggleModal("")}>
          &#10006;
        </PopupCloseStyled>
        <ContentWrapper>
          {learner.avatar?.image ? (
            <ChangePinAvatarDiv>
              <ChangePinAvatar src={learner.avatar.image} />
            </ChangePinAvatarDiv>
          ) : (
            <ChangePinAvatar noDiv src={avatar} />
          )}
          <LearnerName>
            {learner.firstName} {learner.lastName}
          </LearnerName>
          <PinLabel>{t("parentDashboard.manage.pinLabel")}</PinLabel>
          <Form onSubmit={handleSubmit(onSubmitHandler)}>
            <Pin setPinValue={setPinValue} pinLength={4} />
            {formState.errors.pin && (
              <StyledError topMargin="0.442rem" bottomMargin="0.883rem">
                {formState.errors.pin?.message}
              </StyledError>
            )}
            {error && (
              <StyledError
                centered
                topMargin="0.883rem"
                bottomMargin="0.883rem"
              >
                {error}
              </StyledError>
            )}
            <Button
              resPending={requestInProgress}
              invert={false}
              buttonWidth="100%"
              topMargin="1.5rem"
            >
              {t("parentDashboard.manage.setPinBtn")}
            </Button>
          </Form>
        </ContentWrapper>
      </ModalWrapper>
    </Modal>
  );
};
