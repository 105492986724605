import * as yup from "yup";
import i18n from "../../i18n";

export const changePinSchema = () => {
  return yup.object({
    pin: yup
      .string()
      .required(i18n.t("validation.pin.required"))
      .length(4, i18n.t("validation.pin.min")),
  });
};
