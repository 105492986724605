export const RoutePath = Object.freeze({
  home: {
    locale: {
      en: "/",
      sr: "/",
      si: "/",
    },
  },
  login: {
    locale: {
      en: "/login",
      sr: "/prijava",
      si: "/login",
    },
  },
  register: {
    locale: {
      en: "/register",
      sr: "/registracija",
      si: "/registracija",
    },
  },
  resetPassword: {
    locale: {
      en: "/reset-password",
      sr: "/reset-password",
      si: "/reset-password",
    },
  },
  confirmEmail: {
    locale: {
      en: "/confirm-email",
      sr: "/potvrdi-email",
      si: "/potvrdi-email",
    },
  },
  profile: {
    locale: {
      en: "/profile",
      sr: "/profil",
      si: "/profil",
    },
  },
  onboarding: {
    locale: {
      en: "/onboarding",
      sr: "/dobrodosli",
      si: "/dobrodosli",
    },
    account: {
      locale: {
        en: "account",
        sr: "nalog",
        si: "nalog",
      },
    },
    preferences: {
      locale: {
        en: "preferences",
        sr: "svidjanja",
        si: "svidjanja",
      },
    },
  },
  lessonPage: {
    locale: {
      en: "/lesson/:subjectCode/:lessonCode",
      sr: "/lekcija/:subjectCode/:lessonCode",
      si: "/lesson/:subjectCode/:lessonCode",
    },
    sub: {
      lesson: {
        locale: {
          en: "",
          sr: "",
          si: "",
        },
      },
      survey: {
        locale: {
          en: "test/:quizId",
          sr: "kviz/:quizId",
          si: "kviz/:quizId",
        },
      },
    },
  },
  parentDashboard: {
    locale: {
      en: "/dashboard",
      sr: "/komandna-tabla",
      si: "/armaturna-plosca",
    },
    sub: {
      events: {
        locale: {
          en: "events",
          sr: "dogadjaji",
          si: "dogodki",
        },
        sub: {
          history: {
            locale: {
              en: "history",
              si: "zgodovina",
              sr: "istorija",
            },
          },
          upcoming: {
            locale: {
              en: "upcoming",
              si: "upcoming",
              sr: "predstojeci",
            },
          },
        },
      },
    },
  },
  forgotPassword: {
    locale: {
      en: "/forgot-password",
      sr: "/zaboravljena-sifra",
      si: "/pozabili-geslo",
    },
  },
  learnerSetup: {
    locale: {
      en: "/setup-profile",
      sr: "/kreiranje-profila",
      si: "/setup-profile",
    },
  },
  learnerProfile: {
    locale: {
      en: "/profile-settings",
      sr: "/podesavanja-profila",
      si: "/profile-settings",
    },
  },
  unauthorized: {
    locale: {
      en: "/unauthorized",
      sr: "/neautorizovan",
      si: "/unauthorized",
    },
  },
  inactiveSubscription: {
    locale: {
      en: "/inactive-subscription",
      sr: "/istekla-subskripcija",
      si: "/ni-aktivne-narocnine",
    },
  },
  search: {
    locale: {
      en: "/search",
      si: "/iskanje",
      sr: "/pretraga",
    },
  },
  tournaments: {
    locale: {
      en: "/tournament",
      si: "/turnir",
      sr: "/turnir",
    },
    sub: {
      list: {
        locale: {
          en: "list",
          si: "lista",
          sr: "lista",
        },
        sub: {
          upcoming: {
            locale: {
              en: "upcoming",
              si: "prihajajoce",
              sr: "predstojeci",
            },
          },
          history: {
            locale: {
              en: "history",
              si: "history",
              sr: "istorija",
            },
          },
        },
      },
      individualTournaments: {
        locale: {
          en: "individual-tournament/:id/:quizId",
          si: "individual-tournament/:id/:quizId",
          sr: "individual-tournament/:id/:quizId",
        },
      },
      result: {
        locale: {
          en: "result/:id",
          si: "rezultat/:id",
          sr: "rezultat/:id",
        },
      },
    },
  },
  individualTournaments: {
    locale: {
      en: "/individual-tournament/:id",
      si: "/individual-tournament/:id",
      sr: "/individual-tournament/:id",
    },
  },
});
