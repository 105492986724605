import tw, { styled } from "twin.macro";

export const Wrapper = styled.div(
  () => tw`w-full min-h-screen flex flex-col items-center justify-center px-4`
);

export const ContentWrapper = styled.div(
  () => tw`w-full max-w-[60rem] flex flex-col items-center justify-center`
);

export const Heading = styled.h1(() => [
  tw`font-sans font-bold text-2xl text-black text-center mb-8`,
]);

export const Description = styled.p<{ last?: boolean }>(({ last }) => [
  tw`font-sans text-black opacity-60 mb-4 text-center whitespace-pre-line`,
  last && tw`mb-24`,
]);

export const BtnDiv = styled.div(
  () => tw`w-full max-w-[23.125rem] flex items-center justify-center`
);
