import {
  AssistantDialogContentDescription,
  LessonSummaryIcon,
  LessonSummaryMetaWrapper,
} from "./styled";

type Props = {
  icon: string;
  text: string;
};

export const OptionSelectedTitle = ({ icon, text }: Props) => {
  return (
    <LessonSummaryMetaWrapper>
      <LessonSummaryIcon src={icon} />
      <AssistantDialogContentDescription>
        {`"${text}"`}
      </AssistantDialogContentDescription>
    </LessonSummaryMetaWrapper>
  );
};
