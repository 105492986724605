import tw, { styled } from "twin.macro";
import { ButtonStyled } from "../../../common/components/Button/Button";

import { StyledSpinner } from "../../../common/components/Spinner/Spinner";

export const MethodsWrapper = styled.div(
  () => tw`flex items-center justify-center mt-12 mb-10`
);

export const MethodButton = styled.button(
  ({ second, selected }: { second?: boolean; selected: boolean }) => [
    tw`font-sans h-14 sm:h-10 px-10 sm:px-6 rounded-[3.125rem]`,
    selected
      ? tw`bg-gold text-white z-10`
      : tw`bg-light-gray-secondary text-black`,
    second && tw`-ml-6 sm:-ml-4`,
  ]
);

export const PaymentWrapper = styled.div(({ sm }: { sm?: boolean }) => [
  tw`w-full flex flex-col items-center sm:flex-row sm:space-x-15 sm:justify-center sm:items-start`,
  sm &&
    tw`sm:flex-col sm:space-x-0 sm:items-center lg:flex-row lg:space-x-15 lg:justify-center lg:items-start`,
]);

export const DetailsWrapper = styled.div(({ sm }: { sm?: boolean }) => [
  tw`w-full max-w-[26.25rem] flex flex-col order-2 sm:order-1`,
  sm && tw`sm:order-2 lg:order-1`,
]);

export const DetailsCardSectionWrapper = styled.div(
  () => tw`w-full grid grid-cols-2 grid-rows-2 gap-x-4 gap-y-5 mb-6`
);

export const DetailsSectionWrapper = styled.div(
  () => tw`w-full grid grid-cols-2 grid-rows-3 gap-x-4 gap-y-5 mb-6`
);

export const InputWrapper = styled.div(
  ({ full, mb }: { full?: boolean; mb?: string }) => [
    full ? tw`col-span-full` : null,
    mb ? tw`mb-6` : null,
  ]
);

export const PromotionalCodeWrapper = styled.div(
  () => tw`w-full flex space-x-3.5 mb-6`
);

export const PromotionalCodeButton = styled.button(
  () => tw`text-dark-blue font-sans text-sm`
);

export const SubscriptionTypeWrapper = styled.div(
  ({ sm }: { sm?: boolean }) => [
    tw`border border-dark-gray w-64 mb-6 sm:mb-0 py-8 px-6 sm:w-72 order-1 sm:order-2 flex flex-col rounded-lg`,
    sm && tw`sm:order-1 sm:mb-6 lg:mb-0 lg:order-2`,
  ]
);

export const SubscriptionHeadingWrapper = styled.div(
  () => tw`flex justify-between px-2`
);

export const SubscriptionDeleteIcon = styled.img(() => tw`cursor-pointer`);

export const SubscriptionTypeHeading = styled.h4(
  () => tw`font-sans text-gold font-medium text-base mb-2.5`
);

export const SubscriptionTypeName = styled.span(
  () => tw`font-sans text-lg text-black font-semibold mb-5`
);

export const SubscriptionTypeSelectWrapper = styled.div(
  () => tw`flex mb-5 items-center`
);

export const SubscriptionTypeLabel = styled.label(
  () => tw`font-sans text-base font-medium text-black ml-2 mr-5`
);

export const SubscriptionPriceWrapper = styled.div(
  () => tw`flex w-full mb-4 items-center`
);

export const SubscriptionPriceHeading = styled.h4(
  () => tw`font-sans text-gold font-medium text-base`
);

export const SubscriptionTotalWrapper = styled.div<{ noBorder?: boolean }>(
  ({ noBorder = false }) => [
    tw`flex pt-4 border-t border-gold w-full items-center`,
    noBorder && tw`border-none`,
  ]
);

export const SubscriptionPriceAmount = styled.span(
  () => tw`font-sans text-black font-medium text-base ml-auto`
);

export const SubscriptionTotalAmount = styled.span(
  () => tw`font-sans text-black font-semibold text-lg ml-auto`
);

export const SubscriptionTotalHeading = styled.h4(
  () => tw`font-sans text-black font-medium text-base`
);

export const SubscriptionTypeInput = styled.input();

export const HeadingStyled = styled.h2(
  () => tw`font-semibold text-lg font-sans text-black mb-4`
);

export const ContactUsParagraph = styled.p(
  () =>
    tw`font-sans text-lg font-medium text-black mb-1 text-center sm:text-left`
);

export const ContactUsLink = styled.span(
  () =>
    tw`font-sans text-dark-blue font-semibold text-lg mb-6 text-center sm:text-left`
);

export const RequestCodeWrapper = styled.div(
  () =>
    tw`w-full flex flex-col items-center xl:flex-row space-y-5 xl:space-x-5 xl:space-y-0 py-4 px-5 bg-light-gray-secondary rounded-lg`
);

export const RequestCodeDiv = styled.div();

export const RequestCodeImage = styled.img();

export const RequestCodeParagraph = styled.p(
  () =>
    tw`font-sans font-semibold text-black text-base mb-4 text-center xl:text-left`
);

export const stripeStyles = {
  base: {
    fontFamily: "Inter",
    fontSize: "0.85rem",
    fontWeight: "300",
  },
  "::placeholder": {
    fontFamily: "Inter",
    fontSize: "0.75rem",
  },
};

export const stripeClasses = {
  focus: "border border-dark-blue",
  base: "w-full text-black border border-dark-gray px-3 py-2 rounded-lg",
  placeholder: "text-black opacity-70",
};

// Modal

export const Title = styled.h1(() => tw`font-sans text-2xl font-bold py-4`);

export const Description = styled.p(() => tw`font-sans text-center py-4`);

export const ActionWrapper = styled.div(
  () => tw`flex gap-x-4 justify-self-end mt-10`
);

export const Button = styled(ButtonStyled)(() => tw`w-40`);

export const DeleteButton = styled(Button)(() => tw`bg-error-450 border-none`);

export const PaymentSpinner = styled(StyledSpinner)(() => tw`ml-2`);

export const PaymentRenewalInfo = styled.p(
  () => tw`text-xs mt-[-3.4rem] font-sans`
);

export const ExternLink = styled.a(() => tw`underline font-sans text-[#00f]`);
