import { FC } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Wrapper, { StyledMain } from "./Wrapper";

const Home: FC = ({ children }) => {
  return (
    <Wrapper data-theme="yuno">
      <Header />
      <StyledMain>{children}</StyledMain>
      <Footer />
    </Wrapper>
  );
};

export default Home;
