import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { OptionSelectedTitle } from "./OptionSelectedTitle";
import earth from "../../assets/common/assistant/earth.svg";
import { useAsyncState } from "../../common/hooks/useAsyncState";
import { AsyncActions } from "../../store/enums/AsyncActions";
import { LoadingWrapper, ResultWrapper, RelatedLessonLink } from "./styled";
import { AnimatedDots } from "../../common/components/AnimatedDots/AnimatedDots";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/type";
import { getRoute } from "../../common/utils/getRoute";
import { RoutePath } from "../../common/consts/route-path";
import { env } from "../../common/config/env";
import { NoResultComponent } from "./NoResult";

type Props = {
  onRelatedLessonClick: () => void;
};

export const RelatedLessons = ({ onRelatedLessonClick }: Props) => {
  const { t } = useTranslation();
  const { requestInProgress } = useAsyncState(AsyncActions.Assistant);
  const relatedLessons = useSelector(
    (state: StoreState) => state.assistant.relatedLessons
  );

  return (
    <>
      <OptionSelectedTitle
        icon={earth}
        text={t("assistant.dialog.options.related")}
      />
      {requestInProgress ? (
        <LoadingWrapper>
          <AnimatedDots />
        </LoadingWrapper>
      ) : (
        <ResultWrapper>
          {relatedLessons.length === 0 ? (
            <NoResultComponent />
          ) : (
            relatedLessons.map(({ id, reason, code, subjectCode }) => (
              <Link
                key={id}
                to={getRoute(
                  {
                    subjectCode,
                    lessonCode: code,
                  },

                  RoutePath.lessonPage.locale[env.locale]
                )}
                onClick={onRelatedLessonClick}
              >
                <RelatedLessonLink>{reason}</RelatedLessonLink>
              </Link>
            ))
          )}
        </ResultWrapper>
      )}
    </>
  );
};
