import { ChangeEvent, useEffect, useState, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Button from "../../../common/components/Button/Button";
import { Modal } from "../../../common/components/Modal/Modal";
import {
  PopupCloseStyled,
  PopupHeadingStyled,
} from "../../../common/components/Modal/ModalStyled";
import { StyledError } from "../../../common/components/Error/Error";

import { ModalWrapper } from "../ModalStyled";
import {
  ActionWrapper,
  InputWrapper,
  MobilePhoneNumberInput,
  MobilePhoneInputGroup,
  StyledSelect,
  MobileCodeWrapper,
  Label,
  ErrorWrapper,
} from "./OTPStyled";
import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { sendCodeToMobilePhone } from "../../../store/thunks/sendCodeToMobilePhone";
import { ResendOTPModal } from "./ResendOTPModal";
import { countryCodes } from "../../../common/consts/countryCodes";
import { gradeSelectStyles } from "../AddLearners/AddLearnersStyled";
import { parsePhoneNumber } from "awesome-phonenumber";

type Props = {
  onClose: () => void;
};

export const MobilePhoneInputModal = ({ onClose }: Props) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [code, setCode] = useState({
    value: countryCodes[0].code,
    label: `${countryCodes[0].dialCode} ${countryCodes[0].flag}`,
  });
  const [shouldShowError, setShouldShowError] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    requestInProgress,
    clearSuccess,
    clearNotification,
    notification,
    error: serverError,
  } = useAsyncState(AsyncActions.SendCodeToPhone);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value.trim());
    validatePhoneNumber(e.target.value.trim(), code.value);
  };

  const isPhoneNumberValid = (val: string, code: string) => {
    const pn = parsePhoneNumber(val, code);
    return pn.isPossible() && pn.isMobile();
  };

  const validatePhoneNumber = (val: string, code: string) => {
    setErrorMsg(
      isPhoneNumberValid(val.trim(), code)
        ? ""
        : t("register.otp.mobileForm.errorMessage")
    );
  };

  const handleBlur = () => setShouldShowError(true);

  const onSubmit = () => {
    if (isPhoneNumberValid(phoneNumber, code.value)) {
      dispatch(
        sendCodeToMobilePhone(
          parsePhoneNumber(phoneNumber, code.value).getNumber()
        )
      );
    } else {
      validatePhoneNumber(phoneNumber, code.value);
      setShouldShowError(true);
    }
  };

  const handleEnter = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  useEffect(() => {
    return () => {
      clearSuccess();
      clearNotification();
    };
  }, []);

  return notification ? (
    <ResendOTPModal handleCloseModal={onClose} />
  ) : (
    <Modal sm>
      <ModalWrapper onKeyDown={handleEnter}>
        <PopupCloseStyled onClick={onClose}>&#10006;</PopupCloseStyled>
        <PopupHeadingStyled>
          {t("register.otp.mobileForm.title")}
        </PopupHeadingStyled>
        <InputWrapper>
          <Label> {t("register.otp.mobileForm.label")}</Label>
          <MobilePhoneInputGroup>
            <MobileCodeWrapper>
              <StyledSelect
                placeholder={t("register.otp.mobileForm.codePlaceholder")}
                options={countryCodes.map(({ flag, code, dialCode }) => ({
                  value: code,
                  label: `${dialCode} ${flag}`,
                }))}
                styles={gradeSelectStyles}
                onChange={(obj: any) => setCode(obj)}
                value={code}
                controlShouldRenderValue
              />
            </MobileCodeWrapper>
            <MobilePhoneNumberInput
              placeholder={t("register.otp.mobileForm.placeholder")}
              type={"tel"}
              onChange={handleChange}
              name={"phone-number"}
              onBlur={handleBlur}
              inputWidth={"17rem"}
            />
          </MobilePhoneInputGroup>
          <ErrorWrapper>
            <StyledError topMargin="0" bottomMargin="0">
              {shouldShowError && errorMsg ? errorMsg : ""}
            </StyledError>
            {serverError && (
              <StyledError topMargin="0" bottomMargin="0">
                {serverError}
              </StyledError>
            )}
          </ErrorWrapper>
        </InputWrapper>
        <ActionWrapper>
          <Button
            onClick={onClose}
            buttonWidth="12.366rem"
            invert
            type={"button"}
          >
            {t("register.otp.mobileForm.cancel")}
          </Button>
          <Button
            type={"button"}
            onClick={onSubmit}
            resPending={requestInProgress}
            buttonWidth="12.366rem"
          >
            {t("register.otp.mobileForm.send")}
          </Button>
        </ActionWrapper>
      </ModalWrapper>
    </Modal>
  );
};
