import { ReduxThunk } from "../../type";
import { setBusyAction } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import {
  SponsorTournamentView,
  TournamentResultForSponsor,
} from "../../types/tournament";
import {
  setTournamentsForSponsor,
  setResultsForSponsor,
} from "../../slices/tournament";
import { Id, toast } from "react-toastify";
import i18n from "../../../i18n";

export const fetchTournamentsForSponsor: () => ReduxThunk =
  () =>
  async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({ key: AsyncActions.FetchTournamentResult, value: true })
    );

    try {
      const res = await client.get<SponsorTournamentView[]>(
        ApiRoutes.TournamentsForSponsor
      );
      dispatch(setTournamentsForSponsor(res.data));
    } catch (e) {
      toast.error(i18n.t("response.errorFetchingSponsorTournaments"), {
        position: toast.POSITION.TOP_CENTER,
        toastId: i18n.t("response.errorFetchingSponsorTournaments") as Id,
        progress: undefined,
      });
    }

    dispatch(
      setBusyAction({ key: AsyncActions.FetchTournamentResult, value: false })
    );
  };

export const fetchTournamentResultsForSponsor: () => ReduxThunk =
  () =>
  async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({
        key: AsyncActions.FetchTournamentResultsForSponsor,
        value: true,
      })
    );

    try {
      const res = await client.get<TournamentResultForSponsor[]>(
        ApiRoutes.TournamentResultsForSponsor
      );
      dispatch(setResultsForSponsor(res.data));
    } catch (e) {
      toast.error(i18n.t("response.errorFetchingSponsorTournaments"), {
        position: toast.POSITION.TOP_CENTER,
        toastId: i18n.t("response.errorFetchingSponsorTournaments") as Id,
        progress: undefined,
      });
    }

    dispatch(
      setBusyAction({
        key: AsyncActions.FetchTournamentResultsForSponsor,
        value: false,
      })
    );
  };
