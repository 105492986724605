import { ReduxThunk } from "../../type";
import { setBusyAction, setErrorAction, setSuccess } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { Exception } from "../../../common/enums/Exceptions";
import i18n from "../../../i18n";

export const sendResetPasswordEmail: (data: { email: string }) => ReduxThunk = (
  data
) => {
  return async (dispatch, _, { client }) => {
    try {
      dispatch(setBusyAction({ key: AsyncActions.SendResetLink, value: true }));
      await client.post(ApiRoutes.ForgotPassword, data);
      dispatch(setSuccess({ key: AsyncActions.SendResetLink, value: true }));
    } catch (err: any) {
      if (err.response?.data?.message === Exception.EntityNotFound) {
        dispatch(
          setErrorAction({
            key: AsyncActions.SendResetLink,
            value: i18n.t("response.noSuchEmail"),
          })
        );
      } else {
        dispatch(
          setErrorAction({
            key: AsyncActions.SendResetLink,
            value: i18n.t("response.500"),
          })
        );
      }
    }
    dispatch(setBusyAction({ key: AsyncActions.SendResetLink, value: false }));
  };
};
