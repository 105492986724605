import tw, { styled } from "twin.macro";
import { ButtonStyled } from "../Button/Button";

export const Title = styled.h1(() => tw`font-dyslexic font-bold text-base`);

export const Description = styled.p(
  () => tw`text-lg font-sans w-5/6 text-base whitespace-pre-line`
);

export const Button = styled(ButtonStyled)(() => tw`w-[15rem]`);

export const Wrapper = styled.div(
  () => tw`flex flex-col gap-y-4 w-11/12 items-center py-8 gap-y-8`
);
