import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../../../common/components/Button/Button";
import { env } from "../../../common/config/env";
import { RoutePath } from "../../../common/consts/route-path";
import {
  BtnDiv,
  IncompleteParagraph,
  Wrapper,
} from "./IncompleteProfileStyled";

export const IncompleteProfile = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onButtonClick = () => {
    navigate(RoutePath.learnerSetup.locale[env.locale]);
  };
  return (
    <Wrapper>
      <IncompleteParagraph>
        {t("learnerHome.incompleteParagraph")}
      </IncompleteParagraph>
      <BtnDiv>
        <Button
          onClick={onButtonClick}
          invert={false}
          buttonHeight="2.5rem"
          buttonWidth="100%"
        >
          {t("learnerHome.completeBtn")}
        </Button>
      </BtnDiv>
    </Wrapper>
  );
};
