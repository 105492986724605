import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import defaultVideo from "../../../assets/common/no-video.m4v";

import {
  setItem,
  getItem,
  removeItem,
} from "../../../common/utils/localStorageService";
import {
  addVideoWatchTime,
  markVideoWatched,
} from "../../../store/thunks/Learner/depository";
import { StoreState } from "../../../store/type";

import screenIcon from "../../../assets/common/Lesson-Page/Screen.svg";

import {
  Video,
  VideoDiv,
  PopupParagraphStyled,
  ContentWrapper,
  PopupWrapper,
  PopupBtnDiv,
} from "./VideoStyled";
import { Modal } from "../../../common/components/Modal/Modal";
import {
  PopupCloseStyled,
  PopupHeadingStyled,
  PopupImageStyled,
} from "../../../common/components/Modal/ModalStyled";
import Button from "../../../common/components/Button/Button";
import ReactPlayer from "react-player";

export const VideoSection = ({
  depositoryId,
}: {
  depositoryId: string | undefined;
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [continueModalOpen, setContinueModalOpen] = useState(false);
  const ready = useRef(false);
  const lessonId = useSelector((state: StoreState) => state.subjects.lesson.id);
  const video = useSelector((state: StoreState) => state.subjects.lesson.video);
  const videoWatchedAt = useSelector(
    (state: StoreState) => state.subjects.lesson.depository?.videoWatchedAt
  );
  const videoRef = useRef<ReactPlayer | null>(null);
  const learnerId = useSelector(
    (state: StoreState) => state.user.info.username
  );

  let watchTime: number;
  if (getItem(`${learnerId}/${lessonId}`)) {
    watchTime = +getItem(`${learnerId}/${lessonId}`)!;
  }

  const onEndedHandler = () => {
    dispatch(markVideoWatched(depositoryId));
    removeItem(`${learnerId}/${lessonId}`);
  };

  const onMetaDataHandler = () => {
    if (
      videoRef.current &&
      watchTime &&
      watchTime > 0 &&
      watchTime < videoRef.current.getInternalPlayer()?.duration
    ) {
      setContinueModalOpen(true);
    }
  };

  const closeContinueHandler = () => {
    setContinueModalOpen(false);
  };

  const continueLessonHandler = () => {
    setContinueModalOpen(false);
    if (watchTime && videoRef.current && videoRef.current.getInternalPlayer()) {
      videoRef.current.seekTo(watchTime);
      videoRef.current?.getInternalPlayer().play();
    }
  };

  useLayoutEffect(() => {
    const dispatchOnPageClose = () => {
      if (videoRef.current && videoRef.current.getCurrentTime() > 0) {
        setItem(
          `${learnerId}/${lessonId}`,
          videoRef.current.getCurrentTime().toString()
        );
        dispatch(
          addVideoWatchTime(
            depositoryId,
            Math.round(videoRef.current.getCurrentTime() * 1000)
          )
        );
      }
    };
    window.addEventListener("beforeunload", dispatchOnPageClose);

    return () => {
      window.removeEventListener("beforeunload", dispatchOnPageClose);
      dispatchOnPageClose();
    };
  }, []);

  useEffect(() => {
    if (
      !videoWatchedAt ||
      !videoRef.current ||
      !videoRef.current.getInternalPlayer() ||
      !videoRef.current.getInternalPlayer().controlsList
    )
      return;
    videoRef.current.getInternalPlayer().controlsList.remove("noplaybackrate");
  }, [videoWatchedAt, videoRef.current]);

  return (
    <VideoDiv hideTimeline={!videoWatchedAt}>
      <Video
        onLoadedMetadata={onMetaDataHandler}
        onEnded={onEndedHandler}
        ref={videoRef}
        controls
        config={{
          file: {
            attributes: {
              controlsList: "nodownload noplaybackrate",
              disablePictureInPicture: true,
            },
          },
        }}
        onContextMenu={(e: Event) => e.preventDefault()}
        url={video && video.path ? video.path : defaultVideo}
        width="100%"
        height="100%"
        onReady={() => {
          if (
            videoRef.current &&
            videoRef.current.getCurrentTime() !== watchTime &&
            !ready.current &&
            watchTime
          ) {
            ready.current = true;
            if (
              videoWatchedAt &&
              videoRef.current?.getInternalPlayer()?.controlsList
            ) {
              videoRef.current
                .getInternalPlayer()
                .controlsList.remove("noplaybackrate");
            }
          }
        }}
      />
      {continueModalOpen && (
        <Modal>
          <PopupWrapper>
            <PopupCloseStyled onClick={closeContinueHandler}>
              &#10006;
            </PopupCloseStyled>
            <PopupImageStyled src={screenIcon} />
            <PopupHeadingStyled>
              {t("lessonPage.continueHeading")}
            </PopupHeadingStyled>
            <ContentWrapper>
              <PopupParagraphStyled>
                {t("lessonPage.continueDescription")}
              </PopupParagraphStyled>
              <PopupBtnDiv>
                <Button
                  onClick={closeContinueHandler}
                  buttonWidth="100%"
                  buttonHeight="2.5rem"
                  invert
                >
                  {t("lessonPage.continueCloseBtn")}
                </Button>
                <Button
                  onClick={continueLessonHandler}
                  buttonWidth="100%"
                  buttonHeight="2.5rem"
                  invert={false}
                >
                  {t("lessonPage.continueBtn")}
                </Button>
              </PopupBtnDiv>
            </ContentWrapper>
          </PopupWrapper>
        </Modal>
      )}
    </VideoDiv>
  );
};
