import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  AssistantDialogAvatarWrapper,
  AssistantDialogDescription,
  AssistantDialogTextWrapper,
  AssistantDialogTitleWrapper,
  AssistantDialogTitle,
  TitleBackIcon,
  AssistantDialogSelectedOptionTitle,
} from "./styled";
import { AvatarWithSelectedItems } from "../../common/components/Avatar/AvatarWithSelectedItems";
import { DialogContentOptions } from "./AssistantDialog";

import { StoreState } from "../../store/type";

type Props = {
  selectedOption: DialogContentOptions;
  onBackClick: () => void;
};

export const AssistantDialogTitleComponent = ({
  selectedOption,
  onBackClick,
}: Props) => {
  const { t } = useTranslation();
  const username = useSelector((state: StoreState) => state.user.info.username);

  const translation = t(`assistant.dialog.titles.${selectedOption}` as any);

  return (
    <AssistantDialogTitleWrapper>
      {selectedOption === "none" ? (
        <>
          <AssistantDialogAvatarWrapper>
            <AvatarWithSelectedItems noHoverTransform />
          </AssistantDialogAvatarWrapper>
          <AssistantDialogTextWrapper>
            <AssistantDialogDescription>
              {t("assistant.dialog.subtitle")}
            </AssistantDialogDescription>
            <AssistantDialogTitle>{username}</AssistantDialogTitle>
          </AssistantDialogTextWrapper>
        </>
      ) : (
        <>
          <TitleBackIcon onClick={onBackClick} />
          <AssistantDialogSelectedOptionTitle>
            {translation}
          </AssistantDialogSelectedOptionTitle>
        </>
      )}
    </AssistantDialogTitleWrapper>
  );
};
