import tw, { styled } from "twin.macro";

export const Wrapper = styled.div(
  () => tw`w-full flex flex-col p-8 items-center`
);

export const ContentWrapper = styled.div(() => tw`w-full max-w-[26.25rem]`);

export const Heading = styled.h1(
  () => tw`text-2xl font-bold mb-2 w-full text-center`
);
