export const getRoute = (
  params: Record<string, string>,
  ...routes: string[]
) => {
  let route = routes.join("/");
  Object.entries(params).forEach(([key, value]) => {
    route = route.replace(`:${key}`, value);
  });

  return route;
};
