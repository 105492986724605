import React from "react";
import { useSelector } from "react-redux";

import {
  InfoItemWrapper,
  Label,
  Value,
  ContentWrapper,
  ButtonDiv,
} from "./UserInfoStyled";
import Button from "../../../../common/components/Button/Button";
import { StoreState } from "../../../../store/type";
import { useTranslation } from "react-i18next";

type Props = {
  setCurrentComponent: () => void;
};

export const UserInfo = ({ setCurrentComponent }: Props) => {
  const { t } = useTranslation();

  const { firstName, lastName, username } = useSelector(
    (state: StoreState) => state.user.info
  );
  return (
    <React.Fragment>
      <ContentWrapper>
        <InfoItemWrapper>
          <Label>{t("parentDashboard.myProfile.firstName")}</Label>
          <Value>{firstName}</Value>
        </InfoItemWrapper>
        <InfoItemWrapper>
          <Label>{t("parentDashboard.myProfile.lastName")}</Label>
          <Value>{lastName}</Value>
        </InfoItemWrapper>
        <InfoItemWrapper>
          <Label>{t("parentDashboard.myProfile.email")}</Label>
          <Value>{username}</Value>
        </InfoItemWrapper>
      </ContentWrapper>
      <ButtonDiv>
        <Button onClick={setCurrentComponent} invert={false} buttonWidth="100%">
          {t("parentDashboard.myProfile.editInfoBtn")}
        </Button>
      </ButtonDiv>
    </React.Fragment>
  );
};
