import tw, { styled, css } from "twin.macro";

interface ErrorProps {
  centered?: boolean;
  bottomMargin: string;
  topMargin: string;
}
export const StyledError = styled.p<ErrorProps>(
  ({ centered, bottomMargin, topMargin }) => [
    tw`text-base font-bold text-danger font-sans`,
    centered && tw`text-center`,
    css`
      margin-bottom: ${bottomMargin};
      margin-top: ${topMargin};
    `,
  ]
);
