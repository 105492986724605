import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { Exception } from "../../../common/enums/Exceptions";
import i18n from "../../../i18n";
import { AsyncActions } from "../../enums/AsyncActions";
import { setBusyAction, setErrorAction, setSuccess } from "../../slices/async";
import { setTraits, setUserTraits } from "../../slices/traits";
import { ReduxThunk } from "../../type";
import { Trait } from "../../types/traits";

export const patchTraits: (traits: string[]) => ReduxThunk = (traits) => {
  return async (dispatch, _, { client }) => {
    try {
      dispatch(setBusyAction({ key: AsyncActions.PatchTraits, value: true }));
      const response = await client.patch(ApiRoutes.Traits, { traits });
      setUserTraits(response.data);
      dispatch(setSuccess({ key: AsyncActions.PatchTraits, value: true }));
    } catch (err: any) {
      if (err.response?.data?.message === Exception.TraitArrayIsEmpty) {
        dispatch(
          setErrorAction({
            key: AsyncActions.PatchTraits,
            value: i18n.t("profileSetup.personalityTraits.validation.empty"),
          })
        );
      }
      if (err.response?.data?.message === Exception.MoreThanThreeTraits) {
        dispatch(
          setErrorAction({
            key: AsyncActions.PatchTraits,
            value: i18n.t("profileSetup.personalityTraits.validation.exceeds"),
          })
        );
      }
      if (err.response?.data?.message === Exception.FailedToUpdateTraits) {
        dispatch(
          setErrorAction({
            key: AsyncActions.PatchTraits,
            value: i18n.t("profileSetup.personalityTraits.errors.failed"),
          })
        );
      }
    }
    dispatch(setBusyAction({ key: AsyncActions.PatchTraits, value: false }));
  };
};

export const postTrait: (trait: string) => ReduxThunk = (trait) => {
  return async (dispatch, _, { client }) => {
    try {
      dispatch(setBusyAction({ key: AsyncActions.PostTrait, value: true }));
      const response = await client.post<Trait>(ApiRoutes.Traits, {
        name: trait,
      });
      dispatch(setTraits({ ...response.data, newlyCreatedByUser: true }));
      dispatch(setSuccess({ key: AsyncActions.PostTrait, value: true }));
    } catch (err: any) {
      dispatch(
        setErrorAction({
          key: AsyncActions.PostTrait,
          value: i18n.t("profileSetup.personalityTraits.errors.failed"),
        })
      );
    }
    dispatch(setBusyAction({ key: AsyncActions.PostTrait, value: false }));
  };
};
