import tw, { styled } from "twin.macro";
import { Icon } from "../../../components/Tournament/modals/styled";

export const PopupWrapperStyled = styled.div(
  ({ closeOutside }: { closeOutside?: boolean }) => [
    tw`flex items-center justify-center bg-overlay fixed inset-0 px-6 xl:px-60 z-max`,
    closeOutside && tw`flex-col`,
  ]
);

export const PopupDivStyled = styled.div(
  ({
    full,
    closeOutside,
    lg,
    sm,
    md,
    fit,
    xs,
  }: {
    full?: boolean;
    closeOutside?: boolean;
    lg?: boolean;
    sm?: boolean;
    md?: boolean;
    fit?: boolean;
    xs?: boolean;
  }) => [
    tw`flex items-center flex-col justify-start w-[39rem] p-9 rounded-2xl bg-white h-[22.413rem] z-[2000]`,
    full &&
      tw`w-[60rem] p-0 h-[90vh] overflow-scroll lg:overflow-auto lg:h-[32rem] [::-webkit-scrollbar]:w-2 [::-webkit-scrollbar-track]:bg-transparent [::-webkit-scrollbar-corner]:h-0 [::-webkit-scrollbar-thumb]:bg-dark-gray [::-webkit-scrollbar-thumb]:rounded-lg`,
    closeOutside && tw`w-full max-w-[60rem] lg:mx-auto`,
    lg && tw`lg:h-[90vh]`,
    md && tw`lg:h-[50vh]`,
    sm && tw`lg:h-[25rem]`,
    xs && tw`md:h-[15rem]`,
    fit && tw`lg:h-fit`,
  ]
);

export const PopupCloseStyled = styled.span(() => tw`ml-auto cursor-pointer`);

export const PopupHeadingStyled = styled.h2(
  ({ centered }: { centered?: boolean }) => [
    tw`font-bold text-[1.214rem] text-center text-black-secondary mb-8 mt-2.5`,
    centered && tw`text-center`,
  ]
);

export const PopupImageStyled = styled.img();

export const CloseStyled = styled.span(
  () => tw`mb-2 cursor-pointer font-sans text-white text-xl ml-auto`
);

export const CloseIconWrapper = styled.div(
  () => tw`w-full relative cursor-pointer`
);

export const CloseIcon = styled(Icon)(() => tw`top-4 right-4`);
