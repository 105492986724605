import tw, { styled } from "twin.macro";

export const WrapperDiv = styled.div(
  () =>
    tw`w-full lg:w-2/4 flex flex-col border-b border-gold pb-4 lg:pb-0 mb-4 lg:mb-0 lg:border-b-0`
);

export const LessonInfoHeading = styled.h2(
  () => tw`font-sans text-black-secondary font-semibold text-xl mb-4`
);

export const LessonInfo = styled.p(
  () =>
    tw`font-sans text-black-secondary [& *]:(font-sans text-black-secondary)`
);

export const TeacherDiv = styled.div(
  () =>
    tw`w-full flex flex-col space-y-4 py-4 md:py-0 md:space-y-0 md:flex-row  my-4 bg-yellow rounded-lg px-4 items-center min-h-[4.5rem]`
);

export const TeacherAvatar = styled.img(
  () => tw`w-12 h-12 rounded-full md:mr-4`
);

export const TeacherInfoDiv = styled.div(
  () => tw`flex flex-col items-center lg:items-start`
);

export const TeacherName = styled.h4(
  () => tw`font-sans font-semibold text-black-secondary text-center`
);

export const TeacherInfo = styled.h5(
  () => tw`font-sans text-sm text-black-secondary opacity-60 text-left`
);

export const BookSessionLink = styled.a(() => tw`md:ml-auto`);
export const BookSessionBtn = styled.button(
  () =>
    tw`box-border rounded-[2.76rem] w-34 font-semibold font-sans border py-2 text-xs text-dark-blue bg-transparent border-dark-blue inline-flex items-center justify-center`
);

export const Disclaimer = styled.p(
  () => tw`font-sans w-full text-sm text-black-secondary`
);
