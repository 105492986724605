import Menu from "rc-menu";
import Dropdown from "rc-dropdown";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

import { AvatarWrapper, HeaderStyled, Icon, IconDiv } from "./HeaderStyled";
import logo from "../../../assets/common/logo.svg";
import homeIcon from "../../../assets/common/Header/Home.svg";
import menuIcon from "../../../assets/common/hamburger-icon.png";
import tournamentIcon from "../../../assets/common/Header/tournament.svg";
import { redirect } from "../../../store/slices/router";

import { Cup } from "./Cup/Cup";

import { Typography } from "../Typography/Typography";
import { MenuItem } from "../MenuItem/MenuItem";
import { logOutTask } from "../../../store/thunks/logOutTask";
import { removeToken } from "../../utils/localStorageService";
import { useUserHomeRoute } from "../../hooks/useUserHomeRoute";
import { RoutePath } from "../../consts/route-path";
import { env } from "../../config/env";
import { SearchBar } from "./SearchComponent";
import { Alert } from "./Alert/Alert";
import { getRoute } from "../../utils/getRoute";
import { fetchPoints } from "../../../store/thunks/Learner/fetchPoints";
import { AvatarWithSelectedItems } from "../Avatar/AvatarWithSelectedItems";

const TOURNAMENT_ROUTE = getRoute(
  {},
  RoutePath.tournaments.locale[env.locale],
  RoutePath.tournaments.sub.list.locale[env.locale],
  RoutePath.tournaments.sub.list.sub.upcoming.locale[env.locale]
);

export const LoggedIn = () => {
  const homeRoute = useUserHomeRoute();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSelect = ({ key }: { key: string }) => {
    if (key === "logout") {
      sessionStorage.clear();
      removeToken();
      dispatch(logOutTask());
    }
  };

  const navigateTo = (route: string) => dispatch(redirect({ to: route }));

  useEffect(() => {
    dispatch(fetchPoints());
  }, []);

  const overlayMd = () => {
    return (
      <Menu className="hidden md:block" onSelect={handleSelect}>
        <MenuItem key={homeRoute}>
          <Typography onClick={() => navigateTo(homeRoute)}>
            {t("header.home")}
          </Typography>
        </MenuItem>
        <MenuItem key={RoutePath.learnerProfile.locale[env.locale]}>
          <Typography
            onClick={() =>
              navigateTo(RoutePath.learnerProfile.locale[env.locale])
            }
          >
            {t("header.profile")}
          </Typography>
        </MenuItem>

        <MenuItem key="logout">
          <Typography>{t("header.logout")}</Typography>
        </MenuItem>
      </Menu>
    );
  };

  const overlay = () => (
    <Menu className="md:hidden" onSelect={handleSelect}>
      <MenuItem key={homeRoute}>
        <Typography onClick={() => navigateTo(homeRoute)}>
          {t("header.home")}
        </Typography>
      </MenuItem>
      <MenuItem key={RoutePath.learnerProfile.locale[env.locale]}>
        <Typography
          onClick={() =>
            navigateTo(RoutePath.learnerProfile.locale[env.locale])
          }
        >
          {t("header.profile")}
        </Typography>
      </MenuItem>
      <MenuItem key={TOURNAMENT_ROUTE}>
        <Typography onClick={() => navigateTo(TOURNAMENT_ROUTE)}>
          {t("header.tournaments")}
        </Typography>
      </MenuItem>
      <MenuItem key="logout">
        <Typography>{t("header.logout")}</Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <HeaderStyled>
      <img
        src={logo}
        onClick={() => navigateTo(homeRoute)}
        className="mr-auto h-12 cursor-pointer"
        alt={"YUNO"}
      />
      <SearchBar />
      <IconDiv onClick={() => navigateTo(homeRoute)} mdHide>
        <Icon src={homeIcon} />
      </IconDiv>
      <IconDiv onClick={() => navigateTo(TOURNAMENT_ROUTE)} mdHide>
        <Icon src={tournamentIcon} />
      </IconDiv>
      <Cup />
      <Alert />

      <Dropdown trigger="click" animation="slide-up" overlay={overlayMd}>
        <IconDiv mdHide>
          <AvatarWrapper>
            <AvatarWithSelectedItems />
          </AvatarWrapper>
        </IconDiv>
      </Dropdown>

      <Dropdown trigger="click" animation="slide-up" overlay={overlay}>
        <img
          src={menuIcon}
          className="cursor-pointer w-10 h-12 md:hidden"
          alt={"Menu"}
        />
      </Dropdown>
    </HeaderStyled>
  );
};
