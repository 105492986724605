import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BillingHistory } from "../Subscriptions/SubscriptionsDefault/BillingHistory/BillingHistory";
import { EditInfo } from "./EditInfo/EditInfo";
import { Heading, InfoWrapper, Wrapper } from "./MyProfileStyled";
import { UserInfo } from "./UserInfo/UserInfo";

export const MyProfile = () => {
  const { t } = useTranslation();

  const [currentComponent, setCurrentComponent] = useState(1);

  let VisibleComponent;
  switch (currentComponent) {
    case 1:
      VisibleComponent = (
        <UserInfo
          setCurrentComponent={() => {
            setCurrentComponent(2);
          }}
        />
      );
      break;
    case 2:
      VisibleComponent = (
        <EditInfo changeCurrentComponent={() => setCurrentComponent(1)} />
      );
      break;
    default:
      VisibleComponent = null;
  }
  return (
    <Wrapper>
      <InfoWrapper>{VisibleComponent}</InfoWrapper>
      <Heading>{t("parentDashboard.myProfile.paymentHistory")}</Heading>
      <BillingHistory />
    </Wrapper>
  );
};
