import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { env } from "../../../common/config/env";
import { StoreState } from "../../../store/type";
import {
  Category,
  ContactParagraph,
  ContactSpan,
  ContentWrapper,
  Heading,
  Wrapper,
} from "./HelpStyled";
import { SingleQuestion } from "./SingleQuestion/SingleQuestion";
import { Tutorials } from "./Tutorials";
import { faq } from "./faq";

export const Help = () => {
  const { t } = useTranslation();
  const { firstName, lastName } = useSelector(
    (state: StoreState) => state.user.info
  );

  return (
    <Wrapper>
      <ContentWrapper>
        <Heading>{t("parentDashboard.help.heading")}</Heading>
        {faq.map((faqCat, index) => {
          return (
            <React.Fragment key={index}>
              <Category>
                {t(`parentDashboard.help.${faqCat.category}` as any)}
              </Category>
              {faqCat.qa.map((singleQuestion) => {
                return (
                  <SingleQuestion
                    key={singleQuestion.question}
                    questionBody={singleQuestion.question}
                    answer={singleQuestion.answer}
                  />
                );
              })}
            </React.Fragment>
          );
        })}
        <Category>{t("parentDashboard.help.contactHeading")}</Category>
        <ContactParagraph>
          {t("parentDashboard.help.contactParagraphSegmentFirst")}
          <a
            href={`mailto:${env.contactEmail}?subject=${t(
              "parentDashboard.help.emailSubject"
            )}&body=${t("parentDashboard.help.emailBody", {
              firstName,
              lastName,
            })}`}
            target="_blank"
            rel="noreferrer"
          >
            <ContactSpan>{env.contactEmail}.</ContactSpan>
          </a>
        </ContactParagraph>
        <Tutorials />
      </ContentWrapper>
    </Wrapper>
  );
};
