import { useDispatch, useSelector } from "react-redux";
import { CupIcon, IconDiv } from "../HeaderStyled";
import { redirect } from "../../../../store/slices/router";

import cupIcon from "../../../../assets/common/Header/Cup.svg";
import starIcon from "../../../../assets/common/Learner-Home/Star.svg";

import { getRoute } from "../../../utils/getRoute";
import { RoutePath } from "../../../consts/route-path";
import { env } from "../../../config/env";
import { StoreState } from "../../../../store/type";
import { NotificationLabel, NotificationWrapper } from "../Alert/styled";
import { PointsImageStyled, PointsStyled } from "../../Points/styled";

const TOURNAMENT_HISTORY_ROUTE = getRoute(
  {},
  RoutePath.tournaments.locale[env.locale],
  RoutePath.tournaments.sub.list.locale[env.locale],
  RoutePath.tournaments.sub.list.sub.history.locale[env.locale]
);

export const Cup = () => {
  const tournamentPoints = useSelector(
    (state: StoreState) => state.user.info.tournamentPoints
  );
  const dispatch = useDispatch();

  const navigateTo = (route: string) => {
    dispatch(redirect({ to: route }));
  };

  return (
    <IconDiv onClick={() => navigateTo(TOURNAMENT_HISTORY_ROUTE)}>
      <NotificationWrapper>
        <NotificationLabel noPadding>
          <PointsImageStyled src={starIcon} size={"xxs"} />
          <PointsStyled size={"xxs"}>{tournamentPoints}</PointsStyled>
        </NotificationLabel>
        <CupIcon src={cupIcon} />
      </NotificationWrapper>
    </IconDiv>
  );
};
