import tw, { styled } from "twin.macro";

export const SuccessWrapper = styled.div(
  () => tw`w-full flex mt-12 flex-col items-center max-w-[23.125rem]`
);

export const SuccessImg = styled.img();

export const ParagraphStyled = styled.p(
  () => tw`font-sans text-black text-center max-w-[20.42rem] mt-1 mb-9`
);
