import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Button from "../../../common/components/Button/Button";
import { StyledError } from "../../../common/components/Error/Error";
import Form from "../../../common/components/Form/Form";
import Input from "../../../common/components/Input/Input";
import { StyledSuccess } from "../../../common/components/Success/Success";
import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { sendResetPasswordEmail } from "../../../store/thunks/Parent/sendEmail";
import { resetLinkSchema } from "../../../views/validation/ChangePasswordValidationSchema";
import {
  ChangePasswordDescription,
  ContentWrapper,
  Heading,
  Wrapper,
} from "./ChangePasswordStyled";

export const ChangePassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { requestInProgress, error, success } = useAsyncState(
    AsyncActions.SendResetLink
  );
  const { register, handleSubmit, formState } = useForm({
    mode: "onTouched",
    resolver: yupResolver(resetLinkSchema()),
  });

  const sendResetLinkHandler = (data: any) => {
    dispatch(sendResetPasswordEmail(data));
  };
  return (
    <Wrapper>
      <ContentWrapper>
        <Form className="w-full" onSubmit={handleSubmit(sendResetLinkHandler)}>
          <Heading>{t("parentDashboard.password.changeHeading")}</Heading>
          <ChangePasswordDescription>
            {t("parentDashboard.password.changeDescription")}
          </ChangePasswordDescription>
          <Input
            required={false}
            name="email"
            type="text"
            registerInput={register}
            bottomMargin="2.5rem"
            inputWidth="100%"
            placeholderText={t("parentDashboard.password.email")}
            labelText={t("parentDashboard.password.enterEmail")}
          />
          {formState.errors?.email && (
            <StyledError bottomMargin="1rem" topMargin="-2.2rem">
              {formState.errors.email.message}
            </StyledError>
          )}
          {error && (
            <StyledError bottomMargin="1rem" centered topMargin="1rem">
              {error}
            </StyledError>
          )}
          {success && (
            <StyledSuccess bottomMargin="1rem" centered topMargin="1rem">
              {t("parentDashboard.password.sendEmailSuccess")}
            </StyledSuccess>
          )}
          <Button
            resPending={requestInProgress}
            buttonWidth="100%"
            invert={false}
          >
            {t("parentDashboard.password.sendEmailBtn")}
          </Button>
        </Form>
      </ContentWrapper>
    </Wrapper>
  );
};
