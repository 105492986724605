import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { GlobalStyles } from "twin.macro";
import { useState, useEffect, MouseEvent } from "react";
import { buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import chargeIcon from "../../../../assets/common/Learner-Home/Charge.svg";
import {
  AvatarStyled,
  Wrapper,
  ChargeIcon,
  AvatarMessage,
  AvatarMessageWrapper,
} from "./LearnerAvatarStyled";
import { AssistantUsageModal } from "./AssistantUsageModal";

import { StoreState } from "../../../../store/type";
import colors from "../../../../colors.json";
import { AvatarWithSelectedItems } from "../../../../common/components/Avatar/AvatarWithSelectedItems";
import { RoutePath } from "../../../../common/consts/route-path";
import { env } from "../../../../common/config/env";
import { HelpMessage } from "../../../Assistant/HelpMessage";
import { LocalStorageKeys } from "../../../../common/enums/LocalStorageKeys";

export const LearnerAvatar = () => {
  const { activeSubscription } = useSelector(
    (state: StoreState) => state.user.info
  );
  const [assistantUsageModalOpen, setAssistantUsageModalOpen] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const preventBubbling = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleMessageClose = () => {
    sessionStorage.setItem(LocalStorageKeys.SHOWED_AI_FEATURE, "true");
    setShowMessage(false);
  };

  const handleOpenModal = () => {
    handleMessageClose();
    setAssistantUsageModalOpen(true);
  };

  const handleModalClose = () => {
    setAssistantUsageModalOpen(false);
  };

  useEffect(() => {
    const didShow = sessionStorage.getItem(LocalStorageKeys.SHOWED_AI_FEATURE);
    setShowMessage(!didShow);
  }, []);

  const route = `${RoutePath.learnerProfile.locale[env.locale]}?tab=hat`;

  return (
    <Link to={route}>
      <Wrapper>
        <CircularProgressbarWithChildren
          className="w-32 h-32 md:w-24 md:h-24 lg:w-32 lg:h-32 bg-white rounded-full"
          value={
            activeSubscription && activeSubscription.completion <= 100
              ? 100 - activeSubscription.completion
              : 0
          }
          strokeWidth={2}
          styles={buildStyles({
            pathColor: activeSubscription
              ? activeSubscription.completion <= 50
                ? colors.green
                : activeSubscription.completion <= 80
                ? colors.gold
                : colors.danger
              : colors.white,
            trailColor: colors.white,
          })}
        >
          <AvatarMessageWrapper>
            <AvatarStyled>
              <AvatarWithSelectedItems />
            </AvatarStyled>
            <span onClick={preventBubbling}>
              {showMessage && (
                <AvatarMessage>
                  <HelpMessage
                    onMessageClick={handleOpenModal}
                    onClose={handleMessageClose}
                  />
                </AvatarMessage>
              )}
              {assistantUsageModalOpen && (
                <AssistantUsageModal onClose={handleModalClose} />
              )}
            </span>
          </AvatarMessageWrapper>
        </CircularProgressbarWithChildren>
        <ChargeIcon src={chargeIcon} />
        <GlobalStyles />
      </Wrapper>
    </Link>
  );
};
