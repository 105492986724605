import tw, { styled } from "twin.macro";

export const Wrapper = styled.div(() => tw`w-full flex flex-col`);

export const InfoWrapper = styled.div(
  () =>
    tw`w-full bg-white rounded-lg flex flex-col items-center justify-center px-[4.125rem] py-[2.5rem] mb-6`
);

export const Heading = styled.h3(() => tw`font-bold text-black mb-4`);
