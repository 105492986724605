import {
  AllTraitsDiv,
  AvatarWrapper,
  EditIcon,
  Info,
  InfoDiv,
  Name,
  TraitDiv,
  TraitIcon,
  TraitName,
  TraitsSpan,
  Wrapper,
  AvatarIconWrapper,
} from "./LearnerInfoStyled";
import editIcon from "../../../assets/common/Profile-Settings/edit-icon.svg";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../../common/consts/route-path";
import { env } from "../../../common/config/env";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreState } from "../../../store/type";
import { AvatarWithSelectedItems } from "../../../common/components/Avatar/AvatarWithSelectedItems";

export const LearnerInfo = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onEditClick = () => {
    navigate(`${RoutePath.learnerSetup.locale[env.locale]}?startFrom=2`);
  };

  const { firstName, lastName, gender, avatar, traits } = useSelector(
    (state: StoreState) => state.user.info
  );

  const unapprovedTraits = traits?.filter((t) => !t.approved) || [];

  return (
    <Wrapper>
      <AvatarWrapper>
        <AvatarIconWrapper>
          <AvatarWithSelectedItems />
        </AvatarIconWrapper>
      </AvatarWrapper>
      <InfoDiv>
        <Name>{`${firstName} ${lastName}`}</Name>
        <Info>
          {`${t("profileSettings.gender")}: `}
          {`
          ${
            gender
              ? t(`profileSettings.${gender?.toLowerCase()}` as any)
              : t("profileSettings.notSelected")
          } |
          ${t("profileSettings.avatarType")}: 
          ${
            avatar?.name
              ? t(`avatarNames.${avatar.name.toLowerCase()}` as any)
              : t("profileSettings.notSelected")
          }`}
        </Info>
        <TraitsSpan>{t("profileSettings.traits")}:</TraitsSpan>
        <AllTraitsDiv>
          {traits
            ?.filter((t) => t.approved)
            .map((trait) => {
              return (
                <TraitDiv key={trait.id}>
                  {trait.image && <TraitIcon src={trait.image} />}
                  <TraitName>{trait.name}</TraitName>
                </TraitDiv>
              );
            })}
          {traits?.length === 0 && (
            <Info>{t("profileSettings.notSelected")}</Info>
          )}
          {unapprovedTraits.length > 0 && (
            <Info>
              {t("profileSetup.personalityTraits.hasUnapprovedTraits", {
                number: unapprovedTraits.length,
                plural: unapprovedTraits.length > 1 ? "s" : "",
              })}
            </Info>
          )}
        </AllTraitsDiv>
      </InfoDiv>
      <EditIcon
        title={t("profileSettings.editProfile")}
        onClick={onEditClick}
        src={editIcon}
      />
    </Wrapper>
  );
};
