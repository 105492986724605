import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../common/components/Button/Button";
import { redirect } from "../../store/slices/router";

import {
  BtnDiv,
  ContentWrapper,
  Description,
  Heading,
  Wrapper,
} from "./NotFoundStyled";
import { StoreState } from "../../store/type";
import { useUserHomeRoute } from "../../common/hooks/useUserHomeRoute";
import { RoutePath } from "../../common/consts/route-path";
import { env } from "../../common/config/env";

export const NotFound = () => {
  const { t } = useTranslation();
  const isLoggedIn = useSelector((state: StoreState) => state.user.isLoggedIn);
  const homeRoute = useUserHomeRoute();
  const dispatch = useDispatch();

  const redirectUser = () => {
    dispatch(
      redirect({
        to: isLoggedIn ? homeRoute : RoutePath.login.locale[env.locale],
      })
    );
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <Heading>{t("notFound.title")}</Heading>
        <Description>{t("notFound.description")}</Description>
        <BtnDiv>
          <Button
            onClick={redirectUser}
            buttonHeight="2.5rem"
            buttonWidth="100%"
          >
            {t("notFound.btnLabel")}
          </Button>
        </BtnDiv>
      </ContentWrapper>
    </Wrapper>
  );
};
