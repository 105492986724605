import {
  ShopItemIcon,
  ShopItemIconWrapper,
  ShopItemPoints,
  ShopItemWrapper,
  StarIcon,
} from "./styled";
import starIcon from "../../../assets/common/Learner-Home/Star.svg";
import { AvatarItemKind } from "../../../store/types/avatar-items";

type Props = {
  url: string;
  points: number;
  onClick: () => void;
  selected: boolean;
  active: boolean;
  id: string;
  kind: AvatarItemKind;
};

const getPosition = (kind: AvatarItemKind) => {
  switch (kind) {
    case AvatarItemKind.Mask:
    case AvatarItemKind.Eyes:
      return { transform: "scale(3.5)", top: "40%" };
    case AvatarItemKind.Legs:
      return { transform: "scale(7)", top: "-60%" };
    case AvatarItemKind.Body:
    case AvatarItemKind.Gloves:
      return { transform: "scale(5)", top: "-30%" };
    case AvatarItemKind.Mantle:
      return { transform: "scale(3.5)", top: "-20%" };
    case AvatarItemKind.Boots:
      return { transform: "scale(6.5)", top: "-80%" };
    case AvatarItemKind.Torso:
      return { transform: "scale(6)", top: "-30%" };
    case AvatarItemKind.Belt:
      return { transform: "scale(7)", top: "-50%" };
    case AvatarItemKind.Badge:
      return { transform: "scale(8)", top: "-60%" };
    default:
      return {};
  }
};

export const ShopItem = ({
  url,
  points,
  onClick,
  selected,
  active,
  kind,
}: Props) => {
  return (
    <ShopItemWrapper onClick={onClick}>
      <ShopItemIconWrapper $selected={selected} $active={active}>
        <ShopItemIcon src={url} style={getPosition(kind)} />
      </ShopItemIconWrapper>
      <ShopItemPoints>
        <StarIcon src={starIcon} /> {points}
      </ShopItemPoints>
    </ShopItemWrapper>
  );
};
