import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Spinner } from "../../common/components/Spinner/Spinner";
import { useAsyncState } from "../../common/hooks/useAsyncState";
import { AsyncActions } from "../../store/enums/AsyncActions";
import { fetchUserInfo } from "../../store/thunks/fetchUserInfo";
import { LearnerInfo } from "./LearnerInfo/LearnerInfo";
import {
  LearnerWrapper,
  PageWrapper,
  ShopWrapper,
} from "./LearnerProfileStyled";
import { LearnerSubjects } from "./LearnerSubjects/LearnerSubjects";
import { Shop } from "./Shop/Shop";

export const LearnerProfile = () => {
  const { requestInProgress } = useAsyncState(AsyncActions.FetchUserAction);
  const [loadingState, setLoadingState] = useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserInfo());
    setLoadingState(false);
  }, []);

  return (
    <PageWrapper>
      {loadingState || requestInProgress ? (
        <Spinner fullscreen />
      ) : (
        <React.Fragment>
          <LearnerWrapper>
            <LearnerInfo />
            <LearnerSubjects />
          </LearnerWrapper>
          <ShopWrapper>
            <Shop />
          </ShopWrapper>
        </React.Fragment>
      )}
    </PageWrapper>
  );
};
