import { ReduxThunk } from "../../type";
import { addSubjectLessons, addLessonInfo } from "../../slices/subject";
import { setBusyAction, setErrorAction } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import { Lessons, Section } from "../../types/lessons";
import i18n from "../../../i18n";
import { AxiosError } from "axios";
import { Exception } from "../../../common/enums/Exceptions";
import { redirect } from "../../slices/router";
import { env } from "../../../common/config/env";
import { RoutePath } from "../../../common/consts/route-path";

export const fetchSubjectLessons: (
  subjectCode: string,
  preselect?: boolean
) => ReduxThunk = (subjectCode, preselect) => {
  const sortLessons = (a: Lessons, b: Lessons) => {
    return a.lessonOrder - b.lessonOrder;
  };

  return async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({
        key: AsyncActions.FetchSubjectLessonsAction,
        value: true,
      })
    );
    try {
      const response = await client.get(`subject/learner/${subjectCode}`);
      const lessons: Lessons[] = [];
      response.data.sections.forEach((section: Section) => {
        lessons.push(...section.lessons);
      });

      const sortedLessons = lessons.sort(sortLessons);
      dispatch(addSubjectLessons(sortedLessons));
      if (preselect) {
        dispatch(
          fetchLessonInfo({ subjectCode, lessonCode: sortedLessons[0].code })
        );
      }
    } catch (err: any) {
      dispatch(
        setErrorAction({
          key: AsyncActions.FetchSubjectLessonsAction,
          value: i18n.t("learnerHome.errors.subjectFetch"),
        })
      );
    }
    dispatch(
      setBusyAction({
        key: AsyncActions.FetchSubjectLessonsAction,
        value: false,
      })
    );
  };
};

export const fetchLessonInfo: (data: {
  subjectCode: string | undefined;
  lessonCode: string | undefined;
}) => ReduxThunk = ({ subjectCode, lessonCode }) => {
  return async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({
        key: AsyncActions.FetchLessonInfoAction,
        value: true,
      })
    );
    try {
      const response = await client.get(
        `lesson/learner/${subjectCode}/${lessonCode}`
      );

      dispatch(addLessonInfo(response.data));
    } catch (err: any) {
      if (
        err.response.data.message
          .toLowerCase()
          .includes("could not find any entity")
      ) {
        dispatch(redirect({ to: RoutePath.home.locale[env.locale] }));
      }
      if (
        (err as AxiosError<{ message: Exception }>).response?.data.message ===
        Exception.EntityNotFound
      ) {
        dispatch(
          redirect({
            to: RoutePath.home.locale[env.locale],
            options: { replace: true },
          })
        );
      }
      dispatch(
        setErrorAction({
          key: AsyncActions.FetchLessonInfoAction,
          value: i18n.t("learnerHome.errors.lessonFetch"),
        })
      );
    }
    dispatch(
      setBusyAction({
        key: AsyncActions.FetchLessonInfoAction,
        value: false,
      })
    );
  };
};
