import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SliceNames } from "../enums/SliceNames";
import {
  AssistantState,
  GeneratedQnA,
  RelatedLesson,
  SearchResult,
} from "../types/assistant";

const initialState: AssistantState = {
  animate: false,
  generatedQnA: [],
  lessonSummary: null,
  relatedLessons: [],
  searchResult: [],
};

export const assistantSlice = createSlice({
  name: SliceNames.Assistant,
  initialState,
  reducers: {
    setAnimation: (state, { payload }: PayloadAction<boolean>) => {
      state.animate = payload;
    },
    setQnA: (state, { payload }: PayloadAction<Array<GeneratedQnA>>) => {
      state.generatedQnA = payload;
    },
    setLessonSummary: (state, { payload }: PayloadAction<string>) => {
      state.lessonSummary = payload;
    },
    setRelatedLessons: (
      state,
      { payload }: PayloadAction<Array<RelatedLesson>>
    ) => {
      state.relatedLessons = payload;
    },
    setSearchResult: (
      state,
      { payload }: PayloadAction<Array<SearchResult>>
    ) => {
      state.searchResult = payload;
    },
    reset: () => {
      return initialState;
    },
  },
});

export const {
  reset,
  setAnimation,
  setQnA,
  setLessonSummary,
  setRelatedLessons,
  setSearchResult,
} = assistantSlice.actions;

export default assistantSlice.reducer;
