import React from "react";
import { useTranslation } from "react-i18next";
import {
  Title,
  DescriptionWrapper,
  Report,
  Description,
  Wrapper,
  ContentWrapper,
  ActionWrapper,
} from "./ErrorComponentStyled";
import Button from "../../common/components/Button/Button";
import { RoutePath } from "../../common/consts/route-path";
import { env } from "../../common/config/env";

export const ErrorComponent = () => {
  const { t } = useTranslation();

  const logout = () => {
    window.location.assign(
      new URL(RoutePath.home.locale[env.locale], window.location.origin)
    );
  };
  return (
    <Wrapper className="bg-auth bg-cover">
      <ContentWrapper>
        <Title>{t("errorPage.title")}</Title>
        <DescriptionWrapper>
          <Description>{t("errorPage.description")}</Description>
          <Description>{t("errorPage.reportDescription")}</Description>
          <Report
            href={`mailto:${env.contactEmail}?subject=${t(
              "errorPage.mailSubject"
            )}&body=${t("errorPage.mailBody")}`}
            target="_blank"
            rel="noreferrer"
          >
            {t("errorPage.report")}
          </Report>
        </DescriptionWrapper>
        <ActionWrapper>
          <Button buttonWidth="15rem" onClick={logout}>
            {t("errorPage.btnLabel")}
          </Button>
        </ActionWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};
