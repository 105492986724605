import { useTranslation } from "react-i18next";
import { Fragment, useState } from "react";
import dayjs from "../../config/dayjs";
import {
  Collapse,
  IconWrapper,
  Item,
  ItemDescription,
  ItemValue,
  MobileTournamentItem,
  Panel,
  PanelContent,
  PanelItemDescription,
  PanelItemValue,
  StarIcon,
  TabletTournamentWrapper,
  TournamentIcon,
  TournamentTitle,
  TournamentType,
  ActionWrapper,
  ActionLabel,
} from "./styled";
import cupIcon from "../../../assets/common/Lesson-Page/Cup.svg";
import starIcon from "../../../assets/common/Lesson-Page/Star.svg";

import { Tournament } from "../../../store/types/tournament";
import { ViewTournamentResult } from "./ViewTournamentResult";
import { getDuration, getParticipationTranslation } from "./utils";
import { TournamentResultStatus } from "../../enums/TournamentResultStatus";

export const HistoryTournamentList = ({
  tournaments,
}: {
  tournaments: Tournament[];
}) => {
  const { t } = useTranslation();
  const [activePanel, setActivePanel] = useState<string>("");

  const handleSetActivePanel = (val: string) => () => {
    setActivePanel((pre) => (pre === val ? "" : val));
  };

  return (
    <Collapse activeKey={activePanel} accordion={true} destroyInactivePanel>
      {tournaments.map(
        ({ id, startsAt, name, endsAt, points, pointsEarned, status }) => (
          <Panel
            collapsible="header"
            key={id}
            header={
              <Fragment>
                {/*mobile*/}
                <MobileTournamentItem onClick={handleSetActivePanel(id)}>
                  <IconWrapper>
                    <TournamentIcon src={cupIcon} />
                  </IconWrapper>
                  <Item>
                    <ItemValue>{name || t("tournaments.tournament")}</ItemValue>
                    <ItemDescription extraSmall>
                      {t("tournaments.pastTournamentDetails.individual")}
                    </ItemDescription>
                  </Item>
                </MobileTournamentItem>
                {/*tablet*/}
                <TabletTournamentWrapper onClick={handleSetActivePanel(id)}>
                  <TournamentTitle>
                    <IconWrapper>
                      <TournamentIcon src={cupIcon} />
                    </IconWrapper>
                    {name || t("tournaments.tournament")}
                  </TournamentTitle>
                  <TournamentType>
                    {t("tournaments.pastTournamentDetails.individual")}
                  </TournamentType>
                </TabletTournamentWrapper>
              </Fragment>
            }
          >
            <PanelContent>
              <PanelItemDescription>
                {t("tournaments.pastTournamentDetails.duration")}:
              </PanelItemDescription>
              <PanelItemValue>{getDuration(endsAt, startsAt)}</PanelItemValue>
              <PanelItemDescription>
                {t("tournaments.pastTournamentDetails.endedAt")}:
              </PanelItemDescription>
              <PanelItemValue>
                {dayjs(startsAt).format("DD MMM/HH:mm")}
              </PanelItemValue>
              <PanelItemDescription>
                {t("tournaments.pastTournamentDetails.pointsEarned")}:
              </PanelItemDescription>
              <PanelItemValue>
                {status === TournamentResultStatus.Completed && (
                  <StarIcon src={starIcon} />
                )}
                {getParticipationTranslation(status, pointsEarned, t)}
              </PanelItemValue>
              <PanelItemDescription>
                {t("tournaments.pastTournamentDetails.rewards")}:
              </PanelItemDescription>
              <PanelItemValue>
                <StarIcon src={starIcon} />
                {points}
              </PanelItemValue>
              <ActionLabel>
                {t("tournaments.pastTournamentDetails.seeResults")}:
              </ActionLabel>
              <ActionWrapper>
                <ViewTournamentResult id={id} />
              </ActionWrapper>
            </PanelContent>
          </Panel>
        )
      )}
    </Collapse>
  );
};
