import React from "react";
import { ErrorComponent } from "../../components/ErrorComponent/ErrorComponent";
import { env } from "../../common/config/env";

export class ErrorBoundary extends React.Component {
  state: { hasError: boolean };
  props: any;
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    env.nodeEnv === "development" && console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorComponent />;
    }

    return this.props.children;
  }
}
