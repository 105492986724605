import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "../../../../common/components/Button/Button";
import { useAsyncState } from "../../../../common/hooks/useAsyncState";
import { useLearners } from "../../../../common/hooks/useLearners";
import { AsyncActions } from "../../../../store/enums/AsyncActions";
import { StoreState } from "../../../../store/type";
import { AddLearners } from "../../../Register/AddLearners/AddLearners";
import { ButtonDiv, NoAddText, AddLearnerDiv } from "./AddLearnerStyled";

type AddLearnerProps = {
  setStep: (val: number) => void;
};

export const AddLearner = ({ setStep }: AddLearnerProps) => {
  const { t } = useTranslation();

  const { learnerLimit } = useSelector((state: StoreState) => state.user.info);
  const { allLearners: learners } = useLearners();
  const { notification } = useAsyncState(AsyncActions.ChangeFont);

  if (
    learners &&
    learnerLimit &&
    learners.length >= learnerLimit &&
    !notification
  ) {
    return (
      <React.Fragment>
        <NoAddText>{t("parentDashboard.manage.limitReached")}</NoAddText>
        <ButtonDiv>
          <Button onClick={() => setStep(1)} buttonWidth="20rem" invert={false}>
            Go Back
          </Button>
        </ButtonDiv>
      </React.Fragment>
    );
  }

  return (
    <AddLearnerDiv>
      <AddLearners addOne />
    </AddLearnerDiv>
  );
};
