import tw, { styled } from "twin.macro";

export const Wrapper = styled.div(
  () =>
    tw`w-full p-4 bg-white rounded-lg min-h-[13.375rem] border border-light-gray-secondary flex`
);

export const AvatarWrapper = styled.div(
  () => tw`w-24 h-24 sm:h-[9.375rem] sm:w-[9.375rem] mr-6 self-center`
);

export const AvatarIconWrapper = styled.div(
  () => tw`w-24 h-24 sm:h-[9.375rem] sm:w-[9.375rem]`
);

export const InfoDiv = styled.div(() => tw`flex flex-col mx-auto pt-6`);

export const Name = styled.span(
  () => tw`font-bold text-black text-lg sm:text-2xl mb-1`
);

export const Info = styled.span(() => tw`font-sans text-black mb-5`);

export const TraitsSpan = styled.span(
  () => tw`font-sans text-black opacity-60 mb-2`
);

export const AllTraitsDiv = styled.div(
  () => tw`grid w-full gap-x-2 gap-y-2 justify-items-start`
);

export const TraitDiv = styled.div(
  () =>
    tw`flex items-center justify-center rounded-full min-w-[7rem] h-10 bg-light-gray-secondary`
);

export const TraitIcon = styled.img(() => tw`w-5 mr-1.5`);

export const TraitName = styled.span(
  () => tw`font-sans text-black text-sm font-medium`
);

export const EditIcon = styled.img(
  () => tw` w-[2.5rem] cursor-pointer self-start`
);
