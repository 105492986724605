import * as yup from "yup";
import i18n from "../../i18n";

export const gameNameValidationSchema = () => {
  return yup
    .object({
      gameName: yup
        .string()
        .trim()
        .required(
          i18n.t("validation.required", {
            fieldName: i18n.t("profileSetup.gameName.fieldName"),
          })
        )
        .min(
          2,
          i18n.t("validation.min", {
            fieldName: i18n.t("profileSetup.gameName.fieldName"),
            suffix: "a",
            length: 2,
          })
        )
        .max(
          15,
          i18n.t("validation.maxWithOption", {
            fieldName: i18n.t("profileSetup.gameName.fieldName"),
            length: 15,
          })
        )
        .matches(
          /^([_\p{L}0-9-])+$/gu,
          i18n.t("profileSetup.gameName.gameNameContentValidation")
        ),
    })
    .required();
};

export const genderValidationSchema = () => {
  return yup
    .object({
      gender: yup
        .string()
        .nullable()
        .required(i18n.t("profileSetup.selectGender.validation")),
    })
    .required();
};

export const avatarValidationSchema = () => {
  return yup
    .object({
      avatar: yup
        .string()
        .nullable()
        .required(i18n.t("profileSetup.avatarType.validation")),
    })
    .required();
};

export const addTraitValidationSchema = () => {
  return yup
    .object({
      addedTrait: yup
        .string()
        .required(
          i18n.t("validation.required", {
            fieldName: i18n.t(
              "profileSetup.personalityTraits.validation.fieldName"
            ),
          })
        )
        .min(
          2,
          i18n.t("validation.min", {
            fieldName: i18n.t(
              "profileSetup.personalityTraits.validation.fieldName"
            ),
            suffix: "a",
            length: 2,
          })
        )
        .max(
          64,
          i18n.t("validation.max", {
            fieldName: i18n.t(
              "profileSetup.personalityTraits.validation.fieldName"
            ),
          })
        ),
    })
    .required();
};
