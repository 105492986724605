import { FaqCategory } from "../../../common/enums/FAQ";
import dayjs from "../../../common/config/dayjs";
import { env } from "../../../common/config/env";

type FAQs = {
  category: FaqCategory;
  createdAt: Date;
  qa: Array<{
    question: string;
    answer: string;
  }>;
};

const createHref = (to: string, isMail: boolean) => {
  return `<a href="${isMail ? "mailto:" + to : to}"
          target="_blank"
          rel="noreferrer"
          style="font-family:Inter;color:blue;text-decoration:underline;"
          >${to}</a>`;
};

const faqObj: { en: FAQs[]; si: FAQs[] } = {
  en: [
    {
      category: FaqCategory.Lesson,
      createdAt: dayjs().subtract(1, "year").toDate(),
      qa: [
        {
          question: "Green screen in video player",
          answer:
            "Can you hear the audio of a YouTube video on your computer, but the video player is green? If so, try watching the video in a different browser. If that doesn't work, here are some more troubleshooting tips.",
        },
      ],
    },
    {
      category: FaqCategory.Registration,
      createdAt: dayjs().subtract(1, "day").toDate(),
      qa: [
        {
          question: "Clear cache & cookies",
          answer:
            "When you use a browser, such as Chrome, it saves some information from websites in its cache and cookies. Clearing them fixes certain problems, such as loading or formatting issues on sites.",
        },
      ],
    },
    {
      category: FaqCategory.Subscriptions,
      createdAt: dayjs().subtract(1, "month").toDate(),
      qa: [
        {
          question: "Change your subscription privacy settings",
          answer: `You can choose to make the channels that you're subscribed to private or public. By default, all settings are set to private.
Private: When your subscriptions are set to private, no other users can see what channels you subscribe to. Your account is not shown in a channel's Subscribers list, even if you're subscribed.
Note: If you participate in a subscribers-only live chat, other viewers will publicly see that you're subscribed to the channel.
Public: When your subscriptions are set to public, other users can see what channels you subscribe to. Your subscriptions are listed on your channel homepage. Your account is listed in the Subscribers list for any channel that you subscribe to. `,
        },
      ],
    },
  ],
  si: [
    {
      category: FaqCategory.Lesson,
      createdAt: dayjs().subtract(1, "year").toDate(),
      qa: [
        {
          question: "Kaj je YUNO portal?",
          answer:
            "Portal YUNO je tvoja osnovna šola online, kjer te čaka posneta učna snov. Vsaka učna lekcija je sestavljena iz VIDEO razlage, ki nikoli ni daljša od enajstih minut, ZAPISKA, VAJ in REŠITEV s postopki.",
        },
        {
          question: "Kaj vse najdem na portalu YUNO?",
          answer: `Na portalu YUNO te čaka posneta učna snov osnovne šole od 3. do 9. razreda. Aktualno ponudbo lahko najdeš na ${createHref(
            env.yunoPortal + "/offer",
            false
          )}.`,
        },
        {
          question: "Komu je portal namenjen?",
          answer:
            "Portal je namenjen vsem učencem in staršem kot učni pripomoček, učno pomoč, nadomestek inštrukcij … Kadar manjkaš v šoli, kadar gre v šoli prehitro ali prepočasi, kadar potrebuješ razlago slišati še enkrat, kadar potrebuješ dodatne vaje …",
        },
        {
          question: "Kdo pripravlja vsebine?",
          answer:
            "Vsebine učnega portala YUNO zate pripravljamo učitelji, ki že desetletja učimo v osnovnih šolah in točno vemo, kaj potrebuješ. Vsaka učna snov je olupljena do bistva, kratka, jedrnata in pripravljena enako, kot bi jo slišal v razredu.",
        },
        {
          question:
            "Kako vem, da so vsebine take, kot jih moj otrok obravnava v šoli?",
          answer:
            "Učne vsebine portala YUNO so pripravljene popolnoma v skladu s cilji in standardi javnih učnih načrtov, ki so univerzalni za vse osnovne šole v Sloveniji, ne glede na to, katera učna gradiva učenci uporabljajo v šoli.",
        },
        {
          question: "Ali YUNO pokriva samo minimalne standarde znanja?",
          answer:
            "Ne, učni portal YUNO pokriva tako minimalne kot splošne in operativne standarde in cilje znanja, kot so navedeni v javnih učnih načrtih.",
        },
        {
          question: "Nekaj vsebin še na portalu manjka, kdaj bodo na voljo?",
          answer: `Na učnem portalu YUNO te čaka že ogromno učnih vsebin. Učitelji se trudimo, da bodo še manjkajoče zate na voljo v najkrajšem možnem času. V primeru, da določeno učno vsebino, ki je na portalu še ni, želiš predelati podrobneje, lahko vedno naročiš inštrukcije z učiteljem. Piši nam na ${createHref(
            env.contactEmail,
            true
          )}.`,
        },
        {
          question: "Ali bodo na YUNO tudi vsebine izbirnih predmetov?",
          answer: `Ali bodo na YUNO tudi vsebine izbirnih predmetov?',answer: 'Da, tudi vsebine izbirnih predmetov kmalu prihajajo na portal YUNO. Tukaj pa te že čaka dodaten predmet »Kako se učiti?«. Predloge za vsebine, ki jih želiš videti na portalu YUNO, nam lahko sporočiš na ${createHref(
            env.contactEmail,
            true
          )}.`,
        },
      ],
    },
    {
      category: FaqCategory.Functionality,
      createdAt: dayjs().toDate(),
      qa: [
        {
          question:
            "Kako lahko spremenim pisavo na zapiskih, vajah in rešitvah?",
          answer:
            "Pisavo na zapiskih, vajah in rešitvah lahko spremenite s klikom na Otroci in »Spremeni pisavo«. Odpre se okno, kjer so predstavljene pisave, sami pa izberete, katero želite. Pisava, primerna za učence z dislekcijo, je OpenDyslexic.",
        },
        {
          question:
            "Kje lahko vidim, koliko lekcij je moj otrok že pogledal in zaključil? ",
          answer:
            "Slednje lahko vidite v razdelku Spremljaj napredek. Izberete učenca, za katerega želite pogledati statistiko, slednjo pa najdete v razdelku desno z naslovom »Nedavna aktivnost«",
        },
        {
          question: "Zakaj otrok ne more zamenjati avatarja?",
          answer: "Avatarja lahko učenec zamenja enkrat letno.",
        },
        {
          question: "Kako otrok pridobiva točke?",
          answer:
            "Z vsako zaključeno ali začeto učno lekcijo portala YUNO vaš učenec pridobiva in zbira točke.",
        },
        {
          question: "Čemu so točke namenjene?",
          answer:
            "Točke so namenjene motivaciji ob učenju, udeležbi na prihajajočih turnirjih iz znanja ter veljajo kot denar za nakup v Trgovini, ki bo na voljo kmalu.",
        },
        {
          question: "Kako deluje Rezerviraj čas z učiteljem?",
          answer: `Rezerviraj čas z učiteljem predstavlja naročilo na inštrukcije pri izbrani učni snovi, kjer učenec potrebuje dodatno razlago ali dodatno vajo. Na ${createHref(
            env.contactEmail,
            true
          )} pošljite sporočilo, pri katerem predmetu potrebujete dodatno razlago in za vas bomo organizirali 1 na 1 inštrukcije z YUNO učiteljem.`,
        },
      ],
    },

    {
      category: FaqCategory.Registration,
      createdAt: dayjs().subtract(1, "day").toDate(),
      qa: [
        {
          question:
            "Registrirali smo se na portal in plačali, vsebin ne vidim. Kaj naj storim?",
          answer:
            "Vsebin ne vidite, saj ste na starševskem pogledu portala YUNO (Prijava kot STARŠ). V portal se morate prijavi v pogledu učenca (Prijava kot UČENEC). Uporabniške podatke ste ob nakupu prejeli tudi prek e–pošte (preverite še v nabiralniku Vsiljena pošta oz. Spam). Slednje pa lahko vedno najdete pod razdelkom Otroci, kjer lahko uporabniško ime in PIN za vstop tudi spremenite.",
        },
        {
          question:
            "Otrok je pozabil uporabniško ime in geslo, kaj lahko storim?",
          answer:
            "Slednje lahko vedno najdete pod razdelkom Otroci (Prijava kot STARŠ), kjer lahko uporabniško ime in PIN za vstop tudi spremenite.",
        },
        {
          question:
            "Otrok je spremenil uporabniško ime in ga pozabil. Kako ga ponastavim?",
          answer:
            "Slednje lahko vedno najdete pod razdelkom Otroci (Prijava kot STARŠ), kjer lahko uporabniško ime in PIN za vstop tudi spremenite.",
        },
        {
          question: "Želim dodati še drugega otroka. Kaj naj storim?",
          answer:
            "Portal YUNO omogoča, da lahko dodate do tri učence. Slednje storite pod razdelkom Otroci, kjer kliknete »Dodaj učenca« in odpre se vam obrazec za registracijo in plačilo.",
        },
        {
          question:
            "Imam več kot tri otroke, za katere želim YUNO naročnino. Kako naj dodam še četrtega?",
          answer: `Pišite nam na ${createHref(
            env.contactEmail,
            true
          )} in skupaj bomo uredili.`,
        },
      ],
    },
    {
      category: FaqCategory.Subscriptions,
      createdAt: dayjs().subtract(1, "month").toDate(),
      qa: [
        {
          question: "Kako se obračunava mesečna in kako letna naročnina?",
          answer: `Naročnina na portal YUNO je lahko mesečna (30 dni) ali letna (365 dni). YUNO naročnina se po izteku naročniškega obdobja obnavlja avtomatsko za obdobje enega (1) razen, če jo Uporabnik prekine ali odpove. Prekinitev oziroma odpoved enostavno sporočite na ${createHref(
            env.contactEmail,
            true
          )} in ta prične veljati z naslednjim obračunskim obdobjem.`,
        },
        {
          question: "Želim podaljšati naročnino! Kako to naredim?",
          answer: `Podaljšanje je mogoče s klikom na Naročnine in »Obnovi naročnino« ter plačilom s kreditno kartico ali s plačilom po predračunu. Za predračun nam pišite na ${createHref(
            env.contactEmail,
            true
          )}`,
        },
        {
          question: "Zakaj je bolje kupiti letno kot mesečno naročnino?",
          answer:
            "V primeru letne naročnine vam 4 mesece naročnine podarimo brezplačno! Ob zaključku šolskega leta pa portal avtomatsko preide v naslednji razred. YUNO je učni pripomoček, ki vam na vaši izobraževalni poti pomaga celostno. Zato smo za vas posneli večino predmetov – in ne samo ‘glavne’ – z namenom, da si z YUNO lahko pomagate v vsaki učni situaciji.",
        },
        {
          question:
            "Imeli smo aktivno naročnino nekaj mesecev, potem pa en mesec ne. Ali me moj uporabniški račun počaka takšen, kot je bil, z vsemi osvojenimi točkami ali moram graditi od začetka?",
          answer: "Da, portal YUNO vas počaka točno takšen, kot je bil.",
        },
        {
          question: "Kaj pomeni ikona baterije nad avatarjem?",
          answer:
            "Ikona baterije predstavlja tvojo naročnino na portal YUNO. Ob nakupu naročnine je polna in zelena – skozi čas naročnine pa postaja vedno bolj rdeča in se prazni. Ko postane popolnoma rdeča, pomeni, da je naročnina potekla.",
        },
        {
          question:
            "Ali poleg naročnine obstaja še kakšna druga možnost, kjer bi otrok imel pomoč tudi 1:1?",
          answer: `Seveda! Vedno lahko naročite inštrukcije z YUNO učiteljem. Pišite nam na ${createHref(
            env.contactEmail,
            true
          )}.`,
        },
      ],
    },
  ],
};

export const faq = faqObj[env.locale];
