import { ReduxThunk } from "../../type";
import { setBusyAction, setErrorAction, setSuccess } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { Exception } from "../../../common/enums/Exceptions";
import i18n from "../../../i18n";

export const resetPassword: (data: {
  token: string | null;
  password: string;
}) => ReduxThunk = (data) => {
  return async (dispatch, _, { client }) => {
    try {
      dispatch(setBusyAction({ key: AsyncActions.ResetPassword, value: true }));
      await client.post(ApiRoutes.ResetPassword, data);

      dispatch(setSuccess({ key: AsyncActions.ResetPassword, value: true }));
    } catch (err: any) {
      if (err.response?.data?.message === Exception.NewPasswordSameAsOld) {
        dispatch(
          setErrorAction({
            key: AsyncActions.ResetPassword,
            value: i18n.t("resetPassword.passwordsIdentical"),
          })
        );
      } else {
        dispatch(
          setErrorAction({
            key: AsyncActions.ResetPassword,
            value: i18n.t("response.500"),
          })
        );
      }
    }
    dispatch(setBusyAction({ key: AsyncActions.ResetPassword, value: false }));
  };
};
