import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import i18n from "../../../i18n";
import { AsyncActions } from "../../enums/AsyncActions";
import {
  setBusyAction,
  setErrorAction,
  setNotification,
} from "../../slices/async";
import { ReduxThunk } from "../../type";

type Props = {
  learnerUsername: string;
  font: string;
  learnerName: string;
};

export const changeFont: (data: Props[], addOne?: boolean) => ReduxThunk = (
  data,
  addOne
) => {
  return async (dispatch, _, { client }) => {
    dispatch(setBusyAction({ key: AsyncActions.ChangeFont, value: true }));
    try {
      await Promise.all(
        data.map(async (learner) => {
          return await client.patch(
            `${ApiRoutes.AddLearners}/${learner.learnerUsername}`,
            { font: learner.font }
          );
        })
      );

      dispatch(setNotification({ key: AsyncActions.ChangeFont, value: null }));
      if (!addOne) {
        dispatch(
          setNotification({ key: AsyncActions.AddLearnersAction, value: null })
        );
      }
    } catch (err: any) {
      dispatch(
        setErrorAction({
          key: AsyncActions.ChangeFont,
          value: i18n.t("response.500"),
        })
      );
    }
    dispatch(setBusyAction({ key: AsyncActions.ChangeFont, value: false }));
  };
};
