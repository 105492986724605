import { Link } from "react-router-dom";
import { ButtonStyled } from "../../../common/components/Button/Button";
import { Icon, Paragraph, ResultPageTitle, NoResultsWrapper } from "./styled";
import { getRoute } from "../../../common/utils/getRoute";
import { RoutePath } from "../../../common/consts/route-path";
import { env } from "../../../common/config/env";
import successGif from "../../../assets/gifs/Successfully Registered.gif";

type Props = {
  title: string;
  description: string;
  btnLabel: string;
};

export const ResultsNotAvailable = ({
  title,
  description,
  btnLabel,
}: Props) => {
  return (
    <NoResultsWrapper>
      <ResultPageTitle>{title}</ResultPageTitle>
      <Icon src={successGif} />
      <Paragraph>{description}</Paragraph>
      <Link
        to={getRoute(
          {},
          RoutePath.tournaments.locale[env.locale],
          RoutePath.tournaments.sub.list.locale[env.locale],
          RoutePath.tournaments.sub.list.sub.upcoming.locale[env.locale]
        )}
      >
        <ButtonStyled buttonWidth={"11rem"} invert>
          {btnLabel}
        </ButtonStyled>
      </Link>
    </NoResultsWrapper>
  );
};
