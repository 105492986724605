import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchTournamentResultsForSponsor } from "../../../store/thunks/Parent/tournaments";
import { StoreState } from "../../../store/type";
import {
  Table,
  TableWrapper,
  TBody,
} from "../../../common/components/Tournament/styled";
import { LearnerTournamentHistoryItem } from "./LearnerTournamentHistoryItem";
import { LearnerTournamentHistoryCollapse } from "./LearnerTournamentHistoryCollapse";
import { NoEvents } from "./NoEvents";
import { useRefresh } from "../../../common/hooks/useRefresh";

export const LearnerTournamentHistory = () => {
  const results = useSelector(
    (state: StoreState) => state.tournaments.resultsForSponsor
  );

  const state = useRefresh(60000);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTournamentResultsForSponsor());
  }, [state]);

  return (
    <>
      {results.length === 0 && <NoEvents />}
      <TableWrapper>
        <Table>
          <TBody>
            {results.map((d) => (
              <LearnerTournamentHistoryItem key={d.id} {...d} />
            ))}
          </TBody>
        </Table>
      </TableWrapper>
      <LearnerTournamentHistoryCollapse />
    </>
  );
};
