import tw, { styled } from "twin.macro";
import { Link } from "react-router-dom";

export const BannerWrapper = styled.div(
  () =>
    tw`w-full bg-white rounded-lg flex items-center flex-col lg:flex-row lg:h-[3.125rem] lg:box-border px-4 mt-6 mb-4`
);

export const BannerHeading = styled.h1(
  () => tw`font-bold text-xl text-black my-2 text-center lg:my-0 lg:mr-auto`
);

export const PathWrapper = styled.div(() => tw`flex mb-2 lg:mb-0`);

export const BannerHomeSegment = styled(Link)(
  () => tw`text-xs font-sans text-dark-blue mr-1.5`
);

export const Arrow = styled.span(
  () => tw`text-black font-sans text-xs mr-1.5 font-bold`
);

export const PathSegment = styled.span(
  () => tw`text-xs font-sans text-black opacity-60`
);
