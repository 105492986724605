import { useAsyncState } from "./useAsyncState";
import { AsyncActions } from "../../store/enums/AsyncActions";

export const useNewlyAddedLearners = () => {
  const { notification: byCodeNotification } = useAsyncState(
    AsyncActions.PayByCodeSuccessful
  );

  const { notification: byCardNotification } = useAsyncState(
    AsyncActions.PayByCardSuccessful
  );

  const { notification: renewByCardNotification } = useAsyncState(
    AsyncActions.RenewByCardSuccessful
  );

  const { notification: renewByCodeNotification } = useAsyncState(
    AsyncActions.RenewByCodeSuccessful
  );

  return (
    byCardNotification ||
    byCodeNotification ||
    renewByCardNotification ||
    renewByCodeNotification ||
    ""
  )
    ?.split("&")
    .filter((s) => !!s);
};
