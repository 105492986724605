import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Wrapper, Title, AllPointsWrapper, PointsWrapper } from "./styled";
import { CategoryItem } from "./CategoryItem";
import { ShopItemsModal } from "./ShopItemsModal";
import { Points } from "../../../common/components/Points/Points";
import { reset as resetItems } from "../../../store/slices/avatar-item";

import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../store/type";
import { itemCategories } from "./shared";
import { fetchAvatarItems } from "../../../store/thunks/Learner/fetchAvatarItems";
import { ShopTab } from "../../../common/types/ShopTabs";

export const Shop = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { availablePoints, totalPoints } = useSelector(
    (state: StoreState) => state.user.info
  );

  const tab = searchParams.get("tab");

  const handleClose = () => {
    setSearchParams("");
  };

  const setTab = (tab: ShopTab) => {
    setSearchParams(`tab=${tab}`);
  };

  useEffect(() => {
    dispatch(fetchAvatarItems());
    return () => {
      dispatch(resetItems());
    };
  }, []);

  return (
    <Wrapper>
      <div>
        <Title>{t("shop.shop")}</Title>
        <AllPointsWrapper>
          <PointsWrapper>
            {t("shop.totalPoints")} <Points points={totalPoints} />
          </PointsWrapper>
          <PointsWrapper>
            {t("shop.available")} <Points points={availablePoints} />
          </PointsWrapper>
        </AllPointsWrapper>
      </div>
      {itemCategories.map(({ icon, label }) => (
        <CategoryItem
          onItemSelect={setTab}
          icon={icon}
          label={label}
          key={label}
        />
      ))}
      {tab && (
        <ShopItemsModal onClose={handleClose} activeTab={tab as ShopTab} />
      )}
    </Wrapper>
  );
};
