import { Routes, Route } from "react-router-dom";

import { RoutePath } from "../../consts/route-path";
import { env } from "../../config/env";

import { NotLoggedIn } from "./NotLoggedIn";
import { LoggedIn } from "./LoggedIn";
import { HeaderStyled } from "./HeaderStyled";
import { ParentLoggedIn } from "./ParentLoggedIn";

const Header = () => {
  return (
    <Routes>
      <Route
        path={RoutePath.login.locale[env.locale]}
        element={
          <HeaderStyled>
            <NotLoggedIn />
          </HeaderStyled>
        }
      />
      <Route
        path={RoutePath.forgotPassword.locale[env.locale]}
        element={
          <HeaderStyled>
            <NotLoggedIn />
          </HeaderStyled>
        }
      />
      <Route
        path={RoutePath.register.locale[env.locale]}
        element={
          <HeaderStyled>
            <NotLoggedIn />
          </HeaderStyled>
        }
      />
      <Route
        path={RoutePath.parentDashboard.locale[env.locale]}
        element={<ParentLoggedIn />}
      />

      <Route path={RoutePath.home.locale[env.locale]} element={<LoggedIn />} />
      <Route path={`${RoutePath.lessonPage.locale[env.locale]}`}>
        <Route
          path={RoutePath.lessonPage.sub.survey.locale[env.locale]}
          element={<LoggedIn />}
        />

        <Route index element={<LoggedIn />} />
      </Route>
      <Route
        path={RoutePath.learnerSetup.locale[env.locale]}
        element={<LoggedIn />}
      />
      <Route
        path={RoutePath.learnerProfile.locale[env.locale]}
        element={<LoggedIn />}
      />
      <Route
        path={RoutePath.search.locale[env.locale]}
        element={<LoggedIn />}
      />
      <Route
        path={RoutePath.tournaments.locale[env.locale]}
        element={<LoggedIn />}
      >
        <Route path={"*"} element={<LoggedIn />} />
      </Route>
      <Route
        path={
          RoutePath.tournaments.sub.individualTournaments.locale[env.locale]
        }
        element={<LoggedIn />}
      >
        <Route path={"*"} element={<LoggedIn />} />
      </Route>
    </Routes>
  );
};
export default Header;
