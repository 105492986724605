import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SliceNames } from "../enums/SliceNames";
import {
  BusyAction,
  ErrorAction,
  NotificationAction,
  SuccessAction,
} from "../types/async";
import { AsyncActions } from "../enums/AsyncActions";

type AsyncState = {
  requestInProgress: Partial<Record<AsyncActions, boolean | undefined>>;
  errors: Partial<Record<AsyncActions, string | null>>;
  notifications: Partial<Record<AsyncActions, string | null>>;
  success: Partial<Record<AsyncActions, boolean | undefined>>;
};

const initialState: AsyncState = {
  requestInProgress: {},
  errors: {},
  notifications: {},
  success: {},
};

export const asyncSlice = createSlice({
  name: SliceNames.AsyncSlice,
  initialState,
  reducers: {
    setBusyAction: (
      state,
      { payload: { key, value } }: PayloadAction<BusyAction>
    ) => {
      state.requestInProgress[key] = value;
    },
    setErrorAction: (
      state,
      { payload: { key, value } }: PayloadAction<ErrorAction>
    ) => {
      state.errors[key] = value;
    },
    setNotification: (
      state,
      { payload: { key, value } }: PayloadAction<NotificationAction>
    ) => {
      state.notifications[key] = value;
    },
    setSuccess: (
      state,
      { payload: { key, value } }: PayloadAction<SuccessAction>
    ) => {
      state.success[key] = value;
    },
    reset: () => {
      return initialState;
    },
  },
});

export const {
  setBusyAction,
  setErrorAction,
  setNotification,
  setSuccess,
  reset,
} = asyncSlice.actions;

export default asyncSlice.reducer;
