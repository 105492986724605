import tw, { styled } from "twin.macro";

export const NoAddText = styled.span(
  () => tw`m-auto mt-4 mb-8 text-danger text-lg font-bold font-sans`
);

export const ButtonDiv = styled.div(() => tw`w-full flex justify-center`);

export const AddLearnerDiv = styled.div(
  () => tw`bg-white rounded-lg w-full min-h-[25rem] px-4`
);

export const PaymentWrapper = styled.div(
  () => tw`bg-white rounded-lg w-full px-4 pb-12`
);
