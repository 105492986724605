import {
  SurveyActionsWrapper,
  SurveyButton,
} from "../../../components/LessonPage/LessonSurvey/styled";
import { UserAnswers } from "./Survey";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../store/type";
import { SurveyResult } from "../../../store/types/survey";
import { useAsyncState } from "../../hooks/useAsyncState";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { addSurveyResult } from "../../../store/slices/survey";
import { SurveyResult as SurveyResultType } from "../../../store/types/survey";

type Props = {
  answers: UserAnswers<string, string>;
  onSubmit: (result: SurveyResultType) => void;
  onCancel: () => void;
};

export const SurveyActions = ({ answers, onSubmit, onCancel }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const quiz = useSelector((s: StoreState) => s.survey.quiz);
  const canSubmit = Object.keys(answers).length === quiz?.questions.length;

  const { requestInProgress } = useAsyncState(AsyncActions.SubmitSurveyResult);

  const submit = () => {
    if (!canSubmit || !quiz) return;
    const correctAnswerIds = quiz.questions
      .map((q) => {
        return q.answers.filter((a) => a.correct).map((a) => a.id);
      })
      .flat(2);

    const result: SurveyResult = {
      totalQuestions: quiz.questions.length,
      correctlyAnswered: 0,
      wronglyAnswered: 0,
    };

    Object.values(answers).forEach((a) => {
      if (correctAnswerIds.includes(a)) {
        result.correctlyAnswered++;
      } else {
        result.wronglyAnswered++;
      }
    });

    onSubmit(result);

    dispatch(addSurveyResult({ result, answers: Object.values(answers) }));
  };

  return (
    <SurveyActionsWrapper>
      <SurveyButton onClick={onCancel} invert>
        {t("quiz.cancelButtonLabel")}
      </SurveyButton>
      <SurveyButton
        resPending={!canSubmit || requestInProgress}
        disabled={!canSubmit || requestInProgress}
        onClick={submit}
      >
        {t("quiz.submitButtonLabel")}
      </SurveyButton>
    </SurveyActionsWrapper>
  );
};
