import tw, { styled } from "twin.macro";
import colors from "../../../../../colors.json";

export const Wrapper = styled.div(
  () =>
    tw`xl:h-[45rem] xl:overflow-scroll w-full xl:w-[35.125rem] xl:mr-0 bg-white rounded-lg p-4`
);

export const SelectWrapper = styled.div(
  () => tw`flex flex-col md:flex-row items-center space-y-2 md:space-y-0`
);

export const Heading = styled.h2(() => tw` text-base text-black md:mr-auto`);

export const AvatarDiv = styled.div(
  () => tw`flex items-center justify-center w-7 h-7 mr-1 rounded-full bg-yellow`
);

export const SelectAvatar = styled.img(({ noDiv }: { noDiv?: boolean }) => [
  tw`w-5 h-5`,
  noDiv && tw`w-7 h-7 mr-1`,
]);

export const SelectDiv = styled.div(() => tw`flex items-center`);

export const SelectName = styled.span(() => tw`font-sans text-sm text-black`);

export const SubjectsWrapper = styled.div(
  () => tw`my-4 min-h-[6rem] space-y-8 relative`
);

export const selectStyles = {
  indicatorSeparator: () => ({
    display: "hidden",
  }),
  control: (provided: any) => ({
    ...provided,
    width: "13.4375rem",
    border: `1px solid ${colors["dark-gray"]}`,
    cursor: "pointer",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontColor: colors.black,
  }),

  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontColor: colors.black,
  }),
  option: (provided: any) => ({
    ...provided,
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontColor: colors.black,
    backgroundColor: colors.white,
    cursor: "pointer",
  }),
};
