import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ActionButton } from "./styled";
import insufficientPoints from "../../../assets/gifs/Insufficient-Points.gif";

import { StoreState } from "../../../store/type";
import { useUserHomeRoute } from "../../../common/hooks/useUserHomeRoute";
import { TournamentModal } from "./TournamentModal";

type Props = {
  onClose: () => void;
  subjectCode: boolean | string;
};

export const InsufficientPointsModal = ({ onClose, subjectCode }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const subjects = useSelector((state: StoreState) => state.subjects.subjects);
  const homeRoute = useUserHomeRoute();

  const redirectToLessons = () => {
    const code =
      typeof subjectCode === "string" && !!subjectCode
        ? subjectCode
        : subjects[0]?.code;
    navigate(`${homeRoute}?subjectCode=${code}`);
    onClose();
  };

  const title = t("tournaments.modal.insufficientPoints.title");

  const descriptions = [
    { text: t("tournaments.modal.insufficientPoints.explanation") },
    { text: t("tournaments.modal.insufficientPoints.action") },
  ];

  return (
    <TournamentModal
      onClose={onClose}
      title={title}
      descriptions={descriptions}
      icon={insufficientPoints}
    >
      <ActionButton onClick={redirectToLessons}>
        {t("tournaments.modal.insufficientPoints.buttonLabel")}
      </ActionButton>
    </TournamentModal>
  );
};
