import * as yup from "yup";
import i18n from "../../i18n";

export const resetLinkSchema = () => {
  return yup
    .object({
      email: yup
        .string()
        .trim()
        .required(
          i18n.t(`validation.required`, {
            fieldName: i18n.t("fieldNames.email"),
          })
        )
        .email(i18n.t(`validation.email`)),
    })
    .required();
};

export const changePasswordSchema = () => {
  return yup
    .object({
      password: yup
        .string()
        .required(
          i18n.t(`validation.required`, {
            fieldName: i18n.t("fieldNames.password"),
          })
        )
        .min(
          8,
          i18n.t("validation.min", {
            fieldName: i18n.t("fieldNames.password"),
            suffix: "ov",
            length: 8,
          })
        )
        .max(
          64,
          i18n.t(`validation.max`, { fieldName: i18n.t("fieldNames.password") })
        ),
    })
    .required();
};
