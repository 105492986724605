import { Outlet, useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { TournamentWrapper, Title, ListWrapper } from "./styled";
import {
  ToggleTournamentButton,
  ToggleTournamentsWrapper,
} from "../../../common/components/Tournament/styled";
import { env } from "../../../common/config/env";
import { RoutePath } from "../../../common/consts/route-path";

export const TournamentLists = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <TournamentWrapper>
      <Title>{t("tournaments.title")}</Title>
      <ToggleTournamentsWrapper>
        <Link
          to={`./${
            RoutePath.tournaments.sub.list.sub.upcoming.locale[env.locale]
          }`}
        >
          <ToggleTournamentButton
            selected={pathname.includes(
              RoutePath.tournaments.sub.list.sub.upcoming.locale[env.locale]
            )}
          >
            {t("tournaments.firstTab")}
          </ToggleTournamentButton>
        </Link>
        <Link
          to={`./${
            RoutePath.tournaments.sub.list.sub.history.locale[env.locale]
          }`}
        >
          <ToggleTournamentButton
            second
            selected={pathname.includes(
              RoutePath.tournaments.sub.list.sub.history.locale[env.locale]
            )}
          >
            {t("tournaments.secondTab")}
          </ToggleTournamentButton>
        </Link>
      </ToggleTournamentsWrapper>
      <ListWrapper>
        <Outlet />
      </ListWrapper>
    </TournamentWrapper>
  );
};
