import { FC } from "react";
import tw, { styled } from "twin.macro";

export const TournamentWrapper: FC = ({ children }) => {
  return (
    <div
      className={
        "min-h-app bg-pattern-black flex flex-col gap-8 items-center py-8"
      }
    >
      {children}
    </div>
  );
};

export const Title = styled.h1(
  () => tw`font-dyslexic font-bold text-black text-2xl md:text-4xl`
);

export const ListWrapper = styled.div(
  () =>
    tw`bg-white rounded-2xl border-2 border-light-gray-secondary pt-4 pb-2 px-7 w-10/12 overflow-auto`
);

export const Notice = styled.div(() => tw`text-2xl text-center`);
