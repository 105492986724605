import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Modal } from "../../../common/components/Modal/Modal";
import { StyledError } from "../../../common/components/Error/Error";
import {
  Button,
  DeleteButton,
  ActionWrapper,
  Title,
  Description,
} from "./PaymentStyled";

import { removeLearnerThunk } from "../../../store/thunks/Learner/removeLearner";
import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { useEffect } from "react";

type Props = {
  username: string;
  onClose: () => void;
  learner: string;
};

export const DeleteLearnerModal = ({ username, learner, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const asyncStateKey: any = `${AsyncActions.RemoveLearner}/${username}`;
  const { requestInProgress, error } = useAsyncState(asyncStateKey);

  const handleDeleteLearner = () => {
    if (requestInProgress) return;
    dispatch(removeLearnerThunk(username));
  };

  useEffect(() => {
    if (requestInProgress === false && !error) {
      onClose();
    }
  }, [requestInProgress, error]);

  return (
    <Modal xs>
      <Title>
        {t("register.addLearners.deleteLearnerModal.title", {
          learner,
        })}
      </Title>
      {error ? (
        <StyledError bottomMargin={"1rem"} topMargin={"1rem"} centered>
          {error}
        </StyledError>
      ) : (
        <Description>
          {t("register.addLearners.deleteLearnerModal.description")}
        </Description>
      )}
      <ActionWrapper>
        <Button invert onClick={onClose} resPending={requestInProgress}>
          {t("register.addLearners.deleteLearnerModal.cancel")}
        </Button>
        <DeleteButton
          resPending={requestInProgress}
          onClick={handleDeleteLearner}
        >
          {t("register.addLearners.deleteLearnerModal.remove")}
        </DeleteButton>
      </ActionWrapper>
    </Modal>
  );
};
