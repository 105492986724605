import { ApiRoutes } from "../../common/enums/ApiRoutes";
import { ReduxThunk } from "../type";
import { School } from "../types/school";
import { setBusyAction, setErrorAction } from "../slices/async";
import { AsyncActions } from "../enums/AsyncActions";
import { t } from "i18next";
import { setSchools } from "../slices/school";

export const fetchSchools: (name: string) => ReduxThunk = (name) => {
  return async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({ key: AsyncActions.FetchSchoolsAction, value: true })
    );
    try {
      const res = await client.get<School[]>(ApiRoutes.Schools, {
        params: {
          name,
        },
      });
      dispatch(setSchools(res.data));
    } catch (error) {
      dispatch(
        setErrorAction({
          key: AsyncActions.FetchSchoolsAction,
          value: t("response.getSchools"),
        })
      );
    }
    dispatch(
      setBusyAction({ key: AsyncActions.FetchSchoolsAction, value: false })
    );
  };
};
