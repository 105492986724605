import { yupResolver } from "@hookform/resolvers/yup";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import Button from "../../common/components/Button/Button";
import { StyledError } from "../../common/components/Error/Error";
import Form from "../../common/components/Form/Form";
import Input from "../../common/components/Input/Input";
import { StyledSuccess } from "../../common/components/Success/Success";
import { env } from "../../common/config/env";
import { RoutePath } from "../../common/consts/route-path";
import { useAsyncState } from "../../common/hooks/useAsyncState";
import { AsyncActions } from "../../store/enums/AsyncActions";
import { changePasswordSchema } from "../../views/validation/ChangePasswordValidationSchema";
import {
  ChangePasswordDescription,
  ContentWrapper,
  Heading,
  PageWrapper,
  Wrapper,
} from "./ResetPasswordStyled";
import { resetPassword } from "../../store/thunks/Parent/resetPassword";
import { useTranslation } from "react-i18next";

export const ResetPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [token] = useSearchParams();

  const { requestInProgress, error, success } = useAsyncState(
    AsyncActions.ResetPassword
  );
  const { register, handleSubmit, formState } = useForm({
    mode: "onTouched",
    resolver: yupResolver(changePasswordSchema()),
  });

  const resetPasswordHandler = ({ password }: any) => {
    dispatch(resetPassword({ token: token.get("token"), password }));
  };
  return (
    <PageWrapper>
      <Wrapper>
        <ContentWrapper>
          <Form
            className="w-full"
            onSubmit={handleSubmit(resetPasswordHandler)}
          >
            <Heading>{t("resetPassword.changeHeading")}</Heading>
            <ChangePasswordDescription>
              {t("resetPassword.changeDescription")}
            </ChangePasswordDescription>
            <Input
              required={false}
              name="password"
              type="password"
              registerInput={register}
              bottomMargin="2rem"
              inputWidth="100%"
              placeholderText={t("resetPassword.newPassword")}
              labelText={t("resetPassword.enterNewPassword")}
            />
            {formState.errors?.password && (
              <StyledError bottomMargin="1rem" topMargin="-2.2rem">
                {formState.errors.password.message}
              </StyledError>
            )}
            {error && (
              <StyledError bottomMargin="1rem" centered topMargin="0">
                {error}
              </StyledError>
            )}
            {success && (
              <StyledSuccess bottomMargin="1rem" centered topMargin="1rem">
                {t("resetPassword.resetSuccess")}{" "}
                <Link
                  className="text-dark-blue font-sans"
                  to={RoutePath.parentDashboard.locale[env.locale]}
                >
                  {t("resetPassword.redirect")}
                </Link>{" "}
                {t("resetPassword.loginFirst")}
              </StyledSuccess>
            )}
            <Button
              resPending={requestInProgress}
              buttonWidth="100%"
              invert={false}
            >
              {t("resetPassword.changeHeading")}
            </Button>
          </Form>
        </ContentWrapper>
      </Wrapper>
    </PageWrapper>
  );
};
