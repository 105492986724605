import { useTranslation } from "react-i18next";

import {
  AssistantHelpMessage,
  CloseHelpMessageIcon,
  HelpMessageWrapper,
} from "./styled";

type Props = {
  onClose: () => void;
  onMessageClick: () => void;
};

export const HelpMessage = ({ onClose, onMessageClick }: Props) => {
  const { t } = useTranslation();
  return (
    <HelpMessageWrapper>
      <AssistantHelpMessage onClick={onMessageClick}>
        {t("assistant.help.message")}
      </AssistantHelpMessage>
      <CloseHelpMessageIcon onClick={onClose} />
    </HelpMessageWrapper>
  );
};
