import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../common/components/Button/Button";
import { env } from "../../common/config/env";
import { RoutePath } from "../../common/consts/route-path";
import { logOutTask } from "../../store/thunks/logOutTask";
import {
  BtnDiv,
  ContentWrapper,
  Description,
  Heading,
  Wrapper,
} from "./InactiveSubscriptionStyled";

export const InactiveSubscription = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onRedirectToLogin = () => {
    dispatch(logOutTask());
    navigate(`${RoutePath.login.locale[env.locale]}?showParent=true`);
  };
  return (
    <Wrapper>
      <ContentWrapper>
        <Heading>{t("inactiveSubscription.heading")}</Heading>
        <Description>{t("inactiveSubscription.description1")}</Description>
        <Description last>{t("inactiveSubscription.description2")}</Description>
        <BtnDiv>
          <Button
            onClick={onRedirectToLogin}
            buttonHeight="2.5rem"
            buttonWidth="100%"
          >
            {t("inactiveSubscription.logout")}
          </Button>
        </BtnDiv>
      </ContentWrapper>
    </Wrapper>
  );
};
