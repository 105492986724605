import { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { getItem } from "../../../common/utils/localStorageService";
import { LocalStorageKeys } from "../../../common/enums/LocalStorageKeys";

import { studentValidationSchema } from "../../../views/validation/loginValidationSchema";

import { LabelStyled } from "../../../common/components/Input/Input";
import { StyledError } from "../../../common/components/Error/Error";
import Pin from "../../../common/components/Pin/Pin";
import Button from "../../../common/components/Button/Button";
import Form from "../../../common/components/Form/Form";
import { StyledButton } from "./StudentStyled";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { loginChild } from "../../../store/thunks/user";
import { LoginForm } from "../../../common/types/LoginForm";
import { LoginAvatar } from "../LoginAvatar/LoginAvatar";
import { PinWrapper, Wrapper } from "./StudentStyled";

const RememberedStudentTab = (props: any) => {
  const { t } = useTranslation();
  const { requestInProgress, error } = useAsyncState(
    AsyncActions.LoginStudentAction
  );
  const dispatch = useDispatch();
  const { formState, handleSubmit, setValue, clearErrors } = useForm<LoginForm>(
    {
      mode: "onTouched",
      resolver: yupResolver(studentValidationSchema()),
    }
  );
  const [pinValue, setPinValue] = useState("");

  const onLogin = (data: LoginForm) => {
    dispatch(loginChild(data));
  };

  useEffect(() => {
    if (pinValue.length === 4) {
      clearErrors("password");
    }
    setValue("password", pinValue);
  }, [pinValue]);

  useEffect(() => {
    const username = getItem(LocalStorageKeys.REMEMBERED_CHILD);
    if (username) {
      setValue("username", username);
    }
  }, []);

  return (
    <Wrapper>
      <LoginAvatar
        onTabChange={props.onStudentTabChange}
        username={getItem(LocalStorageKeys.REMEMBERED_CHILD) as string}
      />
      <Form className="w-full" onSubmit={handleSubmit(onLogin)}>
        <PinWrapper>
          <LabelStyled>{t("login.pin.label")}</LabelStyled>
          <Pin
            pinLength={4}
            bottomMargin="1.325rem"
            setPinValue={setPinValue}
          />
          {formState.errors.password && (
            <StyledError topMargin="-0.883rem" bottomMargin="1.325rem">
              {formState.errors.password?.message}
            </StyledError>
          )}
        </PinWrapper>
        {error && (
          <StyledError centered bottomMargin="1.325rem" topMargin="-0.883rem">
            {error}
          </StyledError>
        )}
        <Button
          resPending={requestInProgress}
          bottomMargin="1.325rem"
          invert={false}
        >
          {t("login.button.login")}
        </Button>
      </Form>
      <StyledButton
        data-parent-tab="false"
        invert
        onClick={props.onStudentTabChange}
      >
        {t("login.button.loginWith")}
      </StyledButton>
    </Wrapper>
  );
};

export default RememberedStudentTab;
