import { useTranslation } from "react-i18next";
import { Fragment } from "react";
import { Item, StarIcon, StyledRow, TData } from "./styled";
import starIcon from "../../../assets/common/Lesson-Page/Star.svg";
import dayjs from "../../config/dayjs";
import { Tournament } from "../../../store/types/tournament";
import { ViewTournamentResult } from "./ViewTournamentResult";
import { getDuration } from "./utils";
import { TournamentTableItemComponent } from "./TournamentTableItemComponent";
import { TableItemComponent } from "./TableItemComponent";
import { TournamentResultStatus } from "../../enums/TournamentResultStatus";
import { getParticipationTranslation } from "./utils";

export const HistoryTournamentListItemLg = ({
  endsAt,
  startsAt,
  name,
  points,
  id,
  pointsEarned,
  status,
}: Tournament) => {
  const { t } = useTranslation();
  const duration = getDuration(endsAt, startsAt);

  return (
    <StyledRow>
      <TData>
        <TournamentTableItemComponent name={name} />
      </TData>
      <TData>
        <TableItemComponent
          label={t("tournaments.pastTournamentDetails.duration")}
          value={duration}
        />
      </TData>
      <TData>
        <TableItemComponent
          label={t("tournaments.pastTournamentDetails.endedAt")}
          value={dayjs(startsAt).format("DD MMM/HH:mm")}
        />
      </TData>
      <TData>
        <TableItemComponent
          label={t("tournaments.pastTournamentDetails.rewards")}
          value={
            <Fragment>
              <StarIcon src={starIcon} />
              {points}
            </Fragment>
          }
        />
      </TData>
      <TData>
        <TableItemComponent
          label={t("tournaments.pastTournamentDetails.gainedPoints")}
          value={
            <Fragment>
              {status === TournamentResultStatus.Completed && (
                <StarIcon src={starIcon} />
              )}
              {getParticipationTranslation(status, pointsEarned, t)}
            </Fragment>
          }
        />
      </TData>
      <TData>
        <Item>
          <ViewTournamentResult id={id} />
        </Item>
      </TData>
    </StyledRow>
  );
};
