import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import assistantImage from "../../../../assets/common/Learner-Home/assistant-popup.png";
import { Modal } from "../../../../common/components/Modal/Modal";
import { PopupCloseStyled } from "../../../../common/components/Modal/ModalStyled";
import {
  ActionButton,
  ActionWrapper,
  ModalContent,
  ModalHeader,
  ContentWrapper,
  SideContent,
  CenterContent,
  AvatarStyled,
  ModalAvatarWrapper,
  OuterWrapper,
  Description,
  Username,
  AssistantPopupImage,
} from "./LearnerAvatarStyled";
import { AvatarWithSelectedItems } from "../../../../common/components/Avatar/AvatarWithSelectedItems";
import { StoreState } from "../../../../store/type";

type Props = {
  onClose: () => void;
};

export const AssistantUsageModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const { info } = useSelector((s: StoreState) => s.user);

  const name = `${info.firstName} ${info.lastName}`;

  return (
    <Modal>
      <ModalHeader>
        <PopupCloseStyled onClick={onClose}>&#10006;</PopupCloseStyled>
      </ModalHeader>
      <ContentWrapper>
        <ModalContent>
          <SideContent>
            <AssistantPopupImage src={assistantImage}></AssistantPopupImage>
          </SideContent>
          <CenterContent>
            <OuterWrapper>
              <ModalAvatarWrapper>
                <AvatarStyled>
                  <AvatarWithSelectedItems />
                </AvatarStyled>
              </ModalAvatarWrapper>
            </OuterWrapper>
            <div>
              <Description>
                {t("learnerHome.assistantModal.hi")}
                <Username>{name}</Username>!
              </Description>
              <br />
              <Description>
                {t("learnerHome.assistantModal.description")}
              </Description>
            </div>
          </CenterContent>
        </ModalContent>
        <ActionWrapper>
          <ActionButton onClick={onClose}>
            {t("learnerHome.assistantModal.action")}
          </ActionButton>
        </ActionWrapper>
      </ContentWrapper>
    </Modal>
  );
};
