import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getUpcomingTournaments } from "../../../store/thunks/Learner/tournament";
import { StoreState } from "../../../store/type";
import { Title, EventsWrapper } from "./styled";
import { FilteredEvents } from "./FilteredEvents";
import { filteredEventProps } from "./data";
import { useRefresh } from "../../../common/hooks/useRefresh";

export const Events = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useRefresh(15000);
  const tournaments = useSelector(
    (state: StoreState) => state.tournaments.upcoming
  );

  useEffect(() => {
    dispatch(getUpcomingTournaments());
  }, []);

  return (
    <EventsWrapper>
      <Title>
        {t(
          `learnerHome.events.${tournaments.length > 0 ? "title" : "noEvents"}`
        )}
      </Title>
      {tournaments.length > 0 &&
        filteredEventProps.map((d) => <FilteredEvents key={d.label} {...d} />)}
    </EventsWrapper>
  );
};
