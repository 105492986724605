import { useState } from "react";
import { Elements } from "react-stripe-elements";

import { PaymentWrapper } from "./PaymentStyled";

import ByCard from "./ByCard";
import { ByCode } from "./ByCode";
import { MethodButton, MethodsWrapper } from "./PaymentStyled";
import { EstimatedBilling } from "./EstimatedBilling";
import { EstimatedBillingForm } from "../../../common/types/EstimatedBillingForm";
import { useTranslation } from "react-i18next";
import { Learner } from "../../../store/types/learners";

type Props = {
  sm?: boolean;
  renewal?: boolean;
  learner?: Learner;
};
const PaymentC = ({ sm, renewal, learner }: Props) => {
  const [method, setMethod] = useState(1);
  const [code, setCode] = useState("");
  const [subscriptions, setSubscriptions] = useState<EstimatedBillingForm>([]);
  const { t } = useTranslation();

  const fonts = [
    { cssSrc: "https://fonts.googleapis.com/css2?family=Inter&display=swap" },
  ];
  return (
    <Elements fonts={fonts}>
      <>
        <MethodsWrapper>
          <MethodButton
            onClick={() => {
              setMethod(1);
            }}
            selected={method === 1}
          >
            {t("register.payment.creditCard")}
          </MethodButton>
          <MethodButton
            onClick={() => {
              setMethod(2);
            }}
            selected={method === 2}
            second
          >
            {t("register.payment.paymentCode")}
          </MethodButton>
        </MethodsWrapper>

        <PaymentWrapper sm={sm}>
          {method === 1 ? (
            <ByCard
              sm={sm}
              subscriptions={subscriptions}
              renewal={renewal}
              setCode={setCode}
            />
          ) : (
            <ByCode renewal={renewal} sm={sm} subscriptions={subscriptions} />
          )}
          <EstimatedBilling
            sm={sm}
            renewal={renewal}
            learner={learner}
            subscriptions={subscriptions}
            setSubscriptions={setSubscriptions}
            code={code}
            showPeriod={method === 1}
          />
        </PaymentWrapper>
      </>
    </Elements>
  );
};

export const Payment = (p: Props) => {
  return <PaymentC {...p} />;
};
