import { PointInfoDiv, Points, StarIcon, Task } from "./LessonCompletedStyled";
import starIcon from "../../../assets/common/Lesson-Page/Star-Small.svg";

type Props = {
  points: number;
  label: string;
  noMargin?: boolean;
};

export const EarnedPoints = ({ points, label, noMargin }: Props) => {
  return (
    <PointInfoDiv noMargin={noMargin}>
      <Points>
        <StarIcon src={starIcon} />
        {points}
      </Points>
      <Task>{label}</Task>
    </PointInfoDiv>
  );
};
