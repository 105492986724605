import { useTranslation } from "react-i18next";

export const useMonthName = () => {
  const { t } = useTranslation();

  const monthNames = [
    t(`monthNames.jan`),
    t(`monthNames.feb`),
    t(`monthNames.mar`),
    t(`monthNames.apr`),
    t(`monthNames.may`),
    t(`monthNames.jun`),
    t(`monthNames.jul`),
    t(`monthNames.aug`),
    t(`monthNames.sep`),
    t(`monthNames.oct`),
    t(`monthNames.nov`),
    t(`monthNames.dec`),
  ];
  return monthNames;
};
