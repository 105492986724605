import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { EstimatedBillingRequest } from "../../../common/types/EstimateBillingRequest";
import mapCodeError from "../../../common/utils/mapCodeError";
import { AsyncActions } from "../../enums/AsyncActions";
import { setNotification } from "../../slices/async";
import {
  setCheckoutAttemptStatusAction,
  setPaymentErrorAction,
  setCheckoutRenewalAttemptStatusAction,
} from "../../slices/payment";
import { PaymentRequestStatus } from "../../types/payment";
import { Exception } from "../../../common/enums/Exceptions";
import i18n from "../../../i18n";
import { updateLearners } from "../../slices/learners";
import { addSubscriptions } from "../../slices/subscriptions";
import { ReduxThunk } from "../../type";
import { Learner } from "../../types/learners";
import { Subscription } from "../../types/subscriptions";

const getError = (
  message: Exception | string[] | string | undefined
): string => {
  switch (message) {
    case Exception.CodeSubscriptionsMismatch:
      return i18n.t("response.codeSubscriptionsMismatch");
    case Exception.InvalidCode:
      return i18n.t("response.invalidCode");
    case Exception.SubscriptionAlreadyRenewed:
      return i18n.t("response.subscriptionAlreadyRenewed");
    default:
      return i18n.t("response.applyCode");
  }
};

export const checkoutByCodeTask: (
  code: string,
  subscriptions: EstimatedBillingRequest[],
  renewal: boolean,
  learners?: Learner[]
) => ReduxThunk =
  (code, subscriptions, renewal) =>
  async (dispatch, _, { client }) => {
    try {
      renewal
        ? dispatch(
            setCheckoutRenewalAttemptStatusAction(PaymentRequestStatus.Pending)
          )
        : dispatch(
            setCheckoutAttemptStatusAction(PaymentRequestStatus.Pending)
          );
      dispatch(setPaymentErrorAction(""));

      const apiUrl = !renewal
        ? ApiRoutes.SubscriptionCheckoutByCode
        : `${ApiRoutes.SubscriptionRenewByCode}${subscriptions[0].username}`;

      const res = await client.post<Learner[]>(apiUrl, {
        code: code,
        subscriptions,
      });

      const error = (res as any).error;

      if (error) {
        dispatch(
          renewal
            ? setCheckoutRenewalAttemptStatusAction(PaymentRequestStatus.Idle)
            : setCheckoutAttemptStatusAction(PaymentRequestStatus.Idle)
        );
        dispatch(setPaymentErrorAction(mapCodeError(error.message[0])));
        return;
      }

      dispatch(
        renewal
          ? setCheckoutRenewalAttemptStatusAction(
              PaymentRequestStatus.Successful
            )
          : setCheckoutAttemptStatusAction(PaymentRequestStatus.Successful)
      );
      dispatch(
        setNotification({
          key: renewal
            ? AsyncActions.RenewByCodeSuccessful
            : AsyncActions.PayByCodeSuccessful,
          value: res.data.reduce((acc, cur) => `${acc}&${cur.id}`, ""),
        })
      );
      dispatch(updateLearners(res.data));
      dispatch(
        addSubscriptions(
          res.data.flatMap((l) => l.subscriptions as Subscription[])
        )
      );
    } catch (e: any) {
      if (!renewal) {
        dispatch(setCheckoutAttemptStatusAction(PaymentRequestStatus.Idle));
      } else {
        dispatch(
          setCheckoutRenewalAttemptStatusAction(PaymentRequestStatus.Idle)
        );
      }

      dispatch(
        setPaymentErrorAction(
          Array.isArray(e.response?.data?.message)
            ? getError(e.response.data.message[0])
            : getError(e.response.data.message)
        )
      );
    }
  };
