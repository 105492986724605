import tw, { styled } from "twin.macro";
import { Link } from "react-router-dom";
import { ButtonStyled } from "../../../common/components/Button/Button";

export const ModalInnerWrapper = styled.div(
  () => tw`flex items-center w-full flex-col p-9`
);

export const PopupHeadingStyled = styled.h3(
  () => tw`text-black-secondary font-dyslexic text-[1.375rem] mb-2`
);

export const PopupParagraphStyled = styled.p(
  () =>
    tw`text-sm font-sans text-center w-full text-black-secondary opacity-60 mb-6`
);

export const PointsWrapperStyled = styled.div<{ hasQuizPoints: boolean }>(
  ({ hasQuizPoints }) => [
    tw`bg-yellow flex flex-col lg:flex-row items-center rounded-lg px-9 py-7 mb-6 w-full justify-center max-w-[40rem]`,
    hasQuizPoints && tw`max-w-[45rem]`,
  ]
);

export const PointInfoDiv = styled.div<{ noMargin?: boolean }>(
  ({ noMargin }) => [
    tw`flex items-center flex-col lg:mr-5`,
    noMargin && tw`lg:mr-0`,
  ]
);

export const StarIcon = styled.img(() => tw`w-3.5 mr-1`);

export const Points = styled.span(
  () =>
    tw`font-sans text-black-secondary text-center flex items-center text-lg font-bold`
);

export const Task = styled.span(
  () =>
    tw`font-sans w-full text-sm text-black-secondary opacity-60 max-h-[1rem] text-center`
);

export const Operator = styled.span(
  () => tw`font-sans lg:mr-5 text-4xl text-black-secondary`
);

export const CupDiv = styled.div(() => tw`flex w-full justify-center mb-8`);

export const CupImage = styled.img(() => tw`min-w-[3.5rem]`);

export const TotalDiv = styled.div(() => tw`flex flex-col ml-2`);
export const TotalHeading = styled.span(
  () => tw`font-medium text-gold text-sm font-sans `
);

export const TotalPoints = styled.span(
  () => tw`font-bold text-black-secondary font-sans text-5xl`
);

export const BtnDiv = styled.div(
  () =>
    tw`flex flex-col space-y-8 md:space-y-0 md:flex-row md:space-x-2 w-full max-w-[29rem]  items-center`
);

export const QuizParagraph = styled.h2(
  () => tw`font-sans font-bold text-lg mb-8`
);

export const StyledLink = styled(Link)(() => tw``);

export const Button = styled(ButtonStyled)<{ full?: boolean }>(({ full }) => [
  tw`w-[14rem]`,
  full && tw`w-full`,
]);
