import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "../../../store/type";
import { fetchClassrooms } from "../../../store/thunks/fetchClassrooms";
import { SelectComponent } from "../../../common/components/Select/Select";
import { useEffect } from "react";

type SelectProps = {
  styles: object;
  placeholder: string;
  options?: object[];
  components?: object;
};

type Props = {
  value: string;
  onChange: (val: any) => any;
  onBlur: () => void;
} & SelectProps;

export const SelectClassroom = ({ onChange, value, ...rest }: Props) => {
  const selectClassroomValue = (options: any, value: string) => {
    return options.find(
      (option: { id: string; label: string }) => option.id === value
    );
  };
  const dispatch = useDispatch();
  const classrooms = useSelector(
    (state: StoreState) => state.schoolsState.classrooms
  );

  useEffect(() => {
    dispatch(fetchClassrooms());
  }, []);

  const getClassroomName = (title: string) => {
    const match = title.match(/\d{1,}/);
    if (!match) return title;
    return `${match[0]}.`;
  };

  return (
    <SelectComponent
      {...rest}
      onChange={onChange}
      backspaceRemovesValue
      isClearable
      value={
        selectClassroomValue(classrooms, value) &&
        selectClassroomValue(classrooms, value).value
      }
      options={classrooms.map(({ title, id }) => ({
        value: id,
        label: getClassroomName(title),
      }))}
    />
  );
};
