import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { Exception } from "../../../common/enums/Exceptions";
import { AsyncActions } from "../../enums/AsyncActions";
import { setBusyAction, setErrorAction, setSuccess } from "../../slices/async";
import { setUserInfo } from "../../slices/user";
import { ReduxThunk } from "../../type";
import i18n from "../../../i18n";

export const patchUsername: (username: string) => ReduxThunk = (username) => {
  return async (dispatch, _, { client }) => {
    try {
      dispatch(setBusyAction({ key: AsyncActions.PatchUsername, value: true }));
      await client.patch(ApiRoutes.LearnerProfile, {
        username,
      });

      dispatch(setUserInfo({ username: username }));
      dispatch(setSuccess({ key: AsyncActions.PatchUsername, value: true }));
    } catch (e: any) {
      if (e.response?.data?.message === Exception.UsernameAlreadyExists) {
        dispatch(
          setErrorAction({
            key: AsyncActions.PatchUsername,
            value: i18n.t("profileSetup.gameName.errors.usernameTaken"),
          })
        );
      } else if (
        e.response?.data?.message === Exception.InvalidLearnerUsername
      ) {
        dispatch(
          setErrorAction({
            key: AsyncActions.PatchUsername,
            value: i18n.t("profileSetup.gameName.errors.invalid"),
          })
        );
      } else if (
        e.response?.data?.message === Exception.FailedToUpdateUsername
      ) {
        dispatch(
          setErrorAction({
            key: AsyncActions.PatchUsername,
            value: i18n.t("profileSetup.gameName.errors.failed"),
          })
        );
      } else {
        dispatch(
          setErrorAction({
            key: AsyncActions.PatchUsername,
            value: i18n.t("response.500"),
          })
        );
      }
    }
    dispatch(setBusyAction({ key: AsyncActions.PatchUsername, value: false }));
  };
};
