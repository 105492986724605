import { ReduxThunk } from "../../type";
import { setBusyAction } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { TournamentResult } from "../../types/tournament";
import { setTournamentResult } from "../../slices/tournament";
import { sortTournamentResults } from "../../utils/sortTournamentResults";

export const fetchTournamentResult: (tournamentId: string) => ReduxThunk =
  (tournamentId) =>
  async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({ key: AsyncActions.FetchTournamentResult, value: true })
    );

    try {
      const res = await client.get<TournamentResult[]>(
        `${ApiRoutes.TournamentResult}/${tournamentId}`
      );
      dispatch(setTournamentResult(sortTournamentResults(res.data)));
    } catch (e) {
      console.log(e);
    }
    dispatch(
      setBusyAction({ key: AsyncActions.FetchTournamentResult, value: false })
    );
  };
