import tw, { styled } from "twin.macro";

export const BannerWrapperStyled = styled.div(
  () =>
    tw`flex flex-col lg:flex-row w-full lg:w-[unset] bg-light-gray-secondary items-center px-4 lg:px-38 min-h-[3.5rem]`
);

export const BackBtnDiv = styled.div(
  tw`flex items-center mb-4 mt-6 lg:mt-0 lg:mb-0 w-full lg:w-[unset]`
);

export const BackBtn = styled.button(() => tw`lg:mr-6 w-8 lg:w-[unset]`);

export const BackIcon = styled.img(() => tw`min-w-[0.5rem]`);

export const SubjectNameDiv = styled.div(
  () =>
    tw`flex w-full lg:w-[unset] -ml-4 items-center justify-center lg:justify-start cursor-pointer`
);

export const SubjectSpan = styled.span(() => tw`font-sans text-gold mr-1.5`);

export const SubjectName = styled.span(
  () => tw`font-sans text-black font-semibold`
);

export const LessonName = styled.h1(
  () =>
    tw`mx-auto font-sans text-black-secondary text-xl font-semibold text-center mb-4 lg:mb-0`
);

export const PointsDivStyled = styled.div(
  () => tw`flex mb-6 lg:mb-0 items-center`
);

export const InnerPointsDivStyled = styled.div(
  () => tw`flex justify-center bg-black rounded-full px-3 py-2 items-center`
);

export const PointsImageStyled = styled.img(() => tw`mr-1`);

export const PointsStyled = styled.span(
  () => tw`text-light-gold text-sm font-bold font-sans`
);

export const PointsHeading = styled.h6(
  () => tw`font-sans text-black mr-2 font-medium text-sm`
);

export const TotalPointStyled = styled.span(
  () => tw`font-sans text-dark-gray text-sm`
);

export const SurveyPointsWrapper = styled(TotalPointStyled)(
  () => tw`whitespace-pre`
);
