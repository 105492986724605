import { injectStripe, ReactStripeElements } from "react-stripe-elements";
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
} from "react-stripe-elements";
import Input, {
  LabelStyled,
  SpanStyled,
} from "../../../../../../common/components/Input/Input";
import { Modal } from "../../../../../../common/components/Modal/Modal";
import {
  DetailsCardSectionWrapper,
  HeadingStyled,
  InputWrapper,
  stripeClasses,
  stripeStyles,
} from "../../../../../Register/Payment/PaymentStyled";
import { ContentWrapper, Heading, Wrapper } from "./AddMethodModalStyled";
import { useTranslation } from "react-i18next";
import { PaymentFormFieldName } from "../../../../../../common/types/PaymentForm";
import { PopupCloseStyled } from "../../../../../../common/components/Modal/ModalStyled";
import Button from "../../../../../../common/components/Button/Button";
import { usePayments } from "../../../../../../store/hooks/usePayments";
import { paymentMethodSchema } from "../../../../../../views/validation/paymentValidationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { StoreState } from "../../../../../../store/type";
import { StyledError } from "../../../../../../common/components/Error/Error";
import Form from "../../../../../../common/components/Form/Form";
import { useAsyncState } from "../../../../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../../../../store/enums/AsyncActions";
import { useEffect } from "react";
import { fetchPaymentMethod } from "../../../../../../store/thunks/Parent/fetchPaymentMethod";
const AddMethodModal = ({
  triggerModal,
  stripe,
  elements,
}: {
  triggerModal: () => void;
  stripe: ReactStripeElements.StripeProps | null;
  elements: stripe.elements.Elements | null;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { requestInProgress, error, success, clearSuccess } = useAsyncState(
    AsyncActions.PostPaymentMethod
  );
  const { username, firstName, lastName } = useSelector(
    (state: StoreState) => state.user.info
  );

  const { formState, handleSubmit, register } = useForm<{
    nameOnCard: string;
    email: string;
  }>({
    mode: "onTouched",
    resolver: yupResolver(paymentMethodSchema()),
    defaultValues: {
      nameOnCard: firstName && lastName ? `${firstName} ${lastName}` : "",
      email: username ? username : "",
    },
  });

  const { postPaymentMethod } = usePayments(stripe, elements);
  const submitHandler = (data: { nameOnCard: string; email: string }) => {
    dispatch(postPaymentMethod(data));
  };

  useEffect(() => {
    if (success) {
      dispatch(fetchPaymentMethod());
      triggerModal();
    }
  }, [success]);

  useEffect(() => {
    return () => {
      clearSuccess;
    };
  }, []);

  if (!stripe || !elements) return null;

  return (
    <Modal lg>
      <Form className="w-full" onSubmit={handleSubmit(submitHandler)}>
        <Wrapper>
          <PopupCloseStyled onClick={triggerModal}>&#10006;</PopupCloseStyled>
          <ContentWrapper>
            <Heading>
              {t("parentDashboard.subscriptions.changeMethodBtn")}
            </Heading>
            <HeadingStyled>
              {t("register.payment.byCard.details")}
            </HeadingStyled>
            <DetailsCardSectionWrapper>
              <InputWrapper full>
                <LabelStyled>
                  {t("register.payment.byCard.label.creditCardNumber")}
                  <SpanStyled>*</SpanStyled>
                </LabelStyled>

                <CardNumberElement
                  classes={stripeClasses}
                  style={stripeStyles}
                />
              </InputWrapper>
              <InputWrapper>
                <LabelStyled>
                  {t("register.payment.byCard.label.validDuration")}
                  <SpanStyled>*</SpanStyled>
                </LabelStyled>

                <CardExpiryElement
                  classes={stripeClasses}
                  style={stripeStyles}
                />
              </InputWrapper>
              <InputWrapper>
                <LabelStyled>
                  {t("register.payment.byCard.label.cvv")}
                  <SpanStyled>*</SpanStyled>
                </LabelStyled>

                <CardCVCElement classes={stripeClasses} style={stripeStyles} />
              </InputWrapper>
            </DetailsCardSectionWrapper>

            <InputWrapper mb="1.5rem" full>
              <Input
                type="text"
                inputWidth="100%"
                registerInput={register}
                placeholderText={t("register.payment.byCard.placeholders.name")}
                name={PaymentFormFieldName.NameOnCard}
                required={false}
                labelText={t("register.payment.byCard.label.nameOnCard")}
              />
              {formState.errors?.nameOnCard?.message && (
                <StyledError topMargin="0" bottomMargin="0">
                  {formState.errors.nameOnCard.message}
                </StyledError>
              )}
            </InputWrapper>
            <InputWrapper mb="1.5rem" full>
              <Input
                type="text"
                inputWidth="100%"
                registerInput={register}
                placeholderText={t(
                  "register.payment.byCard.placeholders.billingEmail"
                )}
                name={PaymentFormFieldName.Email}
                required={false}
                labelText={t(
                  "register.payment.byCard.placeholders.billingEmail"
                )}
              />
              {formState.errors?.email?.message && (
                <StyledError topMargin="0" bottomMargin="0">
                  {formState.errors.email.message}
                </StyledError>
              )}
            </InputWrapper>
            {error && (
              <StyledError centered topMargin="0" bottomMargin="0.5rem">
                {error}
              </StyledError>
            )}
            <Button
              resPending={requestInProgress}
              invert={false}
              buttonWidth="100%"
            >
              {t("parentDashboard.subscriptions.changeMethodBtn")}
            </Button>
          </ContentWrapper>
        </Wrapper>
      </Form>
    </Modal>
  );
};

export default injectStripe<{
  triggerModal: () => void;
}>(({ stripe, elements, triggerModal }) => (
  <AddMethodModal
    stripe={stripe}
    elements={elements}
    triggerModal={triggerModal}
  />
));
