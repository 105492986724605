import React from "react";
import * as LessonsPopupStyled from "./LessonsPopupStyled";
import alarmIcon from "../../../assets/common/Learner-Home/Lessons Popup/Alarm.svg";
import starIcon from "../../../assets/common/Learner-Home/Lessons Popup/Star.svg";
import { LessonInfo } from "../../../store/types/lessons";
import { useTranslation } from "react-i18next";
import Button from "../../../common/components/Button/Button";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeSubjectState } from "../../../store/slices/subject";
import { addDepository } from "../../../store/thunks/Learner/lesson";
import { getRoute } from "../../../common/utils/getRoute";
import { RoutePath } from "../../../common/consts/route-path";
import { env } from "../../../common/config/env";

type Props = {
  lesson: LessonInfo;
  subjectCode: string;
  isMobileView: boolean;
};

export const LessonInfoDiv = ({ lesson, subjectCode, isMobileView }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateHandler = (subjectCode: string, lessonCode: string) => {
    if (!lesson.depository && lesson.id) {
      dispatch(addDepository(lesson.id));
    }
    dispatch(removeSubjectState());
    navigate(
      getRoute(
        {
          subjectCode,
          lessonCode,
        },

        RoutePath.lessonPage.locale[env.locale]
      )
    );
  };

  return (
    <React.Fragment>
      {!isMobileView && (
        <LessonsPopupStyled.LessonName>
          {lesson.name}
        </LessonsPopupStyled.LessonName>
      )}
      <LessonsPopupStyled.ValuesDiv>
        <LessonsPopupStyled.ValuesIcon src={starIcon} />
        <LessonsPopupStyled.PointsSpan>
          {lesson.points}{" "}
          {t("learnerHome.pointsHeading", {
            suffix:
              lesson.points === 1
                ? "a"
                : lesson.points === 2
                ? "i"
                : lesson.points === 3 || lesson.points === 4
                ? "e"
                : "",
          })}
        </LessonsPopupStyled.PointsSpan>
        <LessonsPopupStyled.ValuesIcon src={alarmIcon} />
        <LessonsPopupStyled.TimeSpan>
          {lesson.lessonTime ? lesson.lessonTime : 23} {t("learnerHome.time")}
        </LessonsPopupStyled.TimeSpan>
      </LessonsPopupStyled.ValuesDiv>
      <LessonsPopupStyled.LessonInfoHeading>
        {t("learnerHome.lessonInfoHeading")}
      </LessonsPopupStyled.LessonInfoHeading>
      <LessonsPopupStyled.LessonInfo
        dangerouslySetInnerHTML={{
          __html: lesson.description,
        }}
      />
      <Button
        invert={false}
        buttonWidth="16rem"
        bottomMargin={isMobileView ? "2rem" : "6rem"}
        onClick={() => {
          navigateHandler(subjectCode, lesson.code);
        }}
      >
        {t("learnerHome.startLearningBtn")}
      </Button>
    </React.Fragment>
  );
};
