import tw, { styled, css } from "twin.macro";
import colors from "../../../colors.json";

export const WrapperDiv = styled.div(
  () =>
    tw`relative h-4 w-full bg-dark-gold rounded-full mt-4 mb-4 transition-all ease-out duration-300`
);

export const InnerDiv = styled.div(({ width }: { width: string }) => [
  tw`h-full rounded-full`,
  css`
    width: ${width};
    background: repeating-linear-gradient(
      137deg,
      ${colors.gold},
      ${colors.gold} 0.85rem,
      ${colors.white} 0.85rem,
      ${colors.white} 1rem
    );
  `,
]);
