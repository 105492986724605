import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "rc-dropdown";
import Menu from "rc-menu";
import "rc-dropdown/assets/index.css";

import logo from "../../../assets/common/logo.svg";
import icon from "../../../assets/common/hamburger-icon.png";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";
import { RoutePath } from "../../consts/route-path";
import { env } from "../../config/env";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../store/type";
import { logOutTask } from "../../../store/thunks/logOutTask";
import { MenuItem, PortalIcon, PortalLink } from "./HeaderStyled";

interface DropdownProps {
  trigger: any;
  closeOnSelect: boolean;
  overlay: any;
  animation: string;
}

export const NotLoggedIn = () => {
  const { t } = useTranslation();
  const isUserLoggedIn = useSelector(
    (state: StoreState) => state.user.isLoggedIn
  );
  const dispatch = useDispatch();

  const logOut = () => {
    // hack to prevent flash showing of second menu
    setTimeout(() => dispatch(logOutTask()), 200);
  };

  const overlay = (
    <Menu selectable={false} className="z-20 w-32 sm:hidden">
      {isUserLoggedIn ? (
        <MenuItem key="1">
          <div onClick={logOut}>{t("login.button.logout")}</div>
        </MenuItem>
      ) : (
        <>
          <MenuItem key="1">
            <Link to={RoutePath.login.locale[env.locale]}>
              {t("login.button.login")}
            </Link>
          </MenuItem>
          <MenuItem key="2">
            <Link to={RoutePath.register.locale[env.locale]}>
              {t("login.button.register")}
            </Link>
          </MenuItem>
        </>
      )}
    </Menu>
  );

  const dropdownProps: DropdownProps = {
    trigger: ["click"],
    closeOnSelect: true,
    overlay: overlay,
    animation: "slide-up",
  };
  return (
    <React.Fragment>
      <PortalLink target={"_blank"} href={env.yunoPortal} rel="noreferrer">
        <PortalIcon alt="yuno-banner-icon" src={logo} className="h-12" />
      </PortalLink>
      <div className="space-x-2 hidden sm:inline-block">
        {isUserLoggedIn ? (
          <Button buttonWidth={"8.75rem"} onClick={logOut} disabled>
            {t("login.button.logout")}
          </Button>
        ) : (
          <>
            <Link to={RoutePath.register.locale[env.locale]}>
              <Button invert buttonWidth={"8.75rem"} disabled>
                {t("login.button.register")}
              </Button>
            </Link>
            <Link to={RoutePath.login.locale[env.locale]}>
              <Button invert={false} buttonWidth={"8.75rem"}>
                {t("login.button.login")}
              </Button>
            </Link>
          </>
        )}
      </div>
      <Dropdown {...dropdownProps}>
        <img
          alt="dropdown-icon"
          src={icon}
          className="cursor-pointer w-10 h-12 sm:hidden"
        />
      </Dropdown>
    </React.Fragment>
  );
};
