import tw, { styled, css } from "twin.macro";
import { ButtonStyled } from "../../../../common/components/Button/Button";

export const AvatarStyled = styled.div(
  () => tw`w-32 h-32 md:w-32 md:h-32 lg:w-32 lg:h-32`
);

export const Wrapper = styled.div(
  () =>
    tw`absolute bottom-0 left-2/4 -translate-x-2/4 translate-y-[80%] md:translate-y-2/4 rounded-full bg-white p-1`
);

export const ChargeIcon = styled.img(
  () => tw`absolute top-0 left-2/4 -translate-x-2/4 -translate-y-2/4`
);

export const AvatarMessageWrapper = styled.div(() => tw`relative`);

export const AvatarMessage = styled.div(
  () =>
    tw`absolute -left-2/4 md:left-full -top-2/4 md:top-1/4 w-[15rem] md:w-[18rem] z-10`
);

export const ModalHeader = styled.div(
  () => tw`h-6 w-full text-black flex flex-row-reverse p-4`
);

export const ContentWrapper = styled.div(
  () => tw`flex justify-between flex-col h-full pb-10 w-full px-4 md:px-0`
);

export const ModalContent = styled.div(
  () =>
    tw`w-full flex flex-col-reverse items-center md:items-start md:flex-row-reverse`
);

export const CenterContent = styled.div(
  () => tw`max-w-[20rem] flex flex-col items-center gap-4`
);

export const SideContent = styled.div(
  () => tw`w-full md:w-4/12 h-full p-0 md:pt-8 md:pl-10`
);

export const ActionWrapper = styled.div(() => tw`flex justify-around p-4`);

export const ActionButton = styled(ButtonStyled)(() => tw`w-60`);

export const ModalAvatarWrapper = styled.div(() => tw`rounded-full bg-yellow`);

export const OuterWrapper = styled.span(() => [
  tw`flex justify-center items-center p-1 rounded-full bg-white shadow-xl`,
  css`
    width: fit-content;
  `,
]);

export const Description = styled.p(
  () => tw`text-center whitespace-normal md:whitespace-pre text-xl`
);

export const Username = styled.span(() => tw`text-gold`);

export const AssistantPopupImage = styled.img(
  () => tw`m-auto md:m-0 h-[18rem] w-auto`
);
