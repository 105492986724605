import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  MethodInfo,
  InfoDiv,
  MethodName,
  Wrapper,
  ButtonDiv,
} from "./PaymentMethodStyled";
import Button from "../../../../../common/components/Button/Button";
import React, { useEffect, useState } from "react";
import { fetchPaymentMethod } from "../../../../../store/thunks/Parent/fetchPaymentMethod";
import { StoreState } from "../../../../../store/type";
import AddMethodModal from "./AddMethodModal/AddMethodModal";
import { Elements } from "react-stripe-elements";
import { useAsyncState } from "../../../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../../../store/enums/AsyncActions";
import { Spinner } from "../../../../../common/components/Spinner/Spinner";
import { StyledError } from "../../../../../common/components/Error/Error";

export const PaymentMethod = () => {
  const fonts = [
    { cssSrc: "https://fonts.googleapis.com/css2?family=Inter&display=swap" },
  ];

  const [loadingState, setLoadingState] = useState<boolean>(true);

  const { requestInProgress, error } = useAsyncState(
    AsyncActions.FetchPaymentMethods
  );
  const [addPaymentModalOpen, setAddPaymentModalOpen] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const { methods: paymentMethods } = useSelector(
    (state: StoreState) => state.payments
  );
  const { t } = useTranslation();

  const triggerModal = () => {
    setAddPaymentModalOpen((pre) => !pre);
  };

  useEffect(() => {
    dispatch(fetchPaymentMethod());
    setLoadingState(false);
  }, []);
  return (
    <Wrapper>
      {loadingState || requestInProgress ? (
        <Spinner size="sm" />
      ) : error ? (
        <StyledError centered topMargin="auto" bottomMargin="auto">
          {error}
        </StyledError>
      ) : (
        <React.Fragment>
          {paymentMethods && paymentMethods.length > 0 ? (
            <React.Fragment>
              <InfoDiv>
                <MethodName>
                  {`${paymentMethods[0].card.brand} ${t(
                    "parentDashboard.subscriptions.cardName"
                  )}`}
                </MethodName>
                <MethodInfo>{`${t(
                  "parentDashboard.subscriptions.cardEndingWith"
                )} ${paymentMethods[0].card.last4}`}</MethodInfo>
              </InfoDiv>
            </React.Fragment>
          ) : (
            <InfoDiv>
              <MethodName>
                {t("parentDashboard.subscriptions.noMethod")}
              </MethodName>
            </InfoDiv>
          )}

          <ButtonDiv>
            <Button
              onClick={triggerModal}
              padding="2rem 1rem"
              invert
              buttonHeight="2.5rem"
              buttonWidth="100%"
            >
              {paymentMethods && paymentMethods.length > 0
                ? t("parentDashboard.subscriptions.changeMethodBtn")
                : t("parentDashboard.subscriptions.addPaymentMethod")}
            </Button>
          </ButtonDiv>
          {addPaymentModalOpen && (
            <Elements fonts={fonts}>
              <AddMethodModal triggerModal={triggerModal} />
            </Elements>
          )}
        </React.Fragment>
      )}
    </Wrapper>
  );
};
