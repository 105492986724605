import { FC } from "react";
import PinInput from "react-pin-input";
import colors from "../../../colors.json";

const Input = PinInput as unknown as FC<any>;

const Pin = (props: any) => {
  return (
    <Input
      onChange={props.setPinValue}
      onComplete={props.setPinValue}
      type="numeric"
      length={props.pinLength}
      style={{
        width: "100%",
        display: "grid",
        columnGap: "0.5rem",
        justifyItems: "stretch",
        gridTemplateColumns: `repeat(${props.pinLength}, auto)`,
        marginBottom: `${props.bottomMargin}`,
      }}
      inputStyle={{
        width: "100%",
        borderRadius: "0.441rem",
        border: `1px solid ${colors["dark-gray"]}`,
        height: "2.208rem",
        fontFamily: "Inter",
      }}
      inputFocusStyle={{ border: `1px solid ${colors["dark-blue"]}` }}
    />
  );
};

export default Pin;
