import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getGeneratedQnA,
  getLessonSummary,
  getRelatedLessons,
} from "../../store/thunks/Learner/assistant";

import arrow from "../../assets/common/assistant/arrow.svg";
import {
  DialogOption,
  DialogOptionIcon,
  DialogOptionText,
  ArrowIcon,
  ArrowWrapper,
} from "./styled";
import smart from "../../assets/common/assistant/smart.svg";
import pencil from "../../assets/common/assistant/pencil.svg";
import earth from "../../assets/common/assistant/earth.svg";
import rocket from "../../assets/common/assistant/rocket.svg";
import { StoreState } from "../../store/type";
import { DialogContentOptions } from "./AssistantDialog";

type AssistantDialogOptionProps = {
  icon: string;
  text: string;
  onClick: () => void;
};

export const AssistantDialogOption = ({
  icon,
  text,
  onClick,
}: AssistantDialogOptionProps) => {
  return (
    <DialogOption onClick={onClick}>
      <DialogOptionIcon src={icon} />
      <DialogOptionText>{text}</DialogOptionText>
      <ArrowWrapper>
        <ArrowIcon src={arrow} />
      </ArrowWrapper>
    </DialogOption>
  );
};

type Props = {
  onOptionSelect: (option: DialogContentOptions) => void;
};

export const AssistantDialogOptions = ({ onOptionSelect }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lesson = useSelector((state: StoreState) => state.subjects.lesson);

  const lessonId = lesson.id || "";
  const data = [
    {
      text: t("assistant.dialog.options.related"),
      icon: earth,
      onClick: () => {
        dispatch(getRelatedLessons(lessonId));
        onOptionSelect("related");
      },
    },
    {
      text: t("assistant.dialog.options.summarize"),
      icon: smart,
      onClick: () => {
        dispatch(getLessonSummary(lessonId));
        onOptionSelect("summary");
      },
    },
    {
      text: t("assistant.dialog.options.practice"),
      icon: pencil,
      onClick: () => {
        dispatch(getGeneratedQnA(lessonId));
        onOptionSelect("qna");
      },
    },
    {
      text: t("assistant.dialog.options.explore"),
      icon: rocket,
      onClick: () => {
        onOptionSelect("search");
      },
    },
  ];

  return (
    <>
      {data.map(({ icon, text, onClick }, i) => (
        <AssistantDialogOption
          key={i}
          icon={icon}
          text={text}
          onClick={onClick}
        />
      ))}
    </>
  );
};
