export enum SliceNames {
  UserSlice = "UserSlice",
  AsyncSlice = "AsyncSlice",
  RouterSlice = "RouterSlice",
  SchoolSlice = "SchoolSlice",
  PaymentSlice = "PaymentSlice",
  SubjectsSlice = "SubjectsSlice",
  FAQSlice = "faqSlice",
  LearnersSlice = "LearnersSlice",
  SubscriptionsSlice = "SubscriptionsSlice",
  ActivitySlice = "activitySlice",
  TraitsSlice = "traitsSlice",
  AvatarsSlice = "avatarsSlice",
  FavoriteSubjectsSlice = "favoriteSubjectsSlice",
  LearnerProgress = "LearnerProgress",
  PaymentHistory = "PaymentHistory",
  Survey = "Survey",
  Search = "Search",
  Notification = "Notification",
  Tournaments = "Tournaments",
  AvatarItems = "AvatarItems",
  Assistant = "Assistant",
}
