import { ReactNode } from "react";

import { Item, ItemDescription, ItemValue } from "./styled";

type Props = {
  label: string;
  value: ReactNode;
};

export const TableItemComponent = ({ label, value }: Props) => {
  return (
    <Item>
      <ItemDescription>{label}</ItemDescription>
      <ItemValue>{value}</ItemValue>
    </Item>
  );
};
