import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { StoreState } from "../../../store/type";
import { Survey } from "../../../common/components/Survey/Survey";
import { Wrapper } from "../../../common/components/Survey/styled";

import { submitLessonQuizResult } from "../../../store/thunks/Learner/submitLessonQuiz";
import { SurveyResult as SurveyResultType } from "../../../store/types/survey";
import { redirect } from "../../../store/slices/router";
import { getRoute } from "../../../common/utils/getRoute";
import { RoutePath } from "../../../common/consts/route-path";
import { env } from "../../../common/config/env";
import { fetchLessonInfo } from "../../../store/thunks/Learner/fetchLessons";
import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { Spinner } from "../../../common/components/Spinner/Spinner";

export const LessonSurvey = () => {
  const params = useParams<{
    quizId: string;
    lessonCode: string;
    subjectCode: string;
  }>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const quizId = params.quizId;
  const lesson = useSelector((s: StoreState) => s.subjects.lesson);
  const lessonId = lesson?.id;
  const depositoryId = lesson.depository?.id;
  const pageTitle = !lesson?.depository?.surveyDoneAt
    ? t("lessonPage.resultPageTitle")
    : t("lessonPage.surveyDoneAnotherTime");
  const subjectCode = params.subjectCode;
  const lessonCode = params.lessonCode;

  const { requestInProgress } = useAsyncState(
    AsyncActions.FetchLessonInfoAction
  );

  useEffect(() => {
    if (subjectCode && lessonCode && !lessonId) {
      dispatch(fetchLessonInfo({ subjectCode, lessonCode }));
    }
  }, [subjectCode, lessonCode, lessonId]);

  const handleCancel = () => {
    dispatch(
      redirect({
        to: getRoute(params, RoutePath.lessonPage.locale[env.locale]),
      })
    );
  };

  const handleLessonQuizSubmit = (result: SurveyResultType) => {
    if (!quizId || !lessonId || !depositoryId) return;
    dispatch(
      submitLessonQuizResult({
        ...result,
        quizId,
        lessonId,
        depositoryId,
        lessonCode: lesson.code,
      })
    );
  };

  if (requestInProgress) {
    return (
      <Wrapper>
        <Spinner />
      </Wrapper>
    );
  }

  return (
    <Survey
      resultPageTitle={pageTitle}
      onSubmit={handleLessonQuizSubmit}
      onCancel={handleCancel}
    />
  );
};
