import React, { useEffect } from "react";
import { GlobalStyles } from "twin.macro";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { RouteWatcher } from "./views/components/RouteWatcher";
import Home from "./common/components/Home/Home";
import { AppRoutes } from "./common/routing/appRoutes";
import { AuthRoute } from "./views/components/AuthGuard";
import { useDispatch } from "react-redux";
import { fetchUserInfo } from "./store/thunks/fetchUserInfo";
import { Spinner } from "./common/components/Spinner/Spinner";
import { useSelector } from "react-redux";
import { StoreState } from "./store/type";
import { StripeProvider } from "react-stripe-elements";
import { env } from "./common/config/env";
import { ToastContainer } from "react-toastify";
import { NotFoundPage } from "./views/pages/NotFoundPage";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const dispatch = useDispatch();
  const { dataFetched } = useSelector((state: StoreState) => state.user);

  useEffect(() => {
    dispatch(fetchUserInfo());
  }, []);

  if (!dataFetched) {
    return <Spinner blur />;
  }

  return (
    <StripeProvider apiKey={env.stripeKey}>
      <Router>
        <Home>
          <GlobalStyles />
          <ToastContainer limit={3} hideProgressBar={true} autoClose={5000} />
          <RouteWatcher />
          <Routes>
            {AppRoutes.map(({ element: Element, subPaths, ...rest }) => (
              <Route
                key={rest.path}
                {...rest}
                element={
                  <AuthRoute auth={rest.authProtected} accessRole={rest.roles}>
                    <Element />
                  </AuthRoute>
                }
              >
                {subPaths?.map(({ element: SubElement, ...rest }) => (
                  <Route
                    key={rest.path}
                    {...rest}
                    element={
                      <AuthRoute
                        auth={rest.authProtected}
                        accessRole={rest.roles}
                      >
                        <SubElement />
                      </AuthRoute>
                    }
                  />
                ))}
              </Route>
            ))}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Home>
      </Router>
    </StripeProvider>
  );
}

export default App;
