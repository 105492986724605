import React from "react";
import tw, { styled } from "twin.macro";

export const PageWrapper = ({ children }: { children: React.ReactNode }) => (
  <div className="bg-profile-setup flex flex-col p-12 items-center w-full min-h-app bg-cover bg-center">
    {children}
  </div>
);

export const Heading = styled.h1(
  ({ smallMargin }: { smallMargin?: boolean }) => [
    tw`text-black text-[2rem] text-center mb-[2.5rem]`,
    smallMargin && tw`mb-3`,
  ]
);

export const Description = styled.p(
  ({ smallMargin }: { smallMargin?: boolean }) => [
    tw`font-sans text-black opacity-60 mb-[2.5rem] text-center`,
    smallMargin && tw`mb-8`,
  ]
);

export const ButtonDiv = styled.div(
  () =>
    tw` space-y-4 flex flex-col w-full max-w-[22.5rem] justify-center sm:space-y-0 sm:space-x-4 sm:flex-row`
);

export const SubmitDiv = styled.div(
  () => tw`w-full mt-[3.75rem] flex flex-col items-center space-y-4`
);
