import { PaymentRequestStatus } from "../../types/payment";
import {
  setPaymentErrorAction,
  setPaymentMethodAction,
  setPaymentMethodAttemptStatus,
} from "../../slices/payment";
import { ReduxThunk } from "../../type";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";

export const getPaymentMethodsTask: () => ReduxThunk =
  () =>
  async (dispatch, _, { client }) => {
    try {
      dispatch(setPaymentMethodAttemptStatus(PaymentRequestStatus.Pending));
      dispatch(setPaymentErrorAction(""));
      const res = await client.get(ApiRoutes.PaymentMethods);
      dispatch(setPaymentMethodAttemptStatus(PaymentRequestStatus.Idle));
      dispatch(setPaymentMethodAction((res as any).data || []));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      dispatch(setPaymentMethodAttemptStatus(PaymentRequestStatus.Idle));
      dispatch(setPaymentErrorAction(e?.message || "Nekaj ​​je šlo narobe"));
    }
  };
