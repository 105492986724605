import { useEffect, useState } from "react";
import { Learner } from "../../../store/types/learners";
import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { Payment } from "../../Register/Payment/Payment";
import { SubscriptionsDefault } from "./SubscriptionsDefault/SubscriptionsDefault";
import {
  SubscriptionsWrapper,
  SubscriptionSuccessWrapper,
} from "./SubscriptionsStyled";
import { PaymentSuccess } from "../../Register/PaymentSuccess/PaymentSuccess";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../store/type";
import { PaymentRequestStatus } from "../../../store/types/payment";
import { setCheckoutRenewalAttemptStatusAction } from "../../../store/slices/payment";

type Props = {
  renewalLearnerId: string | null;
  setRenewalLearnerId: (val: string | null) => void;
};

export const Subscriptions = ({ setRenewalLearnerId }: Props) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { clearNotification: clearByCodeNotification } = useAsyncState(
    AsyncActions.RenewByCodeSuccessful
  );
  const { clearNotification: clearRenewByCardNotification } = useAsyncState(
    AsyncActions.RenewByCardSuccessful
  );

  const [renewalLearner, setRenewalLearner] = useState<Learner | undefined>(
    undefined
  );
  const { checkoutRenewalAttemptStatus } = useSelector(
    (state: StoreState) => state.payments
  );

  const resetState = () => {
    setCurrentPage(1);
    setRenewalLearner(undefined);

    dispatch(setCheckoutRenewalAttemptStatusAction(PaymentRequestStatus.Idle));
    clearRenewByCardNotification();
    clearByCodeNotification();
  };

  useEffect(() => {
    if (checkoutRenewalAttemptStatus === PaymentRequestStatus.Successful) {
      setCurrentPage(3);
    }
  }, [checkoutRenewalAttemptStatus]);

  useEffect(() => {
    return () => {
      clearRenewByCardNotification();
      clearByCodeNotification();
      dispatch(
        setCheckoutRenewalAttemptStatusAction(PaymentRequestStatus.Idle)
      );
    };
  }, []);
  let VisibleComponent;

  switch (currentPage) {
    case 1:
      VisibleComponent = (
        <SubscriptionsDefault
          setSubscribeId={setRenewalLearnerId}
          setRenewalLearner={setRenewalLearner}
        />
      );
      break;
    case 2:
      VisibleComponent = (
        <SubscriptionsWrapper>
          <Payment sm renewal learner={renewalLearner} />
        </SubscriptionsWrapper>
      );
      break;
    case 3:
      VisibleComponent = (
        <SubscriptionSuccessWrapper>
          <PaymentSuccess onContinue={resetState} />
        </SubscriptionSuccessWrapper>
      );
      break;
    default:
      VisibleComponent = null;
  }
  useEffect(() => {
    if (renewalLearner) {
      setCurrentPage(2);
    }
  }, [renewalLearner]);
  return VisibleComponent;
};
