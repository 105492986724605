import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/type";
import { RoutePath } from "../../common/consts/route-path";
import { env } from "../../common/config/env";
import { UserRole } from "../../common/enums/UserRole";

type Props = {
  auth: boolean;
  children: JSX.Element;
  accessRole: UserRole[];
};

export const AuthRoute = ({ auth, children, accessRole }: Props) => {
  const location = useLocation();
  const {
    isLoggedIn,
    info: { role, emailConfirmed, activeSubscription },
  } = useSelector((state: StoreState) => state.user);
  const learners = useSelector((state: StoreState) => state.learners);
  const subscriptions = useSelector((state: StoreState) => state.subscriptions);

  const registrationNotComplete =
    !emailConfirmed || !learners?.length || !subscriptions?.length;

  if (auth && !isLoggedIn) {
    return (
      <Navigate
        to={RoutePath.login.locale[env.locale]}
        state={{ from: location }}
      />
    );
  }

  if (role && accessRole.indexOf(role) === -1) {
    return (
      <Navigate
        to={
          role === UserRole.Learner
            ? RoutePath.home.locale[env.locale]
            : RoutePath.parentDashboard.locale[env.locale]
        }
        replace
      />
    );
  }

  if (auth && registrationNotComplete && role === UserRole.Sponsor) {
    return <Navigate to={RoutePath.register.locale[env.locale]} replace />;
  }

  if (
    role === UserRole.Learner &&
    !activeSubscription &&
    location.pathname !== RoutePath.inactiveSubscription.locale[env.locale]
  ) {
    return (
      <Navigate
        to={RoutePath.inactiveSubscription.locale[env.locale]}
        replace
      />
    );
  }
  return children;
};
