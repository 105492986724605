import * as LearnerInfoStyled from "./LearnerInfoStyled";
import { InfoItem } from "./InfoItem/InfoItem";
import { useSelector } from "react-redux";
import { StoreState } from "../../../store/type";
import { SectionTitle } from "../LearnerHomeStyled";
import { useTranslation } from "react-i18next";

export const LearnerInfo = () => {
  const { t } = useTranslation();
  const { username, firstName, lastName, school, activeClass } = useSelector(
    (state: StoreState) => state.user.info
  );
  return (
    <LearnerInfoStyled.Wrapper>
      <SectionTitle>{t("learnerHome.profileInfoHeading")}</SectionTitle>
      <LearnerInfoStyled.GridContainerStyled>
        <InfoItem title={t("learnerHome.username")} value={username} />
        <InfoItem
          title={t("learnerHome.fullName")}
          value={`${firstName} ${lastName}`}
        />
        <InfoItem title={t("learnerHome.school")} value={school?.name} />
        <InfoItem
          title={t("learnerHome.grade")}
          value={`${t("learnerHome.grade")} ${activeClass?.name}`}
        />
      </LearnerInfoStyled.GridContainerStyled>
    </LearnerInfoStyled.Wrapper>
  );
};
