import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";

import { AsyncActions } from "../../../store/enums/AsyncActions";
import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { otpSchema } from "../../../views/validation/registerValidationSchema";
import { registerOTP } from "../../../store/thunks/register";
import { StoreState } from "../../../store/type";
import { setUserInfo } from "../../../store/slices/user";
import { logOutTask } from "../../../store/thunks/logOutTask";

import {
  PINWrapperStyled,
  ActionsButtonStyled,
  UsernameStyled,
  ActionsWrapperStyled,
} from "./OTPStyled";

import { Modal } from "../../../common/components/Modal/Modal";
import {
  PopupHeadingStyled,
  PopupCloseStyled,
  PopupImageStyled,
} from "../../../common/components/Modal/ModalStyled";

import { StyledError } from "../../../common/components/Error/Error";
import Form from "../../../common/components/Form/Form";
import popupImage from "../../../assets/common/Frame.svg";
import { HeadingStyled } from "../../../common/components/Register/RegisterHeading";
import { OTPParagraphStyled } from "../../../common/components/Register/RegisterParagraph";
import Button from "../../../common/components/Button/Button";
import Pin from "../../../common/components/Pin/Pin";
import {
  LabelStyled,
  SpanStyled,
} from "../../../common/components/Input/Input";
import { ModalWrapper } from "../ModalStyled";
import { resendOTP } from "../../../store/thunks/resendOTP";
import { ResendOTPModal } from "./ResendOTPModal";
import { MobilePhoneInputModal } from "./MobilePhoneInputModal";

type Props = {
  onPopupClose: () => void;
  onReturnToInfo: () => void;
};

export const OTP = ({ onPopupClose, onReturnToInfo }: Props) => {
  const {
    requestInProgress: resendInProgress,
    success: resendSuccess,
    error: resendError,
    clearSuccess: clearResendSuccess,
  } = useAsyncState(AsyncActions.ResendOTPAction);
  const { error, requestInProgress, notification, clearNotification } =
    useAsyncState(AsyncActions.ConfirmCodeAction);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [pinValue, setPinValue] = useState("");
  const [isMobilePhonePopupOpened, setIsMobilePhonePopupOpened] =
    useState(false);
  const { username } = useSelector((state: StoreState) => state.user.info);

  const { formState, handleSubmit, setValue, clearErrors } = useForm({
    mode: "onTouched",
    resolver: yupResolver(otpSchema()),
  });

  const onOTPResendHandler = () => {
    dispatch(resendOTP());
  };

  const onSubmitHandler = (data: any) => {
    dispatch(registerOTP(data));
  };

  const onChangeEmail = () => {
    localStorage.removeItem("Token");
    onReturnToInfo();
    dispatch(logOutTask());
  };

  const toggleMobilePhonePopup = (open: boolean) => () => {
    setIsMobilePhonePopupOpened(open);
  };

  useEffect(() => {
    if (pinValue.length === 4) {
      clearErrors("pin");
    }
    setValue("pin", pinValue);
  }, [pinValue]);

  const toggleModal = () => {
    setModalOpen((pre) => !pre);
  };

  useEffect(() => {
    if (notification) {
      setModalOpen(true);
    }
  }, [notification]);

  const handleCloseModal = () => {
    toggleModal();
    onPopupClose();
    clearNotification();
    dispatch(setUserInfo({ emailConfirmed: true, learnerLimit: 3 }));
  };

  return (
    <React.Fragment>
      <HeadingStyled>{t("register.otp.heading")}</HeadingStyled>
      <OTPParagraphStyled>{t("register.otp.paragraph")} </OTPParagraphStyled>
      <UsernameStyled>{username}</UsernameStyled>
      <Form onSubmit={handleSubmit(onSubmitHandler)}>
        <PINWrapperStyled>
          <LabelStyled>
            {t("register.otp.label")}
            <SpanStyled>*</SpanStyled>
          </LabelStyled>
          <Pin bottomMargin="1.5rem" pinLength={4} setPinValue={setPinValue} />
          <ActionsWrapperStyled>
            <ActionsButtonStyled
              onClick={onChangeEmail}
              type="button"
              addMargin
            >
              {t("register.otp.actions.change")}
            </ActionsButtonStyled>
            <ActionsButtonStyled
              disabled={resendInProgress}
              inProgress={resendInProgress}
              error={resendError}
              onClick={onOTPResendHandler}
              type="button"
            >
              {resendError
                ? t("response.resendOTP")
                : resendInProgress
                ? t("register.otp.actions.resendInProgress")
                : t("register.otp.actions.resend")}
            </ActionsButtonStyled>
            <ActionsButtonStyled
              type={"button"}
              onClick={toggleMobilePhonePopup(true)}
            >
              {t("register.otp.actions.smsVerification")}
            </ActionsButtonStyled>
          </ActionsWrapperStyled>
          {formState.errors.pin && (
            <StyledError centered topMargin="0" bottomMargin="0.883rem">
              {formState.errors.pin?.message}
            </StyledError>
          )}
          {error && (
            <StyledError centered topMargin="0" bottomMargin="0.883rem">
              {error}
            </StyledError>
          )}
          <Button
            type="submit"
            invert={false}
            resPending={requestInProgress}
            bottomMargin="4rem"
          >
            {t("register.otp.button")}
          </Button>
        </PINWrapperStyled>
      </Form>
      {modalOpen && (
        <Modal>
          <ModalWrapper>
            <PopupCloseStyled onClick={handleCloseModal}>
              &#10006;
            </PopupCloseStyled>

            <PopupImageStyled src={popupImage}></PopupImageStyled>
            <PopupHeadingStyled>
              {t("register.otp.popup.heading")}
            </PopupHeadingStyled>
            <Button
              invert={false}
              onClick={handleCloseModal}
              buttonWidth="12.366rem"
            >
              {t("register.otp.popup.button")}
            </Button>
          </ModalWrapper>
        </Modal>
      )}
      {resendSuccess && (
        <ResendOTPModal handleCloseModal={clearResendSuccess} />
      )}
      {isMobilePhonePopupOpened && (
        <MobilePhoneInputModal onClose={toggleMobilePhonePopup(false)} />
      )}
    </React.Fragment>
  );
};
