import tw, { styled } from "twin.macro";
import { MenuItem as Item } from "../MenuItem/MenuItem";

export const HeaderStyled = styled.header(
  () =>
    tw`px-1 z-30 sm:px-24 md:px-36 h-16 bg-white shadow-sm flex items-center`
);

export const IconDiv = styled.div(({ mdHide }: { mdHide?: boolean }) => [
  tw`flex cursor-pointer flex-shrink-0 items-center justify-center bg-yellow w-8 h-8 sm:w-[2.5rem] sm:h-[2.5rem] mr-4 rounded-full`,
  mdHide && tw`hidden md:flex`,
]);

export const Icon = styled.img();

export const CupIcon = styled(Icon)(
  () => tw`w-[1.25rem] h-[1.43rem] self-center`
);

export const AvatarWrapper = styled.div(() => tw`w-8 h-8`);

export const SearchDiv = styled.div(
  () =>
    tw`items-center bg-yellow h-[2.5rem] px-4 hidden lg:flex lg:w-86 rounded-full mx-4`
);

export const SearchDivWrapper = styled.div(() => tw`hidden md:inline-block`);

export const SearchInput = styled.input(
  () =>
    tw`w-full mr-auto bg-transparent outline-none focus:outline-none font-sans text-base text-black placeholder:font-sans placeholder:text-sm placeholder:opacity-60 placeholder:text-black`
);

export const SearchLabel = styled.label();
export const SearchIcon = styled.img();

// Search dropdown

export const DropdownSearchIcon = styled.img(() => tw`mx-4`);

export const DropdownButton = styled.button();

export const DropdownWrapper = styled.div(
  () => tw`relative pt-1 block lg:hidden`
);

export const DropdownDiv = styled.div(
  () =>
    tw`w-80 flex items-center justify-center px-4 h-[4rem] rounded-lg bg-white border border-light-gray absolute top-12 left-[-13rem] sm:-left-20`
);

export const SearchDropdownDiv = styled.div(
  () =>
    tw`items-center bg-yellow h-[2.5rem] px-4 flex lg:hidden rounded-full mx-4`
);

export const MenuItem = styled(Item)(
  () =>
    tw`font-sans !font-normal !text-base !text-black cursor-pointer hover:!bg-dark-blue hover:!text-white min-w-[5rem]`
);

export const PortalLink = styled.a(() => tw`mr-auto`);
export const PortalIcon = styled.img(() => tw`h-12`);
