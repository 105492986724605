import tw, { styled } from "twin.macro";
import RcCollapse from "rc-collapse";

export const Wrapper = styled.div(() => tw`hidden lg:block w-10/12`);

const Row = styled.div(() => tw`rounded-lg grid grid-rows-1 grid-cols-4 mb-2`);
const Cell = styled.div(() => tw`flex items-center pl-20`);

export const Header = styled(Row)(() => tw`bg-mud h-[2.5rem]`);

export const HeaderCell = styled(Cell)(
  () => tw`cursor-pointer text-white text-sm font-sans`
);

export const FilterWrapper = styled.div(() => tw`lg:hidden`);

export const Filter = styled.div(
  () =>
    tw`cursor-pointer border-dark-gray border rounded-lg text-black font-sans text-sm flex gap-x-1 py-1 px-2 inline-block mx-3 mb-1 font-sans`
);

export const DataRow = styled(Row)(
  () => tw`bg-white border-cream h-[4.25rem] border`
);

export const DataCell = styled(Cell)(
  () => tw`text-base text-black-secondary font-sans`
);

export const FilterIcon = styled.img(() => tw`ml-2 inline-block mb-px`);

export const StarIcon = styled.img(() => tw`mr-2 w-[1.06rem] h-[1.19rem]`);

export const UserDetailsWrapper = styled.div(
  () => tw`flex gap-x-2 items-center`
);

export const UserClassWrapper = styled.div(() => tw`flex flex-col`);

export const Username = styled.p(() => tw`text-base font-sans text-black`);
export const UserGrade = styled.p(() => tw`opacity-60 text-black text-xs`);

export const ResultPageTitle = styled.h1(
  () => tw`font-dyslexic font-bold text-4xl text-center px-2`
);

export const ResultPageSubtitle = styled.h1(
  () => tw`font-dyslexic font-bold text-2xl`
);

export const Panel = styled(RcCollapse.Panel)(() => tw`mb-2 shadow-item`);
export const Collapse = styled(RcCollapse)(() => tw`lg:hidden w-10/12`);

export const PanelHeader = styled.div<{ active: boolean }>(({ active }) => [
  tw`flex px-2 justify-between items-center h-20 cursor-pointer px-2`,
  active && tw`border-b border-b-dark-gray rounded`,
]);

export const PanelContent = styled.div(
  () => tw`grid grid-rows-4 grid-cols-2 py-2 px-4`
);

export const PointsWrapper = styled.div(() => tw`flex gap-1`);

export const Description = styled.div(() => tw`flex items-center`);

// NoParticipants
export const Icon = styled.img(() => tw`w-48 h-48`);

export const Paragraph = styled(Description)(() => tw`text-lg  font-sans`);

export const NoResultsWrapper = styled(Wrapper)(
  () => tw`flex pt-10 lg:flex flex-col gap-8 items-center text-center`
);
