import tw, { styled } from "twin.macro";

export const Wrapper = styled.div(
  () => tw`w-full flex p-8 flex-col items-center`
);

export const ContentWrapper = styled.div(
  () => tw`w-full items-center flex flex-col max-w-[23.75rem]`
);

export const AvatarDiv = styled.div(
  () =>
    tw` flex items-center justify-center bg-yellow w-44 h-44 rounded-full mb-6 border-[0.3rem] border-white shadow-md`
);

export const SuccessParagraph = styled.p(
  () => tw`font-bold text-[1.375rem] text-black text-center`
);

export const NameSpan = styled.span(() => tw`text-gold`);

export const BtnDiv = styled.div(
  () => tw`w-full flex justify-center mt-8 max-w-[14rem]`
);
