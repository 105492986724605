import { memo } from "react";
import { Routes, Route } from "react-router-dom";
import { env } from "../../../common/config/env";
import { RoutePath } from "../../../common/consts/route-path";
import { TournamentLists } from "./TournamentLists";
import { TournamentsHistory } from "./TournamentsHistory";
import { UpcomingTournaments } from "./UpcomingTournaments";

export const MemoizedTournamentList = memo(function MemoizeTournamentList() {
  return (
    <Routes>
      <Route path="/" element={<TournamentLists />}>
        <Route
          path={RoutePath.tournaments.sub.list.sub.history.locale[env.locale]}
          element={<TournamentsHistory />}
        />
        <Route
          path={RoutePath.tournaments.sub.list.sub.upcoming.locale[env.locale]}
          element={<UpcomingTournaments />}
        />
      </Route>
    </Routes>
  );
});
