import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SliceNames } from "../enums/SliceNames";
import {
  AvatarItem,
  AvatarItemKind,
  AvatarItemsState,
} from "../types/avatar-items";
import { ShopTab } from "../../common/types/ShopTabs";

const getTab = (kind: AvatarItemKind): ShopTab => {
  switch (kind) {
    case AvatarItemKind.Accessory:
      return "other";
    case AvatarItemKind.Badge:
      return "other";
    case AvatarItemKind.Belt:
      return "other";
    case AvatarItemKind.Body:
      return "body";
    case AvatarItemKind.Boots:
      return "boots";
    case AvatarItemKind.Eyes:
      return "hat";
    case AvatarItemKind.Gloves:
      return "other";
    case AvatarItemKind.Hat:
      return "hat";
    case AvatarItemKind.Legs:
      return "body";
    case AvatarItemKind.Mantle:
      return "body";
    case AvatarItemKind.Mask:
      return "hat";
    case AvatarItemKind.Torso:
      return "body";
    default:
      return "other";
  }
};

const initialState: AvatarItemsState = {
  hat: {},
  body: {},
  boots: {},
  other: {},
};

export const avatarItemSlice = createSlice({
  name: SliceNames.AvatarItems,
  initialState,
  reducers: {
    addItems: (state, { payload }: PayloadAction<AvatarItem[]>) => {
      payload.forEach((i) => {
        const tab = getTab(i.kind);
        if (!state[tab]?.[i.kind]) {
          state[tab][i.kind] = [];
        }
        state[tab][i.kind]?.push(i);
      });
    },
    reset: () => {
      return initialState;
    },
  },
});

export const { addItems, reset } = avatarItemSlice.actions;

export default avatarItemSlice.reducer;
