import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  PageWrapper,
  ResultWrapper,
} from "../../../common/components/Survey/styled";
import { Survey as SurveyComponent } from "../../../common/components/Survey/Survey";
import { TournamentExpiredModal } from "../modals/TournamentExpiredModal";

import { StoreState } from "../../../store/type";
import { SurveyError } from "../../../common/components/Survey/SurveyError";
import { getTournament } from "../../../store/thunks/Learner/tournament";
import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { removeCurrentTournament } from "../../../store/slices/tournament";
import { Spinner } from "../../../common/components/Spinner/Spinner";
import { TimerComponent } from "../../../common/components/Tournament/Timer";
import { LeaveTournamentModal } from "../modals/LeaveTournamentModal";
import { setShowLeaveTournamentModal } from "../../../store/slices/survey";
import { SurveyResult } from "../../../store/types/survey";
import { submitTournamentQuizResult } from "../../../store/thunks/Learner/submitTournamentQuizResult";

export const IndividualTournament = () => {
  const { id: tournamentId, quizId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { requestInProgress, error } = useAsyncState(
    AsyncActions.FetchTournament
  );

  const tournament = useSelector(
    (state: StoreState) => state.tournaments.current
  );

  const handleCancel = () => {
    dispatch(setShowLeaveTournamentModal(true));
  };

  const handleSubmit = (result: SurveyResult) => {
    if (!quizId || !tournament) return;
    dispatch(
      submitTournamentQuizResult({
        ...result,
        tournamentId: tournament.id,
        quizId,
      })
    );
  };

  useEffect(() => {
    if (!tournamentId) return;
    dispatch(getTournament(tournamentId));

    return () => {
      dispatch(removeCurrentTournament());
    };
  }, [tournamentId]);

  if (error)
    return (
      <ResultWrapper>
        <SurveyError msg={error} />
      </ResultWrapper>
    );

  if (requestInProgress || !tournament) {
    return (
      <ResultWrapper>
        <Spinner />
      </ResultWrapper>
    );
  }

  return (
    <PageWrapper>
      <TimerComponent date={tournament.endsAt} />
      <SurveyComponent
        resultPageTitle={t("tournaments.tournamentResult.title")}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        isTournament={true}
      />
      <TournamentExpiredModal endsAt={tournament?.endsAt} />
      <LeaveTournamentModal />
    </PageWrapper>
  );
};
