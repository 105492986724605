import { ReduxThunk } from "../type";
import { BaseInfoForm } from "../../common/types/BaseInfoForm";
import { OTPForm } from "../../common/types/OTPForm";
import { ApiRoutes } from "../../common/enums/ApiRoutes";
import {
  setBusyAction,
  setErrorAction,
  setNotification,
} from "../slices/async";
import { AsyncActions } from "../enums/AsyncActions";
import { AuthResponse } from "../types/user";
import { setToken } from "../../common/utils/localStorageService";
import i18n from "../../i18n";
import { LearnerPayload } from "../../common/types/LearnerPayload";
import { Learner } from "../types/learners";
import { addLearners } from "../slices/learners";
import { Exception } from "../../common/enums/Exceptions";
import { env } from "../../common/config/env";

const convertToIETFLanguageTag = (lang: string) => {
  if (lang === "si") return "sl";
  if (lang === "en") return "en-US";
  return null;
};

export const registerBaseInfo: (data: BaseInfoForm) => ReduxThunk = (data) => {
  return async (dispatch, _, { client }) => {
    dispatch(setBusyAction({ key: AsyncActions.RegisterAction, value: true }));
    try {
      const locale = convertToIETFLanguageTag(env.locale);
      const registerResponse = await client.post<AuthResponse>(
        ApiRoutes.BaseInfo,
        { ...(locale ? { locale } : {}), ...data }
      );
      if (registerResponse.data.access_token) {
        setToken(registerResponse.data.access_token);
      }
      dispatch(
        setNotification({
          key: AsyncActions.RegisterAction,
          value: i18n.t("register.otp.popup.heading"),
        })
      );
    } catch (err: any) {
      if (
        err.response?.data?.message.includes(
          "duplicate key value violates unique constraint"
        )
      ) {
        dispatch(
          setErrorAction({
            key: AsyncActions.RegisterAction,
            value: i18n.t("response.emailInUse"),
          })
        );
      } else if (
        err.response?.data?.message.includes(
          "username must be a lowercase string"
        )
      ) {
        dispatch(
          setErrorAction({
            key: AsyncActions.RegisterAction,
            value: i18n.t("response.errorEmailCapitalLetters"),
          })
        );
      } else {
        dispatch(
          setErrorAction({
            key: AsyncActions.RegisterAction,
            value: i18n.t("response.500"),
          })
        );
      }
    }
    dispatch(setBusyAction({ key: AsyncActions.RegisterAction, value: false }));
  };
};

export const registerOTP: (data: OTPForm) => ReduxThunk = (data) => {
  return async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({ key: AsyncActions.ConfirmCodeAction, value: true })
    );

    try {
      const response = await client.post<AuthResponse>(ApiRoutes.OTP, {
        code: data.pin,
      });
      if (response.data.access_token) {
        setToken(response.data.access_token);
      }
      dispatch(
        setNotification({
          key: AsyncActions.ConfirmCodeAction,
          value: i18n.t("register.otp.popup.heading"),
        })
      );
    } catch (err: any) {
      if (err.response?.data?.message === Exception.EmailConfirmCodeMismatch) {
        dispatch(
          setErrorAction({
            key: AsyncActions.ConfirmCodeAction,
            value: i18n.t("response.invalidConfirmationCode"),
          })
        );
      } else if (
        err.response.data.message.includes("Could not find any entity")
      ) {
        dispatch(
          setErrorAction({
            key: AsyncActions.ConfirmCodeAction,
            value: i18n.t("response.wrongOtpCode"),
          })
        );
      } else {
        dispatch(
          setErrorAction({
            key: AsyncActions.ConfirmCodeAction,
            value: i18n.t("response.500"),
          })
        );
      }
    }
    dispatch(
      setBusyAction({ key: AsyncActions.ConfirmCodeAction, value: false })
    );
  };
};

export const addLearnersThunk: (data: LearnerPayload) => ReduxThunk = (
  data
) => {
  return async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({ key: AsyncActions.AddLearnersAction, value: true })
    );
    try {
      const response = await client.post<Learner[]>(
        ApiRoutes.AddLearners,
        data
      );
      dispatch(
        setNotification({
          key: AsyncActions.ChangeFont,
          value: "In progress",
        })
      );
      dispatch(
        setNotification({
          key: AsyncActions.AddLearnersAction,
          value: response.data.reduce((previousString, currentLearner) => {
            if (!previousString) {
              return `${currentLearner.id}`;
            }
            return `${previousString}/${currentLearner.id}`;
          }, ""),
        })
      );
      dispatch(addLearners(response.data.map((l) => ({ ...l, items: [] }))));
    } catch (err: any) {
      dispatch(
        setErrorAction({
          key: AsyncActions.AddLearnersAction,
          value: i18n.t("response.500"),
        })
      );
    }
    dispatch(
      setBusyAction({ key: AsyncActions.AddLearnersAction, value: false })
    );
  };
};
