import tw, { styled } from "twin.macro";

export const Wrapper = styled.div(
  () => tw`w-full flex flex-col mb-12 relative`
);

export const ContentWrapper = styled.div(
  () =>
    tw`mb-2 w-full hidden xl:flex py-3 px-6 xl:items-center rounded-lg bg-white`
);

export const SpinnerWrapper = styled.div(() => tw`h-20 bg-white`);

export const Heading = styled.h4(
  () => tw`font-sans text-black opacity-70 text-sm font-normal w-full`
);

export const InfoDiv = styled.div(
  () => tw`flex flex-col items-center mb-6 bg-white p-4`
);

export const MethodName = styled.span(
  () => tw`font-sans text-black font-medium`
);
