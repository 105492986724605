import { useTranslation } from "react-i18next";
import { Description, Wrapper } from "./styled";
import { useSelector } from "react-redux";
import { StoreState } from "../../../store/type";
import { UpcomingTournamentsComponent } from "../../../common/components/Tournament/UpcomingTournamentsComponent";
import { useRefresh } from "../../../common/hooks/useRefresh";
import { FilteredEventProps } from "./data";

export const FilteredEvents = ({
  label,
  filter,
  sorter,
}: FilteredEventProps) => {
  const { t } = useTranslation();
  useRefresh(10000);

  const tournaments = useSelector(
    (state: StoreState) => state.tournaments.upcoming
  );

  const filtered = tournaments
    .filter(filter)
    .sort(sorter)
    .slice(0, label === "upcoming" ? 5 : 10);

  return (
    <Wrapper>
      {filtered.length > 0 && (
        <Description>{t(`learnerHome.events.${label}`)}</Description>
      )}
      <UpcomingTournamentsComponent tournaments={filtered} />
    </Wrapper>
  );
};
