import { ReduxThunk } from "../../type";
import { addSubjects } from "../../slices/subject";
import { setBusyAction, setErrorAction } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import i18n from "../../../i18n";
import { SubjectType } from "../../types/subjects";

export const fetchSubjects: () => ReduxThunk = () => {
  return async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({ key: AsyncActions.FetchSubjectsAction, value: true })
    );
    try {
      const response = await client.get<SubjectType[]>("subject/learner");
      dispatch(addSubjects(response.data));
    } catch (err: any) {
      dispatch(
        setErrorAction({
          key: AsyncActions.FetchSubjectsAction,
          value: i18n.t("learnerHome.errors.subjects"),
        })
      );
    }
    dispatch(
      setBusyAction({ key: AsyncActions.FetchSubjectsAction, value: false })
    );
  };
};
