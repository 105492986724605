import { ReduxThunk } from "../../type";
import {
  setBusyAction,
  setErrorAction,
  setNotification,
} from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import { StripeSubscription } from "../../types/user";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import {
  setStripeSubscriptions,
  deleteStripeSubscriptions,
} from "../../slices/user";
import i18n from "../../../i18n";

export const deleteStripeSubscriptionTasks =
  (subscriptionId: string): ReduxThunk =>
  async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({ key: AsyncActions.CancelStripeSubscription, value: true })
    );

    try {
      await client.delete<{ status: "Success" }>(
        `${ApiRoutes.DeleteStripeSubscription}/${subscriptionId}`
      );
      dispatch(deleteStripeSubscriptions(subscriptionId));
      dispatch(
        setNotification({
          key: AsyncActions.CancelStripeSubscription,
          value: subscriptionId,
        })
      );
    } catch (e) {
      dispatch(
        setErrorAction({
          value: i18n.t("response.genericError"),
          key: AsyncActions.CancelStripeSubscription,
        })
      );
    }

    dispatch(
      setBusyAction({
        key: AsyncActions.CancelStripeSubscription,
        value: false,
      })
    );
  };

export const getStripeSubscriptions =
  (): ReduxThunk =>
  async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({ key: AsyncActions.GetStripeSubscriptions, value: true })
    );

    try {
      const res = await client.get<StripeSubscription[]>(
        ApiRoutes.GetStripeSubscriptions
      );
      dispatch(setStripeSubscriptions(res.data));
    } catch (e) {
      dispatch(
        setErrorAction({
          value: i18n.t("response.genericError"),
          key: AsyncActions.GetStripeSubscriptions,
        })
      );
    }

    dispatch(
      setBusyAction({ key: AsyncActions.GetStripeSubscriptions, value: false })
    );
  };
