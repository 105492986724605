import { useSelector } from "react-redux";
import { StoreState } from "../../store/type";
import { env } from "../config/env";
import { RoutePath } from "../consts/route-path";
import { UserRole } from "../enums/UserRole";

export const useUserHomeRoute = (): string => {
  const role = useSelector((state: StoreState) => state.user.info.role);

  return role === UserRole.Sponsor
    ? RoutePath.parentDashboard.locale[env.locale]
    : RoutePath.home.locale[env.locale];
};
