import { HTMLProps } from "react";
import tw, { styled, css } from "twin.macro";
interface ComponentProps {
  inputWidth?: string;
  placeholderText: string;
  labelText?: string;
  icon?: any;
  value?: string | null;
  bottomMargin?: string | null;
  required: boolean;
  type: string;
  registerInput: any;
  name: string;
}
interface InputProps {
  inputWidth?: string;
  bottomMargin?: string | null;
  inputHeight?: string;
  type: string;
}

export const InputStyled = styled.input<InputProps>(
  ({ inputWidth, bottomMargin, inputHeight }) => [
    tw`focus:outline-none focus:border-dark-blue border border-dark-gray rounded-lg px-3 font-sans h-10 placeholder:text-black placeholder:opacity-60 placeholder:font-sans placeholder:font-normal placeholder:text-sm font-sans mr-auto`,
    inputWidth &&
      css`
        width: ${inputWidth};
      `,
    bottomMargin &&
      css`
        margin-bottom: ${bottomMargin};
      `,
    inputHeight &&
      css`
        height: ${inputHeight};
      `,
  ]
);

export const IconStyled = styled.label(() => tw`inline-block -ml-8 -mb-px`);

export const SpanStyled = styled.span(() => tw`text-danger`);

export const LabelStyled = styled.label<HTMLProps<HTMLLabelElement>>(
  () => tw`block font-sans text-black text-sm font-normal mb-2`
);

export const Input = (props: ComponentProps) => {
  return (
    <div className="w-full">
      {props.labelText && (
        <LabelStyled>
          {props.labelText}
          {props.required ? <SpanStyled>*</SpanStyled> : null}
        </LabelStyled>
      )}
      <InputStyled
        value={props.value}
        {...props.registerInput(props.name)}
        placeholder={props.placeholderText}
        inputWidth={props.inputWidth}
        bottomMargin={props.bottomMargin ? props.bottomMargin : null}
        type={props.type}
      />
      {props.icon ? (
        <IconStyled>
          <img src={props.icon} className="h-4" />
        </IconStyled>
      ) : null}
    </div>
  );
};

export default Input;
