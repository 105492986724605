import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../../common/components/Button/Button";
import { useLearners } from "../../../../../common/hooks/useLearners";
import {
  AvatarStyled,
  InfoDiv,
  NameStyled,
  LearnerWrapper,
  InfoTextStyled,
} from "../../../../Register/LearnerPins/LearnerPinsStyled";
import { UnsubscribedLearnerDiv } from "../LearnersListStyled";
import { removeLearnerThunk } from "../../../../../store/thunks/Learner/removeLearner";
import {
  UnsubscribedTitle,
  UnsubscribedWrapper,
  DeleteButton,
} from "../LearnersListStyled";

import { useDispatch } from "react-redux";
import { useAsyncState } from "../../../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../../../store/enums/AsyncActions";
import { ErrorStyled } from "../../../../LessonPage/LessonPageStyled";
import { Learner } from "../../../../../store/types/learners";
import { Avatar } from "../../../../../common/components/Avatar/Avatar";

const LearnerItem = ({
  learner,
  onSubscribe,
}: {
  learner: Learner;
  onSubscribe: (id: string) => void;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { error, requestInProgress } = useAsyncState(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    `${AsyncActions.RemoveLearner}/${learner.username}`
  );

  const removeLearner = (username: string) => {
    dispatch(removeLearnerThunk(username));
  };

  return (
    <>
      <LearnerWrapper bgWhite key={learner.id}>
        <AvatarStyled>
          <Avatar
            items={learner.items.map((i) => i.avatarItem)}
            icon={learner.avatar?.image}
          />
        </AvatarStyled>
        <InfoDiv>
          <NameStyled>{`${learner.firstName} ${learner.lastName}`}</NameStyled>
          <InfoTextStyled>
            {learner.activeClass
              ? `${learner.activeClass?.name}, ${learner.school?.name}`
              : ""}
          </InfoTextStyled>
        </InfoDiv>
        <UnsubscribedLearnerDiv>
          <Button
            invert
            buttonHeight="2.5rem"
            buttonWidth="10.375rem"
            resPending={requestInProgress}
            onClick={() => onSubscribe(learner.id)}
          >
            {t("parentDashboard.manage.subscribe")}
          </Button>
        </UnsubscribedLearnerDiv>
        <UnsubscribedLearnerDiv>
          <DeleteButton
            buttonHeight="2.5rem"
            buttonWidth="10.375rem"
            onClick={() => removeLearner(learner.username)}
            resPending={requestInProgress}
          >
            {t("parentDashboard.manage.removeLearner")}
          </DeleteButton>
        </UnsubscribedLearnerDiv>
      </LearnerWrapper>
      {error && <ErrorStyled>{error}</ErrorStyled>}
    </>
  );
};

export const UnsubscribedLearners = ({
  onSubscribe,
}: {
  onSubscribe: (id: string) => void;
}) => {
  const { neverSubscribed } = useLearners();
  const { t } = useTranslation();

  return neverSubscribed.length > 0 ? (
    <UnsubscribedWrapper>
      <UnsubscribedTitle>
        {t("parentDashboard.manage.unsubscribedUsers")}
      </UnsubscribedTitle>
      {neverSubscribed.map((learner) => {
        return (
          <LearnerItem
            onSubscribe={onSubscribe}
            key={learner.id}
            learner={learner}
          />
        );
      })}
    </UnsubscribedWrapper>
  ) : null;
};
