import tw, { styled } from "twin.macro";
import { ButtonStyled } from "../../../common/components/Button/Button";

export const Wrapper = styled.div(
  () => tw`mt-8 flex flex-col gap-y-8 items-center`
);

export const ContentWrapper = styled.div(
  () => tw`flex flex-col gap-y-2 w-full`
);

export const NoEventsWrapper = styled.div(
  () => tw`flex items-center justify-center`
);

export const Title = styled.h1(() => tw`font-sans font-bold text-lg`);

// Tournament Permissions
export const TournamentPermissionsWrapper = styled.div(
  () => tw`flex flex-col gap-y-2 w-full mx-2`
);

export const PermissionDiv = styled.div(
  () =>
    tw`grid grid-rows-3 grid-cols-2 lg:grid-rows-1 lg:grid-cols-4 rounded-xl bg-white p-2 gap-2`
);

export const PermissionDivContentWrapper = styled.div(() => tw``);

export const PermissionDivLabel = styled.div(
  () => tw`font-sans opacity-70 text-sm mb-1`
);

export const PermissionDivValue = styled.div(() => tw`font-sans`);

export const ButtonWrapper = styled.div(
  () =>
    tw`row-start-3 col-span-2 lg:col-start-4 lg:row-start-1 lg:col-span-1 flex items-center justify-center lg:justify-end pr-2`
);

export const OpenConsentModalButton = styled(ButtonStyled)(
  () => tw`px-2 w-[13rem]`
);
