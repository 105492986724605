import { ReduxThunk } from "../../type";
import { setBusyAction } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";

type TournamentResultData = {
  tournamentId: string;
  quizId: string;
  totalQuestions: number;
  correctlyAnswered: number;
  wronglyAnswered: number;
};

export const submitTournamentQuizResult =
  (body: TournamentResultData): ReduxThunk =>
  async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({ value: true, key: AsyncActions.SubmitTournamentResult })
    );

    try {
      await client.post(ApiRoutes.SubmitTournamentQuizResult, body);
    } catch (e) {
      console.log("error");
    }

    dispatch(
      setBusyAction({ value: false, key: AsyncActions.SubmitTournamentResult })
    );
  };
