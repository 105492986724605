import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SliceNames } from "../enums/SliceNames";
import { Subscription } from "../types/subscriptions";

const initialState = [] as Subscription[];

const subscriptionSlice = createSlice({
  name: SliceNames.SubscriptionsSlice,
  initialState,
  reducers: {
    addSubscriptions: (state, { payload }: PayloadAction<Subscription[]>) => {
      payload.forEach((s) => state.push(s));
    },

    reset: () => {
      return initialState;
    },
  },
});

export const { addSubscriptions, reset } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
