import tw, { styled } from "twin.macro";

export const Wrapper = styled.div(() => tw`w-full`);

export const BackBtn = styled.img(() => tw`cursor-pointer`);

export const Avatar = styled.img();

export const Username = styled.h4(
  () => tw`text-lg font-semibold font-sans text-black mb-6`
);
