import { FC } from "react";
import { Description } from "./styled";

type Props = { label: string };

export const DescriptionCell: FC<Props> = ({ label, children }) => {
  return (
    <>
      <Description>{label}</Description>
      <Description>{children}</Description>
    </>
  );
};
