import { groupBy } from "lodash/fp";
import { ReduxThunk } from "../../type";
import { setBusyAction } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import { SearchResult } from "../../types/search";
import { setResult } from "../../slices/search";

export const searchLessons =
  (classroomName: number, query: string, subjectCode?: string): ReduxThunk =>
  async (dispatch, _, { client }) => {
    dispatch(setBusyAction({ key: AsyncActions.SearchLessons, value: true }));
    try {
      const response = await client.get<SearchResult[]>(
        `/lesson/public/search?classroomName=${classroomName}&query=${query}${
          subjectCode ? `&subjectCode=${subjectCode}` : ""
        }`
      );

      const groupedBySubject = groupBy("subject.name", response.data);
      const data: Record<string, Record<string, SearchResult[]>> = {};
      Object.keys(groupedBySubject).forEach((key) => {
        data[key] = groupBy("section.name", groupedBySubject[key]);
      });

      dispatch(setResult(data));
    } catch (e) {
      console.log(e);
    }
    dispatch(setBusyAction({ key: AsyncActions.SearchLessons, value: false }));
  };
