export enum NotificationKind {
  TournamentStart = "TournamentStart",
  TournamentEnd = "TournamentEnd",
  ScheduledClass = "ScheduledClass",
}

type NotificationCommon = {
  id: string;
  username: string | null;
  classroomId: string | null;
  seen: boolean;
  createdAt: string;
  updatedAt: string;
};

export type TournamentStart = NotificationCommon & {
  kind: NotificationKind.TournamentStart;
  json: {
    id: string;
    name: string;
    date: string;
    quizId: string;
  };
};

export type TournamentEnd = NotificationCommon & {
  kind: NotificationKind.TournamentEnd;
  json: {
    id: string;
    name: string;
    date: string;
    quizId: string;
  };
};

export type Notification = TournamentStart | TournamentEnd;
