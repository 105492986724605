import { ReduxThunk } from "../../type";
import { setBusyAction, setErrorAction } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { changeItemStatus } from "../../slices/user";
import { UpdateResult } from "../../types/shared";
import { LearnerItem } from "../../types/user";

export const updateAvatarItems =
  (items: LearnerItem[]): ReduxThunk =>
  async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({ key: AsyncActions.ChangeItemStatus, value: true })
    );
    try {
      dispatch(changeItemStatus(items));
      await client.patch<UpdateResult>(`${ApiRoutes.ChangeItemStatus}`, {
        items,
      });
    } catch (e) {
      dispatch(
        setErrorAction({ key: AsyncActions.ChangeItemStatus, value: "Error" })
      );
    }

    dispatch(
      setBusyAction({ key: AsyncActions.ChangeItemStatus, value: false })
    );
  };
