import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreState } from "../../../store/type";
import {
  GridItemDiv,
  Heading,
  HeadingAndEditDiv,
  NoSubjects,
  SubjectIcon,
  SubjectName,
  SubjectsGrid,
  Wrapper,
} from "./LearnerSubjectsStyled";

export const LearnerSubjects = () => {
  const { favoriteSubjects } = useSelector(
    (state: StoreState) => state.user.info
  );
  const { t } = useTranslation();

  return (
    <Wrapper>
      <HeadingAndEditDiv>
        <Heading>{t("profileSettings.subjects")}</Heading>
      </HeadingAndEditDiv>
      {favoriteSubjects && favoriteSubjects.length > 0 ? (
        <SubjectsGrid>
          {favoriteSubjects.map((favoriteSubject) => {
            return (
              <GridItemDiv key={favoriteSubject.id}>
                {favoriteSubject.image && (
                  <SubjectIcon src={favoriteSubject.image} />
                )}
                <SubjectName>{favoriteSubject.name}</SubjectName>
              </GridItemDiv>
            );
          })}
        </SubjectsGrid>
      ) : (
        <NoSubjects>{t("profileSettings.notSelected")}</NoSubjects>
      )}
    </Wrapper>
  );
};
