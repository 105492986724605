import tw, { styled } from "twin.macro";
import { ButtonStyled } from "../../common/components/Button/Button";

export const NoResultWrapper = styled.section(() => tw`md:pt-8`);

export const QueryWrapper = styled.div(() => tw``);

export const SearchResultWrapper = styled.div(
  () => tw`flex flex-col mb-6 pl-2 md:pl-0 w-10/12`
);

export const Title = styled.h1(
  () => tw`text-xl font-semibold mb-2 mt-4 font-sans`
);

export const Tag = styled.div(
  () => tw`px-1 rounded text-xs border border-dark-blue font-sans lowercase`
);

export const LessonContent = styled.div(
  () => tw`flex justify-between flex-wrap my-1`
);

export const LessonName = styled.div(
  () => tw`text-lg font-semibold font-sans text-dark-blue`
);

export const SectionName = styled.span(() => tw`font-sans text-lg`);

export const SubjectSpan = styled.span(() => tw`font-bold font-sans text-lg`);

export const ContentWrapper = styled.div(
  () =>
    tw`mb-4 bg-white shadow p-4 rounded-lg flex flex-col gap-y-2  md:(flex-row gap-y-0 justify-between)`
);

export const ButtonWrapper = styled.div(
  () => tw`flex justify-center items-center`
);

export const Button = styled(ButtonStyled)(
  () => tw`w-28 md:(flex rounded-full w-15 h-15)`
);

export const Icon = styled.img(() => tw``);

export const LessonDataWrapper = styled.div(() => tw``);

export const TitleWrapper = styled.div(() => tw``);

export const HeaderWrapper = styled.div(
  () => tw`block md:(flex justify-between) lg:block items-center`
);
