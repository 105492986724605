import { useTranslation } from "react-i18next";
import errorIcon from "../../../../assets/common/Lesson-Page/Error.svg";

import Button from "../../../../common/components/Button/Button";
import {
  PopupHeadingStyled,
  PopupCloseStyled,
  PopupImageStyled,
} from "../../../../common/components/Modal/ModalStyled";
import {
  PopupParagraphStyled,
  PopupWrapper,
  ContentWrapper,
} from "../../Video/VideoStyled";
import { PopupErrorBtnDiv } from "./LessonResourcesStyled";

import { Modal } from "../../../../common/components/Modal/Modal";

export const ErrorModal = ({
  closeErrorModal,
}: {
  closeErrorModal: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Modal>
      <PopupWrapper>
        <PopupCloseStyled onClick={closeErrorModal}>&#10006;</PopupCloseStyled>
        <PopupImageStyled src={errorIcon} />
        <ContentWrapper>
          <PopupHeadingStyled>
            {t("lessonPage.errorHeading")}
          </PopupHeadingStyled>
          <PopupParagraphStyled>
            {t("lessonPage.errorParagraph")}
          </PopupParagraphStyled>
          <PopupErrorBtnDiv>
            <Button onClick={closeErrorModal} invert buttonWidth="100%">
              {t("lessonPage.continueCloseBtn")}
            </Button>
          </PopupErrorBtnDiv>
        </ContentWrapper>
      </PopupWrapper>
    </Modal>
  );
};
