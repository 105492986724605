import tw, { styled } from "twin.macro";

export const Wrapper = styled.div(
  () =>
    tw`flex mt-32 lg:mt-24 p-8 flex-col w-full items-center bg-light-gray-secondary`
);

export const IncompleteParagraph = styled.p(
  () => tw`text-center font-bold text-black text-xl mb-12`
);

export const BtnDiv = styled.div(
  () => tw`flex justify-center w-full max-w-[23.75rem]`
);
