import * as yup from "yup";
import i18n from "../../i18n";
export const baseInfoSchema = () => {
  const translationPath = "register.baseInfo.validation";
  return yup
    .object({
      firstName: yup
        .string()
        .required(
          i18n.t(`validation.required`, {
            fieldName: i18n.t("fieldNames.firstName"),
          })
        )
        .min(
          2,
          i18n.t("validation.min", {
            fieldName: i18n.t("fieldNames.firstName"),
            suffix: "a",
            length: 2,
          })
        )
        .matches(/^\p{L}+'*\s*'*\p{L}+$/u, i18n.t("validation.alphabet"))
        .max(
          64,
          i18n.t(`validation.max`, {
            fieldName: i18n.t("fieldNames.firstName"),
          })
        ),
      lastName: yup
        .string()
        .required(
          i18n.t(`validation.required`, {
            fieldName: i18n.t("fieldNames.lastName"),
          })
        )
        .min(
          2,
          i18n.t("validation.min", {
            fieldName: i18n.t("fieldNames.lastName"),
            suffix: "a",
            length: 2,
          })
        )
        .matches(/^\p{L}+'*\s*'*\p{L}+$/u, i18n.t("validation.alphabet"))
        .max(
          64,
          i18n.t(`validation.max`, { fieldName: i18n.t("fieldNames.lastName") })
        ),

      username: yup
        .string()
        .trim()
        .lowercase()
        .required(
          i18n.t(`validation.required`, {
            fieldName: i18n.t("fieldNames.email"),
          })
        )
        .email(i18n.t(`validation.email`)),
      password: yup
        .string()
        .required(
          i18n.t(`validation.required`, {
            fieldName: i18n.t("fieldNames.password"),
          })
        )
        .min(
          8,
          i18n.t("validation.min", {
            fieldName: i18n.t("fieldNames.password"),
            suffix: "ov",
            length: 8,
          })
        )
        .max(
          64,
          i18n.t(`validation.max`, { fieldName: i18n.t("fieldNames.password") })
        ),

      passwordConfirm: yup
        .string()
        .required(i18n.t(`${translationPath}.passwordConfirm.required`))
        .oneOf(
          [yup.ref("password")],
          i18n.t(`${translationPath}.passwordConfirm.match`)
        ),
      terms: yup.bool().oneOf([true], i18n.t(`${translationPath}.terms`)),
      subscribeToNotifications: yup.bool(),
    })
    .required();
};

export const otpSchema = () => {
  return yup.object({
    pin: yup
      .string()
      .required(i18n.t("validation.pin.required"))
      .length(4, i18n.t("validation.pin.min")),
  });
};

const learnerSchema = {
  firstName: yup
    .string()
    .required(
      i18n.t(`validation.required`, {
        fieldName: i18n.t("fieldNames.firstName"),
      })
    )
    .min(
      2,
      i18n.t("validation.min", {
        fieldName: i18n.t("fieldNames.firstName"),
        suffix: "a",
        length: 2,
      })
    )
    .matches(/^\p{L}+'*\s*'*\p{L}+$/u, i18n.t("validation.alphabet"))
    .max(
      64,
      i18n.t(`validation.max`, { fieldName: i18n.t("fieldNames.firstName") })
    ),
  lastName: yup
    .string()
    .required(
      i18n.t(`validation.required`, {
        fieldName: i18n.t("fieldNames.lastName"),
      })
    )
    .min(
      2,
      i18n.t("validation.min", {
        fieldName: i18n.t("fieldNames.lastName"),
        suffix: "a",
        length: 2,
      })
    )
    .matches(/^\p{L}+'*\s*'*\p{L}+$/u, i18n.t("validation.alphabet"))
    .max(
      64,
      i18n.t(`validation.max`, { fieldName: i18n.t("fieldNames.lastName") })
    ),
  activeClass: yup
    .string()
    .required(i18n.t("register.addLearners.validation.classroom.required")),
  school: yup
    .string()
    .required(i18n.t("register.addLearners.validation.school.required")),
};

export const addLearnersSchema = () => {
  return yup.object({
    learners: yup.array().of(yup.object().shape(learnerSchema)),
  });
};
