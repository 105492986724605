import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { merge } from "lodash";
import { SliceNames } from "../enums/SliceNames";
import { Learner } from "../types/learners";
import { Consent } from "../types/user";

export const initialState = [] as Learner[];

const learnersSlice = createSlice({
  name: SliceNames.LearnersSlice,
  initialState,
  reducers: {
    addLearners: (state, { payload }: PayloadAction<Learner[]>) => {
      payload.forEach((l) => state.push(l));
    },
    setLearners: (state, { payload }: PayloadAction<Learner[]>) => {
      state.splice(0, state.length, ...payload);
    },
    updateLearners: (state, { payload }: PayloadAction<Learner[]>) => {
      payload.forEach((updatedLearner) => {
        const index = state.findIndex((l) => l.id === updatedLearner.id);
        if (index > -1) {
          state[index] = merge(state[index], updatedLearner);
        }
      });
    },
    deleteLearner: (state, { payload }: PayloadAction<string>) => {
      const index = state.findIndex((l) => l.id === payload);
      if (index > -1) {
        state.splice(index, 1);
      }
    },
    editLearnerPin: (
      state,
      { payload }: PayloadAction<{ username: string; pin: string }>
    ) => {
      const index = state.findIndex((learner) => {
        return learner.username === payload.username;
      });
      if (index > -1) {
        state[index].pinCode = payload.pin;
      }
    },
    reset: () => {
      return initialState;
    },
    removeLearner: (
      state,
      { payload }: PayloadAction<{ username: string }>
    ) => {
      return state.filter((l) => l.username !== payload.username);
    },
    updateConsent: (state, { payload }: PayloadAction<Consent>) => {
      state.forEach((l) => {
        if (l.username === payload.username) {
          l.consent = payload;
        }
      });
    },
  },
});

export const {
  addLearners,
  updateLearners,
  deleteLearner,
  reset,
  editLearnerPin,
  setLearners,
  removeLearner,
  updateConsent,
} = learnersSlice.actions;

export default learnersSlice.reducer;
