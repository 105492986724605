import { createSlice } from "@reduxjs/toolkit";
import { SliceNames } from "../enums/SliceNames";
import { Trait } from "../types/traits";

const initialState: {
  traits: Trait[];
  userTraits: Trait[];
} = {
  traits: [],
  userTraits: [],
};

export const traitsSlice = createSlice({
  name: SliceNames.TraitsSlice,
  initialState,
  reducers: {
    setTraits: (state, { payload }) => {
      state.traits = state.traits.concat(payload);
    },
    setUserTraits: (state, { payload }) => {
      state.userTraits = payload;
    },

    removeTraits: (state) => {
      state.traits = initialState.traits;

      state.userTraits = initialState.userTraits;
    },
  },
});

export const { setTraits, removeTraits, setUserTraits } = traitsSlice.actions;

export default traitsSlice.reducer;
