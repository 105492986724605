import { DialogContentOptions } from "./AssistantDialog";
import { AssistantDialogOptions } from "./AssistantDialogOption";
import { RelatedLessons } from "./RelatedLessons";
import { AssistantSearch } from "./AssistantSearch";
import { QnA } from "./QnA";
import { LessonSummary } from "./LessonSummary";

type Props = {
  onOptionSelect: (option: DialogContentOptions) => void;
  selectedOption: DialogContentOptions;
  onClose: () => void;
};

export const AssistantDialogContent = ({
  selectedOption,
  onOptionSelect,
  onClose,
}: Props) => {
  switch (selectedOption) {
    case "none":
      return <AssistantDialogOptions onOptionSelect={onOptionSelect} />;
    case "summary":
      return <LessonSummary />;
    case "qna":
      return <QnA />;
    case "related":
      return <RelatedLessons onRelatedLessonClick={onClose} />;
    case "search":
      return <AssistantSearch onSearchClick={onClose} />;
    default:
      return <AssistantDialogOptions onOptionSelect={onOptionSelect} />;
  }
};
