import tw, { styled, css } from "twin.macro";
import RcCollapse from "rc-collapse";
import { ButtonStyled } from "../Button/Button";

export const TData = styled.td(() => tw`max-w-[12.5rem] first:py-5 first:px-6`);

export const Item = styled.div(() => tw`flex flex-col text-black gap-1`);

export const TournamentItem = styled.div(() => tw`flex break-words`);

export const TournamentDetails = styled.div([
  () => tw`flex flex-col text-black gap-1`,
  css`
    word-break: break-word;
  `,
]);

export const ItemDescription = styled.span<{ extraSmall?: boolean }>(
  ({ extraSmall }) => [
    tw`font-sans opacity-70 text-sm`,
    extraSmall && tw`text-xs`,
  ]
);

export const ItemValue = styled.span(
  () => tw`text-base font-sans tracking-wider`
);

export const StyledRow = styled.tr(() => tw`rounded-md shadow-item`);

export const IconWrapper = styled.div(() => [
  tw`flex items-center justify-center bg-oker w-12 h-12  rounded-full mr-2 min-w-[2.5rem]`,
]);

export const TournamentIcon = styled.img(() => tw`w-6 h-6`);

export const StarIcon = styled.img(() => tw`w-5 h-5 mr-[4px] inline mb-[3px]`);

export const ActionWrapper = styled.div(
  () => tw`col-span-2 md:col-span-1 flex md:block items-center justify-center`
);

export const Action = styled(ButtonStyled)(() => tw`w-[9.125rem]`);

export const Panel = styled(RcCollapse.Panel)(() => tw`mb-2 shadow-item`);
export const Collapse = styled(RcCollapse)(() => tw`lg:hidden`);

export const PanelContent = styled.div(
  () => tw`grid grid-rows-5 grid-cols-2 py-2 px-4`
);

export const PanelItemDescription = styled(ItemDescription)(
  () => tw`items-center flex items-center min-h-[2.5rem]`
);

export const ActionLabel = styled(PanelItemDescription)(
  () => tw`hidden md:flex`
);

export const PanelItemValue = styled(ItemValue)(
  () => tw`items-center flex items-center min-h-[2.5rem]`
);

export const TournamentType = styled(ItemDescription)(() => tw`text-base`);

export const TournamentTitle = styled.div(
  () => tw`flex items-center gap-x-4 text-lg font-sans`
);

export const MobileTournamentItem = styled.div(
  () => tw`flex sm:hidden h-15 cursor-pointer px-2 items-center`
);

export const TabletTournamentWrapper = styled.span(
  () =>
    tw`hidden sm:flex px-2 justify-between items-center h-20 cursor-pointer px-2`
);

export const Timer = styled(ItemValue)<{ warning: boolean }>(({ warning }) => [
  tw`font-semibold`,
  warning ? tw`text-sour-pomegranate` : tw`text-darker-green`,
]);

export const TournamentClosed = styled(ItemValue)(
  () => tw`font-semibold text-danger`
);

export const TimerWrapper = styled.div(
  () => tw`absolute right-[1.44rem] font-sans`
);

export const CancelActionWrapper = styled.div(
  () => tw`absolute bottom-[2.3rem] md:bottom-[3.2rem] md:self-auto`
);

export const ToggleTournamentsWrapper = styled.span(
  () => tw`bg-light-gray-secondary rounded-[3.125rem]`
);

export const ToggleTournamentButton = styled.button(
  ({ second, selected }: { second?: boolean; selected: boolean }) => [
    tw`font-sans h-14 sm:h-10 px-10 sm:px-6 rounded-[3.125rem]`,
    selected ? tw`bg-gold text-white z-10` : tw`bg-transparent text-black`,
    second && tw`-ml-3`,
  ]
);

export const TableWrapper = styled.div(() => tw`hidden lg:block`);
export const Table = styled.table(() => [
  tw`w-full border-separate`,
  css`
    border-collapse: separate;
    border-spacing: 0 0.5rem;
  `,
]);
export const TBody = styled.tbody(() => tw``);
