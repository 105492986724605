import { ReduxThunk } from "../type";
import {
  setBusyAction,
  setErrorAction,
  setNotification,
} from "../slices/async";
import { AsyncActions } from "../enums/AsyncActions";
import i18n from "../../i18n";
import { ApiRoutes } from "../../common/enums/ApiRoutes";

export const sendCodeToMobilePhone: (phoneNumber: string) => ReduxThunk =
  (phoneNumber) =>
  async (dispatch, _, { client }) => {
    dispatch(setBusyAction({ value: true, key: AsyncActions.SendCodeToPhone }));

    try {
      const res = await client.post<{ result: "Ok" }>(
        ApiRoutes.SendCodeToMobile,
        { phoneNumber }
      );
      dispatch(
        setNotification({
          key: AsyncActions.SendCodeToPhone,
          value: res.data.result,
        })
      );
    } catch (e) {
      dispatch(
        setErrorAction({
          key: AsyncActions.SendCodeToPhone,
          value: i18n.t("register.otp.mobileForm.errorMessage"),
        })
      );
    }
    dispatch(
      setBusyAction({ value: false, key: AsyncActions.SendCodeToPhone })
    );
  };
