import { useTranslation } from "react-i18next";
import {
  CategoryItemWrapper,
  CategoryItemContentWrapper,
  BackgroundImage,
  BalloonsImageWrapper,
  BalloonsImage,
  CategoryItemContent,
  CategoryItemLabel,
  Icon,
} from "./styled";
import { OnShopTabSelect } from "./shared";
import { ShopTab } from "../../../common/types/ShopTabs";

type Props = {
  icon: string;
  label: ShopTab;
  onItemSelect: OnShopTabSelect;
};

export const CategoryItem = ({ label, icon, onItemSelect }: Props) => {
  const { t } = useTranslation();

  return (
    <CategoryItemWrapper onClick={() => onItemSelect(label)}>
      <BackgroundImage />
      <BalloonsImageWrapper pos="left">
        <BalloonsImage />
      </BalloonsImageWrapper>
      <BalloonsImageWrapper pos="right">
        <BalloonsImage />
      </BalloonsImageWrapper>
      <CategoryItemContentWrapper>
        <CategoryItemContent>
          <Icon src={icon} />
          <CategoryItemLabel>{t(`shop.${label}`)}</CategoryItemLabel>
        </CategoryItemContent>
      </CategoryItemContentWrapper>
    </CategoryItemWrapper>
  );
};
