import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Modal } from "../Modal/Modal";
import { Title, Button, Wrapper, Description } from "./styled";
import { VideoPlayer } from "../Video/Video";
import { StoreState } from "../../../store/type";
import { UserRole } from "../../enums/UserRole";

type Props = { onClose: () => void; url: string };

export const TutorialModal = ({ onClose, url }: Props) => {
  const { t } = useTranslation();
  const { role } = useSelector((state: StoreState) => state.user.info);

  return (
    <Modal lg>
      <Wrapper>
        <Title>{t("tutorial.title")}</Title>
        <Description>
          {role === UserRole.Sponsor
            ? t("tutorial.descriptionParent")
            : t("tutorial.descriptionLearner")}
        </Description>
        <VideoPlayer url={url} playing={true} />
        <Button onClick={onClose}>
          {role === UserRole.Sponsor
            ? t("tutorial.closeTutorialParent")
            : t("tutorial.closeTutorialLearner")}
        </Button>
      </Wrapper>
    </Modal>
  );
};
