import tw, { styled, css } from "twin.macro";
import ReactPlayer from "react-player";

export const Wrapper = styled.div<{ hideTimeline?: boolean }>(
  ({ hideTimeline }) => [
    tw`w-full flex items-center justify-center relative`,
    hideTimeline &&
      css`
        video::-webkit-media-controls-timeline {
          display: none;
        }
      `,
  ]
);

export const Video = styled(ReactPlayer)(() => [
  tw`w-full max-w-[46.68rem] min-w-[18.75rem] min-h-[17rem] cursor-pointer`,
]);
