import {
  ChangeEvent,
  Fragment,
  SyntheticEvent,
  useEffect,
  useState,
  KeyboardEvent,
} from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import i18n from "../../../i18n";
import searchIcon from "../../../assets/common/Header/Search.svg";
import {
  DropdownButton,
  DropdownDiv,
  DropdownSearchIcon,
  DropdownWrapper,
  SearchDiv,
  SearchDivWrapper,
  SearchIcon,
  SearchInput,
  SearchLabel,
  SearchDropdownDiv,
} from "./HeaderStyled";
import { redirect } from "../../../store/slices/router";
import { getRoute } from "../../utils/getRoute";
import { RoutePath } from "../../consts/route-path";
import { env } from "../../config/env";

export const SearchBar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const query = params.get("query");

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const toggleDropdown = (e: SyntheticEvent) => {
    e.stopPropagation();
    setDropdownOpen((pre) => !pre);
  };

  const handleEnter = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      search();
    }
  };

  const search = () => {
    dispatch(
      redirect({
        to: getRoute(
          {},
          `${RoutePath.search.locale[env.locale]}?query=${searchQuery}`
        ),
      })
    );
    setDropdownOpen(false);
  };

  useEffect(() => {
    if (query) {
      setSearchQuery(query);
    }
  }, [query]);

  useEffect(() => {
    const hideDropdown = () => {
      setDropdownOpen(false);
    };
    document.body.addEventListener("click", hideDropdown);

    return () => {
      document.body.removeEventListener("click", hideDropdown);
    };
  }, []);

  return (
    <Fragment>
      <SearchDivWrapper onKeyDown={handleEnter}>
        <SearchDiv>
          <SearchInput
            value={searchQuery}
            onChange={handleSearch}
            id="search"
            placeholder={i18n.t("header.searchBar")}
          />
          <SearchLabel htmlFor="search" onClick={search}>
            <SearchIcon src={searchIcon} />
          </SearchLabel>
        </SearchDiv>
      </SearchDivWrapper>
      <DropdownWrapper>
        <DropdownButton
          onClick={(e) => {
            toggleDropdown(e);
          }}
        >
          <DropdownSearchIcon src={searchIcon} />
        </DropdownButton>
        {dropdownOpen && (
          <DropdownDiv
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <SearchDropdownDiv onKeyDown={handleEnter}>
              <SearchInput
                id="search-dropdown"
                placeholder={i18n.t("header.searchBar")}
                value={searchQuery}
                onChange={handleSearch}
              />
              <SearchLabel htmlFor="search-dropdown" onClick={search}>
                <SearchIcon src={searchIcon} />
              </SearchLabel>
            </SearchDropdownDiv>
          </DropdownDiv>
        )}
      </DropdownWrapper>
    </Fragment>
  );
};
