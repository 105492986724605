import { ReduxThunk } from "../type";
import { ApiRoutes } from "../../common/enums/ApiRoutes";
import { LoginForm } from "../../common/types/LoginForm";
import { setBusyAction, setErrorAction } from "../slices/async";
import { AsyncActions } from "../enums/AsyncActions";
import { redirect } from "../slices/router";
import { setItem } from "../../common/utils/localStorageService";
import { LocalStorageKeys } from "../../common/enums/LocalStorageKeys";
import { logIn, setUserInfo } from "../slices/user";
import { RoutePath } from "../../common/consts/route-path";
import { env } from "../../common/config/env";
import i18n from "../../i18n";
import { UserState } from "../types/user";
import { setLearners } from "../slices/learners";
import { addSubscriptions } from "../slices/subscriptions";
import { Learner } from "../types/learners";
import { Subscription } from "../types/subscriptions";
import { Exception } from "../../common/enums/Exceptions";

export const loginParent: ({ username, password }: LoginForm) => ReduxThunk = (
  data
) => {
  return async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({ key: AsyncActions.LoginParentAction, value: true })
    );
    try {
      const logInResponse = await client.post(ApiRoutes.LoginSponsor, data);
      setItem(LocalStorageKeys.TOKEN_KEY, logInResponse.data.access_token);
      const userInfoRes = await client.get<
        UserState["info"] & {
          learners: Learner[];
          subscriptions: Subscription[];
        }
      >(ApiRoutes.UserInfo);

      if (
        !userInfoRes.data.emailConfirmed ||
        !userInfoRes.data.learners?.length ||
        !userInfoRes.data.subscriptions?.length
      ) {
        dispatch(redirect({ to: RoutePath.register.locale[env.locale] }));
      } else {
        dispatch(
          redirect({ to: RoutePath.parentDashboard.locale[env.locale] })
        );
      }
      dispatch(setLearners(userInfoRes.data.learners));
      dispatch(addSubscriptions(userInfoRes.data.subscriptions));
      dispatch(setUserInfo(userInfoRes.data));

      dispatch(logIn());
    } catch (e: any) {
      if (e.response?.data?.message === Exception.InvalidCredentials) {
        dispatch(
          setErrorAction({
            key: AsyncActions.LoginParentAction,
            value: i18n.t("response.loginParent"),
          })
        );
      } else {
        dispatch(
          setErrorAction({
            key: AsyncActions.LoginParentAction,
            value: i18n.t("response.500"),
          })
        );
      }
    }
    dispatch(
      setBusyAction({ key: AsyncActions.LoginParentAction, value: false })
    );
  };
};

export const loginChild: ({ username, password }: LoginForm) => ReduxThunk = ({
  username,
  password,
}) => {
  return async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({ key: AsyncActions.LoginStudentAction, value: true })
    );
    try {
      const logInResponse = await client.post(ApiRoutes.LoginLearner, {
        username,
        password,
      });
      setItem(LocalStorageKeys.TOKEN_KEY, logInResponse.data.access_token);
      const fetchResponse = await client.get(ApiRoutes.UserInfo);
      dispatch(setUserInfo(fetchResponse.data));
      dispatch(logIn());
      dispatch(redirect({ to: RoutePath.home.locale[env.locale] }));
    } catch (e: any) {
      if (e.response?.data?.message === Exception.InvalidCredentials) {
        dispatch(
          setErrorAction({
            key: AsyncActions.LoginStudentAction,
            value: i18n.t("response.loginStudent"),
          })
        );
      } else {
        dispatch(
          setErrorAction({
            key: AsyncActions.LoginStudentAction,
            value: i18n.t("response.500"),
          })
        );
      }
    }
    dispatch(
      setBusyAction({ key: AsyncActions.LoginStudentAction, value: false })
    );
  };
};
