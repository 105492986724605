import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SliceNames } from "../enums/SliceNames";
import { PaymentHistory } from "../types/paymentHistory";
const initialState: PaymentHistory[] = [];

const paymentHistorySlice = createSlice({
  name: SliceNames.PaymentHistory,
  initialState,
  reducers: {
    setPaymentHistory: (
      state,
      { payload }: PayloadAction<PaymentHistory[]>
    ) => {
      state.splice(0, state.length, ...payload);
    },
  },
});

export const { setPaymentHistory } = paymentHistorySlice.actions;

export default paymentHistorySlice.reducer;
