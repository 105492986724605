import { useTranslation } from "react-i18next";
import { PointsTooltip } from "./PointsTooltip";
import starIcon from "../../../../assets/common/Learner-Home/Star.svg";
import tournamentIcon from "../../../../assets/common/Header/Cup.svg";

import * as SubjectStyled from "./SubjectStyled";

type SubjectProps = {
  iconPath: string | undefined;
  name: string | null;
  completed: number | null;
  total: number | null;
  viewLessons?: (isOpen: boolean) => void;
  setSubjectCode?: (subjectCode: string) => void;
  code: string | null;
  hideBtn?: boolean;
  lg?: boolean;
  points: number;
  tournamentPoints: number;
};

export const Subject = ({
  iconPath,
  name,
  completed,
  total,
  code,
  viewLessons,
  setSubjectCode,
  hideBtn,
  lg,
  points,
  tournamentPoints,
}: SubjectProps) => {
  const { t } = useTranslation();

  return (
    <SubjectStyled.SubjectWrapper>
      <SubjectStyled.SubjectIcon src={iconPath} />
      <SubjectStyled.SubjectInfoDiv>
        <SubjectStyled.SubjectNameDiv>
          <SubjectStyled.SubjectName>{name}</SubjectStyled.SubjectName>
          <SubjectStyled.PointsCompletedWrapper>
            <SubjectStyled.PointsWrapper>
              <PointsTooltip icon={starIcon} points={points} />
              <PointsTooltip icon={tournamentIcon} points={tournamentPoints} />
            </SubjectStyled.PointsWrapper>
            <SubjectStyled.ProgressSpan>
              {completed}
              <SubjectStyled.TotalSpan>/{total}</SubjectStyled.TotalSpan>
            </SubjectStyled.ProgressSpan>
          </SubjectStyled.PointsCompletedWrapper>
        </SubjectStyled.SubjectNameDiv>
        <SubjectStyled.ProgressBarWrapper lg>
          <SubjectStyled.ProgressBarInner
            lg={lg}
            width={`${completed && total && (completed / total) * 100}%`}
          />
        </SubjectStyled.ProgressBarWrapper>
        {!hideBtn && (
          <SubjectStyled.ViewLessonBtn
            onClick={() => {
              if (viewLessons && setSubjectCode) {
                viewLessons(true);
                setSubjectCode(code ? code : "");
              }
            }}
          >
            {t("learnerHome.viewLessonsBtn")}
          </SubjectStyled.ViewLessonBtn>
        )}
      </SubjectStyled.SubjectInfoDiv>
    </SubjectStyled.SubjectWrapper>
  );
};
