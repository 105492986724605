import { ChangePassword } from "../ParentDashboard/ChangePassword/ChangePassword";
import { Wrapper } from "./ForgotPasswordStyled";

export const ForgotPassword = () => {
  return (
    <Wrapper>
      <ChangePassword />
    </Wrapper>
  );
};
