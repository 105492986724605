import { Classroom } from "../types/school";

export const prepareClassrooms = (classrooms: Classroom[]): Classroom[] => {
  return classrooms.map((classroom) => ({
    ...classroom,
    title: `${classroom.title.slice(0, 1).toUpperCase()}${classroom.title
      .slice(1)
      .replace(/\W/, " ")}`,
  }));
};
