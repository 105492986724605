import tw, { styled } from "twin.macro";

export const InnerPointsDivStyled = styled.div<{
  smallPadding: boolean;
  transparent: boolean;
  sans?: boolean;
}>(({ smallPadding, transparent, sans }) => [
  tw`flex justify-center rounded-full px-3 py-2 items-center`,
  smallPadding && tw`px-1 py-0`,
  !transparent && tw`bg-black`,
  sans && tw`font-sans`,
]);

export const PointsImageStyled = styled.img<{ size: "xs" | "xxs" }>(
  ({ size }) => [
    tw`mr-1`,
    size === "xs" && tw`w-4 h-4`,
    size === "xxs" && tw`w-2 h-2`,
  ]
);

export const PointsStyled = styled.span<{ size?: "xs" | "xxs" }>(({ size }) => [
  tw`text-light-gold text-sm font-bold font-sans`,
  size === "xxs" && tw`text-xxs`,
]);
