import {
  AnswerLabel,
  AnswerWrapper,
  RankWrapper,
} from "../../../components/LessonPage/LessonSurvey/styled";
import { QuizAnswer } from "../../../store/types/survey";

const getLetterOrderFromNumberOrder = (n: number) => {
  return String.fromCharCode("A".charCodeAt(0) + n);
};

type Props = {
  answer: QuizAnswer;
  order: number;
  selected: boolean;
  onSelectAnswer: (id: string) => void;
};

export const QuestionAnswer = ({
  answer,
  order,
  selected,
  onSelectAnswer,
}: Props) => {
  return (
    <AnswerWrapper
      onClick={() => onSelectAnswer(answer.id)}
      $highlight={selected}
    >
      <AnswerLabel>{answer.text}</AnswerLabel>
      <RankWrapper>{getLetterOrderFromNumberOrder(order)}</RankWrapper>
    </AnswerWrapper>
  );
};
