import * as yup from "yup";
import i18n from "../../i18n";

export const paymentSchema = () => {
  return yup.object({
    name: yup
      .string()
      .required(
        i18n.t("validation.required", { fieldName: i18n.t("fieldNames.name") })
      )
      .min(
        2,
        i18n.t("validation.min", {
          fieldName: i18n.t("fieldNames.name"),
          suffix: "a",
          length: 2,
        })
      )
      .max(
        64,
        i18n.t("validation.max", { fieldName: i18n.t("fieldNames.name") })
      ),
    email: yup
      .string()
      .trim()
      .required(
        i18n.t("validation.required", { fieldName: i18n.t("fieldNames.email") })
      )
      .email(i18n.t("validation.email"))
      .max(
        64,
        i18n.t("validation.max", { fieldName: i18n.t("fieldNames.email") })
      ),
    city: yup
      .string()
      .required(
        i18n.t("validation.required", { fieldName: i18n.t("fieldNames.city") })
      )
      .min(
        2,
        i18n.t("validation.min", {
          fieldName: i18n.t("fieldNames.city"),
          suffix: "a",
          length: 2,
        })
      )
      .max(
        64,
        i18n.t("validation.max", { fieldName: i18n.t("fieldNames.city") })
      ),
    country: yup
      .string()
      .required(
        i18n.t("validation.required", {
          fieldName: i18n.t("fieldNames.country"),
        })
      )
      .min(
        2,
        i18n.t("validation.min", {
          fieldName: i18n.t("fieldNames.country"),
          suffix: "a",
          length: 2,
        })
      )
      .max(
        64,
        i18n.t("validation.max", { fieldName: i18n.t("fieldNames.country") })
      ),
    state: yup
      .string()
      .optional()
      .matches(/\p{L}/gu, {
        excludeEmptyString: true,
        message: i18n.t("register.payments.state.letters"),
      }),
    postalCode: yup.string().required(
      i18n.t("validation.required", {
        fieldName: i18n.t("fieldNames.postalCode"),
      })
    ),
    phoneNumber: yup
      .string()
      .required(
        i18n.t("validation.required", {
          fieldName: i18n.t("fieldNames.phoneNumber"),
        })
      )
      .min(
        2,
        i18n.t("validation.min", {
          fieldName: i18n.t("fieldNames.phoneNumber"),
          suffix: "a",
          length: 2,
        })
      )
      .max(
        64,
        i18n.t("validation.max", {
          fieldName: i18n.t("fieldNames.phoneNumber"),
        })
      ),
    nameOnCard: yup
      .string()
      .required(
        i18n.t("validation.required", {
          fieldName: i18n.t("fieldNames.nameOnCard"),
        })
      )
      .min(
        2,
        i18n.t("validation.min", {
          fieldName: i18n.t("fieldNames.nameOnCard"),
          suffix: "a",
          length: 2,
        })
      )
      .max(
        64,
        i18n.t("validation.max", { fieldName: i18n.t("fieldNames.nameOnCard") })
      ),
    address: yup
      .string()
      .required(
        i18n.t("validation.required", {
          fieldName: i18n.t("fieldNames.address"),
        })
      )
      .min(
        2,
        i18n.t("validation.min", {
          fieldName: i18n.t("fieldNames.address"),
          suffix: "a",
          length: 2,
        })
      )
      .max(
        64,
        i18n.t("validation.max", { fieldName: i18n.t("fieldNames.address") })
      ),
  });
};

export const paymentMethodSchema = () => {
  return yup.object({
    nameOnCard: yup
      .string()
      .required(
        i18n.t("validation.required", { fieldName: i18n.t("fieldNames.name") })
      )
      .min(
        2,
        i18n.t("validation.min", {
          fieldName: i18n.t("fieldNames.name"),
          suffix: "a",
          length: 2,
        })
      )
      .max(
        64,
        i18n.t("validation.max", { fieldName: i18n.t("fieldNames.name") })
      ),
    email: yup
      .string()
      .trim()
      .required(
        i18n.t("validation.required", { fieldName: i18n.t("fieldNames.email") })
      )
      .email(i18n.t("validation.email"))
      .max(
        64,
        i18n.t("validation.max", { fieldName: i18n.t("fieldNames.email") })
      ),
  });
};
