import React, { useEffect, useState } from "react";
import {
  SubscriptionTypeWrapper,
  SubscriptionTypeHeading,
  SubscriptionTypeName,
  SubscriptionTypeSelectWrapper,
  SubscriptionTypeInput,
  SubscriptionTypeLabel,
  SubscriptionPriceWrapper,
  SubscriptionPriceHeading,
  SubscriptionPriceAmount,
  SubscriptionTotalWrapper,
  SubscriptionTotalHeading,
  SubscriptionTotalAmount,
  SubscriptionHeadingWrapper,
  SubscriptionDeleteIcon,
} from "./PaymentStyled";
import { useDispatch, useSelector } from "react-redux";
import {
  postEstimatedBillingAction,
  postEstimatedBillingRenewalAction,
} from "../../../store/thunks/payments/postEstimatedBilling";
import { StoreState } from "../../../store/type";
import { SubscriptionType } from "../../../common/enums/SubscriptionType";
import { useDebounce } from "../../../common/hooks/useDebounce";
import { omit } from "ramda";
import { EstimatedBillingForm } from "../../../common/types/EstimatedBillingForm";
import { StyledError } from "../../../common/components/Error/Error";
import { useTranslation } from "react-i18next";
import { Learner } from "../../../store/types/learners";
import { useLearners } from "../../../common/hooks/useLearners";
import {
  setEstimatedBilling,
  setEstimatedBillingError,
} from "../../../store/slices/payment";
import deleteIcon from "../../../assets/common/Delete.svg";
import { DeleteLearnerModal } from "./DeleteLearnerModal";

type Props = {
  code?: string;
  subscriptions: EstimatedBillingForm;
  setSubscriptions: React.Dispatch<React.SetStateAction<EstimatedBillingForm>>;
  sm?: boolean;
  renewal?: boolean;
  learner?: Learner;
  showPeriod: boolean;
};

export const EstimatedBilling = ({
  sm,
  code = "",
  subscriptions,
  setSubscriptions,
  renewal,
  learner,
  showPeriod,
}: Props) => {
  const dispatch = useDispatch();
  const [learnerToDelete, setLearnerToDelete] = useState<null | string>(null);

  const shouldRenderDelete = !renewal && (sm ? subscriptions.length > 1 : true);

  const { neverSubscribed: learners } = useLearners();
  const addedSubscriptions = useSelector(
    (state: StoreState) => state.subscriptions
  );

  const { data: billing, error } = useSelector(
    (state: StoreState) => state.payments.estimatedBilling
  );
  const { t } = useTranslation();

  const debounced = useDebounce(subscriptions, 300);

  useEffect(() => {
    return () => {
      dispatch(setEstimatedBillingError(null));
      dispatch(setEstimatedBilling({ learners: [] }));
    };
  }, []);

  useEffect(() => {
    if (!debounced || debounced.length === 0) return;

    dispatch(
      renewal
        ? postEstimatedBillingRenewalAction(
            debounced.map(omit(["learnerName"]))[0],
            code
          )
        : postEstimatedBillingAction(debounced.map(omit(["learnerName"])), code)
    );
  }, [debounced, code]);

  useEffect(() => {
    if (!learners) return;
    if (!renewal) {
      const filteredLearners = learners.filter((learner) => {
        return !addedSubscriptions?.some((subscription) => {
          return learner.id === subscription.learnerId;
        });
      });
      setSubscriptions(
        filteredLearners.map(({ username, firstName, lastName }) => ({
          username,
          subscriptionType: SubscriptionType.Monthly,
          learnerName: `${firstName} ${lastName}`,
        }))
      );
    } else if (renewal && learner) {
      setSubscriptions([
        {
          username: learner.username,
          subscriptionType: SubscriptionType.Monthly,
          learnerName: `${learner.firstName} ${learner.lastName}`,
        },
      ]);
    }
  }, [learners]);

  const handleChangeSubscriptionType =
    (index: number, type: SubscriptionType) => () => {
      setSubscriptions((pre) => [
        ...pre.slice(0, index),
        { ...pre[index], subscriptionType: type },
        ...pre.slice(index + 1),
      ]);
    };

  const handleDelete = (learnerUsername: string) => {
    if (renewal) return;
    // if adding one learner in parent dashboard
    if (sm) {
      setSubscriptions((pre) =>
        pre.filter((l) => l.username !== learnerUsername)
      );
      return;
    }
    setLearnerToDelete(learnerUsername);
  };

  return (
    <SubscriptionTypeWrapper sm={sm}>
      {subscriptions.map(({ username, subscriptionType, learnerName }, i) => {
        const billingUser = billing.learners.find(
          (user) => user.username === username
        );
        return (
          <React.Fragment key={`${learnerName}-${i}`}>
            <SubscriptionHeadingWrapper>
              <SubscriptionTypeHeading>
                {t("register.payment.estimatedBilling.title")}
              </SubscriptionTypeHeading>
              {shouldRenderDelete && (
                <SubscriptionDeleteIcon
                  src={deleteIcon}
                  onClick={() => handleDelete(username)}
                />
              )}
            </SubscriptionHeadingWrapper>
            <SubscriptionTypeName>{learnerName}</SubscriptionTypeName>
            <SubscriptionTypeHeading>
              {t("register.payment.estimatedBilling.subscription")}
            </SubscriptionTypeHeading>
            <SubscriptionTypeSelectWrapper>
              <SubscriptionTypeInput
                type="radio"
                checked={subscriptionType === SubscriptionType.Monthly}
                onChange={handleChangeSubscriptionType(
                  i,
                  SubscriptionType.Monthly
                )}
              />
              <SubscriptionTypeLabel>
                {t(
                  `register.payment.estimatedBilling.subscriptionType.${SubscriptionType.Monthly}`
                )}
              </SubscriptionTypeLabel>
              <SubscriptionTypeInput
                type="radio"
                checked={subscriptionType === SubscriptionType.Yearly}
                onChange={handleChangeSubscriptionType(
                  i,
                  SubscriptionType.Yearly
                )}
              />
              <SubscriptionTypeLabel>
                {t(
                  `register.payment.estimatedBilling.subscriptionType.${SubscriptionType.Yearly}`
                )}
              </SubscriptionTypeLabel>
            </SubscriptionTypeSelectWrapper>
            <SubscriptionPriceWrapper>
              <SubscriptionPriceHeading>
                {t("register.payment.estimatedBilling.price")}
              </SubscriptionPriceHeading>
              <SubscriptionPriceAmount>
                {billing.currencySymbol}
                {billingUser?.price.actualPrice}
                {showPeriod &&
                  subscriptionType === SubscriptionType.Monthly &&
                  !!billingUser?.price.actualPrice &&
                  `/${t(`register.payment.estimatedBilling.per.month`)}`}
              </SubscriptionPriceAmount>
            </SubscriptionPriceWrapper>
          </React.Fragment>
        );
      })}
      <SubscriptionTotalWrapper>
        <SubscriptionTotalHeading>
          {t("register.payment.estimatedBilling.total")}
        </SubscriptionTotalHeading>
        <SubscriptionTotalAmount>
          {billing.currencySymbol}
          {billing.toPay}
        </SubscriptionTotalAmount>
      </SubscriptionTotalWrapper>
      {billing.hasCodeDiscount && (
        <SubscriptionTotalWrapper noBorder>
          <SubscriptionTotalHeading>
            {t("register.payment.estimatedBilling.afterCouponExpires")}
          </SubscriptionTotalHeading>
          <SubscriptionTotalAmount>
            {billing.currencySymbol}
            {billing.toPayAfterCodeExpires}
          </SubscriptionTotalAmount>
        </SubscriptionTotalWrapper>
      )}
      {error && (
        <StyledError topMargin="1rem" bottomMargin="0">
          {error}
        </StyledError>
      )}
      {!!learnerToDelete && (
        <DeleteLearnerModal
          onClose={() => setLearnerToDelete(null)}
          username={learnerToDelete}
          learner={
            subscriptions.find((l) => l.username === learnerToDelete)
              ?.learnerName || "Ne postoji"
          }
        />
      )}
    </SubscriptionTypeWrapper>
  );
};
