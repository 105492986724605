import { useTranslation } from "react-i18next";
import { useUserHomeRoute } from "../../../common/hooks/useUserHomeRoute";
import {
  Arrow,
  BannerHeading,
  BannerHomeSegment,
  BannerWrapper,
  PathSegment,
  PathWrapper,
} from "./BannerStyled";

type BannerProps = {
  bannerPath: string | undefined;
};

export const Banner = ({ bannerPath }: BannerProps) => {
  const { t } = useTranslation();
  const homeRoute = useUserHomeRoute();
  return (
    <BannerWrapper>
      <BannerHeading>{bannerPath}</BannerHeading>
      <PathWrapper>
        <BannerHomeSegment to={`${homeRoute}?step=1`}>
          {t("parentDashboard.monitorProgress.homePath")}
        </BannerHomeSegment>
        <Arrow>&gt;</Arrow>
        <PathSegment>{bannerPath} </PathSegment>
      </PathWrapper>
    </BannerWrapper>
  );
};
