import axios from "axios";
import { store } from "../../store/store";
import { env } from "./env";
import { getToken } from "../utils/localStorageService";
import { setErrorAction } from "../../store/slices/async";
import { AsyncActions } from "../../store/enums/AsyncActions";
import { RoutePath } from "../consts/route-path";
import { ApiRoutes } from "../enums/ApiRoutes";
import { redirect } from "../../store/slices/router";
import { Exception } from "../enums/Exceptions";

const client = axios.create({
  baseURL: env.baseUrl,
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (
      err.response?.status === 401 &&
      !!getToken() &&
      !(err.response?.data?.path === ApiRoutes.UserInfo) &&
      err.response?.data?.message !== Exception.InvalidCredentials
    ) {
      store.dispatch(
        setErrorAction({ key: AsyncActions.Authorization, value: "error" })
      );
      store.dispatch(
        redirect({ to: RoutePath.unauthorized.locale[env.locale] })
      );
    }
    return Promise.reject(err);
  }
);

client.interceptors.request.use((config) => {
  config.headers = {
    Authorization: `Bearer ${getToken()}`,
    "Content-Type": "application/json",
  };
  return config;
});

export { client };
