import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AvatarDiv,
  ChangeFontDiv,
  ChangeFontButton,
  AvatarWrapper,
} from "./LearnersListStyled";
import {
  LearnerWrapper,
  InfoDiv,
  NameStyled,
  InfoTextStyled,
  LightTitle,
  CopyInfoDiv,
  LoginInfoStyled,
  CopyButton,
  CopyIcon,
  SendInfoStyled,
  ChangePinDiv,
} from "../../../Register/LearnerPins/LearnerPinsStyled";
import copyIcon from "../../../../assets/common/copy.svg";
import sendIcon from "../../../../assets/common/send.svg";
import { Learner } from "../../../../store/types/learners";
import Button from "../../../../common/components/Button/Button";
import { ChangePinModal } from "./ChangePinModal";
import { ChooseFontModal } from "../../../Register/AddLearners/ChooseFontModal";
import { useDispatch } from "react-redux";
import { setNotification } from "../../../../store/slices/async";
import { AsyncActions } from "../../../../store/enums/AsyncActions";
import { useAsyncState } from "../../../../common/hooks/useAsyncState";
import { Avatar } from "../../../../common/components/Avatar/Avatar";

type LearnersListProps = {
  learners: Learner[] | null | undefined;
  changePin?: boolean;
};

export const LearnersList = ({ learners, changePin }: LearnersListProps) => {
  const { notification: chooseFontNotification } = useAsyncState(
    AsyncActions.ChangeFont
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [changePinOpen, setChangePinOpen] = useState("");
  const [changeFontOpen, setChangeFontOpen] = useState("");

  const toggleChangePinModal = (learnerId: string) => {
    if (learnerId) {
      setChangePinOpen(learnerId);
    } else {
      setChangePinOpen("");
    }
  };

  const toggleChangeFontModal = (learnerId: string) => {
    dispatch(
      setNotification({ key: AsyncActions.ChangeFont, value: "In Progress" })
    );
    if (learnerId) {
      setChangeFontOpen(learnerId);
    } else {
      setChangeFontOpen("");
    }
  };
  return (
    <React.Fragment>
      {learners?.map((learner, index) => {
        return (
          <LearnerWrapper bgWhite key={index}>
            <AvatarDiv>
              <AvatarWrapper>
                <Avatar
                  items={learner.items?.map((i) => i.avatarItem) || []}
                  icon={learner.avatar?.image}
                />
              </AvatarWrapper>
            </AvatarDiv>
            <InfoDiv>
              <NameStyled>
                {learner.firstName} {learner.lastName}
              </NameStyled>
              <InfoTextStyled>
                {learner.activeClass.name}, {learner.school?.name}
              </InfoTextStyled>
            </InfoDiv>

            <InfoDiv>
              <LightTitle>{t("register.LearnersInfo.username")}</LightTitle>
              <CopyInfoDiv>
                <LoginInfoStyled>{learner.username}</LoginInfoStyled>
                <CopyButton
                  onClick={() => {
                    navigator.clipboard.writeText(learner.username);
                  }}
                >
                  <CopyIcon src={copyIcon} />
                </CopyButton>
              </CopyInfoDiv>
            </InfoDiv>
            <InfoDiv>
              <LightTitle>{t("register.LearnersInfo.pin")}</LightTitle>
              <CopyInfoDiv>
                <LoginInfoStyled>{learner.pinCode}</LoginInfoStyled>
                <CopyButton
                  onClick={() => {
                    navigator.clipboard.writeText(learner.pinCode);
                  }}
                >
                  <CopyIcon src={copyIcon} />
                </CopyButton>
              </CopyInfoDiv>
            </InfoDiv>
            {changePin ? (
              <React.Fragment>
                <ChangeFontDiv>
                  <ChangeFontButton
                    onClick={() => {
                      toggleChangeFontModal(learner.id);
                    }}
                  >
                    {t("parentDashboard.manage.changeFont")}
                  </ChangeFontButton>
                </ChangeFontDiv>
                <ChangePinDiv>
                  <Button
                    onClick={() => {
                      toggleChangePinModal(learner.id);
                    }}
                    invert
                    buttonWidth="10.375rem"
                    buttonHeight="2.5rem"
                  >
                    {t("parentDashboard.manage.changePin")}
                  </Button>
                </ChangePinDiv>
              </React.Fragment>
            ) : (
              <SendInfoStyled src={sendIcon} />
            )}
            {changePinOpen === learner.id && (
              <ChangePinModal
                learner={learner}
                toggleModal={toggleChangePinModal}
              />
            )}
            {changeFontOpen === learner.id && chooseFontNotification && (
              <ChooseFontModal
                learners={[learner]}
                toggleModal={toggleChangeFontModal}
              />
            )}
          </LearnerWrapper>
        );
      })}
    </React.Fragment>
  );
};
