import { LoadingDot, LoadingDotWrapper } from "./styled";

const delays = ["none", "1", "2"] as const;

export const AnimatedDots = () => {
  return (
    <LoadingDotWrapper>
      {delays.map((delay) => (
        <LoadingDot key={delay} delay={delay} />
      ))}
    </LoadingDotWrapper>
  );
};
