import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  ButtonDiv,
  Heading,
  Info,
  InfoDiv,
} from "../AvailableLearner/AvailableLearnerStyled";
import {
  CancelSubscriptionButton,
  NoActiveSubscriptionsWrapper,
  StripeSubscriptionWrapper,
} from "./styled";
import { CancelSubscriptionModal } from "./CancelSubscriptionModal";
import { StoreState } from "../../../../../store/type";
import { Spinner } from "../../../../../common/components/Spinner/Spinner";
import { useAsyncState } from "../../../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../../../store/enums/AsyncActions";
import { getStripeSubscriptions } from "../../../../../store/thunks/payments/stripeSubscriptionTasks";

export const StripeSubscription = () => {
  const { t } = useTranslation();
  const [subscriptionId, setSubscriptionId] = useState<null | string>(null);
  const { requestInProgress } = useAsyncState(
    AsyncActions.GetStripeSubscriptions
  );
  const dispatch = useDispatch();
  const subscriptions = useSelector(
    (state: StoreState) => state.user.info.stripeSubscriptions
  );

  useEffect(() => {
    dispatch(getStripeSubscriptions());
  }, []);

  return (
    <Fragment>
      {subscriptions.map(({ interval, stripeSubscriptionId, learner }) => (
        <StripeSubscriptionWrapper key={stripeSubscriptionId}>
          <InfoDiv>
            <Heading>{t("parentDashboard.subscriptions.stripe.name")}</Heading>
            <Info>{learner}</Info>
          </InfoDiv>
          <InfoDiv>
            <Heading>{t("parentDashboard.subscriptions.stripe.type")}</Heading>
            <Info>
              {interval === "month"
                ? t("parentDashboard.subscriptions.stripe.monthly")
                : t("parentDashboard.subscriptions.stripe.yearly")}
            </Info>
          </InfoDiv>
          <ButtonDiv>
            <CancelSubscriptionButton
              onClick={() => setSubscriptionId(stripeSubscriptionId)}
            >
              {t("parentDashboard.subscriptions.stripe.cancel")}
            </CancelSubscriptionButton>
          </ButtonDiv>
        </StripeSubscriptionWrapper>
      ))}
      {requestInProgress && (
        <NoActiveSubscriptionsWrapper>
          <Spinner size="sm" />{" "}
        </NoActiveSubscriptionsWrapper>
      )}

      {subscriptions.length === 0 && !requestInProgress && (
        <NoActiveSubscriptionsWrapper>
          <Info>
            {t("parentDashboard.subscriptions.stripe.noSubscriptions")}
          </Info>
        </NoActiveSubscriptionsWrapper>
      )}
      {typeof subscriptionId === "string" && (
        <CancelSubscriptionModal
          onClose={() => setSubscriptionId(null)}
          subscriptionId={subscriptionId}
        />
      )}
    </Fragment>
  );
};
