import tw, { styled } from "twin.macro";

export const Wrapper = styled.div(
  () => tw`w-full flex justify-center rounded-lg bg-white px-2 py-10`
);

export const ContentWrapper = styled.div(
  () => tw`w-full max-w-[23.125rem] flex flex-col items-center`
);

export const Heading = styled.h2(
  () => tw`font-sans font-semibold text-center text-mine-shaft mb-3`
);

export const ChangePasswordDescription = styled.p(
  () => tw`font-sans font-medium text-sm text-center text-mine-shaft mb-8`
);
