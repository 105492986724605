import tw, { styled } from "twin.macro";

export const GridItemDiv = styled.div(() => tw`flex w-full`);

export const TitleStyled = styled.span(
  () => tw`font-sans text-black opacity-60 w-38`
);

export const ColonStyled = styled.span(
  () => tw`font-sans text-black opacity-60 ml-1 mr-1 md:ml-2 md:mr-6`
);

export const InfoStyled = styled.span(
  () => tw`font-sans text-black font-bold w-48`
);
