import { Link } from "react-router-dom";
import { SearchResult } from "../../store/types/assistant";
import {
  SearchResultTitle,
  SearchResultSummary,
  SearchResultWrapper,
} from "./styled";
import { getRoute } from "../../common/utils/getRoute";
import { RoutePath } from "../../common/consts/route-path";
import { env } from "../../common/config/env";

type Props = SearchResult & { onSearchClicked: () => void };

export const SearchResultComponent = ({
  title,
  summary,
  subject,
  code,
  onSearchClicked,
}: Props) => {
  return (
    <SearchResultWrapper>
      <Link
        to={getRoute(
          {
            subjectCode: subject,
            lessonCode: code,
          },

          RoutePath.lessonPage.locale[env.locale]
        )}
        onClick={onSearchClicked}
      >
        <SearchResultTitle>{title}</SearchResultTitle>
      </Link>
      <SearchResultSummary>
        {summary.length < 100 ? summary.length : summary.slice(0, 100) + "..."}
      </SearchResultSummary>
    </SearchResultWrapper>
  );
};
