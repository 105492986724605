import { useTranslation } from "react-i18next";

import {
  StyledRow,
  StarIcon,
  TData,
} from "../../../common/components/Tournament/styled";
import { TournamentTableItemComponent } from "../../../common/components/Tournament/TournamentTableItemComponent";
import starIcon from "../../../assets/common/Lesson-Page/Star.svg";

import { TournamentResultForSponsor } from "../../../store/types/tournament";
import { UserDetails } from "../../Tournament/TournamentResult/UserDetails";
import dayjs from "../../../common/config/dayjs";
import { TournamentResultStatus } from "../../../common/enums/TournamentResultStatus";
import { TableItemComponent } from "../../../common/components/Tournament/TableItemComponent";

export const LearnerTournamentHistoryItem = ({
  tournamentName,
  avatar,
  grade,
  username,
  endsAt,
  points,
  status,
  items,
}: TournamentResultForSponsor) => {
  const { t } = useTranslation();

  return (
    <StyledRow>
      <TData>
        <TournamentTableItemComponent name={tournamentName} />
      </TData>
      <TData>
        <UserDetails
          avatar={avatar}
          grade={grade}
          username={username}
          items={items.map((i) => i.avatarItem)}
        />
      </TData>
      <TData>
        <TableItemComponent
          label={t("tournaments.pastTournamentDetails.endedAt")}
          value={dayjs(endsAt).format("DD MMM YYYY/ hA")}
        />
      </TData>
      <TData>
        <TableItemComponent
          label={t("tournaments.pastTournamentDetails.pointsEarned")}
          value={
            status === TournamentResultStatus.Completed ? (
              <>
                <StarIcon src={starIcon} />
                {points}
              </>
            ) : (
              t("tournaments.notFinished")
            )
          }
        />
      </TData>
    </StyledRow>
  );
};
