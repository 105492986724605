import { ReduxThunk } from "../../type";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { markAllAsRead, markAsRead } from "../../slices/notification";

export const markNotificationsAsRead: () => ReduxThunk =
  () =>
  async (dispatch, _, { client }) => {
    dispatch(markAllAsRead());
    try {
      await client.post(ApiRoutes.MarkNotificationsAsRead);
    } catch (e) {
      // ignore
    }
  };

export const markNotificationAsRead: (id: string) => ReduxThunk =
  (id) =>
  async (dispatch, _, { client }) => {
    dispatch(markAsRead(id));
    try {
      await client.post(`${ApiRoutes.MarkNotificationAsRead}/${id}`);
    } catch {
      //ignore
    }
  };
