import { Id, toast } from "react-toastify";

export const showNotification = (
  message: string,
  type: "error" | "success"
) => {
  toast[type](message, {
    position: toast.POSITION.TOP_CENTER,
    toastId: message as Id,
    progress: undefined,
  });
};
