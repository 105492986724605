import { useEffect, useState } from "react";
import { fetchQuiz } from "../../../store/thunks/Learner/fetchQuiz";
import { SurveyResult } from "./SurveyResult";
import { ResultWrapper, Wrapper } from "./styled";
import { SurveyError } from "./SurveyError";
import { Spinner } from "../Spinner/Spinner";
import { QuestionComponent } from "./QuestionComponent";
import { SurveyActions } from "./SurveyActions";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../store/type";
import { SurveyResult as SurveyResultType } from "../../../store/types/survey";
import { useAsyncState } from "../../hooks/useAsyncState";
import { AsyncActions } from "../../../store/enums/AsyncActions";

export type UserAnswers<
  Question extends string,
  Answer extends string
> = Record<Question, Answer>;

type Props = {
  onSubmit: (result: SurveyResultType) => void;
  onCancel: () => void;
  resultPageTitle: string;
  isTournament?: boolean;
};

export const Survey = ({
  onSubmit,
  resultPageTitle,
  onCancel,
  isTournament = false,
}: Props) => {
  const params = useParams<{ quizId: string }>();
  const quizId = params.quizId;
  const dispatch = useDispatch();

  const { quiz, result, answers } = useSelector((s: StoreState) => s.survey);
  const { requestInProgress, error } = useAsyncState(AsyncActions.FetchQuiz);

  const [userAnswers, setUserAnswers] = useState<UserAnswers<string, string>>(
    {} as UserAnswers<string, string>
  );

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const currentQuestionId = quiz?.questions[currentQuestionIndex]?.id || "";

  const currentQuestionAnswer = userAnswers[currentQuestionId];

  useEffect(() => {
    if (!quizId) return;
    dispatch(fetchQuiz(quizId, isTournament));
  }, [quizId, isTournament]);

  const handleAnswer = (questionId: string, answerId: string) => {
    setUserAnswers((pre) => ({ ...pre, [questionId]: answerId }));
  };

  const handleSkip = () => {
    if (!quiz) return;
    setCurrentQuestionIndex((pre) =>
      pre === quiz.questions.length - 1 ? pre : pre + 1
    );
  };

  const handleGoToPreviousQuestion = () => {
    setCurrentQuestionIndex((pre) => (pre === 0 ? 0 : pre - 1));
  };

  if (!!result && !!answers) {
    return (
      <SurveyResult isTournament={isTournament} pageTitle={resultPageTitle} />
    );
  }

  if (error)
    return (
      <ResultWrapper>
        <SurveyError msg={error} />
      </ResultWrapper>
    );

  if (requestInProgress || !quiz) {
    return (
      <ResultWrapper>
        <Spinner />
      </ResultWrapper>
    );
  }

  if (quiz.questions.length === 0) {
    return (
      <ResultWrapper>
        <SurveyError msg={"error"} />
      </ResultWrapper>
    );
  }

  return (
    <Wrapper>
      <QuestionComponent
        totalQuestions={quiz.questions.length}
        currentQuestion={currentQuestionIndex + 1}
        question={quiz.questions[currentQuestionIndex]}
        onSkipQuestion={handleSkip}
        onPreviousQuestion={handleGoToPreviousQuestion}
        onQuestionAnswer={handleAnswer}
        answer={currentQuestionAnswer}
        onNextQuestion={handleSkip}
      />
      <SurveyActions
        onCancel={onCancel}
        onSubmit={onSubmit}
        answers={userAnswers}
      />
    </Wrapper>
  );
};
