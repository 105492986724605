import { ReduxThunk } from "../../type";
import { removeLearner } from "../../slices/learners";
import { setBusyAction, setErrorAction } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import i18n from "../../../i18n";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { Exception } from "../../../common/enums/Exceptions";

export const removeLearnerThunk: (username: string) => ReduxThunk = (
  username
) => {
  return async (dispatch, _, { client }) => {
    const key: any = `${AsyncActions.RemoveLearner}/${username}`;
    dispatch(
      setBusyAction({
        key,
        value: true,
      })
    );
    try {
      await client.delete(`${ApiRoutes.AddLearners}/${username}`);

      dispatch(removeLearner({ username }));
    } catch (err: any) {
      if (
        err?.response?.data?.message ===
        Exception.RemoveLearnerFailedBecauseOfActiveSubscriptions
      ) {
        dispatch(
          setErrorAction({
            key,
            value: i18n.t("response.failRemoveBecauseActiveSubscription"),
          })
        );
      }
      dispatch(
        setErrorAction({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          key,
          value: i18n.t("response.removeLearner"),
        })
      );
    }
    dispatch(
      setBusyAction({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        key: `${AsyncActions.RemoveLearner}/${username}`,
        value: false,
      })
    );
  };
};
