import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { StoreState } from "../../store/type";
import {
  AssistantDialogContentDescription,
  ResultWrapper,
  LoadingWrapper,
} from "./styled";
import { OptionSelectedTitle } from "./OptionSelectedTitle";
import { NoResultComponent } from "./NoResult";
import smart from "../../assets/common/assistant/smart.svg";
import { useAsyncState } from "../../common/hooks/useAsyncState";
import { AsyncActions } from "../../store/enums/AsyncActions";
import { AnimatedDots } from "../../common/components/AnimatedDots/AnimatedDots";

export const LessonSummary = () => {
  const { t } = useTranslation();
  const lesson = useSelector((state: StoreState) => state.subjects.lesson);
  const { requestInProgress } = useAsyncState(AsyncActions.Assistant);

  let summary = useSelector(
    (state: StoreState) => state.assistant.lessonSummary
  );

  summary ??= "";

  summary = summary.split(".").reduce((acc, cur, i) => {
    const mergeChar = i % 2 === 0 ? ".\n" : ".";
    return acc.trim() + mergeChar + cur.trim();
  });

  return (
    <>
      <OptionSelectedTitle
        icon={smart}
        text={t("assistant.dialog.options.summarize")}
      />
      <AssistantDialogContentDescription>
        {lesson.name}
      </AssistantDialogContentDescription>

      {requestInProgress ? (
        <LoadingWrapper>
          <AnimatedDots />
        </LoadingWrapper>
      ) : summary === "" ? (
        <NoResultComponent />
      ) : (
        <ResultWrapper>{summary}</ResultWrapper>
      )}
    </>
  );
};
