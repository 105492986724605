import { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { NoTournaments } from "./NoTournaments";

import { getRecentTournaments } from "../../../store/thunks/Learner/tournament";
import { StoreState } from "../../../store/type";

import { HistoryTournamentListItemLg } from "../../../common/components/Tournament/HistoryTournamentListItemLg";
import { HistoryTournamentList } from "../../../common/components/Tournament/TournamentHistoryList";
import {
  Table,
  TBody,
  TableWrapper,
} from "../../../common/components/Tournament/styled";

export const TournamentsHistory = () => {
  const dispatch = useDispatch();
  const tournaments = useSelector(
    (state: StoreState) => state.tournaments.history
  );

  useEffect(() => {
    dispatch(getRecentTournaments());
  }, []);

  return tournaments.length > 0 ? (
    <Fragment>
      <TableWrapper>
        <Table>
          <TBody>
            {tournaments.map((t) => (
              <HistoryTournamentListItemLg key={t.id} {...t} />
            ))}
          </TBody>
        </Table>
      </TableWrapper>
      <HistoryTournamentList tournaments={tournaments} />
    </Fragment>
  ) : (
    <NoTournaments />
  );
};
