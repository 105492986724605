import {
  DetailsWrapper,
  ContactUsParagraph,
  ContactUsLink,
  RequestCodeDiv,
  RequestCodeImage,
  RequestCodeParagraph,
  RequestCodeWrapper,
} from "./PaymentStyled";
import paymentImg from "../../../assets/common/payment.svg";
import { useEffect, useState } from "react";

import Pin from "../../../common/components/Pin/Pin";
import Button from "../../../common/components/Button/Button";

import {
  LabelStyled,
  SpanStyled,
} from "../../../common/components/Input/Input";
import { useTranslation } from "react-i18next";
import { StyledError } from "../../../common/components/Error/Error";
import { usePayments } from "../../../store/hooks/usePayments";
import { EstimatedBillingForm } from "../../../common/types/EstimatedBillingForm";
import { omit } from "ramda";
import { useSelector } from "react-redux";
import { StoreState } from "../../../store/type";
import { PaymentRequestStatus } from "../../../store/types/payment";
import { env } from "../../../common/config/env";
import { useLearners } from "../../../common/hooks/useLearners";

type Props = {
  subscriptions: EstimatedBillingForm;
  sm?: boolean;
  renewal?: boolean;
};

export const ByCode = ({ sm, subscriptions, renewal }: Props) => {
  const [pin, setPin] = useState("");
  const [error, setError] = useState<null | string>(null);
  const [shouldShowError, setShouldShowError] = useState(false);
  const {
    error: resError,
    checkoutAttemptStatus,
    checkoutRenewalAttemptStatus,
  } = useSelector((state: StoreState) => state.payments);
  const { firstName, lastName } = useSelector(
    (state: StoreState) => state.user.info
  );
  const { error: estimatedBillingError } = useSelector(
    (state: StoreState) => state.payments.estimatedBilling
  );

  const { allLearners: learners } = useLearners();
  const { t } = useTranslation();

  const { checkoutByCode, emptyPaymentError, clearPaymentStatus } = usePayments(
    null,
    null
  );

  useEffect(() => {
    return () => {
      emptyPaymentError();
      clearPaymentStatus();
    };
  }, []);

  const handleSubmit = () => {
    if (!shouldShowError) {
      setShouldShowError(true);
    }
    setError(pin.length !== 5 ? t("register.paymentByCode.pin.length") : null);
    const filteredLearners = learners?.filter((learner) => {
      return subscriptions.some((subscription) => {
        return subscription.username === learner.username;
      });
    });

    if (pin.length === 5 && !renewal) {
      checkoutByCode(
        pin,
        subscriptions.map(omit(["learnerName"])),
        false,
        filteredLearners
      ); // renewal
    } else if (pin.length === 5 && renewal) {
      checkoutByCode(
        pin,
        subscriptions.map(omit(["learnerName"])),
        true,
        filteredLearners
      );
    }
  };

  return (
    <DetailsWrapper sm={sm}>
      <ContactUsParagraph>
        {t("register.payment.byCode.moreInformation")}
      </ContactUsParagraph>
      <ContactUsLink>{env.contactEmail}</ContactUsLink>
      <LabelStyled>
        {t("register.payment.byCode.labels.enterCode")}
        <SpanStyled>*</SpanStyled>
      </LabelStyled>
      <Pin setPinValue={setPin} bottomMargin="1.325rem" pinLength={5} />
      {error && shouldShowError ? (
        <StyledError topMargin="-1rem" bottomMargin="1rem">
          {error}
        </StyledError>
      ) : null}
      {resError ? (
        <StyledError topMargin="-1rem" bottomMargin="1rem">
          {resError}
        </StyledError>
      ) : null}
      <Button
        bottomMargin="1.766rem"
        invert={false}
        onClick={handleSubmit}
        resPending={
          checkoutAttemptStatus === PaymentRequestStatus.Pending ||
          checkoutRenewalAttemptStatus === PaymentRequestStatus.Pending ||
          estimatedBillingError
        }
      >
        {t("register.payment.byCode.buttons.verify")}
      </Button>
      <RequestCodeWrapper>
        <RequestCodeDiv>
          <RequestCodeParagraph>
            {t("register.payment.byCode.labels.requestCode")}
          </RequestCodeParagraph>
          <a
            href={`mailto:${env.contactEmail}?subject=${t(
              "register.payment.byCode.subject"
            )}&body=${t("register.payment.byCode.body", {
              firstName,
              lastName,
            })}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button invert buttonWidth="100%" type="link">
              {t("register.payment.byCode.buttons.requestCode")}
            </Button>
          </a>
        </RequestCodeDiv>
        <RequestCodeImage src={paymentImg} />
      </RequestCodeWrapper>
    </DetailsWrapper>
  );
};
