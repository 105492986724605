export enum ActivityKind {
  LessonActivity = "LessonActivity",
  TournamentActivity = "TournamentActivity",
}

export enum TournamentResultStatus {
  Started = "Started",
  Completed = "Completed",
}

export type LessonActivity = {
  kind: ActivityKind.LessonActivity;
  data: {
    lessonId: string;
    name: string;
    status: "done" | "started";
    timestamp: string;
  };
};

export type TournamentActivity = {
  kind: ActivityKind.TournamentActivity;
  data: {
    name: string | null;
    status: TournamentResultStatus;
    timestamp: string;
  };
};

export type Activity = LessonActivity | TournamentActivity;
