import {
  AvatarDiv,
  ButtonDiv,
  Heading,
  Info,
  InfoDiv,
  LearnerWrapper,
} from "./AvailableLearnerStyled";
import Button from "../../../../../common/components/Button/Button";
import { useTranslation } from "react-i18next";
import { Learner } from "../../../../../store/types/learners";
import { SubscriptionType } from "../../../../../common/enums/SubscriptionType";
import { Avatar } from "../../../../../common/components/Avatar/Avatar";

type Props = {
  from: string;
  until: string;
  learnerName: string;
  subscriptionType: SubscriptionType;
  learner: Learner;
  setRenewalLearner: (l: Learner) => void;
};
export const AvailableLearner = ({
  from,
  until,
  learnerName,
  subscriptionType,
  learner,
  setRenewalLearner,
}: Props) => {
  const { t } = useTranslation();

  const setRenewalHandler = () => {
    setRenewalLearner(learner);
  };

  return (
    <LearnerWrapper>
      <AvatarDiv>
        <Avatar
          items={learner.items.map((i) => i.avatarItem)}
          icon={learner.avatar?.image}
        />
      </AvatarDiv>
      <InfoDiv>
        <Info>{learnerName}</Info>
      </InfoDiv>
      <InfoDiv>
        <Heading>{t("parentDashboard.subscriptions.lastPaid")}</Heading>
        <Info>{from}</Info>
      </InfoDiv>
      <InfoDiv>
        <Heading>{t("parentDashboard.subscriptions.endsOn")}</Heading>
        <Info>{until}</Info>
      </InfoDiv>
      <InfoDiv>
        <Heading>{t("parentDashboard.subscriptions.type")}</Heading>
        <Info>
          {t(
            `register.payment.estimatedBilling.subscriptionType.${subscriptionType}` as const
          )}
        </Info>
      </InfoDiv>
      <ButtonDiv>
        <Button
          onClick={setRenewalHandler}
          invert
          buttonWidth="100%"
          buttonHeight="2.5rem"
        >
          {t("parentDashboard.subscriptions.renewBtn")}
        </Button>
      </ButtonDiv>
    </LearnerWrapper>
  );
};
