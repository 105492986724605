import { ReduxThunk } from "../../type";
import { setBusyAction, setErrorAction } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { addAvatarItems } from "../../slices/user";
import { LearnerItem } from "../../types/user";

export const createFreeItems =
  (): ReduxThunk =>
  async (dispatch, _, { client }) => {
    dispatch(setBusyAction({ key: AsyncActions.CreateFreeItems, value: true }));
    try {
      const res = await client.post<LearnerItem[]>(ApiRoutes.CreateFreeItems);
      dispatch(addAvatarItems(res.data));
    } catch (e: any) {
      // ignore user already have items
      if (e?.message !== "UserAlreadyHaveItems") {
        dispatch(
          setErrorAction({ key: AsyncActions.CreateFreeItems, value: "Error" })
        );
      }
    }

    dispatch(
      setBusyAction({ key: AsyncActions.CreateFreeItems, value: false })
    );
  };
