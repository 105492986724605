import tw, { styled } from "twin.macro";

export const ParagraphStyled = styled.p(
  () => tw`w-full text-black font-sans text-center mb-6 max-w-[38.3125rem]`
);

export const LearnerWrapper = styled.div(
  ({ bgWhite }: { bgWhite?: boolean }) => [
    tw`w-full max-w-[47.875rem] xl:flex-row flex-col rounded-lg py-4 mb-2 items-center px-6 bg-yellow flex`,
    bgWhite && tw`bg-white w-full max-w-[unset]`,
  ]
);

export const InfoDiv = styled.div(
  () =>
    tw`py-1 w-full xl:w-52 mb-4 xl:mb-0 xl:mr-18 items-center xl:items-start flex flex-col`
);

export const AvatarStyled = styled.div(
  () => tw`xl:mr-3 mt-4 w-16 h-16 xl:mt-0 self-center`
);

export const AvatarIcon = styled.img(
  () => tw`xl:mr-3 mt-4 w-16 h-16 xl:mt-0 self-center`
);

export const SendInfoStyled = styled.img(
  () => tw`xl:ml-auto mb-4 xl:mb-0 self-center`
);

export const CopyInfoDiv = styled.div(
  () => tw`flex  w-full justify-center xl:justify-start`
);

export const CopyIcon = styled.img();

export const CopyButton = styled.button(() => tw`ml-2 min-w-[1rem]`);

export const LightTitle = styled.h5(
  () => tw`font-sans text-black text-sm opacity-70 mb-2`
);

export const NameStyled = styled.span(
  () => tw`font-semibold text-black font-sans `
);

export const InfoTextStyled = styled.span(
  () => tw`font-sans text-center xl:text-left text-black text-sm opacity-70`
);

export const LoginInfoStyled = styled.span(
  () => tw`font-semibold font-sans text-black`
);

export const ButtonDiv = styled.div(
  () => tw`flex w-full max-w-[23.125rem] mt-6 justify-center`
);

export const ChangePinDiv = styled.div(
  () => tw`self-center flex items-center justify-center mb-4 xl:mb-0`
);
