import { FC } from "react";
import { MenuItem as RCMenuItem } from "rc-menu";
import tw, { styled, css } from "twin.macro";
import colors from "../../../colors.json";

export const MenuItem: FC = styled(RCMenuItem)(() => [
  tw`bg-white font-sans`,
  css`
    &.rc-menu-item {
      padding: 7px 7px !important;
    }
    &.rc-menu-item-selected {
      background-color: ${colors["yellow"]};
    }
    &:hover {
      background-color: ${colors["yellow"]} !important;
    }
    &::after {
      content: "" !important;
    }
  `,
]);
