import tw, { styled } from "twin.macro";

export const ResetPasswordSpan = styled.div(
  () =>
    tw`mb-[1.766rem] mt-[1rem] text-left font-sans text-dark-blue text-sm cursor-pointer font-bold hover:underline`
);

export const RenderTabWrapper = styled.div(
  () => tw`pb-2 border-b border-solid border-dark-gray mb-6`
);

export const RenderTabSpan = styled.span(({ active }: { active: boolean }) => [
  tw`pb-[0.58rem] mr-6 font-sans font-semibold cursor-pointer`,
  active && tw`border-solid border-b-2 border-dark-blue`,
]);

export const Wrapper = (props: any) => {
  return (
    <div className="flex bg-auth bg-cover py-6 flex-col items-center xl:flex-row xl:justify-center px-4 sm:px-34 min-h-app h-full">
      {props.children}
    </div>
  );
};

export const InnerWrapper = styled.div(
  () =>
    tw`flex flex-col items-center justify-center xl:flex-row xl:justify-center xl:items-start`
);

export const CardWrapper = styled.div(
  () => tw`xl:mr-auto 2xl:mr-0 order-2 xl:order-1`
);

export const YunoInfoWrapper = styled.div(
  () => tw`flex mb-8 xl:mb-0 xl:pt-6 order-1 xl:order-2 xl:ml-36`
);

export const YunoInfoInnerWrapper = styled.div(
  () =>
    tw`flex flex-col items-center justify-start flex-nowrap box-border min-w-[16rem]`
);

export const YunoInfoHeading = styled.h2(
  () =>
    tw`text-[1.766rem] text-center sm:w-max text-black font-bold mb-8 sm:mb-3`
);

export const YunoInfoDescription = styled.p(
  () =>
    tw`text-center font-normal font-sans whitespace-normal sm:whitespace-pre-line`
);

export const RegisterNowSpan = styled.span(
  () =>
    tw`mb-8 font-semibold underline font-semibold text-dark-blue font-sans cursor-pointer`
);

export const Photo = styled.img(
  () => tw`hidden media-h:inline-block xl:inline-block max-h-84 2xl:max-h-full`
);
