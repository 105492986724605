import tw, { styled } from "twin.macro";
import { ErrorStyled } from "../../LessonPage/LessonPageStyled";

export const Wrapper = styled.div(
  () => tw`flex flex-col w-full items-center max-w-[46rem] px-8`
);

export const InputWrapper = styled.div(
  () => tw`flex items-center justify-center w-full max-w-[31.25rem] mb-[2.5rem]`
);

export const Input = styled.input(
  () =>
    tw`focus:outline-none w-full h-[2.5rem] focus:border-dark-blue border border-dark-gray rounded-l-lg px-3 font-sans placeholder:text-black placeholder:opacity-60 placeholder:font-sans placeholder:font-normal placeholder:text-sm font-sans mr-auto`
);
export const AddButton = styled.button(
  ({ resPending }: { resPending?: boolean }) => [
    tw`font-sans font-semibold w-[7.375rem] text-white bg-gold rounded-r-lg h-[2.5rem]`,
    resPending &&
      tw`text-dark-gray bg-light-gray border-none cursor-not-allowed`,
  ]
);

export const TraitsGrid = styled.div(
  () => tw`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-x-32 gap-y-6`
);

export const TraitLabel = styled.label(
  () => tw`ml-4 flex items-center cursor-pointer`
);

export const TraitIcon = styled.img(() => tw`mr-1`);

export const TraitSpan = styled.span(
  () => tw`font-sans font-medium text-lg first-letter:capitalize`
);
export const TraitInput = styled.input(
  () => tw`w-[1.125rem] flex-shrink-0 h-[1.125rem] cursor-pointer`
);

export const TraitDiv = styled.div(() => tw`flex items-center w-full`);

export const TraitWrapper = styled.div(
  () => tw`flex flex-col items-center text-sm`
);

export const TraitWarning = styled(ErrorStyled)(
  () => tw`text-sm text-black-secondary`
);
