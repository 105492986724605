import tw, { styled, css } from "twin.macro";
import colors from "../../../colors.json";

export const Wrapper = styled.div(({ hidden }: { hidden?: boolean }) => [
  tw`w-full h-2 relative max-w-[37.5rem] mb-34`,
  hidden && tw`hidden`,
]);

export const ProgressOuterDiv = styled.div(
  () => tw`w-full h-2 bg-light-gray-tertiary rounded-lg`
);

export const ProgressInnerDiv = styled.div(
  ({ progress }: { progress: number }) => [
    tw`h-2 rounded-lg transition-all`,
    css`
      width: ${progress}%;
      background: repeating-linear-gradient(
        137deg,
        ${colors.green},
        ${colors.green} 0.45rem,
        ${colors.white} 0.45rem,
        ${colors.white} 0.6rem
      );
    `,
  ]
);

export const StepDiv = styled.div(
  ({
    offset,
    reached,
    last,
  }: {
    offset: number;
    reached?: boolean;
    last?: boolean;
  }) => [
    tw`w-[2.5rem] h-[2.5rem] transition-all rounded-full flex items-center bg-light-gray-tertiary justify-center absolute top-2/4 -translate-x-2/4 -translate-y-2/4`,
    reached && tw`bg-green`,
    css`
      left: ${last ? "100%" : `${offset}%`};
    `,
  ]
);

export const Number = styled.span(({ reached }: { reached?: boolean }) => [
  tw`font-bold text-black transition-all`,
  reached && tw`text-white`,
]);
