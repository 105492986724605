import { FC } from "react";
import {
  Title,
  Description,
  Action,
  IconWrapper,
  Wrapper,
  StyledParagraph,
  CloseIcon,
  CloseIconWrapper,
} from "./styled";
import { Modal } from "../../../common/components/Modal/Modal";
import { GifIcon } from "./icons";
import closeIcon from "../../../assets/common/close.svg";

type Props = {
  onClose?: () => void;
  title: string;
  descriptions: Array<{ text: string; bold?: boolean }>;
  showClose?: boolean;
  icon: string;
};

export const TournamentModal: FC<Props> = ({
  onClose,
  title,
  descriptions,
  children,
  icon,
  showClose = true,
}) => {
  return (
    <Modal>
      <Wrapper>
        {showClose && (
          <CloseIconWrapper>
            <CloseIcon onClick={onClose} src={closeIcon} />
          </CloseIconWrapper>
        )}
        <IconWrapper>
          <GifIcon src={icon} />
        </IconWrapper>
        <Title>{title}</Title>
        <Description>
          {descriptions.map(({ text, bold = false }) => (
            <StyledParagraph bold={bold} key={text}>
              {text}
            </StyledParagraph>
          ))}
        </Description>
        <Action>{children}</Action>
      </Wrapper>
    </Modal>
  );
};
