import tw, { styled } from "twin.macro";

export const LearnerWrapper = styled.div(
  () =>
    tw`w-full mb-2 last:mb-0 bg-white rounded-lg flex flex-col lg:flex-row items-center justify-center px-6 py-5`
);

export const AvatarDiv = styled.div(
  () =>
    tw`flex items-center justify-center w-12 h-12 mr-2 rounded-full bg-yellow`
);

export const InfoDiv = styled.div(
  () =>
    tw`flex flex-col justify-center items-center lg:items-start lg:w-28 xl:w-38 mb-4 lg:mb-0 lg:mr-10 `
);

export const Info = styled.span(() => tw`font-sans text-black font-medium`);

export const Heading = styled.h4(
  () => tw`font-sans text-black text-sm opacity-60`
);

export const ButtonDiv = styled.div(
  () => tw`flex w-full max-w-[20rem] lg:max-w-[9.125rem]`
);
