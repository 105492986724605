import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { AsyncActions } from "../../enums/AsyncActions";
import { setBusyAction } from "../../slices/async";
import { ReduxThunk } from "../../type";
import i18next from "i18next";
import { Id, toast } from "react-toastify";
import i18n from "../../../i18n";

export const fetchPaymentReceipt =
  (transactionId: string): ReduxThunk =>
  async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        key: `${AsyncActions.FetchPaymentReceipt}/${transactionId}`,
        value: true,
      })
    );
    try {
      const res = await client.get<{ link: string }>(
        `${ApiRoutes.PaymentReceipt}/${transactionId}`
      );

      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = res.data.link;
      a.download = i18next.t("parentDashboard.subscriptions.paymentReceipt");
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      toast.error(i18n.t("response.errorDownloadingReceipt"), {
        position: toast.POSITION.TOP_CENTER,
        toastId: i18n.t("response.errorDownloadingReceipt") as Id,
      });
    }

    dispatch(
      setBusyAction({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        key: `${AsyncActions.FetchPaymentReceipt}/${transactionId}`,
        value: false,
      })
    );
  };
