import tw, { styled } from "twin.macro";

export const Wrapper = styled.div(
  () => tw`w-full max-w-[32.75rem] flex flex-col items-center`
);

export const Logo = styled.img(
  ({ marginLg, hidden }: { marginLg?: boolean; hidden?: boolean }) => [
    tw`w-[10.25rem] mt-3 mb-[2.875rem]`,
    marginLg && tw`mb-[7.75rem]`,
    hidden && tw`hidden`,
  ]
);

export const WelcomeHeading = styled.h2(
  () => tw`font-bold text-lg text-center text-black`
);

export const CreateHeading = styled.h2(
  () => tw`text-2xl font-bold text-center text-black`
);

export const AvatarHeading = styled.h1(
  () => tw`text-center text-black font-bold text-4xl mt-4 sm:text-5xl mb-5`
);

export const AvatarGroup = styled.img(() => tw`w-[21rem] mb-20`);

export const StartBtnDiv = styled.div(
  () => tw`w-full flex justify-center max-w-[16rem]`
);
