import tw, { styled } from "twin.macro";

export const PageWrapper = styled.div(
  () =>
    tw`relative flex flex-col sm:flex-row w-full px-1 sm:px-4 md:px-12 lg:px-38 min-h-app bg-gray-bg`
);

export const ContentWrapper = styled.div(
  () => tw`flex flex-col w-full sm:w-[57.93rem] 3xl:w-[51.441rem] h-full px-2`
);
