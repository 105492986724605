import React from "react";
import { useSelector } from "react-redux";

import { useAsyncState } from "../../../common/hooks/useAsyncState";

import { StoreState } from "../../../store/type";
import { ButtonDiv, ParagraphStyled } from "./LearnerPinsStyled";
import { HeadingStyled } from "../../../common/components/Register/RegisterHeading";
import Button from "../../../common/components/Button/Button";

import { useTranslation } from "react-i18next";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { Pins } from "./SinglePin";
import { useNewlyAddedLearners } from "../../../common/hooks/useNewlyAddedLearners";

export const LearnerPins = ({
  setStep,
}: {
  setStep?: (val: number) => void;
}) => {
  const learners = useSelector((state: StoreState) => state.learners);
  const newlyAddedIds = useNewlyAddedLearners();
  const { clearNotification: clearByCodeNotification } = useAsyncState(
    AsyncActions.PayByCodeSuccessful
  );

  const { clearNotification: clearByCardNotification } = useAsyncState(
    AsyncActions.PayByCardSuccessful
  );

  const { clearNotification: clearRenewByCardNotification } = useAsyncState(
    AsyncActions.RenewByCardSuccessful
  );

  const { t } = useTranslation();

  const redirectToHome = () => {
    clearByCardNotification();
    clearByCodeNotification();
    clearRenewByCardNotification();
  };

  const newlyAddedLearners = learners?.filter((l) =>
    newlyAddedIds.includes(l.id)
  );

  const finishAdding = () => {
    clearByCardNotification();
    clearByCodeNotification();
    clearRenewByCardNotification();
    if (setStep) {
      setStep(1);
    }
  };

  return (
    <React.Fragment>
      <HeadingStyled>{t("register.LearnersInfo.heading")}</HeadingStyled>
      <ParagraphStyled>{t("register.LearnersInfo.paragraph")}</ParagraphStyled>
      {newlyAddedLearners
        ? newlyAddedLearners?.map((learner) => {
            return <Pins key={learner.id} learner={learner} />;
          })
        : learners?.map((learner) => {
            return <Pins key={learner.id} learner={learner} />;
          })}
      <ButtonDiv>
        <Button
          onClick={setStep ? finishAdding : redirectToHome}
          invert={false}
          buttonWidth="100%"
          bottomMargin="4.63rem"
        >
          {t("register.LearnersInfo.button")}
        </Button>
      </ButtonDiv>
    </React.Fragment>
  );
};
