import { useCallback, useEffect, useState } from "react";

const mlsToMinutesFactor = 1000 * 60;
const mlsToHoursFactor = mlsToMinutesFactor * 60;

export const useTournamentsSchedule = (
  startsAt: string,
  expiredMessage: string
) => {
  const [timeToTournamentStart, setTimeToTournamentStart] =
    useState<string>("");

  const calculateTimeToTournamentStart = useCallback(() => {
    const duration = +new Date(startsAt) - +new Date(); //milliseconds
    if (duration <= 0) {
      setTimeToTournamentStart(expiredMessage);
      return;
    }
    if (duration < 60001) {
      setTimeToTournamentStart(`${Math.ceil(duration / 1000)}s`); // in seconds
      return;
    }
    let hours: number | string = Math.floor(duration / mlsToHoursFactor);
    let minutes: number | string = Math.floor(
      ((duration - hours * mlsToHoursFactor) % mlsToHoursFactor) /
        mlsToMinutesFactor
    );
    hours = hours.toString().padStart(2, "0");
    minutes = minutes.toString().padStart(2, "0");

    setTimeToTournamentStart(`${hours}h:${minutes}m`);
  }, [startsAt, expiredMessage]);

  const isWarningTime = (timestring: string) =>
    parseInt(timestring.split(":")[0].replace("h", ""), 10) < 10 ||
    timestring.includes("s");

  const isTournamentClosed = (endsAt: string): boolean =>
    new Date() > new Date(endsAt);

  useEffect(() => {
    calculateTimeToTournamentStart();
    const interval = setInterval(() => {
      calculateTimeToTournamentStart();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [startsAt]);

  return {
    timeToTournamentStart,
    isWarningTime,
    isTournamentClosed,
  };
};
