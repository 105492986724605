import i18n from "../../../i18n";
import { AsyncActions } from "../../enums/AsyncActions";
import { setBusyAction, setErrorAction } from "../../slices/async";
import { setUserInfo } from "../../slices/user";
import { ReduxThunk } from "../../type";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";

export const fetchPoints: (lessonId?: string) => ReduxThunk = (lessonId) => {
  return async (dispatch, _, { client }) => {
    try {
      dispatch(setBusyAction({ key: AsyncActions.FetchPoints, value: true }));

      const query = lessonId ? `?lessonId=${lessonId}` : "";

      const {
        data: {
          total,
          pointsBySubject,
          tournamentPointsBySubject,
          tournament: tournamentPoints,
          available,
        },
      } = await client.get<{
        lessonPoints: number;
        available: number;
        total: number;
        pointsBySubject: Record<string, number>;
        tournamentPointsBySubject: Record<string, number>;
        tournament: number;
      }>(`${ApiRoutes.FetchPoints}${query}`);

      dispatch(
        setUserInfo({
          totalPoints: total,
          pointsBySubject,
          tournamentPointsBySubject,
          tournamentPoints,
          availablePoints: available,
        })
      );
    } catch (err) {
      dispatch(
        setErrorAction({
          key: AsyncActions.FetchPoints,
          value: i18n.t("response.errorPoints"),
        })
      );
    }
    dispatch(setBusyAction({ key: AsyncActions.FetchPoints, value: false }));
  };
};
