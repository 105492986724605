import { useUserHomeRoute } from "../../../common/hooks/useUserHomeRoute";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CancelModalButton } from "./styled";
import gif from "../../../assets/gifs/Submit-Tournament.gif";
import { TournamentModal } from "./TournamentModal";

type Props = {
  onClose: () => void;
};

export const CompletedTournamentModal = ({ onClose }: Props) => {
  const homeRoute = useUserHomeRoute();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const descriptions = [
    { text: t("tournaments.modal.completedTournament.description") },
    { text: t("tournaments.modal.completedTournament.subtitle") },
  ];

  const goHome = () => {
    onClose();
    navigate(homeRoute);
  };

  return (
    <TournamentModal
      onClose={goHome}
      title={t("tournaments.modal.completedTournament.title")}
      descriptions={descriptions}
      icon={gif}
    >
      <CancelModalButton buttonWidth={"9.125rem"} invert onClick={goHome}>
        {t("tournaments.modal.completedTournament.label")}
      </CancelModalButton>
    </TournamentModal>
  );
};
