import { ReduxThunk } from "../../type";
import { addSubjects } from "../../slices/subject";
import { setBusyAction, setErrorAction } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import i18n from "../../../i18n";

export const fetchSubjects: (
  learnerUsername: string | undefined
) => ReduxThunk = (learnerUsername) => {
  return async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({ key: AsyncActions.FetchSubjectsAction, value: true })
    );
    try {
      const response = await client.get("subject/learner", {
        params: { learnerUsername },
      });
      dispatch(addSubjects(response.data));
    } catch (err: any) {
      dispatch(
        setErrorAction({
          key: AsyncActions.FetchSubjectsAction,
          value: i18n.t("parentDashboard.monitorProgress.errorFetching"),
        })
      );
    }
    dispatch(
      setBusyAction({ key: AsyncActions.FetchSubjectsAction, value: false })
    );
  };
};
