import { ReduxThunk } from "../../type";
import { setBusyAction, setErrorAction } from "../../slices/async";
import {
  setLessonSummary,
  setQnA,
  setRelatedLessons,
  setSearchResult,
} from "../../slices/assistant";
import {
  LessonSummaryResponse,
  GenerateQnAResponse,
  RelatedLesson,
  SearchResponse,
} from "../../types/assistant";

import { AsyncActions } from "../../enums/AsyncActions";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";

export const getLessonSummary =
  (lessonId: string): ReduxThunk =>
  async (dispatch, _, { client }) => {
    dispatch(setBusyAction({ key: AsyncActions.Assistant, value: true }));
    try {
      const res = await client.get<LessonSummaryResponse>(
        `${ApiRoutes.LessonSummary}/${lessonId}`
      );
      dispatch(setLessonSummary(res.data.summary));
    } catch (e) {
      dispatch(setErrorAction({ key: AsyncActions.Assistant, value: "Error" }));
    }

    dispatch(setBusyAction({ key: AsyncActions.Assistant, value: false }));
  };

export const getGeneratedQnA =
  (lessonId: string): ReduxThunk =>
  async (dispatch, _, { client }) => {
    dispatch(setBusyAction({ key: AsyncActions.Assistant, value: true }));
    try {
      const res = await client.get<GenerateQnAResponse>(
        `${ApiRoutes.GenerateQnA}/${lessonId}`
      );
      dispatch(setQnA(res.data.generatedQnA));
    } catch (e) {
      dispatch(setErrorAction({ key: AsyncActions.Assistant, value: "Error" }));
    }

    dispatch(setBusyAction({ key: AsyncActions.Assistant, value: false }));
  };

export const getRelatedLessons =
  (lessonId: string): ReduxThunk =>
  async (dispatch, _, { client }) => {
    dispatch(setBusyAction({ key: AsyncActions.Assistant, value: true }));
    try {
      const res = await client.get<Array<RelatedLesson>>(
        `${ApiRoutes.RelatedLessons}/${lessonId}`
      );
      dispatch(setRelatedLessons(res.data));
    } catch (e) {
      dispatch(setErrorAction({ key: AsyncActions.Assistant, value: "Error" }));
    }

    dispatch(setBusyAction({ key: AsyncActions.Assistant, value: false }));
  };

export const searchLessons =
  (classroom: number, query: string): ReduxThunk =>
  async (dispatch, _, { client }) => {
    dispatch(setBusyAction({ key: AsyncActions.Assistant, value: true }));
    try {
      const res = await client.get<SearchResponse>(
        `${ApiRoutes.AssistantSearch}/${classroom}?query=${query}`
      );
      dispatch(setSearchResult(res.data));
    } catch (e) {
      dispatch(setErrorAction({ key: AsyncActions.Assistant, value: "Error" }));
    }

    dispatch(setBusyAction({ key: AsyncActions.Assistant, value: false }));
  };
