import { ReduxThunk } from "../../type";
import { setBusyAction, setErrorAction } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import i18n from "../../../i18n";
import { Exception } from "../../../common/enums/Exceptions";

export const addDepository: (lessonId: string | undefined) => ReduxThunk = (
  lessonId
) => {
  return async (dispatch, _, { client }) => {
    dispatch(setBusyAction({ key: AsyncActions.AddDepository, value: true }));
    try {
      await client.post(ApiRoutes.AddDepository, { lessonId });
    } catch (err: any) {
      if (err.response?.data?.message === Exception.LessonAlreadyInDepository) {
        return dispatch(
          setBusyAction({ key: AsyncActions.AddDepository, value: false })
        );
      } else if (
        err.response?.data?.message === Exception.DepositoryLimitReached
      ) {
        dispatch(
          setErrorAction({
            key: AsyncActions.AddDepository,
            value: i18n.t("lessonPage.overAllLimit"),
          })
        );
      } else if (
        err.response?.data?.message === Exception.DepositoryDailyLimitReached
      ) {
        dispatch(
          setErrorAction({
            key: AsyncActions.AddDepository,
            value: i18n.t("lessonPage.dailyLimit"),
          })
        );
      } else {
        dispatch(
          setErrorAction({
            key: AsyncActions.AddDepository,
            value: i18n.t("response.500"),
          })
        );
      }
    }
    dispatch(setBusyAction({ key: AsyncActions.AddDepository, value: false }));
  };
};
