import React, { useEffect } from "react";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";

import { HeadingStyled } from "../../../common/components/Register/RegisterHeading";
import { ParagraphStyled } from "../../../common/components/Register/RegisterParagraph";
import {
  NameDiv,
  SchoolSelectDiv,
  GradeSelectDiv,
  LearnerWrapper,
  LearnerInputsDiv,
  InputButton,
  InputButtonImg,
  gradeSelectStyles,
  schoolSelectStyles,
  IndicatorSeparator,
  ButtonDiv,
} from "./AddLearnersStyled";
import { SelectSchool } from "./SelectSchool";
import { SelectClassroom } from "./SelectClassroom";

import { StyledError } from "../../../common/components/Error/Error";
import Form from "../../../common/components/Form/Form";
import Input from "../../../common/components/Input/Input";
import {
  LabelStyled,
  SpanStyled,
} from "../../../common/components/Input/Input";
import Button from "../../../common/components/Button/Button";

import addMore from "../../../assets/common/Add More.svg";
import learnerDelete from "../../../assets/common/Delete.svg";

import { addLearnersSchema } from "../../../views/validation/registerValidationSchema";
import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { addLearnersThunk } from "../../../store/thunks/register";
import { StoreState } from "../../../store/type";
import { LearnerPayload } from "../../../common/types/LearnerPayload";
import { ChooseFontModal } from "./ChooseFontModal";
import { useLearners } from "../../../common/hooks/useLearners";

type AddLearnerProps = {
  addOne?: boolean;
};

export const AddLearners = ({ addOne }: AddLearnerProps) => {
  const dispatch = useDispatch();
  const { learnerLimit } = useSelector((state: StoreState) => state.user.info);

  const { allLearners } = useLearners();
  const { error, requestInProgress, clearNotification, notification } =
    useAsyncState(AsyncActions.AddLearnersAction);
  const { t } = useTranslation();

  const { register, formState, control, handleSubmit, getValues } = useForm({
    mode: "onTouched",
    defaultValues: {
      learners: [{ firstName: "", lastName: "", activeClass: "", school: "" }],
    },
    resolver: yupResolver(addLearnersSchema()),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "learners",
  });
  const onSubmitHandler = (data: LearnerPayload) => {
    dispatch(addLearnersThunk(data));
  };

  const selectOnChange = (
    val: { value: string },
    onChange: (value: string) => void
  ) => {
    if (val) {
      return onChange(val.value);
    } else {
      return onChange("");
    }
  };

  const appendOrRemove = (index: number) => {
    if (index === fields.length - 1 && !(fields.length === learnerLimit)) {
      return append(
        {
          firstName: "",
          lastName: "",
          activeClass: "",
          school: getValues().learners[index].school,
        },
        { shouldFocus: false }
      );
    }
    return remove(index);
  };

  const newlyAddedLearners = allLearners.filter((learner) => {
    return notification?.split("/").some((id) => id === learner.id);
  });

  useEffect(() => {
    return () => {
      clearNotification();
    };
  }, []);

  return (
    <React.Fragment>
      <HeadingStyled>{t("register.addLearners.heading")}</HeadingStyled>
      <ParagraphStyled>{t("register.addLearners.paragraph")}</ParagraphStyled>
      <Form
        className="flex flex-col justify-start"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        {fields.map((field, index) => {
          return (
            <LearnerWrapper key={field.id}>
              <LearnerInputsDiv addOne={addOne}>
                <NameDiv addOne={addOne}>
                  <Input
                    placeholderText={t(
                      "register.addLearners.inputs.firstName.placeholder"
                    )}
                    labelText={t("register.addLearners.inputs.firstName.label")}
                    required
                    type="text"
                    name={`learners.${index}.firstName`}
                    registerInput={register}
                    inputWidth="100%"
                  />
                  {formState.errors.learners &&
                  formState.errors.learners[index]?.firstName ? (
                    <StyledError bottomMargin="0" topMargin="0.441rem">
                      {formState.errors.learners[index]?.firstName?.message}
                    </StyledError>
                  ) : null}
                </NameDiv>
                <NameDiv addOne={addOne}>
                  <Input
                    placeholderText={t(
                      "register.addLearners.inputs.lastName.placeholder"
                    )}
                    labelText={t("register.addLearners.inputs.lastName.label")}
                    required
                    type="text"
                    name={`learners.${index}.lastName`}
                    registerInput={register}
                    inputWidth="100%"
                  />
                  {formState.errors.learners &&
                  formState.errors.learners[index]?.lastName ? (
                    <StyledError bottomMargin="0" topMargin="0.441rem">
                      {formState.errors.learners[index]?.lastName?.message}
                    </StyledError>
                  ) : null}
                </NameDiv>
                <GradeSelectDiv addOne={addOne}>
                  <LabelStyled>
                    {t("register.addLearners.inputs.classroom.label")}
                    <SpanStyled>*</SpanStyled>
                  </LabelStyled>
                  <Controller
                    name={`learners.${index}.activeClass`}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <SelectClassroom
                        onChange={(val: any) => {
                          selectOnChange(val, onChange);
                        }}
                        value={value}
                        onBlur={onBlur}
                        components={{ IndicatorSeparator }}
                        styles={gradeSelectStyles}
                        placeholder={t(
                          "register.addLearners.inputs.classroom.placeholder"
                        )}
                      />
                    )}
                  />
                  {formState.errors.learners &&
                  formState.errors.learners[index]?.activeClass ? (
                    <StyledError bottomMargin="0" topMargin="0.441rem">
                      {formState.errors.learners[index]?.activeClass?.message}
                    </StyledError>
                  ) : null}
                </GradeSelectDiv>
                <SchoolSelectDiv addOne={addOne}>
                  <LabelStyled>
                    {t("register.addLearners.inputs.school.label")}
                    <SpanStyled>*</SpanStyled>
                  </LabelStyled>
                  <Controller
                    name={`learners.${index}.school`}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <SelectSchool
                        styles={schoolSelectStyles}
                        placeholder={t(
                          "register.addLearners.inputs.school.placeholder"
                        )}
                        onChange={(val: any) => {
                          selectOnChange(val, onChange);
                        }}
                        previousValue={
                          index > 0 ? fields[index - 1].school : null
                        }
                        value={value}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  {formState.errors.learners &&
                  formState.errors.learners[index]?.school ? (
                    <StyledError bottomMargin="0" topMargin="0.441rem">
                      {formState.errors.learners[index]?.school?.message}
                    </StyledError>
                  ) : null}
                </SchoolSelectDiv>
              </LearnerInputsDiv>
              {!addOne && (
                <InputButton
                  type="button"
                  onClick={() => appendOrRemove(index)}
                >
                  <InputButtonImg
                    src={
                      index === fields.length - 1 &&
                      !(fields.length === learnerLimit)
                        ? addMore
                        : learnerDelete
                    }
                  />
                </InputButton>
              )}
            </LearnerWrapper>
          );
        })}
        <ButtonDiv>
          <StyledError centered topMargin="0" bottomMargin="0.883rem">
            {error}
          </StyledError>
          <Button resPending={requestInProgress} invert={false} type="submit">
            {addOne
              ? t("parentDashboard.manage.addKid")
              : t("register.addLearners.button")}
          </Button>
        </ButtonDiv>
      </Form>
      {notification && (
        <ChooseFontModal learners={newlyAddedLearners} addOne={addOne} />
      )}
    </React.Fragment>
  );
};
