import { EstimatedBillingRequest } from "../../../common/types/EstimateBillingRequest";
import { ReduxThunk, StoreState } from "../../type";
import {
  setEstimatedBilling,
  setEstimatedBillingError,
} from "../../slices/payment";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { SetEstimateBillingAction } from "../../types/payment";
import { setBusyAction } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import i18n from "../../../i18n";
import { Exception } from "../../../common/enums/Exceptions";
import { AxiosError } from "axios";
import { ServerResponseError } from "../../../common/types/ServerResponseError";

const getError = (
  message: Exception | string[] | string | undefined
): string => {
  switch (message) {
    case Exception.SubscriptionAlreadyRenewed:
      return i18n.t("response.subscriptionAlreadyRenewed");
    case Exception.LearnersNotEligibleForPayment:
      return i18n.t("response.learnersNotEligibleForPayment");
    case Exception.LearnersHaveActiveStripeSubscription:
      return i18n.t("response.activeStripeSubscription");
    default:
      return i18n.t("response.applyCode");
  }
};

export const postEstimatedBillingAction: (
  payload: EstimatedBillingRequest[],
  promoCode?: string
) => ReduxThunk =
  (payload, promoCode) =>
  async (dispatch, _: () => StoreState, { client }): Promise<void> => {
    dispatch(
      setBusyAction({ key: AsyncActions.GetEstimatedPayingAction, value: true })
    );
    dispatch(setEstimatedBillingError(null));
    try {
      const res = await client.post<SetEstimateBillingAction>(
        ApiRoutes.EstimateBilling,
        {
          subscriptions: payload,
          promoCode,
        }
      );
      dispatch(setEstimatedBilling(res.data));
    } catch (e: any) {
      dispatch(
        setEstimatedBillingError(
          getError(
            (e as AxiosError<ServerResponseError>).response?.data.message
          )
        )
      );
    }
    dispatch(
      setBusyAction({
        key: AsyncActions.GetEstimatedPayingAction,
        value: false,
      })
    );
  };

export const postEstimatedBillingRenewalAction: (
  billingRequest: EstimatedBillingRequest,
  promoCode?: string
) => ReduxThunk =
  (billingRequest, promoCode) =>
  async (dispatch, _, { client }): Promise<void> => {
    dispatch(
      setBusyAction({ key: AsyncActions.GetEstimatedPayingAction, value: true })
    );
    dispatch(setEstimatedBillingError(null));
    try {
      const res = await client.post<unknown, SetEstimateBillingAction>(
        `${ApiRoutes.EstimateBillingRenewal}/${billingRequest.username}`,
        {
          subscriptionType: billingRequest.subscriptionType,
          promoCode,
        }
      );

      dispatch(setEstimatedBilling(res.data));
    } catch (e: any) {
      dispatch(
        setEstimatedBillingError(
          getError(
            (e as AxiosError<ServerResponseError>).response?.data.message
          )
        )
      );
    }

    dispatch(
      setBusyAction({
        key: AsyncActions.GetEstimatedPayingAction,
        value: false,
      })
    );
  };
