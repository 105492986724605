import leftArrow from "../../../../assets/common/Setup-Profile/Left-Arrow.svg";
import rightArrow from "../../../../assets/common/Setup-Profile/Right-Arrow.svg";
import selectedIcon from "../../../../assets/common/Setup-Profile/Selected.svg";

import {
  ArrowButton,
  ArrowImg,
  AvatarDiv,
  AvatarDivWrapper,
  AvatarInput,
  AvatarLabel,
  AvatarTypeSpan,
  DotDiv,
  DotsWrapper,
  SlideFlexDiv,
  SliderWrapper,
  SlidesButtonsWrapper,
  SlideWrapper,
} from "./SliderStyled";
import { GlobalStyles } from "twin.macro";
import React, { useState } from "react";
import { UseFormRegister } from "react-hook-form";
import { SelectedMark } from "../../SelectGender/SelectGenderStyled";
import { Avatar as AvatarType } from "../../../../store/types/avatars";
import { useSelector } from "react-redux";
import { StoreState } from "../../../../store/type";
import { useTranslation } from "react-i18next";
import { Avatar } from "../../../../common/components/Avatar/Avatar";

type Props = {
  avatars: AvatarType[];
  register: UseFormRegister<{ avatar: string | undefined }>;
  avatarWatch: string | undefined;
  setError: (val: boolean) => void;
};

export const Slider = ({ avatars, register, avatarWatch, setError }: Props) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const learnerItems = useSelector(
    (state: StoreState) => state.user.info?.items
  );
  const items = learnerItems.map(({ avatarItem }) => avatarItem);

  const { t } = useTranslation();
  const { avatar: userAvatar } = useSelector(
    (state: StoreState) => state.user.info
  );

  const previousSlideHandler = () => {
    if (currentSlide === 0) {
      return setCurrentSlide(avatars.length - 1);
    }
    setCurrentSlide((pre) => pre - 1);
  };
  const nextSlideHandler = () => {
    if (currentSlide === avatars.length - 1) {
      return setCurrentSlide(0);
    }
    setCurrentSlide((pre) => pre + 1);
  };

  const handleAvatarChangeError = () => {
    if (userAvatar) {
      setError(true);
    }
  };

  return (
    <SliderWrapper>
      <SlidesButtonsWrapper>
        <ArrowButton type="button" onClick={previousSlideHandler}>
          <ArrowImg src={leftArrow} />
        </ArrowButton>
        <SlideWrapper>
          <SlideFlexDiv offset={currentSlide}>
            {avatars.map((avatar, i) => {
              return (
                <React.Fragment key={`${avatar.id}-${i}`}>
                  <AvatarInput
                    disabled={!!userAvatar}
                    type="radio"
                    value={avatar.id}
                    id={avatar.id}
                    {...register("avatar")}
                  />
                  <AvatarLabel
                    onClick={handleAvatarChangeError}
                    htmlFor={avatar.id}
                  >
                    <AvatarDiv selected={avatarWatch == avatar.id}>
                      {avatarWatch == avatar.id && (
                        <SelectedMark src={selectedIcon} />
                      )}
                      <AvatarDivWrapper>
                        <Avatar icon={avatar.image} items={items || []} />
                      </AvatarDivWrapper>
                    </AvatarDiv>
                    <AvatarTypeSpan>
                      {avatar?.name
                        ? t(`avatarNames.${avatar.name.toLowerCase()}` as any)
                        : null}
                    </AvatarTypeSpan>
                  </AvatarLabel>
                </React.Fragment>
              );
            })}
          </SlideFlexDiv>
        </SlideWrapper>
        <ArrowButton type="button" onClick={nextSlideHandler}>
          <ArrowImg src={rightArrow} />
        </ArrowButton>
      </SlidesButtonsWrapper>
      <DotsWrapper>
        {avatars.map((_, i) => (
          <DotDiv key={i} active={i === currentSlide}></DotDiv>
        ))}
      </DotsWrapper>
      <GlobalStyles />
    </SliderWrapper>
  );
};
