import tw, { styled, css } from "twin.macro";

export const Wrapper = styled.div(() => [
  tw`h-screen px-5 py-11 md:px-11 flex items-center`,
  css`
    * {
      font-family: sans-serif;
    }
  `,
]);

export const ContentWrapper = styled.div(
  () =>
    tw`px-2 md:px-4 pt-3 md:pt-6 bg-white font-sans flex flex-col items-center w-full md:w-6/12 m-auto rounded-lg md:rounded-2xl border-2 border-light-gray-secondary`
);

export const Title = styled.h1(
  () => tw`pt-3 md:py-3 w-full text-center text-2xl`
);

export const DescriptionWrapper = styled.div(() => tw`py-11 pb-3`);

export const Description = styled.p(() => tw`pb-3`);

export const Report = styled.a(
  () =>
    tw`mb-8 font-semibold underline font-semibold text-dark-blue font-sans cursor-pointer`
);

export const ActionWrapper = styled.div(() => tw`flex items-center py-11`);
