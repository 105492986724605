import { NoEventsWrapper, Title } from "./styled";
import { useTranslation } from "react-i18next";

export const NoEvents = () => {
  const { t } = useTranslation();
  return (
    <NoEventsWrapper>
      <Title>{t("parentDashboard.events.noEvents")}</Title>
    </NoEventsWrapper>
  );
};
