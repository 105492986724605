import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLearners } from "../../../common/hooks/useLearners";
import { TournamentConsentModal } from "./TournamentConsentModal";
import { TournamentUsernamePermission } from "../../../common/enums/TournamentUsernamePermission";
import { Consent } from "../../../store/types/user";
import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { StoreState } from "../../../store/type";
import { fetchUserInfo } from "../../../store/thunks/fetchUserInfo";
import { useNewlyAddedLearners } from "../../../common/hooks/useNewlyAddedLearners";

export const TournamentConsent = () => {
  const { activeLearners } = useLearners();
  const tutorialSeen = useSelector(
    (state: StoreState) => state.user.info.tutorialSeen
  );
  const [missingConsents, setMissingConsents] = useState<Consent[]>([]);
  const { requestInProgress } = useAsyncState(AsyncActions.FetchUserAction);
  const newlyAddedLearners = useNewlyAddedLearners();
  const dispatch = useDispatch();

  const consents = activeLearners
    .map((l) => l.consent)
    .filter((c) => !!c) as Consent[];

  useEffect(() => {
    dispatch(fetchUserInfo());
  }, []);

  useEffect(() => {
    if (requestInProgress !== false) return;
    setMissingConsents(
      activeLearners.reduce((acc, { username, consent }) => {
        if (!consent) {
          acc.push({
            username,
            tournamentUsernamePermission:
              TournamentUsernamePermission.HideUsername,
          });
        }
        return acc;
      }, [] as Consent[])
    );
  }, [requestInProgress, activeLearners.length]);

  useEffect(() => {
    consents.forEach((c) => {
      setMissingConsents((pre) => {
        const index = pre.findIndex((mc) => mc.username === c.username);
        if (index > -1) {
          return [...pre.slice(0, index), c, ...pre.slice(index + 1)];
        }
        return pre;
      });
    });
  }, [consents.length]);

  useEffect(() => {
    let id: NodeJS.Timer;
    if (activeLearners.filter(({ consent }) => !consent).length === 0) {
      id = setTimeout(() => {
        setMissingConsents([]);
      }, 400);
    }
    return () => {
      clearTimeout(id);
    };
  }, [activeLearners]);

  const handleClose = () => {
    setMissingConsents([]);
  };

  const passedAllAddLearnerSteps = newlyAddedLearners.length === 0;

  return tutorialSeen &&
    missingConsents.length > 0 &&
    requestInProgress === false &&
    passedAllAddLearnerSteps ? (
    <TournamentConsentModal
      onClose={handleClose}
      learnerConsents={missingConsents}
    />
  ) : (
    <></>
  );
};
