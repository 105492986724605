import tw, { styled } from "twin.macro";

export const ContentWrapper = styled.div(
  () => tw`w-full grid grid-cols-1 md:grid-cols-2 justify-items-center my-8`
);

export const InfoItemWrapper = styled.div(
  () => tw`w-full flex flex-col items-center md:items-start mb-6`
);

export const Label = styled.span(
  () => tw`font-sans text-sm text-black opacity-60 mb-2`
);

export const Value = styled.span(() => tw`font-sans font-bold text-black`);

export const ButtonDiv = styled.div(() => tw`w-full max-w-[23.125rem]`);
