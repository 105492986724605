import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionWrapper,
  AvailablePointsWrapper,
  AvailablePointsWrapperSpan,
  BuyItemButton,
  BuyItemPointsWrapper,
  ItemActionsWrapper,
  StarIcon,
  UpdateAvatarButton,
} from "./styled";
import starIcon from "../../../assets/common/Learner-Home/Star.svg";
import { useDispatch, useSelector } from "react-redux";
import { buyItem } from "../../../store/thunks/Learner/buyItem";
import { LearnerItem } from "../../../store/types/user";
import { StoreState } from "../../../store/type";
import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { refundItem } from "../../../store/thunks/Learner/refundItem";
import { ItemStatus } from "../../../common/enums/ItemStatus";
import { AvatarItem, AvatarItemKind } from "../../../store/types/avatar-items";
import { REFUND_ITEM_FACTOR } from "../../../common/consts/itemRefundFactor";
import { InsufficientPointsModal } from "./InsufficientPointsModal";

type Props = {
  selectedItem: AvatarItem | null;
  ownedItem: false | LearnerItem | undefined;
  itemPrice: number;
  onUpdateClick: () => void;
  isUpdateAvailable: boolean;
};

export const AvailablePoints = ({
  points,
  showOnSmall,
}: {
  points: number;
  showOnSmall?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <AvailablePointsWrapper showOnSmall={showOnSmall}>
      {t("shop.availablePoints")}
      <AvailablePointsWrapperSpan>
        <StarIcon $large src={starIcon} $mb />
        {points}
      </AvailablePointsWrapperSpan>
    </AvailablePointsWrapper>
  );
};

export const ShopItemAction = ({
  selectedItem,
  ownedItem,
  itemPrice,
  onUpdateClick,
  isUpdateAvailable,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openInsufficientPointsModal, setOpenInsufficientPointsModal] =
    useState(false);
  const { availablePoints } = useSelector(
    (state: StoreState) => state.user.info
  );

  let canSellItems = true;
  if (ownedItem && ownedItem.avatarItem.kind === AvatarItemKind.Eyes) {
    canSellItems = ownedItem.status != ItemStatus.Active;
  }

  const { requestInProgress: buyingInProgress } = useAsyncState(
    AsyncActions.BuyItem
  );
  const { requestInProgress: refundingInProgress } = useAsyncState(
    AsyncActions.RefundItem
  );

  const { requestInProgress: changeStatusInProgress } = useAsyncState(
    AsyncActions.ChangeItemStatus
  );

  const isUpdateButtonActive =
    !changeStatusInProgress && isUpdateAvailable && canSellItems;

  const handleRefundItem = (item: LearnerItem | null) => () => {
    if (refundingInProgress || !item) return;
    dispatch(refundItem(item.id, item.avatarItem.points));
  };

  const handleBuyItem = (item: AvatarItem | null) => () => {
    if (!item) return;
    if (item.points > availablePoints) {
      setOpenInsufficientPointsModal(true);
      return;
    }
    dispatch(buyItem(item.id));
  };

  return (
    <ActionWrapper>
      <AvailablePoints points={availablePoints} />
      <ItemActionsWrapper>
        {!ownedItem && (
          <BuyItemButton
            disabled={!selectedItem?.id || buyingInProgress}
            $active={!!selectedItem?.id && !buyingInProgress}
            onClick={handleBuyItem(selectedItem)}
          >
            {t("shop.buyItem")}
            <BuyItemPointsWrapper>
              <StarIcon src={starIcon} />
              {itemPrice}
            </BuyItemPointsWrapper>
          </BuyItemButton>
        )}
        {!!ownedItem && canSellItems && (
          <Fragment>
            <BuyItemButton
              disabled={refundingInProgress}
              $active={!refundingInProgress}
              onClick={handleRefundItem(ownedItem)}
            >
              {t("shop.refundItem")}
              <BuyItemPointsWrapper>
                <StarIcon src={starIcon} />
                {Math.ceil(itemPrice * REFUND_ITEM_FACTOR)}
              </BuyItemPointsWrapper>
            </BuyItemButton>
          </Fragment>
        )}
        <UpdateAvatarButton
          onClick={onUpdateClick}
          $active={isUpdateButtonActive}
          disabled={!isUpdateButtonActive}
        >
          {t("shop.updateItem")}
        </UpdateAvatarButton>
      </ItemActionsWrapper>
      {openInsufficientPointsModal && (
        <InsufficientPointsModal
          onClose={() => setOpenInsufficientPointsModal(false)}
        />
      )}
    </ActionWrapper>
  );
};
