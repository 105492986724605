import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLearners } from "../../../../common/hooks/useLearners";
import { useMonthName } from "../../../../common/hooks/useMonthName";
import { StoreState } from "../../../../store/type";
import { Learner } from "../../../../store/types/learners";
import { Subscription } from "../../../../store/types/subscriptions";
import { UnsubscribedLearners } from "../../ManageLearners/LearnersList/UnsubscribedLearners/UnsubscribedLearners";

import { AvailableLearner } from "./AvailableLearner/AvailableLearner";
import { BillingHistory } from "./BillingHistory/BillingHistory";
import { PaymentMethod } from "./PaymentMethod/PaymentMethod";
import { Wrapper, Heading } from "./SubscriptionsDefaultStyled";
import { StripeSubscription } from "./StripeSubscription/StripeSubscription";

type Props = {
  setRenewalLearner: (l: Learner) => void;
  setSubscribeId: (id: string) => void;
};

export const SubscriptionsDefault = ({
  setRenewalLearner,
  setSubscribeId,
}: Props) => {
  const { t } = useTranslation();
  const subscriptions = useSelector((state: StoreState) => state.subscriptions);

  const { allLearners } = useLearners();
  const monthNames = useMonthName();

  const sortedSubscriptions = useMemo(() => {
    return subscriptions?.reduce((acc, cur) => {
      const index = acc.findIndex((lc) => cur.learnerId === lc.learnerId);
      if (index > -1) {
        if (new Date(acc[index].from) <= new Date(cur.from)) {
          acc[index] = cur;
        }
      } else {
        acc.push(cur);
      }
      return acc;
    }, [] as Subscription[]);
  }, [subscriptions]);

  return (
    <Wrapper>
      <Heading>{t("parentDashboard.subscriptions.availableLearners")}</Heading>
      {sortedSubscriptions?.map((subscription) => {
        const startDate = new Date(subscription.from);
        const startMonthName = monthNames[startDate.getMonth()];
        const endDate = new Date(subscription.to);
        const endMonthName = monthNames[endDate.getMonth()];
        const availableLearner = allLearners?.find((learner) => {
          return learner.id === subscription.learnerId;
        });

        if (!availableLearner) return;

        return (
          <AvailableLearner
            key={subscription.id}
            from={`${startDate.getDate()} ${startMonthName} ${startDate.getFullYear()}`}
            until={`${endDate.getDate()} ${endMonthName} ${endDate.getFullYear()}`}
            learnerName={`${availableLearner?.firstName} ${availableLearner?.lastName}`}
            subscriptionType={subscription.type}
            learner={availableLearner}
            setRenewalLearner={setRenewalLearner}
          />
        );
      })}
      <UnsubscribedLearners onSubscribe={setSubscribeId} />
      <Heading>{t("parentDashboard.subscriptions.stripe.title")}</Heading>
      <StripeSubscription />
      <Heading>{t("parentDashboard.subscriptions.paymentMethod")}</Heading>
      <PaymentMethod />
      <Heading>{t("parentDashboard.subscriptions.billingHistory")}</Heading>
      <BillingHistory />
    </Wrapper>
  );
};
