import * as BannerStyled from "./BannerStyled";
import { useSelector } from "react-redux";
import { LearnerAvatar } from "./LearnerAvatar/LearnerAvatar";

import { StoreState } from "../../../store/type";

import { useTranslation } from "react-i18next";
import { Points } from "../../../common/components/Points/Points";
export const Banner = () => {
  const { t } = useTranslation();
  const { firstName, lastName, totalPoints, gender } = useSelector(
    (state: StoreState) => state.user.info
  );

  return (
    <BannerStyled.BannerWrapperStyled>
      <BannerStyled.InnerWrapperStyled>
        <BannerStyled.NameDivStyled>
          <BannerStyled.GreetingStyled>
            {gender
              ? gender === "Female"
                ? t("learnerHome.welcomeHeadingFemale")
                : t("learnerHome.welcomeHeading")
              : null}
          </BannerStyled.GreetingStyled>
          <BannerStyled.NameStyled>
            {firstName} {lastName}
          </BannerStyled.NameStyled>
        </BannerStyled.NameDivStyled>
        <BannerStyled.PointsDivStyled>
          <BannerStyled.PointsHeading>
            {t("learnerHome.pointsHeading", { suffix: "" })}
          </BannerStyled.PointsHeading>
          <Points points={totalPoints} />
        </BannerStyled.PointsDivStyled>
      </BannerStyled.InnerWrapperStyled>
      <LearnerAvatar />
    </BannerStyled.BannerWrapperStyled>
  );
};
