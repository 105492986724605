import tw, { styled, css } from "twin.macro";

export const AddLearnerDiv = styled.div<{ disabled?: boolean }>(
  ({ disabled }) => [
    tw`flex justify-center items-center mb-5 xl:justify-end w-full cursor-pointer`,
    disabled &&
      css`
        opacity: 0.4;
      `,
  ]
);

export const AddLearnerButton = styled.button(
  tw`font-sans font-semibold text-sm text-dark-blue`
);

export const AddLearnersIcon = styled.img(tw`mr-2 w-4`);

export const Wrapper = styled.div(
  () => tw`flex w-full justify-center bg-white rounded-lg px-2`
);

export const CredentialsWrapper = styled.div(
  () =>
    tw`flex flex-col w-full justify-center items-center bg-white rounded-lg px-2`
);
