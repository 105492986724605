import React from "react";
import { useTranslation } from "react-i18next";

import avatar from "../../../assets/common/avatar.svg";
import {
  Avatar,
  CloseSpan,
  Description,
  InfoWrapper,
  Username,
  Wrapper,
} from "./RememberUserCardStyled";

type Props = {
  username: string;
  removeUser: () => void;
  onTabChange: () => void;
};

const RememberUserCard = ({ username, removeUser, onTabChange }: Props) => {
  const { t } = useTranslation();
  const onClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    removeUser();
  };

  return (
    <Wrapper data-parent-tab="true" onClick={onTabChange}>
      <Avatar src={avatar} />
      <InfoWrapper>
        <Description>{t("login.button.loginAs")}</Description>
        <Username>{username}</Username>
      </InfoWrapper>
      <CloseSpan onClick={onClick}>&#10006;</CloseSpan>
    </Wrapper>
  );
};

export default RememberUserCard;
