import { useTranslation } from "react-i18next";
import { Fragment, useState } from "react";
import dayjs from "../../../common/config/dayjs";
import {
  Collapse,
  IconWrapper,
  Item,
  ItemDescription,
  ItemValue,
  MobileTournamentItem,
  Panel,
  PanelContent,
  PanelItemDescription,
  PanelItemValue,
  StarIcon,
  TabletTournamentWrapper,
  TournamentIcon,
  TournamentTitle,
  TournamentType,
} from "../../../common/components/Tournament/styled";
import cupIcon from "../../../assets/common/Lesson-Page/Cup.svg";
import starIcon from "../../../assets/common/Lesson-Page/Star.svg";
import { useSelector } from "react-redux";
import { StoreState } from "../../../store/type";
import { UserDetails } from "../../Tournament/TournamentResult/UserDetails";
import { getDuration } from "../../../common/components/Tournament/utils";

export const LearnerTournamentHistoryCollapse = () => {
  const { t } = useTranslation();
  const [activePanel, setActivePanel] = useState<string>("");
  const results = useSelector(
    (state: StoreState) => state.tournaments.resultsForSponsor
  );

  const handleSetActivePanel = (val: string) => () => {
    setActivePanel((pre) => (pre === val ? "" : val));
  };

  return (
    <Collapse activeKey={activePanel} accordion={true} destroyInactivePanel>
      {results.map(
        ({
          id,
          startsAt,
          tournamentName,
          endsAt,
          points,
          avatar,
          username,
          grade,
          items,
        }) => (
          <Panel
            collapsible="header"
            key={id}
            header={
              <Fragment>
                {/*mobile*/}
                <MobileTournamentItem onClick={handleSetActivePanel(id)}>
                  <IconWrapper>
                    <TournamentIcon src={cupIcon} />
                  </IconWrapper>
                  <Item>
                    <ItemValue>
                      {tournamentName || t("tournaments.tournament")}
                    </ItemValue>
                    <ItemDescription extraSmall>
                      {t("tournaments.pastTournamentDetails.individual")}
                    </ItemDescription>
                  </Item>
                </MobileTournamentItem>
                {/*tablet*/}
                <TabletTournamentWrapper onClick={handleSetActivePanel(id)}>
                  <TournamentTitle>
                    <IconWrapper>
                      <TournamentIcon src={cupIcon} />
                    </IconWrapper>
                    {tournamentName || t("tournaments.tournament")}
                  </TournamentTitle>
                  <TournamentType>
                    {t("tournaments.pastTournamentDetails.individual")}
                  </TournamentType>
                </TabletTournamentWrapper>
              </Fragment>
            }
          >
            <PanelContent>
              <PanelItemDescription>
                {t("tournaments.pastTournamentDetails.learner")}:
              </PanelItemDescription>
              <UserDetails
                avatar={avatar}
                grade={grade}
                username={username}
                items={items.map((i) => i.avatarItem)}
              />
              <PanelItemDescription>
                {t("tournaments.pastTournamentDetails.duration")}:
              </PanelItemDescription>
              <PanelItemValue>{getDuration(endsAt, startsAt)}</PanelItemValue>
              <PanelItemDescription>
                {t("tournaments.pastTournamentDetails.endedAt")}:
              </PanelItemDescription>
              <PanelItemValue>
                {dayjs(endsAt).format("DD MMM YYYY/ hA")}
              </PanelItemValue>
              <PanelItemDescription>
                {t("tournaments.pastTournamentDetails.pointsEarned")}:
              </PanelItemDescription>
              <PanelItemValue>
                <StarIcon src={starIcon} />
                {points}
              </PanelItemValue>
            </PanelContent>
          </Panel>
        )
      )}
    </Collapse>
  );
};
