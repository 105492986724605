import { useTranslation } from "react-i18next";
import { Modal } from "../../../common/components/Modal/Modal";
import {
  CloseIcon,
  CloseIconWrapper,
  ConsentActionWrapper,
  Description,
  Image,
  Title,
  Wrapper,
} from "./styled";
import { TournamentConsentAction } from "./TournamentConsentAction";
import closeIcon from "../../../assets/common/close.svg";
import learnerGif from "../../../assets/gifs/Group-Name.gif";
import { Consent } from "../../../store/types/user";

type Props = {
  onClose: () => void;
  learnerConsents: Consent[];
  closeOnActionDone?: boolean;
};

export const TournamentConsentModal = ({
  onClose,
  learnerConsents,
  closeOnActionDone,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal lg>
      <Wrapper>
        <CloseIconWrapper onClick={onClose}>
          <CloseIcon src={closeIcon} />
        </CloseIconWrapper>
        <Title>
          {t("parentDashboard.monitorProgress.consentTournamentModal.title")}
        </Title>
        <Description>
          {t(
            "parentDashboard.monitorProgress.consentTournamentModal.description1"
          )}
        </Description>
        <Image src={learnerGif} />
        <Description>
          {t(
            "parentDashboard.monitorProgress.consentTournamentModal.description2"
          )}
        </Description>
        <Description>
          {t(
            "parentDashboard.monitorProgress.consentTournamentModal.description3"
          )}
        </Description>
        <ConsentActionWrapper>
          {learnerConsents.map(({ username, tournamentUsernamePermission }) => (
            <TournamentConsentAction
              key={username}
              username={username}
              permission={tournamentUsernamePermission}
              closeOnActionDone={closeOnActionDone}
              onClose={onClose}
            />
          ))}
        </ConsentActionWrapper>
      </Wrapper>
    </Modal>
  );
};
