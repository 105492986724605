import { SubstitutionType } from "typescript";

export enum PaymentRequestStatus {
  Idle = "Idle",
  Pending = "Pending",
  Successful = "Successful",
}

export type PaymentCard = {
  id: string;
  brand: string;
  last4: string;
  exp_month?: number;
  exp_year?: number;
  country?: string;
};

export type PaymentMethod = {
  id: string;
  billing_details: {
    name: string;
    email?: string;
  };
  card: PaymentCard;
};

type EstimatedLearnerPrice = {
  username: string;
  subscriptionType: SubstitutionType;
  learnerName: string;
  price: {
    total: string;
    actualPrice: string;
    discount: string;
    discountPercentage: string;
  };
};

export type PaymentStoreState = {
  methods?: PaymentMethod[];
  paymentMethodsAttemptStatus?: PaymentRequestStatus;
  removePaymentMethodAttemptStatus?: PaymentRequestStatus;
  checkoutAttemptStatus?: PaymentRequestStatus;
  checkoutRenewalAttemptStatus?: PaymentRequestStatus;
  createSubscriptionStatus?: PaymentRequestStatus;
  renewSubscriptionStatus?: PaymentRequestStatus;
  error?: string | null;
  estimatedBilling: {
    data: {
      learners: EstimatedLearnerPrice[];
      totalPrice?: string;
      totalDiscount?: string;
      toPay?: string;
      toPayAfterCodeExpires?: string;
      hasCodeDiscount?: boolean;
      currency?: string;
      currencySymbol?: string;
      extendForDays?: number;
    };
    error: any;
  };
};

export type SetCheckoutAttemptStatusAction = PaymentRequestStatus;
export type SetPaymentErrorAction = string | null;
export type SetCheckoutRenewalAttemptStatusAction = PaymentRequestStatus;
export type RemovePaymentMethodAttemptStatus = PaymentRequestStatus;
export type SetPaymentMethodAttemptStatus = PaymentRequestStatus;
export type SetPaymentMethodAction = PaymentMethod[];
export type SetEstimateBillingAction = any;
export type SetEstimateBillingErrorAction = any;
