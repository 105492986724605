import { createSlice } from "@reduxjs/toolkit";
import { SliceNames } from "../enums/SliceNames";
import { Activity } from "../types/activity";

const initialState: Activity[] = [];

export const activitySlice = createSlice({
  name: SliceNames.ActivitySlice,
  initialState,
  reducers: {
    setActivity: (state, { payload }) => {
      state.splice(0, state.length, ...payload);
    },
  },
});

export const { setActivity } = activitySlice.actions;

export default activitySlice.reducer;
