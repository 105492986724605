import tw, { styled } from "twin.macro";

export const MonitorProgressWrapper = styled.div(
  () => tw`w-full h-full flex flex-col pb-8`
);

export const DescriptionWrapper = styled.div(
  () => tw`w-full text-center md:text-left md:pl-4 h-10`
);

export const StyledLink = styled.span(
  () =>
    tw`mb-8 font-semibold underline font-semibold text-dark-blue font-sans cursor-pointer`
);

export const ErrorWrapper = styled.div(() => tw`bg-white p-4`);
