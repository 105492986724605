import {
  StarIcon,
  StyledRow,
  TData,
} from "../../../common/components/Tournament/styled";
import dayjs from "../../../common/config/dayjs";
import { SponsorTournamentView } from "../../../store/types/tournament";
import { useTranslation } from "react-i18next";
import starIcon from "../../../assets/common/Lesson-Page/Star.svg";
import { TournamentTableItemComponent } from "../../../common/components/Tournament/TournamentTableItemComponent";
import { TableItemComponent } from "../../../common/components/Tournament/TableItemComponent";
import { Fragment } from "react";

export const LearnerUpcomingTournamentItem = ({
  endsAt,
  pointsRequired,
  points,
  name,
}: SponsorTournamentView) => {
  const { t } = useTranslation();

  return (
    <StyledRow>
      <TData>
        <TournamentTableItemComponent name={name} />
      </TData>
      <TData>
        <TableItemComponent
          label={t("tournaments.pastTournamentDetails.startsAt")}
          value={dayjs(endsAt).format("DD MMM YYYY/ ha")}
        />
      </TData>
      <TData>
        <TableItemComponent
          label={t("tournaments.pastTournamentDetails.pointsRequired")}
          value={
            <Fragment>
              <StarIcon src={starIcon} />
              {pointsRequired}
            </Fragment>
          }
        />
      </TData>
      <TData>
        <TableItemComponent
          label={t("tournaments.pastTournamentDetails.rewards")}
          value={
            <Fragment>
              <StarIcon src={starIcon} /> {points}
            </Fragment>
          }
        />
      </TData>
    </StyledRow>
  );
};
