import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Subject } from "./Subject/Subject";
import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { StoreState } from "../../../store/type";
import { fetchSubjects } from "../../../store/thunks/Learner/fetchSubjects";

import { GridContainerStyled, SpinnerDiv } from "./LearnerProgressStyled";
import { SectionWrapper, SectionTitle } from "../LearnerHomeStyled";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { Spinner } from "../../../common/components/Spinner/Spinner";
import { StyledError } from "../../../common/components/Error/Error";

import { useTranslation } from "react-i18next";

export const LearnerProgress = ({
  viewLessons,
  setSubjectCode,
}: {
  viewLessons: (isOpen: boolean) => void;
  setSubjectCode: (subjectCode: string) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { error, requestInProgress } = useAsyncState(
    AsyncActions.FetchSubjectsAction
  );

  const { subjects } = useSelector((state: StoreState) => state.subjects);
  const { pointsBySubject, tournamentPointsBySubject } = useSelector(
    (state: StoreState) => state.user.info
  );

  useEffect(() => {
    dispatch(fetchSubjects());
  }, []);

  return (
    <SectionWrapper>
      <SectionTitle>{t("learnerHome.ProgressHeading")}</SectionTitle>
      {requestInProgress ? (
        <SpinnerDiv>
          <Spinner size="sm" />
        </SpinnerDiv>
      ) : error ? (
        <StyledError topMargin="auto" bottomMargin="auto">
          {error}
        </StyledError>
      ) : (
        <GridContainerStyled>
          {subjects.map((subject) => {
            return (
              <Subject
                viewLessons={viewLessons}
                setSubjectCode={setSubjectCode}
                key={subject.id}
                completed={subject.completedLessonsCount}
                total={subject.lessonCount}
                name={subject.name}
                iconPath={subject.file?.path}
                code={subject.code}
                points={pointsBySubject[subject.code || ""] || 0}
                tournamentPoints={
                  tournamentPointsBySubject[subject.code || ""] || 0
                }
              />
            );
          })}
        </GridContainerStyled>
      )}
    </SectionWrapper>
  );
};
