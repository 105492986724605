import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import i18n from "../../../i18n";
import { AsyncActions } from "../../enums/AsyncActions";
import { setBusyAction, setErrorAction } from "../../slices/async";
import {
  setAllSubjects,
  setFavoriteSubject,
} from "../../slices/favoriteSubjects";
import { ReduxThunk } from "../../type";

export const fetchAllSubjects: () => ReduxThunk = () => {
  return async (dispatch, _, { client }) => {
    try {
      dispatch(
        setBusyAction({ key: AsyncActions.FetchAvailableSubjects, value: true })
      );
      const response = await client.get(ApiRoutes.FetchFavoriteSubjects);
      dispatch(setAllSubjects(response.data));
    } catch (err: any) {
      dispatch(
        setErrorAction({
          key: AsyncActions.FetchAvailableSubjects,
          value: i18n.t("profileSetup.chooseSubjects.errors.fetch"),
        })
      );
    }
    dispatch(
      setBusyAction({ key: AsyncActions.FetchAvailableSubjects, value: false })
    );
  };
};

export const fetchFavoriteSubjects: () => ReduxThunk = () => {
  return async (dispatch, _, { client }) => {
    try {
      dispatch(
        setBusyAction({ key: AsyncActions.FetchFavoriteSubjects, value: true })
      );
      const response = await client.get(ApiRoutes.FavoriteSubjects);
      dispatch(setFavoriteSubject(response.data));
    } catch (err: any) {
      dispatch(
        setErrorAction({
          key: AsyncActions.FetchAvailableSubjects,
          value: i18n.t("profileSetup.chooseSubjects.errors.fetch"),
        })
      );
    }
    dispatch(
      setBusyAction({ key: AsyncActions.FetchFavoriteSubjects, value: false })
    );
  };
};
