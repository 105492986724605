import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TournamentModal } from "./TournamentModal";
import { ActionButton } from "./styled";
import { useUserHomeRoute } from "../../../common/hooks/useUserHomeRoute";
import gif from "../../../assets/gifs/Cancel-Tournament.gif";
import { useTournamentsSchedule } from "../../../common/components/Tournament/useTournamentsSchedule";
import dayjs from "../../../common/config/dayjs";
import { StoreState } from "../../../store/type";

type Props = {
  endsAt?: string;
};

export const TournamentExpiredModal = ({ endsAt }: Props) => {
  const navigate = useNavigate();
  const home = useUserHomeRoute();
  const { t } = useTranslation();
  const { result } = useSelector((state: StoreState) => state.survey);
  const { timeToTournamentStart } = useTournamentsSchedule(
    endsAt || dayjs().format(),
    "finished"
  );

  const goHome = () => navigate(home);

  const descriptions = [
    { text: t("tournaments.modal.tournamentEnded.notFinished") },
    { text: t("tournaments.modal.tournamentEnded.beFaster") },
  ];

  const showModal = timeToTournamentStart === "finished" && !result;

  return showModal ? (
    <TournamentModal
      showClose={false}
      title={t("tournaments.modal.tournamentEnded.title")}
      descriptions={descriptions}
      icon={gif}
    >
      <ActionButton onClick={() => goHome()}>
        {t("tournaments.modal.tournamentEnded.label")}
      </ActionButton>
    </TournamentModal>
  ) : null;
};
