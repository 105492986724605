import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAsyncState } from "../../common/hooks/useAsyncState";
import { useUserHomeRoute } from "../../common/hooks/useUserHomeRoute";
import Register from "../../components/Register/Register";
import { AsyncActions } from "../../store/enums/AsyncActions";
import { redirect } from "../../store/slices/router";
import { StoreState } from "../../store/type";

const RegisterPage = () => {
  const dispatch = useDispatch();
  const route = useUserHomeRoute();
  const isLoggedIn = useSelector((state: StoreState) => state.user.isLoggedIn);
  const subscriptions = useSelector((state: StoreState) => state.subscriptions);
  const { notification: payByCodeNotification } = useAsyncState(
    AsyncActions.PayByCodeSuccessful
  );
  const { notification: payByCardNotification } = useAsyncState(
    AsyncActions.PayByCardSuccessful
  );

  const { notification: renewByCardNotification } = useAsyncState(
    AsyncActions.RenewByCardSuccessful
  );

  useEffect(() => {
    if (
      isLoggedIn &&
      !payByCardNotification &&
      !payByCodeNotification &&
      !renewByCardNotification &&
      subscriptions &&
      subscriptions.length > 0
    ) {
      dispatch(redirect({ to: route }));
    }
  }, [
    payByCodeNotification,
    payByCardNotification,
    renewByCardNotification,
    subscriptions,
    isLoggedIn,
  ]);

  return <Register />;
};

export default RegisterPage;
