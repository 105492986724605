import React from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Label, Wrapper } from "./RememberUserInputStyled";

type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
  inputId: string;
};

export const RememberUserInput = ({ value, onChange, inputId }: Props) => {
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  return (
    <Wrapper>
      <Checkbox
        id={inputId}
        type="checkbox"
        checked={value}
        onChange={handleChange}
      />
      <Label htmlFor={inputId}>{t("inputs.remember")}</Label>
    </Wrapper>
  );
};
