import { useEffect, useState } from "react";
import { Portal } from "react-portal";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { all, any, append, propEq, remove } from "ramda";
import { PopupWrapperStyled } from "../../../common/components/Modal/ModalStyled";
import {
  AvatarIconWrapper,
  AvatarWrapper,
  CloseIcon,
  Ellipse,
  ModalContentWrapper,
  ShopItemsTitle,
  ShopItemsWrapper,
  TabContentWrapper,
  TabPane,
  Tabs,
  TabsWrapper,
  TitleWrapper,
} from "./styled";
import closeIcon from "../../../assets/common/close.svg";
import { itemCategories } from "./shared";
import { ShopTabsComponent } from "./ShopTabs";
import { TabContent } from "./TabContent";
import { AvailablePoints, ShopItemAction } from "./ShopItemAction";
import { Avatar } from "../../../common/components/Avatar/Avatar";

import { StoreState } from "../../../store/type";
import { AvatarItem, AvatarItemKind } from "../../../store/types/avatar-items";
import { ItemStatus } from "../../../common/enums/ItemStatus";
import { updateAvatarItems } from "../../../store/thunks/Learner/updateAvatarItems";
import { LearnerItem } from "../../../store/types/user";
import { ShopTab } from "../../../common/types/ShopTabs";

type Props = {
  onClose: () => void;
  activeTab: ShopTab;
};

export const ShopItemsModal = ({ onClose, activeTab: incoming }: Props) => {
  const [activeTab, setActiveTab] = useState<ShopTab>(incoming);
  const {
    items: owned,
    avatar,
    availablePoints,
  } = useSelector((state: StoreState) => state.user.info);
  const [selectedItem, setSelectedItem] = useState<AvatarItem | null>(null);
  const [itemsOnAvatar, setItemsOnAvatar] = useState<AvatarItem[]>([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const items = useSelector((state: StoreState) => state.avatarItems);

  const userOwnedItem =
    !!selectedItem && owned.find((i) => i.avatarItem.id === selectedItem?.id);

  const handleSelect = (item: AvatarItem) => () => {
    setSelectedItem(item);
    setItemsOnAvatar((pre) => {
      const itemIndex = pre.findIndex((pre) => pre.id === item.id);
      if (itemIndex > -1) {
        if (item.kind === AvatarItemKind.Eyes) return pre;
        return remove(itemIndex, 1, pre);
      }
      const itemWithSameKindIndex = pre.findIndex(
        (pre) => pre.kind === item.kind
      );
      if (itemWithSameKindIndex > -1) {
        return append(item, remove(itemWithSameKindIndex, 1, pre));
      }
      return append(item, pre);
    });
  };

  const isItemActive = (itemId: string) => {
    return any(propEq("id", itemId), itemsOnAvatar);
  };

  const handleTabChange = (tab: ShopTab) => {
    setActiveTab(tab);
    setSelectedItem(null);
  };

  useEffect(() => {
    setItemsOnAvatar((pre) => {
      if (pre.length > 0) return pre;
      return owned
        .filter((i) => i.status === ItemStatus.Active)
        .map((i) => i.avatarItem);
    });
  }, [owned]);

  const ids = itemsOnAvatar.map(({ id }) => id);
  const itemsToUpdate: LearnerItem[] = [];

  owned.forEach((i) => {
    if (i.status === ItemStatus.Inactive && ids.includes(i.avatarItem.id)) {
      itemsToUpdate.push({ ...i, status: ItemStatus.Active });
    } else if (
      i.status === ItemStatus.Active &&
      !ids.includes(i.avatarItem.id)
    ) {
      itemsToUpdate.push({ ...i, status: ItemStatus.Inactive });
    }
  });

  const allItemsOwned = all(
    (id: string) => !!owned.find((i) => i.avatarItem.id === id),
    ids
  );

  const handleUpdateAvatar = () => {
    if (itemsToUpdate.length === 0) return;
    dispatch(updateAvatarItems(itemsToUpdate));
  };

  return (
    <Portal>
      <PopupWrapperStyled>
        <ModalContentWrapper>
          <AvatarWrapper>
            <AvatarIconWrapper>
              <Avatar items={itemsOnAvatar} icon={avatar?.image} />
            </AvatarIconWrapper>
            <Ellipse />
            <CloseIcon hideOn={"big"} onClick={onClose} src={closeIcon} />
          </AvatarWrapper>
          <ShopItemsWrapper>
            <TitleWrapper>
              <ShopItemsTitle>{t("shop.modalTitle")}</ShopItemsTitle>
              <CloseIcon hideOn={"small"} onClick={onClose} src={closeIcon} />
              <AvailablePoints points={availablePoints} showOnSmall />
            </TitleWrapper>
            <TabsWrapper>
              <Tabs
                activeKey={activeTab}
                onChange={(key) => setActiveTab(key as ShopTab)}
                renderTabBar={() => (
                  <ShopTabsComponent
                    activeKey={activeTab}
                    onTabSelect={handleTabChange}
                  />
                )}
              >
                {itemCategories.map(({ label }) => (
                  <TabPane tab={label} key={label}>
                    <TabContentWrapper>
                      <TabContent
                        handleItemSelect={handleSelect}
                        subCategory={items[label]}
                        selectedItemId={selectedItem?.id}
                        isActive={isItemActive}
                      />
                    </TabContentWrapper>
                    <ShopItemAction
                      selectedItem={selectedItem}
                      ownedItem={userOwnedItem}
                      itemPrice={selectedItem?.points || 0}
                      onUpdateClick={handleUpdateAvatar}
                      isUpdateAvailable={allItemsOwned}
                    />
                  </TabPane>
                ))}
              </Tabs>
            </TabsWrapper>
          </ShopItemsWrapper>
        </ModalContentWrapper>
      </PopupWrapperStyled>
    </Portal>
  );
};
