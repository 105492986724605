import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { StoreState } from "../../../../store/type";

import * as LessonInfoStyled from "./LessonInfoStyled";
import { env } from "../../../../common/config/env";
import dayjs from "../../../../common/config/dayjs";

export const LessonInfo = () => {
  const { t } = useTranslation();

  const subject = useSelector(
    (state: StoreState) => state.subjects.lesson.subject?.name
  );

  const { description: lessonDescription, complianceCheck } = useSelector(
    (state: StoreState) => state.subjects.lesson
  );
  const teacherName = useSelector(
    (state: StoreState) => state.subjects.lesson.author?.name
  );

  const teacherPhoto = useSelector(
    (state: StoreState) => state.subjects.lesson.author?.file?.path
  );

  return (
    <LessonInfoStyled.WrapperDiv>
      <LessonInfoStyled.LessonInfoHeading>
        {t("lessonPage.lessonDescription")}
      </LessonInfoStyled.LessonInfoHeading>
      <LessonInfoStyled.LessonInfo
        dangerouslySetInnerHTML={{ __html: lessonDescription }}
      ></LessonInfoStyled.LessonInfo>
      <LessonInfoStyled.TeacherDiv>
        {teacherPhoto && <LessonInfoStyled.TeacherAvatar src={teacherPhoto} />}
        <LessonInfoStyled.TeacherInfoDiv>
          <LessonInfoStyled.TeacherName>
            {teacherName}
          </LessonInfoStyled.TeacherName>
          <LessonInfoStyled.TeacherInfo>
            {`${subject} ${t("lessonPage.teacherSpan")}`}
          </LessonInfoStyled.TeacherInfo>
        </LessonInfoStyled.TeacherInfoDiv>
        <LessonInfoStyled.BookSessionLink
          href={`mailto:${env.contactEmail}?subject=${t(
            "lessonPage.sessionBtn"
          )}&body=${t("lessonPage.bookSessionBody")}`}
          target="_blank"
          rel="noreferrer"
        >
          <LessonInfoStyled.BookSessionBtn>
            {t("lessonPage.sessionBtn")}
          </LessonInfoStyled.BookSessionBtn>
        </LessonInfoStyled.BookSessionLink>
      </LessonInfoStyled.TeacherDiv>
      <LessonInfoStyled.Disclaimer>
        {t("lessonPage.disclaimer", {
          date: dayjs(complianceCheck).format("DD.MM.YYYY"),
        })}
      </LessonInfoStyled.Disclaimer>
    </LessonInfoStyled.WrapperDiv>
  );
};
