import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SliceNames } from "../enums/SliceNames";
import { LearnerProgress } from "../types/learnerProgress";

export const initialState = [] as LearnerProgress[];

const learnerProgress = createSlice({
  name: SliceNames.LearnerProgress,
  initialState,
  reducers: {
    addLearnersProgress: (
      state,
      { payload }: PayloadAction<LearnerProgress[]>
    ) => {
      state.splice(0, state.length, ...payload);
    },
  },
});

export const { addLearnersProgress } = learnerProgress.actions;

export default learnerProgress.reducer;
