import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { store } from "./store/store";
import "./i18n";
import { Spinner } from "./common/components/Spinner/Spinner";
import { ErrorBoundary } from "./views/pages/ErrorPage";

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <I18nextProvider i18n={i18n}>
        <Suspense fallback={<Spinner blur fullscreen={true} />}>
          <Provider store={store}>
            <App />
          </Provider>
        </Suspense>
      </I18nextProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
