import { memo } from "react";
import { Navigate } from "react-router-dom";
import LoginComponent from "../../components/Login/Login";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/type";
import { RoutePath } from "../../common/consts/route-path";
import { env } from "../../common/config/env";
import { UserRole } from "../../common/enums/UserRole";

const LoginPage = () => {
  const {
    isLoggedIn,
    info: { role },
  } = useSelector((state: StoreState) => state.user);

  if (isLoggedIn) {
    return (
      <Navigate
        to={
          role === UserRole.Sponsor
            ? RoutePath.parentDashboard.locale[env.locale]
            : RoutePath.home.locale[env.locale]
        }
        replace
      />
    );
  }

  return <LoginComponent />;
};

export default memo(LoginPage);
