import React from "react";

import backArrow from "../../../assets/common/back-arrow.svg";
import avatarLg from "../../../assets/common/avatar-lg.svg";
import { Avatar, BackBtn, Username, Wrapper } from "./LoginAvatarStyled";

type Props = {
  onTabChange: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  username: string;
};

export const LoginAvatar = ({ username, onTabChange }: Props) => {
  return (
    <>
      <Wrapper>
        <BackBtn
          data-parent-tab="false"
          src={backArrow}
          onClick={onTabChange}
        />
      </Wrapper>
      <Avatar src={avatarLg} />
      <Username>{username}</Username>
    </>
  );
};
