import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "../../../store/type";
import { fetchSchools } from "../../../store/thunks/fetchSchools";
import { isEmpty } from "ramda";
import { SelectComponent } from "../../../common/components/Select/Select";
import { useTranslation } from "react-i18next";

type SelectProps = {
  styles: object;
  placeholder: string;
  options?: object[];
  components?: object;
};
const selectSchoolValue = (options: any, value: string) => {
  return options.find(
    (option: { name: string; label: string }) => option.name === value
  );
};
type Props = {
  value: string;
  previousValue: string | null;
  onChange: (val: any) => any;
  onBlur: () => void;
} & SelectProps;

type DefaultValues = {
  defaultValue: {
    value: string;
    label: string;
  };
};
export const SelectSchool = ({
  onChange,
  value,
  previousValue,
  ...rest
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const schools = useSelector(
    (state: StoreState) => state.schoolsState.schools
  );

  const dispatchFetchSchools = (name: string) => {
    if (isEmpty(name)) return;
    dispatch(fetchSchools(name));
  };

  const defaultOption = schools.find(({ id }) => id === previousValue);
  let defaultValueProp: DefaultValues | { disabled: boolean } = {
    defaultValue: {
      value: "",
      label: "",
    },
  };

  if (defaultOption) {
    defaultValueProp.defaultValue.value = defaultOption.id;
    defaultValueProp.defaultValue.label = defaultOption.name;
  } else if (!defaultOption) {
    defaultValueProp = { disabled: true };
  }

  return (
    <SelectComponent
      {...rest}
      onChange={onChange}
      value={
        selectSchoolValue(schools, value) &&
        selectSchoolValue(schools, value).value
      }
      {...defaultValueProp}
      options={schools.map(({ name, id }) => ({ value: id, label: name }))}
      fetchOptions={dispatchFetchSchools}
      fetchOnEmpty={false}
      backspaceRemovesValue
      isClearable
      noOptionsMessage={({ inputValue }: { inputValue: string }) => {
        return inputValue.length === 0
          ? t("register.addLearners.inputs.school.findSchool")
          : t("register.addLearners.inputs.school.noOptions");
      }}
    />
  );
};
