import React, { useState, MouseEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Tabs, { TabPane } from "rc-tabs";

import Card from "../../common/components/Card/Card";

import photo from "../../assets/common/ransom-note-letters/photo.svg";

import Student from "./Student/Student";
import Parent from "./Parent/Parent";
import RememberedStudentTab from "./Student/RememberedStudentTab";
import RememberedParentTab from "./Parent/RememberedParentTab";

import { useTranslation } from "react-i18next";
import { RoutePath } from "../../common/consts/route-path";
import { env } from "../../common/config/env";
import {
  CardWrapper,
  InnerWrapper,
  Photo,
  RegisterNowSpan,
  RenderTabSpan,
  RenderTabWrapper,
  Wrapper,
  YunoInfoDescription,
  YunoInfoHeading,
  YunoInfoInnerWrapper,
  YunoInfoWrapper,
} from "./LoginStyled";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(t("login.tabs.student"));
  const [storedStudentTab, setStoredStudentTab] = useState(false);
  const [storedParentTab, setStoredParentTab] = useState(false);
  const [query] = useSearchParams();
  const showParentLogin = query.get("showParent");

  const onStudentTabChange = (
    e: React.MouseEvent<HTMLImageElement> | React.MouseEvent<HTMLDivElement>
  ) => {
    const target: any = e.target;
    if (target.dataset.parentTab === "false") {
      return setStoredStudentTab(false);
    }
    return setStoredStudentTab(true);
  };

  const onRegisterNowClick = () => {
    navigate(RoutePath.register.locale[env.locale]);
  };
  const onParentTabChange = (
    e: React.MouseEvent<HTMLImageElement> | React.MouseEvent<HTMLDivElement>
  ) => {
    const target: any = e.target;

    if (target.dataset.parentTab === "false") {
      return setStoredParentTab(false);
    }
    return setStoredParentTab(true);
  };

  const onTabChange = (e: MouseEvent) => {
    const target: any = e.target;

    setActiveTab(target.dataset.tab);
  };

  useEffect(() => {
    if (showParentLogin) {
      setActiveTab(t("login.tabs.parent"));
    }
  }, [showParentLogin, t]);

  const renderTabBar = (props: any) => {
    return (
      <RenderTabWrapper>
        {props.panes.map((pane: any) => {
          const { key } = pane;
          return (
            <RenderTabSpan
              active={props.activeKey === pane.tab}
              onClick={(e) => {
                onTabChange(e);
              }}
              data-tab={pane.tab}
              key={key}
            >
              {key}
            </RenderTabSpan>
          );
        })}
      </RenderTabWrapper>
    );
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <CardWrapper className="">
          <Card cardWidth="28.125rem" paddingX="2.5rem" paddingY="1.324rem">
            <Tabs
              defaultActiveKey={t("login.tabs.student")}
              activeKey={activeTab}
              renderTabBar={() =>
                renderTabBar({
                  panes: [
                    {
                      tab: t("login.tabs.student"),
                      key: t("login.tabs.student"),
                    },
                    {
                      tab: t("login.tabs.parent"),
                      key: t("login.tabs.parent"),
                    },
                  ],
                  activeKey: activeTab,
                })
              }
            >
              <TabPane
                tab={t("login.tabs.student")}
                key={t("login.tabs.student") as string}
              >
                {storedStudentTab ? (
                  <RememberedStudentTab
                    onStudentTabChange={onStudentTabChange}
                  />
                ) : (
                  <Student onStudentTabChange={onStudentTabChange} />
                )}
              </TabPane>

              <TabPane
                tab={t("login.tabs.parent")}
                key={t("login.tabs.parent") as string}
              >
                {storedParentTab ? (
                  <RememberedParentTab onParentTabChange={onParentTabChange} />
                ) : (
                  <Parent onParentTabChange={onParentTabChange} />
                )}
              </TabPane>
            </Tabs>
          </Card>
        </CardWrapper>
        <YunoInfoWrapper>
          <YunoInfoInnerWrapper>
            <YunoInfoHeading>{t("login.info.title")}</YunoInfoHeading>
            <YunoInfoDescription>
              {t("login.info.description")}
            </YunoInfoDescription>
            <RegisterNowSpan onClick={onRegisterNowClick}>
              {t("login.info.link")}
            </RegisterNowSpan>
            <Photo src={photo} />
          </YunoInfoInnerWrapper>
        </YunoInfoWrapper>
      </InnerWrapper>
    </Wrapper>
  );
};

export default Login;
